import { Component, Input, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Highcharts from 'highcharts';
import more from 'highcharts/highcharts-more';
more(Highcharts);
import { PricingService } from '../../pricing.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PricingModelInterpretabilityService} from '../pricing-model-interpretability.service';
import { runInThisContext } from 'vm';
declare var require: any;
const More = require('highcharts/highcharts-more');
More(Highcharts);

const Exporting = require('highcharts/modules/exporting');
Exporting(Highcharts);

const ExportData = require('highcharts/modules/export-data');
ExportData(Highcharts);

const Accessibility = require('highcharts/modules/accessibility');
Accessibility(Highcharts);

@Component({
  selector: 'app-pricing-model-interpretability-pdp',
  templateUrl: './pricing-model-interpretability-pdp.component.html',
  styleUrls: ['./pricing-model-interpretability-pdp.component.scss']
})
export class PricingModelInterpretabilityPdpComponent implements OnInit {
  pdpChartFilters: Array<any> = [];
  public lobProjectId: any;
  public viewName = 'pdp'
  localStorage = localStorage;
  public exploratoryVariable = "1";
  public modelType = "1";
  public modelTechnique= "1,2";
  public filterForExpoDataChart: any;
  public modelTypeFilters = [1, 2];
  public exploratoryVariablesFilters = [1, 2];
  public selectedExploratoryVariable = "";
  
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions : any;
  chartModelData: any;
  closeResult: string;
  updateFlag: boolean = false;
  modelTechniqueCheckbox = [1,2];
  
  constructor(private modalService: NgbModal, private pricingModelInterpretabilityService: PricingModelInterpretabilityService,
              private pricingService: PricingService, private toastr: ToastrService,
              private ngxLoader: NgxUiLoaderService) { }

  ngOnInit(): void {
    Promise.all([this.localStorage.getItem('lobProjectId')]).then(values => {
      this.lobProjectId = values[0];
      this.getFilterData(this.lobProjectId);
      this.getPdpChartData( this.lobProjectId, this.modelType, this.modelTechnique, this.exploratoryVariable);
    })
  }

  // Updating chart data on filter change
  updateFilterStatus(recentFilterChange,i,filterType) {
    if (filterType === 'modelTechniques') {
      this.pdpChartFilters[this.pdpChartFilters.findIndex((obj => obj.filterType === 'modelTechniques'))].data.forEach(e => { if (recentFilterChange.id === e.id) { e.isSelected = recentFilterChange.isSelected } });
      if (this.modelTechniqueCheckbox.includes(recentFilterChange.id) && !recentFilterChange.isSelected) {
        this.modelTechniqueCheckbox = this.modelTechniqueCheckbox.filter(e => e !== recentFilterChange.id);
      }
      else if (!this.modelTechniqueCheckbox.includes(recentFilterChange.id) && recentFilterChange.isSelected) {
        this.modelTechniqueCheckbox.push(recentFilterChange.id);
        this.modelTechniqueCheckbox = this.modelTechniqueCheckbox.sort(function (a, b) { return a - b; });
      }
      if(!this.modelTechniqueCheckbox.length) {
        this.toastr.error('Select at least one Model Technique');
        this.chartOptions = [];
      } else {
        this.modelTechnique = this.modelTechniqueCheckbox.join(',');
        this.getPdpChartData( this.lobProjectId, this.modelType, this.modelTechnique, this.exploratoryVariable);
      }
    } else if (filterType === 'modelTypes') {
      if(!this.modelTechniqueCheckbox.length) {
        this.toastr.error('Select at least one Model Technique');
        this.chartOptions = [];
      } else {
        this.modelType = recentFilterChange;
        this.getPdpChartData( this.lobProjectId, this.modelType, this.modelTechnique, this.exploratoryVariable);
      }
    } else if (filterType === 'exploratoryVariables') {
      if(!this.modelTechniqueCheckbox.length) {
        this.toastr.error('Select at least one Exploratory Variable');
        this.chartOptions = [];
        this.selectedExploratoryVariable = '';
      } else {
        this.exploratoryVariable = recentFilterChange;
        this.getSelectedExploratoryVariable(Number(recentFilterChange));
        this.getPdpChartData( this.lobProjectId, this.modelType, this.modelTechnique, this.exploratoryVariable);
      }
    }
  }
  // Getting selected exploratiry Variable 
  getSelectedExploratoryVariable(recentFilterChange) {
    this.selectedExploratoryVariable = this.pdpChartFilters.filter(e => e.filterType === 'exploratoryVariables')[0].data.filter(x => x.id === recentFilterChange)[0].name.replace(/(^|_)(\w)/g, function ($0, $1, $2) {
      return ($1 && ' ') + $2.toUpperCase()
    });
  }

  // Pdp Chart API call and chart option construction.
  getPdpChartData( lobProjectId, modelType, modelTechnique, exploratoryVariable) {
    this.ngxLoader.start();
    this.chartOptions = [];
    // Getting chart template for PDP
    this.pricingModelInterpretabilityService.getPDPChartTemplateJSON().subscribe(chartTemplate => {
      // PDP chart API call
      this.pricingModelInterpretabilityService.getPdpChartData( lobProjectId, modelType, modelTechnique, exploratoryVariable).subscribe(pdpResponse => {
        if(pdpResponse) {
          //  Format chart API data to CHART OPTION using chart template 
          this.pricingModelInterpretabilityService.formatPdpData(pdpResponse.data,chartTemplate).subscribe(chartData => {
            if(chartData) {
              this.chartOptions = chartData;
              this.ngxLoader.stop();
            }
          })
        } else {this.ngxLoader.stop();}
      })
    })
  }

  // Getting PDP filter data and construct pdp chart filter panel data
  getFilterData(lobProjectId) {
    this.pricingModelInterpretabilityService.getPDPFilterData(lobProjectId).subscribe(responseData => {
      const finalResponse = [] as any;
      responseData.forEach((element, index) => {
        const formattedResponse = {
          ...element,
          title: index === 0 ? "Model Techniques": index === 1 ? "Model Types" : "Exploratory Variables",
          filterType: index === 0 ? "modelTechniques": index === 1 ? "modelTypes" : "exploratoryVariables",
          data: index === 0 ? element.data.map((el, i) => {
            return {
              ...el,
              isSelected: true
            }
          }): element.data.map((el, i) => {
            return {
              ...el,
              isSelected: i === 0 ? true : false
            }
          })
        }
        finalResponse.push(formattedResponse);
      });
     this.pdpChartFilters = finalResponse;
    this.getSelectedExploratoryVariable(1);
    });
  }
 
  filterUser(item) {
    return !!item.displayChart;
  }  

  // Expand chart to large view
  openXl(content, data) {
    this.chartModelData = data;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
