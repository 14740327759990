<div class="module-nav mt-2 fix-height">
    <ul class="nav nav-tabs mt-0" id="pricingTabs" role="tablist">
        <li class="nav-item">
            <a class="nav-link active sub-module" id="overview-tab" data-toggle="tab" href="#overview" role="tab"
                aria-controls="pdp" aria-selected="false">Overview </a>
        </li>
        <li class="nav-item">
            <a class="nav-link sub-module" id="comparison-tab" data-toggle="tab" href="#comparison" role="tab"
                aria-controls="shap" aria-selected="false">Comparison</a>
        </li>
    </ul>
 
    <div class="tab-content bg-white" id="pricingTabsContent">
        <!-- vbb {{overviewGlmListData.param|json}} -->
        <div class="p-2">
            <nav aria-label="breadcrumb ">
                <ol class="breadcrumb mb-2">
                    <li class="breadcrumb-item" *ngFor="let item of breadcrumb; last as isLast">{{item.name}} 
                           </li>
                </ol>
            </nav>
        </div>
        <div class="tab-pane fade show active pdp-tab-content" id="overview" role="tabpanel"
            aria-labelledby="overview-tab">
            <div class="pl-2 pb-2 pr-2 pt-0">
            <app-pricing-model-analysis-overview [chartFilters]="chartFilters" ></app-pricing-model-analysis-overview>
                
            </div>
        </div>
        <div class="tab-pane fade shap-tab-content" id="comparison" role="tabpanel" aria-labelledby="comparison-tab">
            <div class="p-2">
<app-pricing-model-analysis-comparision [chartFilters]="chartFilters"></app-pricing-model-analysis-comparision>
            </div>
        </div>
    </div>
</div>