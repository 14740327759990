import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { DemandForecastService } from './demand-forecast.service';
import { interval, Subject, Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { DemandforecastingService } from '../demandforecasting.service';

@Component({
  selector: 'app-demand-forecast',
  templateUrl: './demand-forecast.component.html',
  styleUrls: ['./demand-forecast.component.scss']
})
export class DemandForecastComponent implements OnInit {

  productFamilies: Array<any> = [];
  selectedProductFamily: any;
  stockKeepingUnits: any;
  timeInterval: Subscription;
  status: any;
  selectedTab: string = 'demandPlanningDashboard';
  previousTab: string = 'demandPlanningDashboard';
  selectedSKU: any;
  performanceData: Array<any> = [];
  localStorage = localStorage;
  productFamilyValue: any;
  oldProductFamilyValue: any;
  unsubscribe$: Subject<boolean> = new Subject();
  constructor(public demandForecastService: DemandForecastService, private loader: NgxUiLoaderService,
    public demandforecastingService: DemandforecastingService, private notificationService: NotificationService,
    private confirmationPopupService: ConfirmationPopupService) { }

  ngOnInit(): void {
    this.getProductFamilyList();
    this.getNotificationData();
  }

  selectTab(selectedTab, event) {
    Promise.all([this.localStorage.getItem('hasPendingSave')]).then(values => {
      const hasPendingSave = values[0];
      if (this.selectedTab === selectedTab) {
        return;
      }
      if (hasPendingSave === 'NO' || hasPendingSave === null) {
        this.selectedTab = selectedTab;
        this.previousTab = selectedTab;
        this.getStockKeepingUnits(this.selectedProductFamily)
      } else if (hasPendingSave === 'YES') {
        this.confirmationPopupService.confirm('Confirm', 'Do you want to leave the section without saving your changes?', '', 'Yes', 'Cancel')
          .then((confirmed) => {
            if (confirmed) {
              this.selectedTab = selectedTab;
              this.previousTab = selectedTab;
              this.getStockKeepingUnits(this.selectedProductFamily);
              this.demandforecastingService.setReloadObject(true);
              this.localStorage.setItem('hasPendingSave', 'NO');
            } else if (!confirmed) {
              this.selectedTab = this.previousTab;
              event.stopPropagation();
            }
          });
      } else {
        this.selectedTab = selectedTab;
        this.previousTab = selectedTab;
        this.getStockKeepingUnits(this.selectedProductFamily)
      }
    });
  }

  getProductFamilyList() {
    this.selectedProductFamily = '';
    this.selectedSKU = '';
    this.demandForecastService.getproductFamily().subscribe((productfamilyResponse) => {
      if (productfamilyResponse && productfamilyResponse.code === 200) {
        this.productFamilies = productfamilyResponse.data;
        this.productFamilyValue = this.oldProductFamilyValue = this.productFamilies[0].id;
        this.selectedProductFamily = productfamilyResponse.data[0].id;
        this.onProductFamilyChange(this.productFamilies[0].id);
      } else { this.loader.stop() }
    })
  }

  changeProductFamily(familyId) {
    this.selectedProductFamily = familyId;
    this.demandforecastingService.setSelectedSKU(null);
    this.getStockKeepingUnits(this.selectedProductFamily);
  }

  onProductFamilyChange(ev): any {
    const event = Number(ev)
    Promise.all([this.localStorage.getItem('hasPendingSave')]).then(values => {
      const hasPendingSave = values[0];
      if (hasPendingSave === 'NO' || hasPendingSave === null) {
        this.productFamilyValue = event;
        this.oldProductFamilyValue = event;
        this.changeProductFamily(event)
        if (this.productFamilies.length) {
          const returnData = this.productFamilies.find(itemInArray => itemInArray.id === event).name;
          return returnData
        }
      } else if (hasPendingSave === 'YES') {
        this.confirmationPopupService.confirm('Confirm', 'Do you want to leave the section without saving your changes?', '', 'Yes', 'Cancel')
          .then((confirmed) => {
            if (confirmed) {
              this.productFamilyValue = event;
              this.oldProductFamilyValue = event;
              this.localStorage.setItem('hasPendingSave', 'NO');
              this.changeProductFamily(this.productFamilyValue)
              if (this.productFamilies.length) {
                const returnData = this.productFamilies.find(itemInArray => itemInArray.id === this.productFamilyValue).name;
                return returnData
              }
            } else if (!confirmed) {
              this.productFamilyValue = this.oldProductFamilyValue;
              if (this.productFamilies.length) {
                const returnData = this.productFamilies.find(itemInArray => itemInArray.id === this.oldProductFamilyValue).name;
                return returnData.toString();
              }
            }
          });
      }
    });
  }

  changeSKU(SKU) {
    this.demandforecastingService.setSelectedSKU(SKU);
    this.selectedSKU = SKU;
    this.getPerformanceData(this.selectedProductFamily, this.selectedSKU);
  }

  getStockKeepingUnits(selectedProductFamilyId) {
    this.loader.start()
    this.stockKeepingUnits = [];
    this.demandForecastService.getStockKeepingUnitList(selectedProductFamilyId).subscribe((skuResponse) => {
      if (skuResponse && skuResponse.code === 200) {
        this.stockKeepingUnits = skuResponse.data;
        this.demandforecastingService.getSelectedSKU().subscribe(sku => {
          if(sku === null){
            this.selectedSKU = this.stockKeepingUnits[0].id;
          } else {
            this.selectedSKU = sku;
          }
        });
        this.getPerformanceData(selectedProductFamilyId, this.selectedSKU);
      } else { this.loader.stop() }
    });
  }
  getPerformanceData(selectedProductFamilyId, selectedSKU) {
    this.demandForecastService.getPerformanceAPIData(selectedProductFamilyId, selectedSKU).subscribe((response) => {
      if (response && response.code === 200) {
        this.performanceData = response.data;
        this.loader.stop();
      }
    })
  }

  getNotificationData() {
    const endPoint = 'demandPlannerNotification/'
    this.timeInterval = interval(5000)
      .pipe(
        startWith(0),
        switchMap(() => this.notificationService.getNotification(endPoint))
      ).subscribe(response => {
        if (response && response.data) {
          this.notificationService.sendNotificationData(response.data);
        }
      });
  }

  ngOnDestroy() {
    this.timeInterval.unsubscribe();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

}
