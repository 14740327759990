<!--TABLE and CHART -->

<div class="tab-content tab-content-optmize bg-white">
    <div class="container-fluid p-2 mb-3">

        <div class="row no-gutters optimized-tables">
            <div class="col-sm-12">
                <div class="card shadow-sm">
                    <div class="card-body" *ngIf="selectedSenarioData">
                        <table class="table table-bordered table-sm table-cs table-optimize mb-0"
                            style="font-size: 0.7rem;" id="OptmizeTable" aria-describedby="mydesc">
                            <thead>
                                <tr>
                                    <th scope="col" colspan="6" class="p-0 bg-primary">
                                        <h5 class="card-title bg-primary">Current Scenario</h5>
                                    </th>
                                    <th scope="col"></th>
                                    <th scope="col" colspan="4" class="p-0 bg-success">
                                        <h5 class="card-title bg-success">Optimal Range
                                            <span style="font-size: 0.7rem;">(Update range and Optimize)</span>
                                        </h5>
                                    </th>
                                    <th scope="col"></th>
                                    <th scope="col" colspan="5" class="p-0 bg-warning">
                                        <h5 class="card-title bg-warning">Optimized Output</h5>
                                    </th>
                                    <th scope="col"></th>
                                    <th scope="col" colspan="2" class="p-0 bg-warning">
                                        <h5 class="card-title bg-warning">Post Optimized Change</h5>
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="col" rowspan="2">Spend Levers</th>
                                    <th scope="col" rowspan="2">Spend <br>(In $)</th>
                                    <th scope="col" rowspan="2">Output<br>(In Units)</th>
                                    <th scope="col" rowspan="2">Margin<br>(In $)</th>
                                    <th scope="col" rowspan="2">Profit<br>(In $)</th>
                                    <th scope="col" class="width-65" rowspan="2">ROI Calculation</th>
                                    <th scope="col"></th>
                                    <th scope="col" class="width-75">Spend limit </th>
                                    <th scope="col" class="width-75" rowspan="2">Min Spend <br>(In $)</th>
                                    <th scope="col" class="width-75">Spend limit </th>
                                    <th scope="col" class="width-75" rowspan="2">Max Spend <br>(In $)</th>
                                    <th scope="col"></th>
                                    <th scope="col" class="width-65" rowspan="2">New Spend <br>(In $)</th>
                                    <th scope="col" class="width-65" rowspan="2">Model Output<br>(In Units)</th>
                                    <th scope="col" class="width-65" rowspan="2">Margin<br>(In $)</th>
                                    <th scope="col" class="width-65" rowspan="2">Profit<br>(In $)</th>
                                    <th scope="col" class="width-65" rowspan="2">ROI Calculation</th>
                                    <th scope="col" rowspan="2"></th>
                                    <th scope="col" rowspan="2" class="width-95">% chg in Spend</th>
                                    <th scope="col" rowspan="2" class="width-95">% chg in Incremental Sales</th>
                                </tr>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Min (%)</th>
                                    <th scope="col">Max (%)</th>
                                    <th scope="col"></th>

                                </tr>
                            </thead>
                            <tbody *ngIf="selectedBrand !== 99">

                                <tr
                                    *ngFor="let item of selectedSenarioTableRecored | slice:0:selectedSenarioTableRecored.length-2; let index = index">
                                    <td [ngClass]="item.isParent  ? 'is-parent' : 'not-parent'">{{item?.spendLever}}
                                    </td>
                                    <td>{{item?.spend}}</td>
                                    <td>{{item?.output}}</td>
                                    <td>{{item?.margin}}</td>
                                    <td>{{item?.profit}}</td>
                                    <td class="width-65">{{item?.roiCalculation}}</td>
                                    <td></td>
                                    <td class="width-65">
                                        <input type="text" *ngIf="index !== 0" class="form-control table-form"
                                            [ngStyle]="(disableEditing && item.spendLever === 'Digital' && selectedBrand === 2) ? {'pointer-events': 'unset'} : {'pointer-events': 'none'}"
                                            [(ngModel)]="selectedSenarioTableRecored[index].min" [value]="item?.min">
                                    </td>
                                    <td class="width-65">{{item?.minSpend}}</td>
                                    <td class="width-65">
                                        <input type="text" *ngIf="index !== 0" class="form-control table-form"
                                            [ngStyle]="(disableEditing && item.spendLever === 'Digital' && selectedBrand === 2) ? {'pointer-events': 'unset'} : {'pointer-events': 'none'}"
                                            [(ngModel)]="selectedSenarioTableRecored[index].max" [value]="item?.max">
                                    </td>
                                    <td class="width-65">{{item?.maxSpend}}</td>
                                    <td></td>
                                    <td>{{item?.optimizedNewSpend}}</td>
                                    <td>{{item?.optimizedModelOutput}}</td>
                                    <td>{{item?.optimizedMargin}}</td>
                                    <td>{{item?.optimizedProfit}}</td>
                                    <td>{{item?.optimizedRoiCalculation}}</td>
                                    <td></td>
                                    <td>
                                        <ng-container
                                            *ngIf="item?.changeInSpend.length && item?.changeInIncrementalSale !== '0.00%'">
                                            <img height=13 width=12 *ngIf="item?.changeInSpend.charAt(0) === '-'"
                                                src="assets/images/icon-arrow-down-red.png" alt="image"/>
                                            <img height=13 width=12 *ngIf="item?.changeInSpend.charAt(0) !== '-'"
                                                src="assets/images/icon-arrow-up-green.png"  alt="image"/>
                                        </ng-container>
                                        <span style="margin-left: 20px;">{{item?.changeInSpend}}</span>
                                    </td>
                                    <td>
                                        <ng-container
                                            *ngIf="item?.changeInIncrementalSale.length && item?.changeInIncrementalSale !== '0.00%'">
                                            <img height=13 width=12
                                                *ngIf="item?.changeInIncrementalSale.charAt(0) === '-'"
                                                src="assets/images/icon-arrow-down-red.png" alt="image"/>
                                            <img height=13 width=12
                                                *ngIf="item?.changeInIncrementalSale.charAt(0) !== '-'"
                                                src="assets/images/icon-arrow-up-green.png" alt="image"/>
                                        </ng-container>
                                        <span style="margin-left: 20px;">{{item?.changeInIncrementalSale}}</span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="selectedBrand === 99">
                                <tr
                                    *ngFor="let item of selectedSenarioTableRecored | slice:0:selectedSenarioTableRecored.length-7; let index = index">
                                    <td [ngClass]="item.isParent  ? 'is-parent' : 'not-parent'">{{item?.spendLever}}
                                    </td>
                                    <td>{{item?.spend}}</td>
                                    <td>{{item?.output}}</td>
                                    <td>{{item?.margin}}</td>
                                    <td>{{item?.profit}}</td>
                                    <td class="width-65">{{item?.roiCalculation}}</td>
                                    <td></td>
                                    <td class="width-65">
                                        <input type="text"
                                            *ngIf="index !== 0 && selectedSenarioTableRecored[index].min.length"
                                            class="form-control table-form"
                                            [ngStyle]="(disableEditing && item.spendLever === 'Digital' && selectedBrand === 2) ? {'pointer-events': 'unset'} : {'pointer-events': 'none'}"
                                            [(ngModel)]="selectedSenarioTableRecored[index].min" [value]="item?.min">
                                    </td>
                                    <td class="width-65">{{item?.minSpend}}</td>
                                    <td class="width-65">
                                        <input type="text"
                                            *ngIf="index !== 0 && selectedSenarioTableRecored[index].max.length"
                                            class="form-control table-form"
                                            [ngStyle]="(disableEditing && item.spendLever === 'Digital' && selectedBrand === 2) ? {'pointer-events': 'unset'} : {'pointer-events': 'none'}"
                                            [(ngModel)]="selectedSenarioTableRecored[index].max" [value]="item?.max">
                                    </td>
                                    <td class="width-65">{{item?.maxSpend}}</td>
                                    <td></td>
                                    <td>{{item?.optimizedNewSpend}}</td>
                                    <td>{{item?.optimizedModelOutput}}</td>
                                    <td>{{item?.optimizedMargin}}</td>
                                    <td>{{item?.optimizedProfit}}</td>
                                    <td>{{item?.optimizedRoiCalculation}}</td>
                                    <td></td>
                                    <td>
                                        <ng-container
                                            *ngIf="item?.changeInSpend.length && item?.changeInIncrementalSale !== '0.00%'">
                                            <img height=13 width=12 *ngIf="item?.changeInSpend.charAt(0) === '-'"
                                                src="assets/images/icon-arrow-down-red.png" alt="image"/>
                                            <img height=13 width=12 *ngIf="item?.changeInSpend.charAt(0) !== '-'"
                                                src="assets/images/icon-arrow-up-green.png" alt="image"/>
                                        </ng-container>
                                        <span style="margin-left: 20px;">{{item?.changeInSpend}}</span>
                                    </td>
                                    <td>
                                        <ng-container
                                            *ngIf="item?.changeInIncrementalSale.length && item?.changeInIncrementalSale !== '0.00%'">
                                            <img height=13 width=12
                                                *ngIf="item?.changeInIncrementalSale.charAt(0) === '-'"
                                                src="assets/images/icon-arrow-down-red.png" alt="image"/>
                                            <img height=13 width=12
                                                *ngIf="item?.changeInIncrementalSale.charAt(0) !== '-'"
                                                src="assets/images/icon-arrow-up-green.png" alt="image"/>
                                        </ng-container>
                                        <span style="margin-left: 20px;">{{item?.changeInIncrementalSale}}</span>
                                    </td>
                                </tr>
                                <tr class="dark-table"
                                    *ngFor="let item of selectedSenarioTableRecored | slice:selectedSenarioTableRecored.length-7:selectedSenarioTableRecored.length-2; let index = index">
                                    <td [ngClass]="item.isParent  ? 'is-parent' : 'not-parent'"> {{item?.spendLever}}
                                    </td>
                                    <td>{{item?.spend}}</td>
                                    <td>{{item?.output}}</td>
                                    <td>{{item?.margin}}</td>
                                    <td>{{item?.profit}}</td>
                                    <td class="width-65">{{item?.roiCalculation}}</td>
                                    <td></td>
                                    <td class="width-65">
                                        
                                    </td>
                                    <td class="width-65"></td>
                                    <td class="width-65">
                                        
                                    </td>
                                    <td class="width-65"></td>
                                    <td></td>
                                    <td>{{item?.optimizedNewSpend}}</td>
                                    <td>{{item?.optimizedModelOutput}}</td>
                                    <td>{{item?.optimizedMargin}}</td>
                                    <td>{{item?.optimizedProfit}}</td>
                                    <td>{{item?.optimizedRoiCalculation}}</td>
                                    <td></td>
                                    <td>

                                    </td>
                                    <td>

                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr
                                    *ngFor="let item of selectedSenarioTableRecored| slice:selectedSenarioTableRecored.length-2:selectedSenarioTableRecored.length">
                                    <th scope="col">{{item?.spendLever}}</th>
                                    <th scope="col" class="width-65">{{item?.spend}}</th>
                                    <th scope="col" class="width-65">{{item?.output}}</th>
                                    <th scope="col" class="width-65">{{item?.margin}}</th>
                                    <th scope="col" class="width-65">{{item?.profit}}</th>
                                    <th scope="col" class="width-65">{{item?.roiCalculation}}</th>
                                    <th scope="col"></th>
                                    <th scope="col" class="width-65">{{item?.min}}</th>
                                    <th scope="col" class="width-65">{{item?.minSpend}}</th>
                                    <th scope="col" class="width-65">{{item?.max}}</th>
                                    <th scope="col" class="width-65">{{item?.maxSpend}}</th>
                                    <th scope="col"></th>
                                    <th scope="col" class="width-65">{{item?.optimizedNewSpend}}</th>
                                    <th scope="col" class="width-65">{{item?.optimizedModelOutput}}</th>
                                    <th scope="col" class="width-65">{{item?.optimizedMargin}}</th>
                                    <th scope="col" class="width-65">{{item?.optimizedProfit}}</th>
                                    <th scope="col" class="width-65">{{item?.optimizedRoiCalculation}}</th>
                                    <th scope="col"></th>
                                    <th scope="col" class="width-65">{{item?.changeInSpend}} </th>
                                    <th scope="col" class="width-105">{{item?.changeInIncrementalSale}} </th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

        </div>
        <div id="accordion" style="margin: 1rem 0 0 0;" *ngIf="selectedBrand !== 99">
            <div class="card">
                <button class="accordion collapsed" data-toggle="collapse" data-target="#collapseTwo"
                    aria-expanded="false" aria-controls="collapseTwo">
                    Optimized Scenario Analysis
                </button>

                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                    <div class="">
                        <div class="row no-gutters">
                            <div class="col-sm-12 col-md-6" *ngIf="!!incrementalSaleChartTemplate">
                                <app-charts [chartOption]="incrementalSaleChartTemplate" [updateFlag]="updateFlag">
                                </app-charts>
                            </div>

                            <div class="col-sm-12 col-md-6" *ngIf="!!mediaResponseChartTemplate">
                                <app-charts [chartOption]="mediaResponseChartTemplate" [updateFlag]="updateFlag">
                                </app-charts>
                            </div>

                            <div class="col-sm-12 col-md-6" *ngIf="!!roiMacChartTemplate">
                                <app-charts [chartOption]="roiMacChartTemplate" [updateFlag]="updateFlag"></app-charts>
                            </div>

                            <div class="col-sm-12 col-md-6" *ngIf="!!shareSpendChartTemplate">
                                <app-charts [chartOption]="shareSpendChartTemplate" [updateFlag]="updateFlag">
                                </app-charts>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-right border-top bg-light p-3 d-none">
            <a href="policy-info.html" class="btn btn-primary btn-sm  prevBtn pull-left"><span>❮</span> Previous</a>
            <a href="tasks-list.html" class="btn btn-light">Cancel</a>
            <a href="#" class="btn btn-primary btn-sm brokerAnalyticsModal" data-toggle="modal"
                data-target="#showRelativeRisk">Get Vehicle Rating</a>
        </div>
    </div>
</div>