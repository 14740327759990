<!--TABLE and CHART -->

<div class="tab-content tab-content-optmize bg-white">
    <div class="container-fluid p-2 mb-3">

        <div class="row no-gutters optimized-tables">
            <div class="col-sm-12">
                <div class="card shadow-sm">
                    <div class="card-body" *ngIf="selectedSenarioData">
                        <table class="table table-bordered table-sm table-cs table-optimize mb-0"
                            style="font-size: 0.7rem;" id="OptmizeTable" aria-describedby="mydesc">
                            <thead>
                                <tr>
                                    <th scope="col" colspan="6" class="p-0 bg-primary">
                                        <h5 class="card-title bg-primary">Current Scenario</h5>
                                    </th>
                                    <th scope="col" class="hidden-column"></th>
                                    <th scope="col" colspan="4" class="p-0 bg-success">
                                        <h5 class="card-title bg-success">Optimal Range
                                            <span style="font-size: 0.7rem;">(Update range and Optimize)</span>
                                        </h5>
                                    </th>
                                    <th scope="col" class="hidden-column"></th>
                                    <th scope="col" colspan="5" class="p-0 bg-warning">
                                        <h5 class="card-title bg-warning">Optimized Output</h5>
                                    </th>
                                    <th scope="col" class="hidden-column"></th>
                                    <th scope="col" colspan="2" class="p-0 bg-warning">
                                        <h5 class="card-title bg-warning">Post Optimized Change</h5>
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="col" rowspan="2">Spend Levers</th>
                                    <th scope="col" rowspan="2">Spend <br>(In $)</th>
                                    <th scope="col" rowspan="2">Output<br>(In Units)</th>
                                    <th scope="col" rowspan="2">Margin<br>(In $)</th>
                                    <th scope="col" rowspan="2">Profit<br>(In $)</th>
                                    <th scope="col" class="width-65" rowspan="2">ROI</th>
                                    <th scope="col" class="hidden-column"></th>
                                    <th scope="col" class="width-75">Spend limit </th>
                                    <th scope="col" class="width-75" rowspan="2">Min Spend <br>(In $)</th>
                                    <th scope="col" class="width-75">Spend limit </th>
                                    <th scope="col" class="width-75" rowspan="2">Max Spend <br>(In $)</th>
                                    <th scope="col" class="hidden-column"></th>
                                    <th scope="col" class="width-65" rowspan="2">New Spend <br>(In $)</th>
                                    <th scope="col" class="width-65" rowspan="2">Model Output<br>(In Units)</th>
                                    <th scope="col" class="width-65" rowspan="2">Margin<br>(In $)</th>
                                    <th scope="col" class="width-65" rowspan="2">Profit<br>(In $)</th>
                                    <th scope="col" class="width-65" rowspan="2">ROI</th>
                                    <th scope="col" rowspan="2" class="hidden-column"></th>
                                    <th scope="col" rowspan="2" class="width-95">% Change in Spend</th>
                                    <th scope="col" rowspan="2" class="width-95">% Change in Incremental Sales</th>
                                </tr>
                                <tr>
                                    <th scope="col" class="hidden-column"></th>
                                    <th scope="col">Min (%)</th>
                                    <th scope="col">Max (%)</th>
                                    <th scope="col" class="hidden-column"></th>

                                </tr>
                            </thead>
                            <tbody *ngIf="selectedBrandId !== '99'">

                                <tr
                                    *ngFor="let item of selectedSenarioTableRecored | slice:0:selectedSenarioTableRecored.length-2; let index = index">
                                    <td [ngClass]="item.isParent  ? 'is-parent' : 'not-parent'">{{item?.spendLever}}
                                    </td>
                                    <td>{{item?.spend | number : '1.0-2'}}</td>
                                    <td>{{item?.output | number : '1.0-2'}}</td>
                                    <td>{{item?.margin | number : '1.0-2'}}</td>
                                    <td>{{item?.profit | number : '1.0-2'}}</td>
                                    <td class="width-65">{{item?.roiCalculation | number : '1.0-2'}}</td>
                                    <td class="hidden-column"></td>
                                    <td class="width-65">
                                        <input type="text" onlyPositiveAndNegativeNumbers *ngIf="index !== 0"
                                            class="form-control table-form"
                                            [disabled]="selectedScenarioType === 'Optimize Custom'"
                                            (ngModelChange)="optimalMinRangeChanged($event)"
                                            [ngClass]="selectedSenarioTableRecored[index].min < -100 || selectedSenarioTableRecored[index].min >= 0 ? 'error-class': ''"
                                            [(ngModel)]="selectedSenarioTableRecored[index].min"
                                            [ngModelOptions]="{standalone: true}">
                                    </td>
                                    <td class="width-65">
                                        <span *ngIf="index === 0"></span><span *ngIf="index !== 0">{{item?.minSpend |
                                            number : '1.0-2'}}</span>
                                    </td>
                                    <td class="width-65">
                                        <input type="text" onlyPositiveAndNegativeNumbers *ngIf="index !== 0"
                                            class="form-control table-form"
                                            [disabled]="selectedScenarioType === 'Optimize Custom'"
                                            [(ngModel)]="selectedSenarioTableRecored[index].max"
                                            [ngModelOptions]="{standalone: true}"
                                            (ngModelChange)="optimalMaxRangeChanged($event, selectedSenarioTableRecored[index].min)"
                                            [ngClass]="selectedSenarioTableRecored[index].max <= selectedSenarioTableRecored[index].min ? 'error-class': ''">
                                    </td>
                                    <td class="width-65">
                                        <span *ngIf="index === 0"></span><span *ngIf="index !== 0">{{item?.maxSpend |
                                            number : '1.0-2'}}</span>
                                    </td>
                                    <td class="hidden-column"></td>
                                    <td>{{item?.optimizedNewSpend | number : '1.0-2'}}</td>
                                    <td>{{item?.optimizedModelOutput | number : '1.0-2'}}</td>
                                    <td>{{item?.optimizedMargin | number : '1.0-2'}}</td>
                                    <td>{{item?.optimizedProfit | number : '1.0-2'}}</td>
                                    <td>{{item?.optimizedRoiCalculation | number : '1.0-2'}}</td>
                                    <td class="hidden-column"></td>
                                    <td>
                                        <ng-container>
                                            <img height=13 width=12 *ngIf="item?.changeInSpend < 0"
                                                src="assets/images/icon-arrow-down-red.png" alt="image"/>
                                            <img height=7 width=15 *ngIf="item?.changeInSpend === '0.00'"
                                                src="assets/images/arrows-orange.png" alt="image"/>
                                            <img height=13 width=12 *ngIf="item?.changeInSpend > 0"
                                                src="assets/images/icon-arrow-up-green.png" alt="image"/>
                                        </ng-container>
                                        <span [ngStyle]="{'margin-left': '10px'}">
                                            <ng-container>{{item?.changeInSpend }} <span *ngIf="item?.changeInSpend">%</span></ng-container>
                                        </span>
                                    </td>
                                    <td>
                                        <ng-container>
                                            <img height=13 width=12 *ngIf="item?.changeInIncrementalSale < 0"
                                                src="assets/images/icon-arrow-down-red.png" alt="image"/>
                                            <img height=7 width=15 *ngIf="item?.changeInIncrementalSale === '0.00'"
                                                src="assets/images/arrows-orange.png" alt="image"/>
                                            <img height=13 width=12 *ngIf="item?.changeInIncrementalSale > 0"
                                                src="assets/images/icon-arrow-up-green.png" alt="image"/>
                                        </ng-container>
                                        <span [ngStyle]="{'margin-left': '10px'}">
                                            <ng-container>{{item?.changeInIncrementalSale}} <span *ngIf="item?.changeInIncrementalSale">%</span></ng-container>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="selectedBrandId === '99'">
                                <ng-container  *ngFor="let item of selectedSenarioTableRecored | slice:0:selectedSenarioTableRecored.length-7; let index = index">
                                  <tr *ngIf="item.showBrandNameOnly" class="brand-name-row">
                                    <td colspan="6" class="is-parent brand-name-text">{{item.brandName}}</td>
                                    <td class="hidden-column"></td>
                                    <td colspan="4"></td>
                                    <td class="hidden-column"></td>
                                    <td colspan="5"></td>
                                    <td class="hidden-column"></td>
                                    <td colspan="2"></td>
                                  </tr>
                                  <tr *ngIf="!item.showBrandNameOnly">
                                      <td [ngClass]="item.isParent  ? 'is-parent' : 'not-parent'">{{item?.spendLever}}
                                      </td>
                                      <td>{{item?.spend | number : '1.0-2'}}</td>
                                      <td>{{item?.output | number : '1.0-2'}}</td>
                                      <td>{{item?.margin | number : '1.0-2'}}</td>
                                      <td>{{item?.profit | number : '1.0-2'}}</td>
                                      <td class="width-65">{{item?.roiCalculation | number : '1.0-2'}}</td>
                                      <td class="hidden-column"></td>
                                      <td class="width-65">
                                          <input type="text" onlyPositiveAndNegativeNumbers *ngIf="!(item.spendLeverType === 'Media' && item.isParent)"
                                              class="form-control table-form"
                                              [disabled]="selectedScenarioType === 'Optimize Custom'"
                                              (ngModelChange)="optimalMinRangeChanged($event)"
                                              [ngClass]="selectedSenarioTableRecored[index].min < -100 || selectedSenarioTableRecored[index].min >= 0 ? 'error-class': ''"
                                              [(ngModel)]="selectedSenarioTableRecored[index].min"
                                              [ngModelOptions]="{standalone: true}">
                                      </td>
                                      <td class="width-65">
                                          <span *ngIf="!(item.spendLeverType === 'Media' && item.isParent)">{{item?.minSpend | number : '1.0-2'}}</span>
                                      </td>
                                      <td class="width-65">
                                          <input type="text" onlyPositiveAndNegativeNumbers *ngIf="!(item.spendLeverType === 'Media' && item.isParent)"
                                              class="form-control table-form"
                                              [disabled]="selectedScenarioType === 'Optimize Custom'"
                                              [(ngModel)]="selectedSenarioTableRecored[index].max"
                                              [ngModelOptions]="{standalone: true}"
                                              (ngModelChange)="optimalMaxRangeChanged($event, selectedSenarioTableRecored[index].min)"
                                              [ngClass]="selectedSenarioTableRecored[index].max <= selectedSenarioTableRecored[index].min ? 'error-class': ''">
                                      </td>
                                      <td class="width-65">
                                          <span *ngIf="!(item.spendLeverType === 'Media' && item.isParent)">{{item?.maxSpend | number : '1.0-2'}}</span>
                                      </td>
                                      <td class="hidden-column"></td>
                                      <td>{{item?.optimizedNewSpend | number : '1.0-2'}}</td>
                                      <td>{{item?.optimizedModelOutput | number : '1.0-2'}}</td>
                                      <td>{{item?.optimizedMargin | number : '1.0-2'}}</td>
                                      <td>{{item?.optimizedProfit | number : '1.0-2'}}</td>
                                      <td>{{item?.optimizedRoiCalculation | number : '1.0-2'}}</td>
                                      <td class="hidden-column"></td>
                                      <td>
                                          <ng-container>
                                              <img height=13 width=12 *ngIf="item?.changeInSpend < 0"
                                                  src="assets/images/icon-arrow-down-red.png" alt="image"/>
                                              <img height=7 width=15 *ngIf="item?.changeInSpend === 0"
                                                  src="assets/images/arrows-orange.png" alt="image"/>
                                              <img height=13 width=12 *ngIf="item?.changeInSpend > 0"
                                                  src="assets/images/icon-arrow-up-green.png" alt="image"/>
                                          </ng-container>
                                          <span [ngStyle]="{'margin-left': '10px'}">
                                              <ng-container *ngIf="item?.changeInSpend !== 0">{{item?.changeInSpend | number : '1.0-2'}} <span *ngIf="item?.changeInSpend">%</span></ng-container>
                                              <ng-container *ngIf="item?.changeInSpend === 0">0.00 %</ng-container>
                                          </span>
                                      </td>
                                      <td>
                                          <ng-container>
                                              <img height=13 width=12 *ngIf="item?.changeInIncrementalSale < 0"
                                                  src="assets/images/icon-arrow-down-red.png" alt="image"/>
                                              <img height=7 width=15 *ngIf="item?.changeInIncrementalSale === 0"
                                                  src="assets/images/arrows-orange.png" alt="image"/>
                                              <img height=13 width=12 *ngIf="item?.changeInIncrementalSale > 0"
                                                  src="assets/images/icon-arrow-up-green.png" alt="image"/>
                                          </ng-container>
                                          <span [ngStyle]="{'margin-left': '10px'}">
                                              <ng-container *ngIf="item?.changeInIncrementalSale !== 0">{{item?.changeInIncrementalSale | number : '1.0-2'}} <span *ngIf="item?.changeInIncrementalSale">%</span></ng-container>
                                              <ng-container *ngIf="item?.changeInIncrementalSale === 0">0.00 %</ng-container>
                                          </span>
                                      </td>
                                  </tr>
                                </ng-container>
                                <tr class="brand-name-row">
                                    <td colspan="6" class="is-parent brand-name-text">Portfolio Total</td>
                                    <td class="hidden-column"></td>
                                    <td colspan="4"></td>
                                    <td class="hidden-column"></td>
                                    <td colspan="5"></td>
                                    <td class="hidden-column"></td>
                                    <td colspan="2"></td>
                                  </tr>
                                <tr class="dark-table"
                                    *ngFor="let item of selectedSenarioTableRecored | slice:selectedSenarioTableRecored.length-7:selectedSenarioTableRecored.length-2; let index = index">
                                    <td [ngClass]="item.isParent  ? 'is-parent' : 'not-parent'"> {{item?.spendLever}}
                                    </td>
                                    <td>{{item?.spend | number : '1.0-2'}}</td>
                                    <td>{{item?.output | number : '1.0-2'}}</td>
                                    <td>{{item?.margin | number : '1.0-2'}}</td>
                                    <td>{{item?.profit | number : '1.0-2'}}</td>
                                    <td class="width-65">{{item?.roiCalculation | number : '1.0-2'}}</td>
                                    <td class="hidden-column"></td>
                                    <td class="width-65"></td>
                                    <td class="width-65"></td>
                                    <td class="width-65"></td>
                                    <td class="width-65"></td>
                                    <td class="hidden-column"></td>
                                    <td>{{item?.optimizedNewSpend | number : '1.0-2'}}</td>
                                    <td>{{item?.optimizedModelOutput | number : '1.0-2'}}</td>
                                    <td>{{item?.optimizedMargin | number : '1.0-2'}}</td>
                                    <td>{{item?.optimizedProfit | number : '1.0-2'}}</td>
                                    <td>{{item?.optimizedRoiCalculation | number : '1.0-2'}}</td>
                                    <td class="hidden-column"></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr
                                    *ngFor="let item of selectedSenarioTableRecored| slice:selectedSenarioTableRecored.length-2:selectedSenarioTableRecored.length; let last = last">
                                    <th scope="col">{{item?.spendLever}}</th>
                                    <th scope="col" class="width-65">
                                        <ng-container *ngIf="!last">{{item?.spend | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.spend !== 0">{{item?.spend | number : '1.0-2'}} <span *ngIf="item?.spend">%</span></ng-container>
                                            <ng-container *ngIf="item?.spend === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                    <th scope="col" class="width-65">
                                        <ng-container *ngIf="!last">{{item?.output | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.output !== 0">{{item?.output | number : '1.0-2'}} <span *ngIf="item?.output">%</span></ng-container>
                                            <ng-container *ngIf="item?.output === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                    <th scope="col" class="width-65">
                                        <ng-container *ngIf="!last">{{item?.margin | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.margin !== 0">{{item?.margin | number : '1.0-2'}} <span *ngIf="item?.margin">%</span></ng-container>
                                            <ng-container *ngIf="item?.margin === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                    <th scope="col" class="width-65">
                                        <ng-container *ngIf="!last">{{item?.profit | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.profit !== 0">{{item?.profit | number : '1.0-2'}} <span *ngIf="item?.profit">%</span></ng-container>
                                            <ng-container *ngIf="item?.profit === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                    <th scope="col" class="width-65">
                                        <ng-container *ngIf="!last">{{item?.roiCalculation | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.roiCalculation !== 0">{{item?.roiCalculation | number : '1.0-2'}} <span *ngIf="item?.roiCalculation">%</span></ng-container>
                                            <ng-container *ngIf="item?.roiCalculation === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                    <th scope="col" class="hidden-column"></th>
                                    <th scope="col" class="width-65">
                                        <ng-container *ngIf="!last && item?.min !== 0">{{item?.min | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.min !== 0">{{item?.min | number : '1.0-2'}} <span *ngIf="item?.min">%</span></ng-container>
                                            <ng-container *ngIf="item?.min === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                    <th scope="col" class="width-65">
                                        <ng-container *ngIf="!last && item?.minSpend !== 0">{{item?.minSpend | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.minSpend !== 0">{{item?.minSpend | number : '1.0-2'}} <span *ngIf="item?.minSpend">%</span></ng-container>
                                            <ng-container *ngIf="item?.minSpend === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                    <th scope="col" class="width-65">
                                        <ng-container *ngIf="!last && item?.max !== 0">{{item?.max | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.max !== 0">{{item?.max | number : '1.0-2'}} <span *ngIf="item?.max">%</span></ng-container>
                                            <ng-container *ngIf="item?.max === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                    <th scope="col" class="width-65">
                                        <ng-container *ngIf="!last && item?.maxSpend !== 0">{{item?.maxSpend | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.maxSpend !== 0">{{item?.maxSpend | number : '1.0-2'}} <span *ngIf="item?.maxSpend">%</span></ng-container>
                                            <ng-container *ngIf="item?.maxSpend === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                    <th scope="col" class="hidden-column"></th>
                                    <th scope="col" class="width-65">
                                        <ng-container *ngIf="!last">{{item?.optimizedNewSpend | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.optimizedNewSpend !== 0">{{item?.optimizedNewSpend | number : '1.0-2'}} <span *ngIf="item?.optimizedNewSpend">%</span></ng-container>
                                            <ng-container *ngIf="item?.optimizedNewSpend === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                    <th scope="col" class="width-65">
                                        <ng-container *ngIf="!last">{{item?.optimizedModelOutput | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.optimizedModelOutput !== 0">{{item?.optimizedModelOutput | number : '1.0-2'}} <span *ngIf="item?.optimizedModelOutput">%</span></ng-container>
                                            <ng-container *ngIf="item?.optimizedModelOutput === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                    <th scope="col" class="width-65">
                                        <ng-container *ngIf="!last">{{item?.optimizedMargin | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.optimizedMargin !== 0">{{item?.optimizedMargin | number : '1.0-2'}} <span *ngIf="item?.optimizedMargin">%</span></ng-container>
                                            <ng-container *ngIf="item?.optimizedMargin === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                    <th scope="col" class="width-65">
                                        <ng-container *ngIf="!last">{{item?.optimizedProfit | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.optimizedProfit !== 0">{{item?.optimizedProfit | number : '1.0-2'}} <span *ngIf="item?.optimizedProfit">%</span></ng-container>
                                            <ng-container *ngIf="item?.optimizedProfit === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                    <th scope="col" class="width-65">
                                        <ng-container *ngIf="!last">{{item?.optimizedRoiCalculation | number : '1.2-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.optimizedRoiCalculation !== 0">{{item?.optimizedRoiCalculation | number : '1.0-2'}} <span *ngIf="item?.optimizedRoiCalculation">%</span></ng-container>
                                            <ng-container *ngIf="item?.optimizedRoiCalculation === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                    <th scope="col" class="hidden-column"></th>
                                    <th scope="col" class="width-65">
                                        <ng-container *ngIf="!last">{{item?.changeInSpend | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.changeInSpend !== 0">{{item?.changeInSpend | number : '1.0-2'}} <span *ngIf="item?.changeInSpend">%</span></ng-container>
                                            <ng-container *ngIf="item?.changeInSpend === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                    <th scope="col" class="width-105">
                                        <ng-container *ngIf="!last">{{item?.changeInIncrementalSale | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.changeInIncrementalSale !== 0">{{item?.changeInIncrementalSale | number : '1.0-2'}} <span *ngIf="item?.changeInIncrementalSale">%</span></ng-container>
                                            <ng-container *ngIf="item?.changeInIncrementalSale === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

        </div>
        <div id="accordion" style="margin: 1rem 0 0 0;" *ngIf="selectedBrandId !== '99'">
            <div class="card">
                <button class="accordion collapsed" data-toggle="collapse" data-target="#collapseTwo"
                    aria-expanded="false" aria-controls="collapseTwo">
                    Optimized Scenario Analysis
                </button>

                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                    <div class="">
                        <div class="row no-gutters">
                            <div class="col-sm-12 col-md-6" >
                                <app-charts [chartOption]="incrementalSaleChartTemplate" [updateFlag]="updateFlag">
                                </app-charts>
                            </div>

                            <div class="col-sm-12 col-md-6">
                                <app-charts [chartOption]="mediaResponseChartTemplate" [updateFlag]="updateFlag">
                                </app-charts>
                            </div>

                            <div class="col-sm-12 col-md-6">
                                <app-charts [chartOption]="roiMacChartTemplate" [updateFlag]="updateFlag"></app-charts>
                            </div>

                            <div class="col-sm-12 col-md-6">
                                <app-charts [chartOption]="shareSpendChartTemplate" [updateFlag]="updateFlag">
                                </app-charts>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
