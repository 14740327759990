import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Highcharts from 'highcharts';
import { ExportToExcelService } from '../../services/export-to-excel.service';
import HC_exporting from "highcharts/modules/exporting";
import HC_Data from "highcharts/modules/export-data";
import Accessbility from "highcharts/modules/accessibility";

HC_exporting(Highcharts);
HC_Data(Highcharts);
Accessbility(Highcharts);

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent {

  @Input() chartOption : any;
  @Input() updateFlag: boolean;
  
 
  chartModelData: any = {};
  closeResult = '';
  dataFound: boolean = true;
  Highcharts: typeof Highcharts = Highcharts;
  @ViewChild("chartRef", { static: false }) chartRef: any;
  constructor(private modalService: NgbModal, private exportToExcelService: ExportToExcelService) { }

  downloadChart() {
     this.chartRef.chart.downloadXLS();
  }

 

  openXl(content, data) {
    this.chartModelData = data;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
