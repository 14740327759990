<div class="left-panel-offset">
  <div class="">
    <div class="row">
      <div class="col-sm-12">
        <h3 class="custom-table-headers">Review</h3>
      </div>
      <div class="col-sm-12">
        <div class="custom-padding">
          <!-- tabs -->
          <div class="module-nav">
            <ul class="nav nav-tabs" id="column-mapping" role="tablist">
              <li class="nav-item" *ngFor="let item of updatedFilePreviewData">
                <a class="nav-link sub-module" (click)="selectTab( item?.sheetName, $event)" role="tab"
                  [ngClass]="{ 'active':selectedSheet=== item?.sheetName}">{{item?.sheetName}}</a>
              </li>
            </ul>
          </div>
          <div class="bg-white rounded custom-height">
            <!-- tab pannel -->
            <div class="tab-content bg-white" id="pricingTabsContent">
              <ng-container *ngFor="let tab of updatedFilePreviewData; let idx = index">
                <div class="tab-pane active show" id="registered-projects" role="tabpanel"
                  [ngClass]="{ 'active show':selectedSheet=== tab?.sheetName}">
                  <div class="table-wrapper">
                    <table class="table table-bordered">
                      <ng-container *ngFor="let row of tab?.data; let first = first; let index = index">
                        <tr [ngClass]="{ 'header-row': first}">
                          <td style="width: 20px; min-width: 20px" *ngIf="!first">{{ index }}</td>
                          <td style="width: 20px; min-width: 20px" *ngIf="first">&nbsp;</td>
                          <td *ngFor="let val of row">
                            {{val}}
                          </td>
                        </tr>
                      </ng-container>
                    </table>
                  </div>
                  <div class="confirm-review">
                    <input type="checkbox" class="checkbox-positioned" name="tab.reviewed" id="review+{{i}}"
                      [(ngModel)]="reviewed" (change)="reviewCheck($event)">
                    <label [ngClass]="{ 'error': incompleteReview }" for="review+{{i}}" class="review-label">Confirm
                      Review</label>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-button-container">
        <button class="btn btn-sm btn-outline-secondary pulled-left" (click)="goToBack()">Back</button>
        <button class="btn btn-sm btn-outline-primary" (click)="getExited()">Exit</button>
        <button class="btn btn-sm btn-primary ml-10" [disabled]="!reviewed" (click)="finalSubmit()">Submit</button>
      </div>
    </div>
  </div>
</div>
