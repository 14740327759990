import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { AmendRuleService } from '../../../../services/amend-rule.service';
import { MetamorphCommonService } from '../../../../services/metamorph-common.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'validation-rules',
  templateUrl: './validation-rules.component.html',
  styleUrls: ['./validation-rules.component.scss']
})
export class ValidationRulesComponent implements OnInit {
  @Input() selectedTab: any;
  @Input() workItemId: any;
  @Input() processId: any;
  @Output() backButtonEvent = new EventEmitter<any>();
  @Output() payLoadAmend = new EventEmitter<any>();
  @Output() amendRules = new EventEmitter<any>();
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  amendRuleListData: Array<any> = []
  selectAll: false;
  isMandatory: true;
  constructor(public amendRuleService: AmendRuleService, private ngxLoader: NgxUiLoaderService, private route: Router, public toastr: ToastrService, private metamorphCommonService:MetamorphCommonService) { }

  ngOnInit(): void {    
    this.getAmendRuleListData();
    this.amendRules.emit({amendRules:true});
    
  }

  getAmendRuleListData() {
    this.amendRuleListData = [];
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      deferRender: true,
      destroy: true,
      columnDefs: [
        {
          targets: [0],
          orderable: false
        }]
    }
    this.amendRuleService.getAmendRuleData(this.processId, this.workItemId).subscribe(response => {
      this.amendRuleListData = response?.data.map(e=> {
        return {
          ...e,
          showDefault: e.isDefault
        }
      });
      this.dtTrigger.next();
      this.ngxLoader.stop();
    })
  }
  
  selectAllRules(selectAll) {
    this.amendRuleListData.forEach((element) => {
      if (!element.isMandatory) {
        element.isDefault = selectAll;
      }
    });
  }
  
  saveData() {
    const selectedIdList = this.amendRuleListData.filter((element) => {
      if (element.isSelected) {
        return element.ruleId
      }
    });
    const selectedRuleIdList = selectedIdList.map((element) => {
        return  {"rule_id":element.ruleId}
    });
    const payload = {  
       status: "Rules Amended",
       ruleListJson: JSON.stringify({
        rule_list_json:selectedRuleIdList
      })
       
    }
    this.payLoadAmend.next({payLoad:payload, selectedView: 'bordeaux-details',workItemId:this.workItemId,amendRules:false});
    
  }
  
  goBack() {
    this.backButtonEvent.next({workItemId: this.workItemId, processId: this.processId, selectedView: 'bordeaux-details',amendRules:false })
  }
}
