import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pricingFilter',
  pure: false
})
export class PricingFilterPipe implements PipeTransform {

  transform(chartOptions: any[], callback: (item: any) => boolean): any  {
    if (!chartOptions || !callback) {
      return chartOptions;
    }
    return chartOptions.filter(item => callback(item));
  }

}
