import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DemandForecastService} from '../demand-forecast.service';

@Component({
  selector: 'app-demand-forecast-show-analysis',
  templateUrl: './demand-forecast-show-analysis.component.html',
  styleUrls: ['./demand-forecast-show-analysis.component.scss']
})
export class DemandForecastShowAnalysisComponent implements OnInit {
  @Input() recommendationData: any;
  lastExecutiveMonth:any;
  productFamilyId:any;
  month:any;
  type:any;
  recommendationObj:any;
  message:any;
  finalMessage:any;
  finalRawMessage:any;
  msgArray:any;
  messageMonth:any;
  public monthList = [
    { id: 1, name: 'Jan' },
    { id: 2, name: 'Feb' },
    { id: 3, name: 'Mar' },
    { id: 4, name: 'Apr' },
    { id: 5, name: 'May' },
    { id: 6, name: 'Jun' },
    { id: 7, name: 'Jul' },
    { id: 8, name: 'Aug' },
    { id: 9, name: 'Sep' },
    { id: 10, name: 'Oct' },
    { id: 11, name: 'Nov' },
    { id: 12, name: 'Dec' },
  ];
  splitMonthYear:any; 
  monthName:any;
  yearMsg:any; 
  monthMsg:any;   
  constructor(private ngxLoader: NgxUiLoaderService,
    private activeModal: NgbActiveModal, private demandForecastService:DemandForecastService) { }

    async ngOnInit() {
    await this.getSupplyChainAnalysis();
  }

  public closeModal() {
    this.activeModal.close(false);
  }

   /***
  * @desc get Supply CHain analysis  
  */
   async getSupplyChainAnalysis() {
    this.ngxLoader.start();
    this.lastExecutiveMonth=this.recommendationData.formData.lastExecutiveMonth;
    this.productFamilyId=this.recommendationData.formData.productFamilyId;
    this.month=this.recommendationData.formData.month;
    this.type=this.recommendationData.model;
      await this.demandForecastService.getSupplyAnalysis(this.lastExecutiveMonth,this.productFamilyId, this.month ,this.type ).subscribe((analysis) => {
        this.recommendationObj = analysis;
        this.msgArray=[];
        let monArray = this.month.split(',');
        if(this.type=='summary'){
          this.message = this.recommendationObj?.data;
          monArray.forEach((key) =>{
            let msgObj:any={};  
            let splitMonthYear:any; 
            let yearMsg:any; 
            let monthMsg:any;  
            splitMonthYear = key.match(/.{1,4}/g);
            this.yearMsg=splitMonthYear[0];
            this.monthMsg=splitMonthYear[1];
            if(this.monthMsg[0]==0){this.monthMsg = this.monthMsg.substring(1);} 
            
              this.monthName = this.monthList.filter((el)=>{
                if(this.monthMsg == el.id){return el.name;}
              });
            msgObj.monthName=this.monthName[0].name
            msgObj.year=this.yearMsg
            let msgCustom = this.message[key].replace("Executive Summary:", "");
            this.finalRawMessage = msgCustom.slice(0, -1); 
            this.finalMessage = this.finalRawMessage.split('.');
            msgObj.text = this.finalMessage;
            this.msgArray.push(msgObj);
          })
        }
        else{ 
          this.message = this.recommendationObj?.data;
          monArray.forEach((key) =>{
            let msgObj:any={};  
            let splitMonthYear:any; 
            let yearMsg:any; 
            let monthMsg:any; 
            splitMonthYear = key.match(/.{1,4}/g);
            this.yearMsg=splitMonthYear[0];
            this.monthMsg=splitMonthYear[1];
            if(this.monthMsg[0]==0){this.monthMsg = this.monthMsg.substring(1);} 
            
              this.monthName = this.monthList.filter((el)=>{
                if(this.monthMsg == el.id){return el.name;}
              });
            msgObj.monthName=this.monthName[0].name
            msgObj.year=this.yearMsg
            this.messageMonth = this.message[key].slice(1);
            this.finalRawMessage = this.messageMonth.replace(/[^\w\s-,()]/gi, '.');
            this.finalRawMessage = this.finalRawMessage.slice(0, -1); 
            this.finalMessage = this.finalRawMessage.split('..');            
            msgObj.text = this.finalMessage;
            this.msgArray.push(msgObj);
          })
        }  
        this.ngxLoader.stop();
       
      });
    
  }
  toggleAccordian(event) {
    var element = event.target;
    element.classList.toggle("active");
    var panel = element.nextElementSibling;   
    if (panel.style.maxHeight === '') {
      panel.style.maxHeight = '0px';
    } else if (panel.style.maxHeight === '0px') {
      panel.style.maxHeight = panel.scrollHeight + "px";
    } else if (panel.style.maxHeight !== '0px') {
      panel.style.maxHeight = '0px';
    }
  }

}
