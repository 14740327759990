import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DemandForecastService } from '../demand-forecast.service';
import * as Highcharts from 'highcharts';
import { DemandforecastingService } from '../../demandforecasting.service';
import { Subject, Subscription } from 'rxjs';
import { ClaimsListService } from '../../../../insurance/claims/claims-list/claims-list.service';
import { stringify } from '@angular/compiler/src/util';

@Component({
  selector: 'app-demand-forecast-dashboard',
  templateUrl: './demand-forecast-dashboard.component.html',
  styleUrls: ['./demand-forecast-dashboard.component.scss']
})
export class DemandForecastDashboardComponent implements OnInit {

  @Input() selectedProductFamily: any;
  @Input() productFamilies: any;
  stockKeepingUnits: any;
  selectedSKU: any = null;
  performanceData: Array<any> = [];
  demandForecastChartData: Array<any> = [];
  demandPlaningDriversChartData: Array<any> = [];
  chartModelData: any = {};
  closeResult = '';
  dataFound: boolean = true;
  Highcharts: typeof Highcharts = Highcharts;
  chartTemplate: any;
  demandForecastData: any;
  cpiIndexData: any;
  discoundOfferedData: any;
  updateFlag: boolean = false;
  SaOPDataLoadStatusSubscription: Subscription
  SaOPDataLoadStatus: any;
  settingEnabled:boolean=false;
  lastExecutionMonthYear:any;
  nextMonthYear:any;
  nextThreeMonthWithYear:any;
  nextThreeMonths:any;
  selectedMonth:any;
  performanceAndChartDataStatus: boolean=false;
  public monthList = [
    { id: 1, name: 'Jan' },
    { id: 2, name: 'Feb' },
    { id: 3, name: 'Mar' },
    { id: 4, name: 'Apr' },
    { id: 5, name: 'May' },
    { id: 6, name: 'Jun' },
    { id: 7, name: 'Jul' },
    { id: 8, name: 'Aug' },
    { id: 9, name: 'Sep' },
    { id: 10, name: 'Oct' },
    { id: 11, name: 'Nov' },
    { id: 12, name: 'Dec' },
  ];
  selectedYear:any;
  newmonth:any;
  selectedYearAndMonth:any;
  chartOptionConfig = {
    chartType: 'line',
    marker: {
      symbol: 'circle',
      fillColor: '#FFFFFF',
      lineWidth: 2,
      lineColor: ''
    }
  }
  multySeriesColors = ['#188fff', '#ff333a', '#00C073', '#e447ea', '#33ddff', '#ff9c33', '#461a63']
  signleSeriesColors = ['#00C073']

  unsubscribe$: Subject<boolean> = new Subject();

  constructor(public demandForecastService: DemandForecastService, 
              private loader: NgxUiLoaderService, private modalService: NgbModal,
              private demandforecastingService: DemandforecastingService,
              private claimsService: ClaimsListService,
              ) { }

  ngOnInit() {
    this.getSupplyChainModel()
    this.getNext3Months();
    this.SaOPDataLoadStatusSubscription = this.demandforecastingService.getSaOPDataLoadStatus().subscribe(status => {
      this.SaOPDataLoadStatus = status;
    })
  }
  async getNext3Months() {
    await this.demandForecastService.getLastExecutionMonth().subscribe((response) => {
      if (response && response.code === 200 && response.data) {
        this.lastExecutionMonthYear = response.data[0].yearMonth;
        const currentMonth = this.lastExecutionMonthYear.toString().substring(4); // getMonth() is zero-indexed; add 1 to give conventional month number.
        const currentYear = this.lastExecutionMonthYear.toString().substring(0, 4);
        this.nextMonthYear = this.getNextMonthsYear(currentMonth, currentYear)
        const next3months = [1, 2, 3].map(n => (currentMonth + n) % 12); // returns array of next three conventional month numbers; %12 caters for end of year wrap-around. 
        this.selectedYear= this.nextMonthYear;
        this.selectedMonth = next3months[0];
        this.selectedYearAndMonth='';
        let modifiedArr =next3months.map(m=>{
          if (m < 10) {
            this.newmonth =  this.nextMonthYear + '0' + m
          } 
          else {
            this.newmonth = this.nextMonthYear + m
          }
          return this.newmonth;
        });
        this.selectedYearAndMonth =modifiedArr.toString();
      }
    });
  }
  getNextMonthsYear(month, year) {
    const date = year + month
    const d = new Date(year);
    d.setMonth(month)
    return d.getFullYear();
  }

  async modelAnalysis(modelName) {
    
    const addModalConfig = {     
      btnSaveText: "",
      btnCancelText: "Cancel",
      model:modelName,
      formData:{
        lastExecutiveMonth :this.lastExecutionMonthYear,
        productFamilyId :this.selectedProductFamily,
        month :this.selectedYearAndMonth
      }

    }
    this.demandForecastService.confirmAddNew(addModalConfig).then(data => {
      if (data) {
        
       return true;
      }
    })
  }
   /***
   * @desc Supply Chain Model Configuration Detail 
   */
   getSupplyChainModel(){
    this.demandForecastService.getSCMModelDetail().subscribe((scMModalObj:any) => {
      if(scMModalObj.enabled==true)
      {
        this.settingEnabled=true;
      }
      
      
    })

  }

  ngOnChanges() {
    this.getStockKeepingUnits(this.selectedProductFamily);
  }

  changeSKU(SKU) {
    this.demandforecastingService.setSelectedSKU(SKU);
    this.selectedSKU = SKU;
    this.getPerformanceAndChartData(this.selectedProductFamily, this.selectedSKU);
  }

  getStockKeepingUnits(selectedProductFamilyId) {
    this.loader.start()
    this.stockKeepingUnits = [];
    this.demandForecastService.getStockKeepingUnitList(selectedProductFamilyId).subscribe((skuResponse) => {
      if (skuResponse && skuResponse.code === 200) {
        this.stockKeepingUnits = skuResponse.data;
        this.stockKeepingUnits.unshift({ id: null, brand: "All", name: 'All', region: "" });
        this.demandforecastingService.getSelectedSKU().subscribe(sku => {
          if(sku === null){
            this.selectedSKU = this.stockKeepingUnits[0].id;
          } else {
            this.selectedSKU = sku;
          }
        });
        this.getPerformanceAndChartData(selectedProductFamilyId, this.selectedSKU);
        this.loader.stop()
      } else { this.loader.stop() }
    });
  }

  getPerformanceAndChartData(productFamilyId: any, selectedSKU?: any) {
    this.loader.start()
    this.demandForecastService.getPerformanceData(productFamilyId, selectedSKU).subscribe((response) => {
      if (response && response.length) {
        response = response.map(e => {
          const responseObj = e === null ? { data: [] } : e;
          return responseObj;
        })

        this.performanceData = response[0].data;
        this.performanceData = this.performanceData.map(e => {
          const obj = {
            ...e,
            data: typeof (e.data) === 'number' ? Number(e.data.toFixed(2)) : e.data
          }
          return obj;
        })
        this.demandForecastService.getDemandForecastChartTemplateJSON().subscribe((template) => {
          this.chartTemplate = template;
          const demandForcastData = {
            title: 'Demand Forecast',
            chartInfo: {title: "Demand Forecast", tooltip: "", format: "week", decimalPoint: 0},

            data: response[1].data
          }
          this.demandForecastData = [response[1]]
          this.cpiIndexData = [response[2].data[0]];
          this.discoundOfferedData = [response[2].data[1]];
          this.performanceAndChartDataStatus = true;
          this.loader.stop()
        })
      } else { this.loader.stop() }
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    this.SaOPDataLoadStatusSubscription.unsubscribe();
  }
}
