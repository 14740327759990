<div class="tab-content tab-content-simulate bg-white">
    <div class="container-fluid p-2 ">
        <div class="row no-gutters optimized-tables">
            <div class="col-sm-12">
                <div class="card shadow-sm">
                    <div class="card-body">
                        <table border="0" cellpadding="0" id="SimulateTable"
                            class="table-cs table mb-0 table-simulate table-bordered table-sm " cellspacing="0"
                            style="font-size: 0.7rem;" aria-describedby="mydesc">
                            <thead>
                                <tr>
                                    <th scope="col" colspan="6" class="p-0 bg-primary">
                                        <h5 class="card-title bg-primary">Current Scenario</h5>
                                    </th>
                                    <th scope="col" class="remove-border"></th>
                                    <th scope="col" colspan="1" class="p-0 bg-success">
                                        <h5 class="card-title bg-success">Simulate Spend </h5>
                                    </th>
                                    <th scope="col" class="remove-border"></th>
                                    <th scope="col" colspan="5" class="p-0 bg-warning">
                                        <h5 class="card-title bg-warning">Simulated Result</h5>
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="col">Spend Levers</th>
                                    <th scope="col">Spend $</th>
                                    <th scope="col">Output <br>(In Units)</th>
                                    <th scope="col">Margin<br> ( In $ )</th>
                                    <th scope="col">Profit <br>( In $ )</th>
                                    <th scope="col">ROI Calculation</th>
                                    <th scope="col" style="width:1%"></th>
                                    <th scope="col" style="width:10%;">Change Spend by %</th>
                                    <th scope="col" style="width:1%"></th>
                                    <th scope="col">New Spend</th>
                                    <th scope="col">Output <br>(In Units)</th>
                                    <th scope="col">Margin <br>( In $ )</th>
                                    <th scope="col">Profit <br>( In $ )</th>
                                    <th scope="col">ROI Calculation</th>
                                </tr>
                            </thead>

                            <tbody *ngIf="selectedBrand !== 99">
                                <tr height="22" class="light-row"
                                    *ngFor="let item of simulateListtData | slice:0:simulateListtData.length-2; let index = index">
                                    <td height="22" [ngClass]="item.isParent  ? 'is-parent' : 'not-parent'">
                                        {{item?.spendLever}}</td>
                                    <td class="width-85">{{item?.spend}}</td>
                                    <td class="width-85">{{item?.modelOutput}}</td>
                                    <td class="width-85">{{item?.margin}}</td>
                                    <td class="width-85">{{item?.profit}}</td>
                                    <td class="width-85">{{item?.roiCalculation}}</td>
                                    <td class="remove-border"></td>
                                    <td class="width-85">
                                        <input
                                            *ngIf="index !== 0 && index !== simulateListtData.length-2 && index !== simulateListtData.length-1"
                                            type="text" class="form-control table-form"
                                            (ngModelChange)="updateChangeValues(index)"
                                            [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="simulateListtData[index].changeSpendByPercent">
                                    </td>
                                    <td class="remove-border"></td>
                                    <td class="width-85">{{item?.newSpend}}</td>
                                    <td class="width-85">{{item?.simulateOutput}}</td>
                                    <td class="width-85">{{item?.simulateOutputMargin}}</td>
                                    <td class="width-85">{{item?.simulateOutputProfit}}</td>
                                    <td class="width-85">{{item?.simulateOutputRoiCalculation}}</td>
                                </tr>
                            </tbody>

                            <tbody *ngIf="selectedBrand === 99">
                                <tr height="22" class="light-row"
                                    *ngFor="let item of simulateListtData | slice:0:simulateListtData.length-7; let index = index">
                                    <td height="22" [ngClass]="item.isParent  ? 'is-parent' : 'not-parent'">
                                        {{item?.spendLever}}</td>
                                    <td class="width-85">{{item?.spend}}</td>
                                    <td class="width-85">{{item?.modelOutput}}</td>
                                    <td class="width-85">{{item?.margin}}</td>
                                    <td class="width-85">{{item?.profit}}</td>
                                    <td class="width-85">{{item?.roiCalculation}}</td>
                                    <td class="remove-border"></td>
                                    <td class="width-85">
                                        <input
                                            *ngIf="index !== 0 && index !== simulateListtData.length-2 && index !== simulateListtData.length-1"
                                            type="text" class="form-control table-form"
                                            (ngModelChange)="updateChangeValues(index)"
                                            [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="simulateListtData[index].changeSpendByPercent">
                                    </td>
                                    <td class="remove-border"></td>
                                    <td class="width-85">{{item?.newSpend}}</td>
                                    <td class="width-85">{{item?.simulateOutput}}</td>
                                    <td class="width-85">{{item?.simulateOutputMargin}}</td>
                                    <td class="width-85">{{item?.simulateOutputProfit}}</td>
                                    <td class="width-85">{{item?.simulateOutputRoiCalculation}}</td>
                                </tr>
                                <tr height="22" class="light-row dark-table"
                                    *ngFor="let item of simulateListtData | slice:simulateListtData.length-7:simulateListtData.length-2; let index = index">
                                    <td height="22" [ngClass]="item.isParent  ? 'is-parent' : 'not-parent'">
                                        {{item?.spendLever}}</td>
                                    <td class="width-85">{{item?.spend}}</td>
                                    <td class="width-85">{{item?.modelOutput}}</td>
                                    <td class="width-85">{{item?.margin}}</td>
                                    <td class="width-85">{{item?.profit}}</td>
                                    <td class="width-85">{{item?.roiCalculation}}</td>
                                    <td class="remove-border"></td>
                                    <td class="width-85"></td>
                                    <td class="remove-border"></td>
                                    <td class="width-85">{{item?.newSpend}}</td>
                                    <td class="width-85">{{item?.simulateOutput}}</td>
                                    <td class="width-85">{{item?.simulateOutputMargin}}</td>
                                    <td class="width-85">{{item?.simulateOutputProfit}}</td>
                                    <td class="width-85">{{item?.simulateOutputRoiCalculation}}</td>
                                </tr>
                            </tbody>

                            <tfoot>
                                <tr height="22" class="light-row"
                                    *ngFor="let item of simulateListtData | slice:simulateListtData.length-2:simulateListtData.length; let index = index">
                                    <th scope="col" height="22">{{item?.spendLever}}</th>
                                    <th scope="col">{{item?.spend}}</th>
                                    <th scope="col">{{item?.modelOutput}}</th>
                                    <th scope="col">{{item?.margin}}</th>
                                    <th scope="col">{{item?.profit}}</th>
                                    <th scope="col">{{item?.roiCalculation}}</th>
                                    <th scope="col" class="remove-border"></th>
                                    <th scope="col"></th>
                                    <th scope="col" class="remove-border"></th>
                                    <th scope="col">{{item?.newSpend}}</th>
                                    <th scope="col">{{item?.simulateOutput}}</th>
                                    <th scope="col">{{item?.simulateOutputMargin}}</th>
                                    <th scope="col">{{item?.simulateOutputProfit}}</th>
                                    <th scope="col">{{item?.simulateOutputRoiCalculation}}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div id="accordion" style="margin: 1rem 0 0 0" *ngIf="selectedBrand !== 99">
            <div class="card">
                <button class="accordion collapsed" data-toggle="collapse" data-target="#collapseTwo"
                    aria-expanded="false" aria-controls="collapseTwo">
                    Simulated Scenario Analysis
                </button>

                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                    <div class="">
                        <div class="row no-gutters">
                            <div class="col-sm-12 col-md-6" *ngIf="!!incrementalSaleChartTemplate">
                                <app-charts [chartOption]="incrementalSaleChartTemplate" [updateFlag]="updateFlag">
                                </app-charts>
                            </div>

                            <div class="col-sm-12 col-md-6" *ngIf="!!mediaResponseChartTemplate">
                                <app-charts [chartOption]="mediaResponseChartTemplate" [updateFlag]="updateFlag">
                                </app-charts>
                            </div>

                            <div class="col-sm-12 col-md-6" *ngIf="!!roiMacChartTemplate">
                                <app-charts [chartOption]="roiMacChartTemplate" [updateFlag]="updateFlag"></app-charts>
                            </div>

                            <div class="col-sm-12 col-md-6" *ngIf="!!shareSpendChartTemplate">
                                <app-charts [chartOption]="shareSpendChartTemplate" [updateFlag]="updateFlag">
                                </app-charts>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>