import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { data } from 'jquery';
import { Subject, Observable, BehaviorSubject, of, forkJoin } from 'rxjs';
const CHART_TMEPLATE_BASE_URL = "../../../../../assets/data/pricing/";
const LIFT_CHART_TEMPLATE_JSON = 'liftChartTemplate.json';
const ACTUAL_CHART_TEMPLATE_JSON = 'actualChartTemplate.json';
const IMPACT_CHART_TEMPLATE_JSON = 'impactChartTemplate.json';
@Injectable({
  providedIn: 'root'
})
export class PricingModelValidationService {

  private updatedFilterValue: Subject<any> = new Subject<any>();
  private sharedFilterEvent: Subject<any> = new Subject<any>();
  updatedFilterValue$: Observable<any> = this.updatedFilterValue.asObservable();
  sharedFilterEvent$: Observable<any> = this.sharedFilterEvent.asObservable();

  constructor(private http: HttpClient) { }

  /**
   * sets filter chart template json
   */
  setUpdatedChartFilterData(updatedData) {
    this.updatedFilterValue.next(updatedData);
  }

  /**
   * Gets filter chart template json
   * @return updatedFilterValue data
   */
  getUpdatedChartFilterData(): Observable<any> {
    return this.updatedFilterValue.asObservable();
  }
// All filter API call
  getFilterChartPanelData(lobProjectId): Observable<any> {
    return this.http.get<any>('pricing/allFilters/' + lobProjectId + '/');
  }

  // Formatting filter data into filter object
  formatFilterPanelData(data): Observable<any> {
    const chartFilters = [] as any;
    const chartFilterObj = {
      "title": {
        "name": "Model Techniques"
      },
      "filterType": "universal",
      "isMultySelect": true,
      "values": [
        {
          "name": "GLM",
          "isSelected": true
        },
        {
          "name": "XGB",
          "isSelected": true
        }
      ]
    };

    const res = data;
    Object.keys(data).forEach(key => {
      if (typeof (data[key]) === 'object') {
        const x = {
          ...chartFilterObj,
          title: {
            ...chartFilterObj.title,
            name: key.replace(/^([a-z]|[A-Z])$/g, (c, i) => (i ? " " : "") + c.toUpperCase())
          },
          filterType: key,
          values: res[key].map((item, index) => {
            return {
              ...item,
              isSelected: index === 0 || index === 1 ? true : false
            }
          })
        }
        chartFilters.push(x);
      }
    })
    return of(chartFilters)
  }

  // Formatting Impact Chart filter data into filter object
  formatImpactChartFilterPanelData(data): Observable<any> {
    const chartFilters = [] as any;
    const chartFilterObj = {
      "title": {
        "name": "Model Techniques"
      },
      "filterType": "universal",
      "isMultySelect": true,
      "values": [
        {
          "name": "GLM",
          "isSelected": true
        },
        {
          "name": "XGB",
          "isSelected": true
        }
      ]
    };

    const res = data;
    Object.keys(data).forEach(key => {
      if (typeof (data[key]) === 'object') {
        const x = {
          ...chartFilterObj,
          title: {
            ...chartFilterObj.title,
            name: key.replace(/^([a-z]|[A-Z])$/g, (c, i) => (i ? " " : "") + c.toUpperCase())
          },
          filterType: key,
          values: res[key].map((item, index) => {
            return {
              ...item,
              isSelected: true
            }
          })
        }
        chartFilters.push(x);
      }
    })
    return of(chartFilters)
  }

  // Lift chart data API call
  getLobModelValidationLiftChart(lobProjectId, modelType, modelTechnique): Observable<any> {
    return this.http.get<any>('lobModelValidationLiftChart/' + lobProjectId + '/' + modelTechnique + '/' + modelType + '/');
  }

  //  ActualvsPredicted chart API call
  getLobModelValidationActualChart(lobProjectId, modelType, modelTechnique, exploratoryVariables): Observable<any> {
    return this.http.get<any>('lobModelValidationActualVsPredicted/' + lobProjectId + '/' + modelTechnique + '/' + modelType + '/' + exploratoryVariables + '/');
  }

  // Impact chart API call
  getImpactChartData(lobProjectId, modelTechnique): Observable<any> {
    return this.http.get<any>('lobModelValidationImpactAnalysis/' + lobProjectId + '/' + modelTechnique + '/');
  }

  // merging and removing duplicate data from Impact chart API response 
  mergeImpactChartData(apiResponce): Observable<any> {
    var data: Array<Object> = [];
    var mergedData = [...apiResponce.data[0].impactAnalysisData]
    var names = new Set(mergedData.map(d => d.name));
    for (var i = 0; i <= apiResponce.data.length - 1; i++) {
      mergedData = [...mergedData, ...apiResponce.data[i].impactAnalysisData.filter(d => !names.has(d.name))];
    }
    return of(mergedData);
  }

  // Formatting impact chart API data into chart Option 
  formatImpactChartData(chartSeriesData, chartTemplate): Observable<any> {
    const finalresponse = [] as any;
    const config = {
      ...chartTemplate,
      title: {
        ...chartTemplate.title,
        text: 'Non-CAT Decile Chart'
      },
      series: chartSeriesData.map((item, itemIndex) => {
        return {
          ...item,
          data: item.data.map(x => {
            return [x[0], (parseFloat(x[1].replace(/,/g, '')))]
          }),
          type: itemIndex === 0 ? 'column' : 'line',
          marker: {
            symbol: 'circle',
            fillColor: '#FFFFFF',
            lineWidth: 2,
            lineColor: null
          },
          visible: true
        };
      })
    }
    finalresponse.push(config);
    return of(finalresponse);
  }

  // Simplify chart data before constructing chart Option
  simplifyResponseToFormat(data): Observable<any> {
    const groupBy = (arr, key) => {
      const initialValue = {};
      return arr.reduce((acc, cval) => {
        const myAttribute = cval[key];
        acc[myAttribute] = [...(acc[myAttribute] || []), cval]
        return acc;
      }, initialValue);
    };
    let final = [] as any;
    const res = groupBy(data, "modelType");
    Object.entries(res).forEach(([key, value]) => {
      let group = [];
      let grp = { 'name': '', 'data': [] } as any;
      grp.name = key;
      res[key].forEach(element => {
        grp.data.push(...element.liftChartData);
      });
      const names = grp.data.map(o => o.name);
      const filtered = grp.data.filter(({ name }, index) => !names.includes(name, index + 1));
      final.push({ 'title': key, 'chartData': filtered.sort((a, b) => (a.name > b.name ? 1 : -1)) });
    });
    return of(final);
  }

  // Lift chart API data into chart Option 
  formateLiftChartAPIData(chartSeriesData, chartTemplate): Observable<any> {
    const finalresponse = [] as any;
    chartSeriesData.forEach((element, index) => {
      const config = {
        ...chartTemplate,
        title: {
          ...chartTemplate.title,
          text: element.modelType.toLowerCase() === 'all' ? 'Combined - '+ element.model : element.modelType  + ' - ' + element.model
        },
        series: element.liftChartData.map((item, itemIndex) => {
          return {
            ...item,
            type: itemIndex === 0 ? 'line' : 'spline',
            dashStyle: itemIndex === 0 ? 'line' : 'Dash',
            visible: true,
            tooltip: {
              valueDecimals: 2
            }
          };
        })
      }
      finalresponse.push(config);
    });
    return of(finalresponse);
  }

  // converting Actual vs Predicted API data into chart option.
  formateActualChartAPIData(chartSeriesData, chartTemplate): Observable<any> {
    const finalresponse = [] as any;
    chartSeriesData.forEach((element, index) => {
      const config = {
        ...chartTemplate,
        title: {
          ...chartTemplate.title,
          text: element.exploratoryVariable.replace(/(^|_)(\w)/g, function ($0, $1, $2) {
            return ($1 && ' ') + $2.toUpperCase()
          }) + ' ' + element.modelType,
        },
        series: element.actualVsPredictedData.map((item, itemIndex) => {
          if(itemIndex === 1) {
            return {
              ...item,
              yAxis: 1,
              type: itemIndex === 0 ? 'column' : 'spline',
              dashStyle: itemIndex === 0 ? 'line' : 'Dash',
              visible: true,
              tooltip: {
                valueDecimals: 2
              }
            };
          } else {
            return {
              ...item,
              type: itemIndex === 0 ? 'column' : 'spline',
              dashStyle: itemIndex === 0 ? 'line' : 'Dash',
              visible: true,
              tooltip: {
                valueDecimals: 2
              }
            };

          } 

        })
      }
      finalresponse.push(config);
    });
    return of(finalresponse);
  }

  /**
  * Gets chart template json
  */
  getLiftChartTemplateJSON(): Observable<any[]> {
    return this.http.get<any>(`${CHART_TMEPLATE_BASE_URL}${LIFT_CHART_TEMPLATE_JSON}`);
  }

  getActualChartTemplateJSON(): Observable<any[]> {
    return this.http.get<any>(`${CHART_TMEPLATE_BASE_URL}${ACTUAL_CHART_TEMPLATE_JSON}`);
  }

  getImpactChartTemplateJSON(): Observable<any[]> {
    return this.http.get<any>(`${CHART_TMEPLATE_BASE_URL}${IMPACT_CHART_TEMPLATE_JSON}`);
  }

  getImpactChartFilterData(lobProjectId): Observable<any> {
    const getModelTechniques = this.http.get<any>('modelTechniques/' + lobProjectId + '/');
    const getModalType = this.http.get<any>('modelTypes/' + lobProjectId + '/');
    return forkJoin([getModelTechniques, getModalType]);
  }

  // show and hide lined for model-technique filter
  modelTechniqueFilter(filter, chartOptions): Observable<any> {
    let updateFlag = false;
    chartOptions.forEach((chartOption) => {
      chartOption.series.forEach((element) => {
        filter[0].values.forEach(item => {
          if (element.name.split(' ')[0].toLowerCase() === item.name.toLowerCase()) {
            element.visible = item.isSelected;
          }
        });
      });
    });
    updateFlag = true;
    return of({ 'chartOptions': chartOptions, 'updateFlag': updateFlag })
  }

}
