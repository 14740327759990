import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { ChatAnalysisService } from '../../chat-analysis.service';
@Component({
  selector: 'app-chat-analysis-listing',
  templateUrl: './chat-analysis-listing.component.html',
  styleUrls: ['./chat-analysis-listing.component.scss']
})
export class ChatAnalysisListingComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  chatListData: Array<any> = []
  constructor(public chatAnalysisService: ChatAnalysisService,private ngxLoader: NgxUiLoaderService, private route: Router) { }

  ngOnInit(): void {
    this.getChatAnalysisListData()
  }

  getChatAnalysisListData(){
    this.ngxLoader.start();
    this.chatListData = [];
   console.log("vb")
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 11,
      processing: true,
      deferRender: true,
      destroy:true
    }
    
    this.chatAnalysisService.getListStatus().subscribe(response => {
      console.log(response)
      if (response && response.data) { 
        this.chatListData = response.data;
        console.log("data",this.chatListData)
        this.dtTrigger.next();
        this.ngxLoader.stop();
      } else {
        console.error('Invalid response format:', response);
      }
    })
    }

    
    /***
   * @desc Show Cat Data
   * @param {String} Module Chat all data show
   */
    getChatData(chat) {
    this.ngxLoader.start();   
    this.chatAnalysisService.setChat(chat);
    this.route.navigate(['/chat/description']);
    this.ngxLoader.stop();
    }
  

}
