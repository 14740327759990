import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfigService } from 'src/app/config.service';
import { PricingService } from '../pricing.service';
import { PricingModelAnalysisService } from './pricing-model-analysis.service';

@Component({
  selector: 'app-pricing-model-analysis',
  templateUrl: './pricing-model-analysis.component.html',
  styleUrls: ['./pricing-model-analysis.component.scss']
})
export class PricingModelAnalysisComponent implements OnInit {
  overviewList: any;
  overviewData: any = [];
  overviewListData: any;
  overviewModelAnalysisData: any;
  comparisionList: any;
  comparisionData: any = [];
  comparisionListData: any;
  overviewGlmList: any;
  overviewGlmListData: any;
  breadcrumb: Array<any> = [];
  public chartFilters: any;
  public lobProjectId: any;
  localStorage = localStorage;
  public modelTechnique = 1;
  public modelType = 1;
  public comparisonChartFilters: any;
  pricingModules: any;

  constructor(private pricingModelAnalysisService: PricingModelAnalysisService, private ngxLoader: NgxUiLoaderService,
    private pricingService: PricingService, private configService: ConfigService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.pricingService.activePricingModule = 'ANALYSIS';
    const item = this.activatedRoute.snapshot.url[0].path.replace('-', ' ').replace(/^([a-z]|[A-Z])$/g, (c, i) => (i ? " " : "") + c.toUpperCase())
    Promise.all([this.localStorage.getItem('lobProjectId')]).then(values => {
      this.lobProjectId = values[0];
      // Adding Breadcumb level 3
      this.pricingService.addBreadcrumbData({ "level": "3", "name": item }).subscribe(data => {
        this.breadcrumb = data;
        this.getFilterData(this.lobProjectId)
      });
    })
  }

  // getting filter data and constructing chart option
  getFilterData(lobProjectId) {
    this.pricingModelAnalysisService.geInduvidualFilterData(lobProjectId).subscribe(responseData => {
      const finalResponse = [] as any;
      responseData.forEach((element, index) => {
        const formattedResponse = {
          ...element,
          title: index === 0 ? "Model Techniques" : "Model Types",
          filterType: index === 0 ? "modelTechniques" : "modelTypes",
          data: element.data.map((el, i) => {
            return {
              ...el,
              isSelected: i === 0 ? true : false
            }
          })
        }
        finalResponse.push(formattedResponse);
      });
      this.chartFilters = finalResponse;
      const temp = this.chartFilters[this.chartFilters.findIndex((obj => obj.filterType === 'modelTechniques'))].data.forEach(e => { e.isSelected = true });
      this.comparisonChartFilters = temp;
    })
  }
}
