<div>

  <div class="sheet-mapping-tab-container m-0 mt-4">
    <div class="bg-white rounded mt-0 p-2 shadowed">
      <h4 class="mt-2 text-dark-custom">Sheet Mapping for "{{fileData?.fileName}}"</h4>
      <div class="tab-content bg-white" id="sheetMappingTabsContent">
        <div class="tab-pane active show" id="registered-projects" role="tabpanel">
          <div class="p-2 tab-content-scroll">
            <div class="row">
              <div class="col-sm-12 custom-label">
                <div class="row">
                  <div class="col-sm-2"><label>Template Sheets</label></div>
                  <div class="col-sm-1"></div>
                  <div class="col-sm-3"><label>Input Sheets</label></div>
                  <div class="col-sm-1"></div>
                  <div class="col-sm-2"><label>Header Start Row</label></div>
                  <div class="col-sm-1"></div>
                </div>
              </div>
            </div>
            <form [formGroup]="sheetMappingForm">
              <div class="row mb-2" *ngFor="let item of sheetMappingFormRows.controls; let i = index;"
                formArrayName="sheets">
                <ng-container [formGroupName]="i">
                  <div class="border-bottom col-sm-12">
                    <div class="row">
                      <div class="col-sm-2 sheet-name">
                        <p>{{ destinationSheetList[i]?.sheetName}} </p>
                      </div>
                      <div class="col-sm-1 text-center">
                        <img src="../../../../assets/images/icon-right-arrow-gray.png" class="sheet-arrow"
                          alt="arrow-right" />
                      </div>
                      <div class="col-sm-3 custom-Dropdown-width">
                        <select class="form-control form-control-sm" formControlName="sourceSheetName"
                          [ngClass]="{ 'error':sheetMappingForm.get('sheets')['controls'][i]['controls'].sourceSheetName.status === 'INVALID' && checkValidation}">
                          <option value="null" selected disabled>Select Source Sheet Name</option>
                          <option *ngFor="let sheet of sheetsList" [ngValue]="sheet">
                            {{sheet}}</option>
                        </select>
                      </div>
                      <div class="col-sm-1"></div>
                      <div class="col-sm-2 header-start">
                        <select class="form-control form-control-sm" formControlName="headerStart"
                          [ngClass]="{ 'error':sheetMappingForm.get('sheets')['controls'][i]['controls'].headerStart.status === 'INVALID' && checkValidation}">
                          <option *ngFor="let x of rowCounter(40); let i = index" [attr.value]="i+1"
                            [attr.selected]='i == 0 ? true : null'>Row {{i+1}}</option>
                        </select>
                      </div>
                      <div class="col-sm-1">
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bottom-button-container">
    <button *ngIf="!isMappingFlow" class="btn btn-sm btn-outline-secondary pulled-left"
      (click)="goToBack()">Back</button>
    <button class="btn btn-sm btn-outline-primary" (click)="getExited()">Exit</button>
    <button class="btn btn-sm btn-outline-primary ml-10" (click)="goForSave()">Save</button>
    <button class="btn btn-sm btn-primary ml-10" (click)="goToNext({})">Next</button>
  </div>

</div>