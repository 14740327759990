<div class="confirmation-modal-header">
    <h3 class="confirmation-modal-title">{{ modalData.title }}</h3>
  </div>
  <div class="modal-body"> 
       
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table dataTable no-footer" #datatableElement>
        <thead>
          <tr>            
            <th scope="col">Rule ID</th>
            <th scope="col">Rule Name</th>
            <th scope="col" style="min-width: 150px;">Rule Discription</th>           
          </tr>
        </thead>
        <tbody *ngIf="ruleListArray?.length != 0">
          <tr *ngFor="let item of ruleListArray, let index as index">            
            <td class="color-black"> Ro{{item?.ruleId}} <span class="is-default" *ngIf="item?.isDefault">D</span></td>
            <td class="color-black">{{item?.ruleName}}</td>
            <td>{{item?.description}}</td>            
          </tr>
        </tbody>
      </table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light btn-sm addcustom"  (click)="decline()">{{ modalData.buttonCancelText
      }}</button>
    
  </div>