import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PricingDataExplorationComponent } from './pricing-data-exploration/pricing-data-exploration.component';
import { PricingFilterChartComponent } from './pricing-filter-chart/pricing-filter-chart.component';
import { PricingLobComponent } from './pricing-lob/pricing-lob.component';
import { PricingManageModelComponent } from './pricing-manage-model/pricing-manage-model.component';
import { PricingModelAnalysisComponent } from './pricing-model-analysis/pricing-model-analysis.component';
import { PricingModelInterpretabilityComponent } from './pricing-model-interpretability/pricing-model-interpretability.component';
import { PricingModelValidationComponent } from './pricing-model-validation/pricing-model-validation.component';
import { PricingProjectComponent } from './pricing-project/pricing-project.component';
import { PricingViewComponent } from './pricing-view/pricing-view.component';


const routes: Routes = [
  {
    path: '',
    component: PricingViewComponent,
    children: [
      { path: 'model-validation', component: PricingModelValidationComponent, data:{title:'Model Validation'}},
      { path: 'model-analysis', component: PricingModelAnalysisComponent, data:{title:'Model Analysis'} },
      { path: 'model-manage', component: PricingManageModelComponent, data:{title:'Manage Model ' }},
      { path: 'model-interpretability', component: PricingModelInterpretabilityComponent, data:{title:'Model Interpretability' }},    
    ]
  },
  { path: 'project', component: PricingProjectComponent },
  { path: 'lob', component: PricingLobComponent },
 
  { path: 'filter-Chart', component: PricingFilterChartComponent },
  { path: 'data-exploration', component: PricingDataExplorationComponent },
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PricingRoutingModule { }
