import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PricingProjectService {

  constructor(private http: HttpClient) { }
  /***
   * @desc Get project head json
   * @returns {Object} Returns Get project head json
   * 
   */
  taskListHead() {
    return [
      { projectId: 'Project ID', coverageType: 'Coverage Type', modelSelected: 'Selected Model', createdDate: 'Created Date', lastUpdated: 'Last Updated', status: 'Status', actions: 'Actions' }
    ]
  }
  /** Author Varun
   * get policy List API
   */
  getPricingList(id): Observable<any[]> {
    return this.http.get<any>('lobProjects/' + id + '/');
  }
}