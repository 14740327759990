<div class="bg">
    <div class="container-fluid mt-5">
        <div class="row">
            <div class="col-sm-12">
                <div class="module-nav underwriter-flow">
                    <div class="float-right dropdown-container">
                        <form class="form-inline">
                            <label class="my-1 mr-2 mt-1" for="p-fam">Product Family</label>
                            <select class="form-control form-control-sm mr-3 mt-1" id="p-fam"
                                *ngIf="productFamilies && productFamilies.length" #productFamily id="pageSize"
                                [(ngModel)]="productFamilyValue"
                                (ngModelChange)="productFamily.value = onProductFamilyChange($event)"
                                [ngModelOptions]="{standalone: true}">
                                <option *ngFor="let item of productFamilies" value="{{item.id}}">{{item?.name}}</option>
                            </select>
                        </form>
                    </div>

                </div>
                <div class="tab-content bg-white" style="height: calc(100vh - 125px);overflow-y: auto;width: 100%;">
                    <div class="container-fluid p-0">
                        <div class="row no-gutters">
                            <div class="col-sm-12 p-1">
                                <div class="demand-planner-metrics">
                                    <div class="row no-gutters dp-metrics">
                                        <div class="col-sm-7">
                                            <div class="card border-0 shadow m-1 map-chart-height">
                                                <div class="card-body">
                                                    <h3 class="section-title d-block">Sales Forecast by Region</h3>
                                                    <div id="map-container" class="text-center pt-1">
                                                        <highcharts-chart *ngIf="isHighcharts" id="container"
                                                            [Highcharts]="Highcharts"
                                                            [constructorType]="chartConstructor"
                                                            [options]="chartOptions" [(update)]="updateFlag"
                                                            style="width: 100%; display: block; height:500">
                                                        </highcharts-chart>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-5">
                                            <div class="card border-0 m-1 custom-shadow">
                                                <div *ngIf="productFamilies?.length; then thenBlock else elseBlock">
                                                </div>
                                                <ng-template #thenBlock>
                                                    <app-initial-saop-planing
                                                        [selectedProductFamily]="selectedProductFamily"  [productFamilies]="productFamilies">
                                                    </app-initial-saop-planing>
                                                </ng-template>
                                                <ng-template #elseBlock></ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>