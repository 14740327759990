<div class="sidebar">
  <ul class="nav nav-sidebar">
    <li class="bordereauxManagement" [ngClass]="{ 'active':selectedNav==='bordereauxManagement'}">
      <a href="javascript:void(0)" data-toggle="tooltip" data-placement="right" title="Summary" 
        (click)="navigateTo('/metamorph/bordereaux-management', 'bordereauxManagement')">
       
      </a>
    </li>
    <li class="ruleConfig" *ngIf="userService.checkAccess('Admin','view')"  [ngClass]="{ 'active':selectedNav==='ruleConfig'}">
      <a href="javascript:void(0)" data-toggle="tooltip" data-placement="right" title="Register & Train"
        (click)="navigateTo('/metamorph/rule-config', 'ruleConfig')">
       
      </a>
    </li>
    <li class="eventLogs" [ngClass]="{ 'active':selectedNav==='eventLogs'}">
      <a href="javascript:void(0)" data-toggle="tooltip" data-placement="right" title="BDX Management"
        (click)="navigateTo('/metamorph/event-log', 'eventLogs')">
       
      </a>
    </li>
  </ul>
</div>
