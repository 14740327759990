import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auto-personal-lines-b',
  templateUrl: './auto-personal-lines-b.component.html',
  styleUrls: ['./auto-personal-lines-b.component.scss']
})
export class AutoPersonalLinesBComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
