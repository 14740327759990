<div class="row">
    <div class="col-sm-2">
        <div class="metric-options">
            <div class="metrics-selection-wrapper mb-3" *ngFor="let filter of chartFilters; index as i">
                <div class="form-group mt-3">
                    <label class="d-block filter-header">{{filter.title}}</label>
                    <ng-container *ngFor="let filterOption of filter.data;  index as index">
                        <div class="custom-control custom-radio custom-control-inline border-0">
                            <input type="radio" id="{{viewName}}-{{filter.filterType}}-{{i}}{{index}}"
                                name="{{viewName}}-{{filter.filterType}}"
                                (change)="updateStatus(filterOption,filter.filterType)" class="custom-control-input"
                                [value]="filterOption.name" [checked]="index === 0 ? true : false">
                            <label class="custom-control-label"
                                for="{{viewName}}-{{filter.filterType}}-{{i}}{{index}}">{{filterOption.name}}</label>
                        </div>

                    </ng-container>

                </div>
            </div>
        </div>
    </div>

    <div class="col-sm-10">
        <div class="exploratory-metrics" style="height: calc(100vh - 305px);
        overflow-y: auto;
        overflow-x: hidden;">
            <div class="row no-gutters">
                <div class="col-sm-12">
                    <div class="card shadow m-2 border-0">
                        <div class="card-body" id="frequency">
                            <h3 *ngIf="modelTechnique===1">Generalized Linear Model Regression Results</h3>
                            <div class="model-res" *ngIf="modelTechnique===1">
                                <div class="row">
                                    <div class="col-md-1 border-right"
                                        *ngFor="let glm of overviewModelAnalysisData?.param">
                                        <h4 class="model-res-label">{{glm?.param}}</h4>
                                        <div class="model-res-val">{{glm?.value}}</div>
                                    </div>
                                </div>
                            </div>
                            <h3 *ngIf="modelTechnique===2">XGB Hyperparameters & Feature Importance</h3>
                            <div class="model-res" *ngIf="modelTechnique===2">
                                <div class="row">
                                    <div class="col-md-1 border-right"
                                        *ngFor="let glm of overviewModelAnalysisData?.param">
                                        <h4 class="model-res-label">{{glm?.Feature}}</h4>
                                        <div class="model-res-val">{{glm?.Values}}</div>
                                    </div>
                                </div>
                            </div>

                            <table *ngIf="modelTechnique===1" class="table table-striped dataTable"
                                style="font-size: 12px;" role="grid" aria-describedby="desc">
                                <thead>
                                    <tr role="row">
                                        <th scope="col" style="width: 450px" class="text-left pl-3"></th>
                                        <th scope="col widthSpace" >Coef</th>
                                        <th scope="col widthSpace">Std Error</th>
                                        <th scope="col widthSpace">Z</th>
                                        <th scope="col widthSpace">P>|z|</th>
                                        <th scope="col widthSpace">[0.025</th>
                                        <th scope="col widthSpace">0.975]</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr role="row" *ngFor="let glm of overviewModelAnalysisData?.score">
                                        <td>{{glm.Feature}}</td>
                                        <td >{{glm.coef}}</td>
                                        <td >{{glm["std err"] | number : '1.3-3'}}</td>
                                        <td >{{glm.z| number : '1.3-3'}}</td>
                                        <td >{{glm["P>|z|"]  | number : '1.3-3'}}</td>
                                        <td >{{glm["[0.025"] | number : '1.3-3'}}</td>
                                        <td >{{glm["0.975]"]| number : '1.3-3'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table *ngIf="modelTechnique===2" class="table table-striped dataTable"
                                style="font-size: 12px;" role="grid" aria-describedby="desc">
                                <thead>
                                    <tr role="row">
                                        <th scope="col" class="text-left pl-3"></th>
                                        <th scope="col" style="width: 150px;">Feature Importance
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr role="row" *ngFor="let glm of overviewModelAnalysisData?.score">
                                        <td>{{glm.feature}}</td>
                                        <td>{{glm.importance  | number : '1.3-3'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>