import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auto-personal-lines-e',
  templateUrl: './auto-personal-lines-e.component.html',
  styleUrls: ['./auto-personal-lines-e.component.scss']
})
export class AutoPersonalLinesEComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
