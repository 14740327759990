import { NgModule, CUSTOM_ELEMENTS_SCHEMA,InjectionToken, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { LoginComponent } from './module/auth/login/login.component';
import { UserServiceService } from 'src/app/shared/services/user-service.service';
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { MsalModule, MsalInterceptor, MsalAngularConfiguration, MSAL_CONFIG_ANGULAR, MsalService, MSAL_CONFIG } from '@azure/msal-angular';
import { HttpInterceptorsInterceptor } from './core/http-interceptors/http-interceptors.interceptor';
import { MatDialogModule } from '@angular/material/dialog';
import { CoreModule } from './core/core.module';
import { Configuration } from 'msal';
import { ConfigService } from './config.service';
import { ShortNamePipe } from './module/profile/short-name.pipe';
const AUTH_CONFIG_URL_TOKEN = new InjectionToken<string>('AUTH_CONFIG_URL');
import { SharedModule } from './shared/shared.module';
import { ProfileComponent } from './module/profile/profile.component';
import { PricingModule } from './module/insurance/pricing/pricing.module';
import { DemandforecastingModule } from './module/supplychain/demand-forecasting/demandforecasting.module';
import { ScmMmmModule } from './module/supplychain/scm-mmm/scm-mmm.module';
import { MetamorphModule } from './module/metamorph/metamorph-base/metamorph.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {DatePipe} from '@angular/common';



export const protectedResourceMap: [string, string[]][] = [
  ['https://graph.microsoft.com/v1.0/me', ['user.read']],
];

const configFile ='assets/config/config.json'
export function initializerFactory(env: ConfigService, configUrl: string): any {
  const promise = env.init(configUrl).then((value) => {
    const x=value;
  });
  return () => promise;
}
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
function MSALConfigFactory(config: ConfigService): Configuration {
  return {
    auth: {

      clientId: config.getSettings('clientId'),
      authority: config.getSettings('AUTHORITY'),
      validateAuthority: config.getSettings('VALIDATION_AUTHORITY'),
      redirectUri: config.getSettings('redirectUrl'),
      postLogoutRedirectUri: config.getSettings('POST_LOGOUT_REDIRECT_URI'),
      navigateToLoginRequestUrl: config.getSettings('NAVIGATION_TO_LOGIN'),
    },
cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE,
    },
  };
}

function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return {
    popUp: !isIE,
    consentScopes: ['user.read', 'openid', 'profile'],
    unprotectedResources: ['https://www.microsoft.com/en-us/'],
    protectedResourceMap,
    extraQueryParameters: {},
  };
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ProfileComponent,
    ShortNamePipe

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    NgxUiLoaderModule,
    MatDialogModule,
    CoreModule,
    MsalModule,
    SharedModule,
    PricingModule,
    DemandforecastingModule,
    ScmMmmModule,
    MetamorphModule,
    FormsModule,
    CommonModule

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ConfigService,DatePipe,
  { provide: AUTH_CONFIG_URL_TOKEN, useValue: configFile },
  { provide: APP_INITIALIZER, useFactory: initializerFactory,
    deps: [ConfigService, AUTH_CONFIG_URL_TOKEN], multi: true },

  { provide: MSAL_CONFIG, useFactory: MSALConfigFactory, deps: [ConfigService] },
  { provide: MSAL_CONFIG_ANGULAR, useFactory: MSALAngularConfigFactory, deps: [ConfigService] },
  UserServiceService, MsalService,
  { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true, },
  { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorsInterceptor, multi: true },
],
  bootstrap: [AppComponent]
})
export class AppModule { }
