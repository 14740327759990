<div class="mt-5 pt-0" style="background: #FAFAFA;">
    <div class="container-fluid">

        

        <div class="row ">
            <div class="col-md-9 mt-2">
                <div class="policy-type">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4 pl-3 mb-0">
                                    <div class="policy-list">
                                        <h5>Policy Type: <span>Home</span></h5>
                                        <div class="perils-list">
                                            <p class="peril accident active">
                                                <img src="assets/images/home-icon.png" class="policy-icon" style="height: 60px;"/>
                                                <!-- <span>Auto</span> -->
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-0">
                                    <div class="policy-details">
                                        <h5>Customer ID <span>
                                            C17454
                                        </span></h5>
                                        <h5>Policy Number<span>POL/2023/HOME/83484</span>  </h5>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-0">
                                    <div class="policy-details status">
                                        <h5>Renewal Status<span>Active</span></h5>
                                        <h5>Renewal Date <span>01 Jan 2024</span> </h5>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        <div class="col-md-6">
                            <div class="policy-coverages">
                                <h5>Policy Coverages</h5>
                                <div class="perils-list">
                                    <!-- <p class="peril accident active">
                                        <img src="assets/images/vandalism.png" />
                                        <span>Vandalism</span>
                                    </p>
                                    <p class="peril accident active">
                                        <img src="assets/images/peril-theft.png" />
                                        <span>Theft</span>
                                    </p> -->
                                    <p class="peril accident active">
                                        <img src="assets/images/windstorm.png" />
                                        <span>Dwelling</span>
                                    </p>
                                    <!-- <p class="peril accident active">
                                        <img src="assets/images/fire-lightining.png" />
                                        <span>Fire and lightning</span>
                                    </p> -->
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-md-3 mt-2">
                <div class="policy-rating">
                    <h5>Key Features</h5>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="policy-list">
                                <div class="perils-list pb-0">
                                    <p class="peril accident active">
                                        <img src="assets/images/Key Features.png" />
                                        <!-- <span>Key Features</span> -->
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <span class="badge badge-features">High income group
                            </span>

                            <span class="badge badge-features"> Homeowner
                            </span>

                           


                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="auto-personal-column">
                    <div class="auto-personal-item">
                        <h2 class="groupHeading">Group D</h2>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Head of household age</h4>
                            <div class="metric-val">46-50
                            </div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Type of Property</h4>
                            <div class="metric-val">Single Family
                            </div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Household Income</h4>
                            <div class="metric-val">$95,000-$120,999

                            </div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Household Size</h4>
                            <div class="metric-val">2+ Persons

                            </div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Home ownership</h4>
                            <div class="metric-val">Homeowner</div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Age of
                                Children
                            </h4>
                            <div class="metric-val">1-3</div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Renewal Probability</h4>
                            <div class="metric-val">70%
                            </div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">CSAT</h4>
                            <div class="metric-val">7</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row mt-2">

            <div class="col-md-3 pr-3">
                <div class="metric features chat-box">
                    <h4 class="groupHeading"><span><img src="assets/images/chat-bot.png" /></span>CSR in Call

                    </h4>
                    <ul class="reasons chat-content"> 
                        <li class="question">My dwelling coverage limit has increased. How does it impact my overall coverage?</li>
                        <li class="answer">An increase in your dwelling coverage limit will provide you with more coverage for your home in the event of a covered loss. This means that if your home is damaged or destroyed, your insurance company will pay up to the increased limit to help you repair or rebuild your home. This increased coverage can provide you with greater peace of mind that you are adequately protected in the event of a loss.
                        </li>
                        <li class="question">What is the deductible for dwelling coverage?</li>
                         <li class="answer">The deductible for dwelling coverage is  $1,000.</li>
                        

                    </ul>
                    <ul class="send-message">
                        <li >
                            <input type="text" placeholder="Start Typing Here..." class="form-control">
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-6">
                        <div class="metric features">
                            <h4 class="groupHeading"><span><img src="assets/images/Billing History.png" /></span>Billing
                                History/Policy/Claim Experience
        
                            </h4>
                            <ul class="reasons">
                                <li>Current dwelling coverage is $200,000.</li>
                                <li>Two claims for water damage in the past three years.</li>
                                <li>Change in Marital status to Married.</li>
                                <li>Addition of a swimming pool to the property</li>
        
        
                            </ul>
                        </div>
                    </div>
        
        
                    <div class="col-md-6 pr-3">
                        <div class="metric features">
                            <h4 class="groupHeading"><span><img src="assets/images/Segment Recommendation.png" /></span>Segment
                                Recommendations </h4>
                            <ul class="reasons">
                                <li>Provide a clear and concise explanation of coverage components and how each component is affected by the change in the dwelling coverage limit.

                                </li>
                                <li>Implement training on effective communication, emphasizing the importance of clarity, patience, and the ability to address customer concerns without rushing.

                                </li>
                            </ul>
                        </div>
                    </div>
        
        
                    <div class="col-md-4">
                        <div class="metric features">
                            <h4 class="groupHeading"><span><img src="assets/images/Driver Profile.png" /></span>Property risk Factors

                            </h4>
                        

                            <ul class="reasons">
                                <li>Proximity to Fire Stations</li>
                                <li>Age of the property   - 20 years</li>
                                <li>Estimated house value - $50,000–$74,999 </li>

                               
                            </ul>
                        </div>
                    </div>
        
                    <div class="col-md-4">
                        <div class="metric features">
                            <h4 class="groupHeading"><span><img src="assets/images/Cross-sell.png" /></span>Cross-sell/Upsell
                                Recommendations
        
                            </h4>
                            <ul class="reasons">
                                

                                <li> Bundling homeowner's insurance with auto insurance .
                                </li>
                            </ul>
                        </div>
                    </div>
        
                    <div class="col-md-4 pr-3">
                        <div class="metric features">
                            <h4 class="groupHeading"><span><img src="assets/images/Customer Feedback.png" /></span>Customer
                                Feedback
                            </h4>
                            <ul class="reasons">
                                <li>The CSR was unable to provide a satisfactory explanation regarding the impact of the increased dwelling coverage limit on my overall coverage. I felt the explanation was rushed and did not provide the clarity I was seeking.

                                </li>
                            </ul>
                        </div>
                    </div>
        
                </div>
            </div>

          
        </div>


    </div>
</div>