<div class="p-relative-custom">
  <table datatable [dtOptions]="dtOptions" aria-describedby="mydesc" class="child_table"
    style="font-size: 11px!important;">
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">Version</th>
        <th scope="col">File/Data Name</th>
        <th scope="col">Reporting Period</th>
        <th scope="col">Ingestion Type</th>
        <th scope="col">Size</th>
        <th scope="col">Uploaded By</th>
        <th scope="col">Last Modified</th>
        <th scope="col">Last Modified By</th>
        <th scope="col">Iteration</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody *ngIf="dataRow?.length != 0">

      <tr *ngFor="let recordx of dataRow[0];">
       
        <td scope="first-col" style="width: 25px;">&nbsp;</td>
        <td scope="first-col" style="width: 165px;">&nbsp;</td>
        <td scope="first-col" style="width: 317px;">
          
          <p class="custom-ellipsis" placement="right" [ngbTooltip]="tooltipOptimizeC" tooltipClass="custom-tooltipColor">{{recordx?.fileName}}</p> 
          <ng-template #tooltipOptimizeC>
            <div>{{recordx?.fileName}}</div>
          </ng-template>
          <span class="badge " [ngClass]="{'customBgBlue': recordx.status=='Ready For Standardization',
                        'badge-success': recordx.status=='Standardized',
                        'customBgRed':  recordx.status=='Standardization Failed',
                        'customBgYellow':  recordx.status=='Standardization In-Progress',
                        'customBgGreen':  recordx.status=='Data uploaded',
                        'badge-danger':  recordx.status=='System Error',
                        'customBgRedBack':  recordx.status=='Mapping Failed'
                    }">{{recordx.status}}</span>
        </td>
        <td scope="first-col" style="width: 106px;position: relative;"><p style=" position: absolute;
          left: -44px;" class="custom-ellipsisDate">
          {{recordx.reportStartDate | date: 'd MMMM yy'}} to
          {{recordx.reportEndDate | date:
          'd MMMM yy'}}
        </p></td>
        <td scope="first-col" style="width: 61px;position: relative;"><span style="position: absolute;
          left: -6px;">{{recordx.ingestionType}}</span></td>
        <td scope="first-col" style="width: 58px;position: relative;"><span style="position: absolute;
          left: 3px;">{{recordx.fileSizeInMb | number : '1.2-2'}} MB </span></td>
        <td scope="first-col" style="width: 65px;    position: relative;"><p style ="position: absolute;
          left: 6px;"class="custom-ellipsisName">{{recordx.uploadedBy}}</p></td>
        <td scope="first-col" style="width: 127px;">{{recordx.updatedAt | date: 'd MMMM yy h:mm:ss a'}}</td>
        <td scope="first-col" style="width: 65px;"><p class="custom-ellipsisName">{{recordx.modifiedBy}}</p></td>
        <td scope="first-col" style="width: 59px;position: relative;"><span style="    position: absolute;
          left: 17px;">{{recordx.iteration}}</span></td>
        <td scope="first-col" style="width: 39px;">
          <div class="dropdown"><img src=".. /../../../../../../assets/images/dots.png" data-toggle="dropdown" aria-expanded="false" />
            <div class="dropdown-menu">
              <button *ngIf="userService.checkAccess('metamorph-standardization','delete')  &&  recordx.status=='Data uploaded'" class="dropdown-item" type="button">Delete</button>
              <button class="dropdown-item" type="button"><a href="{{recordx.downloadInputLink}}">Download Source Data</a></button>
            <button *ngIf="recordx.status!='Data uploaded' && recordx.status!='Ready For Standardization'" class="dropdown-item" type="button"> <a href="{{recordx.downloadOutputLink}}">Download Standardized Data</a></button>
            </div>
        </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="contextmenu">
    <app-context-menu-component [x]="contextmenuX" [y]="contextmenuY" [z]="contextId" [k]="contextClass"
      [l]="contextRecord"></app-context-menu-component>
  </div>
</div>