<div class="header-range-container row" *ngIf="!!updatedFileData">
  <div class="col-sm-12">
    <h3 class="custom-table-headers">Preview</h3>
  </div>
  <div class="col-sm-12">
    <!-- Tabs of sheets -->
    <div class="training-details-container">
      <div class="module-nav">
        <ul class="nav nav-tabs mt-2" id="training-details" role="tablist">
          <ng-container *ngFor="let tab of updatedFileData; index as index">
            <li class="nav-item">
              <a class="nav-link sub-module active" role="tab" (click)="selectSheet( tab?.sheetName, $event)"
                [ngClass]="{ 'active':selectedSheet=== tab?.sheetName}">{{tab?.sheetName}}</a>
            </li>
          </ng-container>
        </ul>
      </div>
      <div class="bg-white rounded mt-0 p-2 tab-panel-wrapper" *ngIf=" updatedFileData?.length">
        <!-- tab pannel -->
        <div class="tab-content bg-white" id="">
          <ng-container *ngFor="let tab of updatedFileData; let idx = index">
            <div class="tab-pane active show" id="registered-projects" role="tabpanel"
              [ngClass]="{ 'active show':selectedSheet=== tab?.sheetName}">
              <div class="table-wrapper">
                <table class="table table-bordered">
                  <ng-container *ngFor="let row of tab?.data; let first = first; let index = index">
                    <tr [ngClass]="{ 'header-row': first}">
                      <td style="width: 20px; min-width: 20px" *ngIf="!first">{{ index }}</td>
                      <td style="width: 20px; min-width: 20px" *ngIf="first">&nbsp;</td>
                      <td *ngFor="let val of row">
                        {{val}}
                      </td>
                    </tr>
                  </ng-container>
                </table>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

    </div>
  </div>
  <div class="bottom-button-container">
    <button class="btn btn-sm btn-outline-secondary pulled-left" (click)="goToBack()">Back</button>
    <!-- don't remove this comment this save functionality -->
    <!-- <button class="btn btn-sm btn-outline-primary" (click)="goForSave()">Save</button> -->
    <button class="btn btn-sm btn-outline-primary" (click)="getExited()">Exit</button>
    <button class="btn btn-sm btn-primary ml-10" (click)="goToNext()">Next</button>
  </div>
</div>
