import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { PricingModelValidationService } from '../pricing-model-validation.service';
import * as Highcharts from 'highcharts';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NONE_TYPE } from '@angular/compiler';
import { PricingService } from '../../pricing.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pricing-model-validation-lift',
  templateUrl: './pricing-model-validation-lift.component.html',
  styleUrls: ['./pricing-model-validation-lift.component.scss']
})
export class PricingModelValidationLiftComponent implements OnInit {

  chartFilters: any;
  liftChartData: any;
  liftChartAPIData: any;
  filterData: any;
  updatedFilterData: any;
  chartModelData: any;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: any;
  chartAPIOptions: any;
  closeResult = '';
  updateFlag: boolean = false;
  liftChartTemplate: any;
  errorString = "";
  public lobProjectId: any;
  localStorage = localStorage;
  public modelTechnique = "1,2";
  public modelType = "1,2,3";
  modelTypeFilters: Array<any> = [];
  modelTechniqueFilters: Array<any> = [];

  constructor(private pricingModelValidationService: PricingModelValidationService, private modalService: NgbModal, 
              private pricingService: PricingService, private ngxLoader: NgxUiLoaderService, public toastr: ToastrService) { }

  ngOnInit(): void {
    this.pricingService.activeTab = "liftChart";
    this.pricingService.sendTabLoadEvent("liftChart");
  }

  ngAfterViewInit(): void {
    Promise.all([this.localStorage.getItem('lobProjectId')]).then(values => {
      this.lobProjectId = values[0];
      this.getFilterData(this.lobProjectId);
    })
  }

  getLiftChartData(lobProjectId, modelType, modelTechnique) {
    // Fetching lift chart template
    this.pricingModelValidationService.getLiftChartTemplateJSON().subscribe(template => {
      if (template) {
        this.liftChartTemplate = template;
        // Lift chart API data
        this.pricingModelValidationService.getLobModelValidationLiftChart(lobProjectId, modelType, modelTechnique).subscribe(apiResponce => {
          if (apiResponce) {
            apiResponce.data.sort((a,b) => a.modelTypeId - b.modelTypeId);
              // Constructing Chart Option
              this.pricingModelValidationService.formateLiftChartAPIData(apiResponce.data, this.liftChartTemplate).subscribe(response => {
                if (response) {
                  this.chartOptions = response;
                  this.ngxLoader.stopAll();
                }
              });
          } else { this.chartOptions = []; this.ngxLoader.stopAll(); };
        });
      };
    });
  }

  // fetch filter panel data
  getFilterData(lobProjectId) {
    this.ngxLoader.start();
    this.pricingModelValidationService.getFilterChartPanelData(lobProjectId).subscribe(data => {
      if (data) {
        this.pricingModelValidationService.formatFilterPanelData(data).subscribe((formattedResponse) => {
          this.formatFilterData(formattedResponse);
        });
      }
    });
  }

  // Formating filter data into filter object based on filter
  formatFilterData(formattedResponse) {
    const filterType1 = ["modelTechniques", "modelTypes"];
    if (formattedResponse) {
      this.chartFilters = formattedResponse.filter((item) => filterType1.includes(item.filterType));
      this.chartFilters[this.chartFilters.findIndex((el) => el.filterType === 'modelTypes')].values.push({modelTypeId: 3, id: 3, name: 'Combined', isSelected: true})
      this.chartFilters.forEach((element, index) => {
        if(element.filterType === 'modelTypes') {
          element.values.forEach((item) => {
            if (item.isSelected) {
              this.modelTypeFilters.push(item.id);
            }})
          this.modelType = this.modelTypeFilters.join(',') 
        }else if(element.filterType === 'modelTechniques') {
          element.values.forEach((item) => {
            if (item.isSelected) {
              this.modelTechniqueFilters.push(item.id);
            }})
          this.modelTechnique = this.modelTechniqueFilters.join(',') 
        }
      });
      this.getLiftChartData(this.lobProjectId, this.modelType, this.modelTechnique);
    }
  }

  // Update chart on filter change
  updateLiftChartFilterStatus(checkbox, i, filterType) {
    if (filterType === 'modelTechniques') {
      if (this.modelTechniqueFilters.includes(checkbox.id)) {
        this.modelTechniqueFilters = this.modelTechniqueFilters.filter(e => e !== checkbox.id);
      } else {
        this.modelTechniqueFilters.push(checkbox.id);
        this.modelTechniqueFilters = this.modelTechniqueFilters.sort(function (a, b) { return a - b; });
      }
    } else {
      if (filterType === 'modelTypes') {
        if (this.modelTypeFilters.includes(checkbox.id)) {
          this.modelTypeFilters = this.modelTypeFilters.filter(e => e !== checkbox.id);
        } else {
          this.modelTypeFilters.push(checkbox.id);
          this.modelTypeFilters = this.modelTypeFilters.sort(function (a, b) { return a - b; });
        }
      }
    }
      
      if (!this.modelTypeFilters.length || !this.modelTechniqueFilters.length) {
        this.toastr.error('Select at least one filter form each filter group');
        this.chartOptions = [];
      } else {
        this.modelType = this.modelTypeFilters.join(",");
        this.modelTechnique = this.modelTechniqueFilters.join(',');
        this.getLiftChartData(this.lobProjectId, this.modelType, this.modelTechnique); 
      }
    
  }
  modelTechniqueFilter(checkbox: any) {
    throw new Error('Method not implemented.');
  }

  /**
   * Descs pricing model validation lift component
   * @param content 
   * @param data 
   */
  openXl(content, data) {
    this.chartModelData = data;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  filterUser(item) {
    return !!item.displayChart;
  }
}
