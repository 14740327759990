<div class="exploratory-metrics">
    <div class="row no-gutters chart-wrapper impact-page-container">
        <div class="col-sm-2 filter-padding">
            <div class="metric-options">
                <div class="metrics-selection-wrapper mb-3" *ngFor="let filter of pdpChartFilters; index as i">
                    <div class="form-group mt-3" *ngIf="filter.filterType === 'modelTechniques'">
                        <label class="d-block filter-header">{{filter.title}}</label>
                        <ng-container *ngFor="let filterOption of filter.data;  index as index">
                            <div class="custom-control custom-checkbox checkbox-margin">
                                <input type="checkbox" id="{{viewName}}-{{filter.filterType}}-{{i}}{{index}}"
                                    name="{{viewName}}-{{filter.filterType}}"
                                    (change)="updateFilterStatus(filterOption,i,filter.filterType)"
                                    class="custom-control-input" [value]="filterOption.name"
                                    [checked]="index === 0 ? true : false" [(ngModel)]="filterOption.isSelected">
                                <label class="custom-control-label checkbox-label-margin"
                                    for="{{viewName}}-{{filter.filterType}}-{{i}}{{index}}">{{filterOption.name}}</label>
                            </div>
                        </ng-container>
                    </div>
                    <div class="form-group mt-3" *ngIf="filter.filterType === 'modelTypes'">
                        <label class="d-block filter-header">{{filter.title}}</label>
                        <select class="custom-select form-control form-control-sm my-1 mr-sm-2 shap-custom-select" 
                        id="{{viewName}}-{{filter.filterType}}" [(ngModel)]="modelType" 
                        (change)="updateFilterStatus(modelType,i,filter.filterType)"
                        [ngModelOptions]="{standalone: true}">                     
                            <option *ngFor="let filterOption of filter?.data;  index as index" [value]="filterOption?.id" [selected]= "filterOption?.id === 1">{{filterOption?.name}}</option>
                        </select>
                    </div>
                    <div class="form-group mt-3" *ngIf="filter.filterType === 'exploratoryVariables'">
                        <label class="d-block filter-header">{{filter.title}}</label>
                        <select class="custom-select form-control form-control-sm my-1 mr-sm-2 shap-custom-select" 
                        id="{{viewName}}-{{filter.filterType}}" [(ngModel)]="exploratoryVariable"
                         (change)="updateFilterStatus(exploratoryVariable,i,filter.filterType)"
                         [ngModelOptions]="{standalone: true}">                     
                            <option *ngFor="let filterOption of filter?.data;  index as index" [value]="filterOption?.id" [selected]= "filterOption?.id === 1">{{filterOption?.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-10">
            <h3 class="section-header">{{selectedExploratoryVariable}}</h3>
            <ng-container *ngFor="let item of chartOptions ; index as i; let last = last">
                <div class="col-6 float-left p-0"> <!--[ngClass]="(i % 2 === 0 && last )?'col-sm-12':'col-sm-6'">-->
                    <div class="card shadow m-1 border-0" style="width: calc(100% -  10px); margin-bottom: 10px; ">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <h3 class="section-title">{{item?.title?.text | titlecase}}</h3>
                                </div>
                                <div class="">
                                    <a href="#" class="mr-2 has-tooltip" title="Expand" data-toggle="modal"
                                        data-target="#expandKPIModal" (click)="openXl(longContent,item)">
                                        <span class="material-icons">open_in_new</span>
                                    </a>
                                    <!-- <a href="#" class="has-tooltip" title="Download in Excel">
                                        <span class="material-icons">file_download</span>
                                    </a> -->
                                </div>
                            </div>
                            <div class="text-center chart-container">
                                <h3 *ngIf="!item.series[0].data.length || !item.series[1].data.length">No Data Found</h3>
                                <highcharts-chart *ngIf="item.series[0].data.length && item.series[1].data.length" class="highcharts" [(update)]="updateFlag" [Highcharts]="Highcharts"
                                    [options]="item">
                                </highcharts-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<!-- Model -->
<ng-template #longContent let-modal>
    <div class="modal-header">
        <h3 class="section-title">{{chartModelData?.title?.text | titlecase}} </h3>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <highcharts-chart class="highcharts-modal" [Highcharts]="Highcharts" [options]="chartModelData">
        </highcharts-chart>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Save click')">Cancel</button>
    </div>
</ng-template>