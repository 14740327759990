<div class="confirmation-modal-header">
    <h3 class="confirmation-modal-title">{{ title }}</h3>
    <!-- <button type="button" class="close conformation-close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button> -->
</div>
<div class="modal-body">
    <p class="confirmation-message" [innerHTML]="message"></p>
    <p *ngIf="!!discription" class="textDescription" [innerHTML]="discription"></p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-light btn-sm addcustom" (click)="decline()">{{ btnCancelText }}</button>
    <button type="button" *ngIf="btnOkText !== 'Delete'" class="btn btn-primary btn-sm" (click)="accept()">{{ btnOkText }}</button>
    <button type="button" *ngIf="btnOkText === 'Delete'" class="btn btn-danger btn-sm" (click)="accept()">{{ btnOkText }}</button>
</div>