import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BordereauxManagementService } from '../../../services/bordereaux-management.service';

@Component({
  selector: 'edit-geography',
  templateUrl: './edit-geography.component.html',
  styleUrls: ['./edit-geography.component.scss'],
})
export class EditGeographyComponent implements OnInit {
  @Input() modalData: any;
  @Input() modalTagsData: any;
  filterData: any;
  geographyList: any;
  selectedGeographyList: any;

  constructor(
    private activeModal: NgbActiveModal,
    public BDXManagementService: BordereauxManagementService
  ) {}

  ngOnInit(): void {
    this.getFilterData();
  }

  getFilterData() {
    this.BDXManagementService.getMetamorphFilterData().subscribe((res) => {
      if (res && res.code === 200) {
        this.geographyList = res.country.map((e) => {
          return {
            ...e,
            isSelected: this.modalData.selectedGeography.includes(e.name),
          };
        });
      }
    });
  }

  public decline() {
    this.activeModal.close(false);
  }

  public save() {
    this.selectedGeographyList = this.geographyList.filter((e) => e.isSelected);
    this.activeModal.close(this.selectedGeographyList);
  }
}
