<div class="claimList">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="module-nav">
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item"  [ngClass]="isActiveModule(pricingModules.PROJECTS)">
                            <a (click)="gotoThisModule(pricingModules.PROJECTS)" href="javascript:void(0)" class="dropbtn sub-module">Projects List </a>
                        </li>
                        <li class="list-inline-item" [ngClass]="isActiveModule(pricingModules.MANAGE)">
                            <a (click)="gotoThisModule(pricingModules.MANAGE)" href="javascript:void(0)" class="dropbtn sub-module">Manage Models</a>
                        </li>
                        <li class="list-inline-item " [ngClass]="isActiveModule(pricingModules.ANALYSIS)">
                            <a (click)="gotoThisModule(pricingModules.ANALYSIS)" href="javascript:void(0)" class="dropbtn sub-module">Model Analysis</a>
                        </li>
                        <li class="list-inline-item" [ngClass]="isActiveModule(pricingModules.INTER)">
                            <a (click)="gotoThisModule(pricingModules.INTER)" href="javascript:void(0)" class="dropbtn sub-module">Model Interpretability </a>
                        </li>
                        <li class="list-inline-item" [ngClass]="isActiveModule(pricingModules.VALIDATION)">
                            <a (click)="gotoThisModule(pricingModules.VALIDATION)" href="javascript:void(0)"  class="dropbtn sub-module">Model Validation</a>
                        </li>
                    </ul>
                </div>
                <div class="pt-0 dt-bootstrap4 fix-height">
                    <router-outlet></router-outlet>
                </div>
                <div class="container-fluid p-0">
                    <div class="border-top bg-light text-right p-2">
                        <button class=" btn btn-outline-primary btn-sm prev"
                            id="" data-toggle="tab" href="tabIdForPrev" (click)="navigateToAnotherPricingModule(prev)"
                        ><span>❮</span> PREVIOUS</button>
                        <button *ngIf="!isModelSelectionVisible && showNextButton " class="btn btn-primary btn-sm" id="" data-toggle="tab" href="#vehicle-info" role="tab"                        
                        (click)="navigateToAnotherPricingModule(next)">Next <span>❯</span></button>
                        <button *ngIf="isModelSelectionVisible" class="btn btn-primary btn-sm" (click)="selectModel()">Select Model</button>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>