<div class="confirmation-modal-header">
    <h3 class="confirmation-modal-title">{{ title }}</h3>
</div>
<div class="modal-body">
    <p class="confirmation-message">Please Enter Scenario Name</p>
    <input type="text" [(ngModel)]="scenarioName" class="form-control">
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-light btn-sm addcustom" (click)="decline()">{{ btnCancelText }}</button>
    <button type="button" class="btn btn-primary btn-sm" [disabled]="scenarioName === ''" (click)="save()">{{ btnOkText }}</button>
</div>