import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-scenario-name-popover',
  templateUrl: './scenario-name-popover.component.html',
  styleUrls: ['./scenario-name-popover.component.scss']
})
export class ScenarioNamePopoverComponent implements OnInit {

  public scenarioName: string = '';
  @Input() title: string;
  @Input() btnOkText: string;
  @Input() scenarioNamePreview: string;
  @Input() btnCancelText: string;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.scenarioName = this.scenarioNamePreview;
  }

  public decline() {
    this.activeModal.close(false);
  }

  public save() {
    this.activeModal.close(this.scenarioName);
  }

}
