import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MetamorphCommonService } from '../metamorph-base/services/metamorph-common.service';
import { UserServiceService } from 'src/app/shared/services/user-service.service';

@Component({
  selector: 'metamorph-side-nav',
  templateUrl: './metamorph-side-nav.component.html',
  styleUrls: ['./metamorph-side-nav.component.scss']
})
export class MetamorphSideNavComponent implements OnInit {

  public selectedNav : string = 'bordereauxManagement'
  constructor(private router: Router, public metamorphCommonService: MetamorphCommonService,public userService: UserServiceService) { }

  ngOnInit(): void {
  }

  navigateTo(url, viewName) {
    this.selectedNav = viewName;
      this.router.navigate([url]);

  }

}
