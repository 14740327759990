<header class="fixed-top bg-light">
  <nav class="navbar header-top navbar-expand-lg navbar-light bg-white pl-0">
    <a  href="javascript:void(0);" (click)="goBack()" *ngIf="displayContent"> <img src="assets/images/left-arrow.png" class="arrow-back"/></a>
    <a class="navbar-brand mr-1" href="javascript:void(0);" (click)="navigateToOtherRoute('/home')"></a>
    <span class="module-name">{{moduleName}}</span>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText"
      aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav ml-md-auto d-md-flex">
        <li class="nav-item notif-link">
          <a class="nav-link dropdown-toggle notif-link" href="javascript:void(0);" data-toggle="dropdown" role="button"
            aria-haspopup="true" aria-expanded="false" placement="bottom" ngbTooltip="Notifications">
            <i class="ui-icons-notification" (click)="readNotification()" aria-hidden="true"></i>
            <span class="fa-stack has-badge" data-count="" *ngIf="unReadNotificationData.length">
            </span>
          </a>
          <app-notification [notificationData]="notificationData"
            [unReadNotificationData]="unReadNotificationData"></app-notification>
        </li>
        <li class="nav-item">
        </li>


        
        <li class="nav-item pr-2">
          <div class="dropdown">
            <a class="nav-link dropdown-toggle" role="button" id="user-profile-links" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <span class="user-profile d-inline-block text-right pr-1">
                <i class="user-name text-dark" aria-hidden="true">
                  {{userName}}
                </i><br>
                <i class="user-role" aria-hidden="true">
                  {{userRole}}
                </i>
              </span>
              <div *ngIf="userImage" class="icon profile-image">
                <img src="{{userImage}}" alt="User Logo">
              </div>
              <div *ngIf="!userImage" class="profileImage">{{userName | uppercase | shortName}}</div>
            </a>
            <div class="dropdown-menu dropdown-menu-right shadow border-0" aria-labelledby="user-profile-links">
              <a *ngIf="userOtherRoles?.length" class="dropdown-item">Switch Role
                <div class="dropdown-menu custom dropdown-menu-left shadow border-0">
                  <a class="dropdown-item" role="button" (click)="changedefaultrole(item.id)"
                    *ngFor="let item of userOtherRoles;index as i">{{item.roleName}} </a>
                </div>
              </a>
              <a *ngIf="userService.checkAccess('Admin','view')" class="dropdown-item" href="javascript:void(0);"
                (click)="navigateToOtherRoute('/admin')">Settings</a>

              <a class="dropdown-item" href="javascript:void(0);" (click)="openAsModel()">Edit Profile</a>

              <a class="dropdown-item" style="cursor: pointer;" *ngIf="loggedIn" (click)="logout()">Signout</a>
            </div>
          </div>
        </li>
        <!-- <li class="nav-item pl-2 border-left">
          <img src="../../assets/images/client-logo.png" alt="Client Logo" style="height: 36px;">
        </li> -->
      </ul>
    </div>
  </nav>
</header>

<div hidden>
  <svg style="display: none;" xmlns="http://www.w3.org/2000/svg">
    <symbol id="man-1" viewBox="0 0 508 508">
      <title>man-1</title>
      <circle style="fill:#FD8469;" cx="254" cy="254" r="254" />
      <g>
        <path style="fill:#84DBFF;" d="M255.2,362.8c-0.4,0-0.8,0.4-1.2,0.4c-0.4,0-0.8-0.4-1.2-0.4H255.2z" />
        <path style="fill:#84DBFF;"
          d="M451.2,414c-46.4,57.2-117.6,94-197.2,94s-150.8-36.8-197.2-94c33.2-44.4,84-66.4,125.6-77.2 c-3.2,26.4,10.4,61.6,10.8,60.8c14-32,60.8-34.8,60.8-34.8s46.8,2.8,60.4,34.8c0.4,0.8,14.4-34.4,10.8-60.8 C367.2,347.6,418.4,369.6,451.2,414z" />
      </g>
      <path style="fill:#FFFFFF;"
        d="M311.2,312c0,0,0,0,0,0.4c0,4-2,29.6-56,50h-2.4c-54-20.4-56-46-56-50c0-0.4,0-0.4,0-0.4 c0-5.2,1.2-10.4,2.4-15.6c15.6,19.6,34.4,32.8,54.8,32.8s39.2-13.2,54.8-32.8C310,301.6,311.2,306.8,311.2,312z" />
      <g>
        <path style="fill:#54C0EB;"
          d="M311.2,312c0,0,2.8,28.8-57.2,51.2c0,0,46.8,2.8,60.4,34.8C315.2,398.8,342,329.2,311.2,312z" />
        <path style="fill:#54C0EB;"
          d="M196.8,312c-30.8,17.2-4,86.8-3.2,85.6c14-32,60.4-34.8,60.4-34.8C194,340.8,196.8,312,196.8,312z" />
      </g>
      <g>
        <path style="fill:#FFFFFF;"
          d="M338,210.8c-3.6,24.8-14.4,48-32.4,65.6c-3.6,3.6-6.8,6.4-10.8,9.2l-6.4-20.4h-68.8l-6.4,20.4 c-12.8-9.6-39.6-36.4-40-61.6C162,80.4,272,110.8,272,110.8C346,105.6,343.6,170.8,338,210.8z" />
        <path style="fill:#FFFFFF;"
          d="M352.8,236.8c-5.6,9.2-13.2,15.6-20.4,18c2.8-6.4,5.2-13.2,7.2-20c0,0,0,0,0-0.4 c1.6-6,3.2-12,4.8-18H344c1.6-6,3.2-12,4.4-18c1.6,0,3.2,0.8,4.8,1.6C362,205.6,361.6,222,352.8,236.8z" />
        <path style="fill:#FFFFFF;"
          d="M175.6,254.8c-7.2-2.4-14.8-8.8-20.4-18c-9.2-14.8-9.2-31.2-0.4-36.4c0.8-0.4,1.2-0.8,2-0.8 c4.8,18.8,10.4,32.8,11.6,35.6C170.4,241.6,172.8,248.4,175.6,254.8z" />
      </g>
      <path id="SVGCleanerId_0" style="fill:#324A5E;"
        d="M297.6,70.8c3.2-36.8-55.2-34.4-55.2-34.4c-88.4,12.8-98,95.2-98,95.2l12-14.4 c-18.8,41.2,9.6,112,12,117.6c14.8,49.2,47.6,94.4,85.6,94.4s70.8-45.2,85.6-94.4l0,0c0,0,0,0,0-0.4c1.6-6,3.2-12,4.4-18h-0.4 c8-32,16.8-64.4,16.8-64.4C383.2,37.2,297.6,70.8,297.6,70.8z M338,210.8c-3.6,24.8-14.4,48-32.4,65.6c-3.6,3.6-7.2,6.4-10.8,9.2 l-6.4-20h-68.8l-6.4,20.4c-12.8-9.6-23.6-22.8-32.4-38c-4-8-6.4-16.4-7.6-23.6C162,80.8,272,111.2,272,111.2 C346,105.6,343.6,170.8,338,210.8z" />
      <g>
        <path id="SVGCleanerId_0_1_" style="fill:#324A5E;"
          d="M297.6,70.8c3.2-36.8-55.2-34.4-55.2-34.4c-88.4,12.8-98,95.2-98,95.2 l12-14.4c-18.8,41.2,9.6,112,12,117.6c14.8,49.2,47.6,94.4,85.6,94.4s70.8-45.2,85.6-94.4l0,0c0,0,0,0,0-0.4c1.6-6,3.2-12,4.4-18 h-0.4c8-32,16.8-64.4,16.8-64.4C383.2,37.2,297.6,70.8,297.6,70.8z M338,210.8c-3.6,24.8-14.4,48-32.4,65.6 c-3.6,3.6-7.2,6.4-10.8,9.2l-6.4-20h-68.8l-6.4,20.4c-12.8-9.6-23.6-22.8-32.4-38c-4-8-6.4-16.4-7.6-23.6 C162,80.8,272,111.2,272,111.2C346,105.6,343.6,170.8,338,210.8z" />
      </g>
      <path style="fill:#324A5E;"
        d="M297.6,70.8c3.2-36.8-55.2-34.4-55.2-34.4c-88.4,12.8-98,95.2-98,95.2l12-14.4 c-18.8,41.2,9.6,112,12,117.6c14.8,49.2,47.6,94.4,85.6,94.4s70.8-45.2,85.6-94.4l0,0c0,0,0,0,0-0.4c1.6-6,3.2-12,4.4-18h-0.4 c8-32,16.8-64.4,16.8-64.4C383.2,37.2,297.6,70.8,297.6,70.8z M338,210.8c-3.6,24.8-14.4,48-32.4,65.6c-3.6,3.6-7.2,6.4-10.8,9.2 l-6.4-20h-68.8l-6.4,20.4c-12.8-9.6-39.6-36.4-40-61.6C162,80.8,272,111.2,272,111.2C346,105.6,343.6,170.8,338,210.8z" />
      <path style="fill:#FFFFFF;" d="M226,279.2c6.8,7.6,16.8,12.8,28,12.8c11.2,0,21.2-4.8,28-12.8H226z" />
    </symbol>
  </svg>
</div>
