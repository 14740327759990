<div class="container-fluid content-bordeaux p-0">
  <div class="row">
    <div class="table-responsive custom-table">
      <h4>Bordereaux Details</h4>
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table">
        <thead>
          <tr class="header">
            <th scope="col"></th>
            <th scope="col">Version</th>
            <th scope="col">File/Data Name</th>
            <th scope="col">Reporting Period</th>
            <th scope="col">Ingestion Type</th>           
            <th scope="col">Uploaded By</th>
            <th scope="col">Last Modified</th>
            <th scope="col">Last Modified By</th>
            <th scope="col">Iteration</th>
            <th scope="col">Rules</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody *ngIf="bordereauxValidationListData?.length != 0">
          <tr #trRef *ngFor="let record of bordereauxValidationListData; let i = index;"
            [ngClass]="{ 'expended-outline': record.expended }">
            <td style="width: 20px;">
              <i class="material-icons cursor-pointer" [ngClass]="{ 'expended-icon': record.expended }"
                (click)="expandRow(trRef, record[record.selected].oldIteration); record.expended = !record.expended">keyboard_arrow_down</i>
            </td>
            <td style="width: 160px;">
              <select [(ngModel)]="record.selected" class="form-control form-control-sm custom-width-control"
                (change)="updateVersion(i,record.selected)">
                <option *ngFor="let version of record.versions" [value]="version">{{version}}</option>
              </select>
             <ng-container
                *ngIf="record[record.selected].latestIteration.status=='Ready for Validation' || record[record.selected].latestIteration.status=='Validation In-Progress'|| record[record.selected].latestIteration.status=='Data uploaded' || record[record.selected].latestIteration.status=='Validation Failed' || record[record.selected].latestIteration.status=='System Error' || record[record.selected].latestIteration.status=='Rules Amended' || record[record.selected].latestIteration.status=='Corrected Data Uploaded'">
                <button type="button" class="btn btn-primary btn-sm inline-button-sm" data-dismiss="modal"
                  (click)="validationProcess(i,record[record.selected].latestIteration.workItemId)"
                  [disabled]="record[record.selected].latestIteration.status=='Validation In-Progress' || record[record.selected].latestIteration.status=='Validation Failed' || record[record.selected].latestIteration.rulesCount ==0 ">
                  <ng-container *ngIf="record[record.selected].latestIteration.status=='Validation In-Progress'">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </ng-container>
                  <ng-container *ngIf="record[record.selected].latestIteration.rulesCount ==0">
                    <span placement="right" [ngbTooltip]="tooltipOptimizeO">Validate</span>
                  </ng-container>
                  <ng-container *ngIf="record[record.selected].latestIteration.rulesCount !=0">
                    <span >Validate</span>
                  </ng-container>
                </button>
                <ng-template #tooltipOptimizeO>
                  <div>No Rules are selected, please select rules to validate</div>
                </ng-template>
              
              </ng-container>
            </td>
            <td style="width: 280px;">
              <p class="custom-ellipsis" placement="right" [ngbTooltip]="tooltipOptimizeP" >
                {{record[record.selected].latestIteration.fileName}}</p>
                <ng-template #tooltipOptimizeP>
                  <div> {{record[record.selected].latestIteration.fileName}} - {{record[record.selected].latestIteration.fileSizeInMb | number : '1.2-2'}} MB</div>
                </ng-template>
              <span class="badge "
                [ngClass]="{
 
                                'customBgBlue': record[record.selected].latestIteration.status=='Ready for Validation' ,
                                'badge-info': record[record.selected].latestIteration.status=='Re-Validation' ,
                                'badge-success': record[record.selected].latestIteration.status=='Validated',
                                'customBgRed':  record[record.selected].latestIteration.status=='Validation Failed',
                                'badge-danger':  record[record.selected].latestIteration.status=='System Error',
                                'customBgYellow':  record[record.selected].latestIteration.status=='Validation In-Progress',
                              'customBgGrey':record[record.selected].latestIteration.status=='Rules Amended',
                              'customBgDarkGrey':record[record.selected].latestIteration.status=='Corrected Data Uploaded'
                            }">{{record[record.selected].latestIteration.status}}
              </span>
            </td>
            <td style="width: 145px;">{{record[record.selected].latestIteration.reportStartDate | date: 'd MMMM yy'}} to
              {{record[record.selected].latestIteration.reportEndDate | date: 'd MMMM yy'}} </td>
            <td style="width: 40px;">{{record[record.selected].latestIteration.ingestionType}}</td>
           
            <td style="width: 89px;"><p class="custom-ellipsisName">{{record[record.selected].latestIteration.uploadedBy}}</p></td>
            <td style="width: 90px;">{{record[record.selected].latestIteration.updatedAt | date: 'd MMMM yy h:mm:ss a'}}</td>
            <td style="width: 89px;"><p class="custom-ellipsisName">{{record[record.selected].latestIteration.modifiedBy}}</p></td>
            <td style="width: 50px;">{{record[record.selected].latestIteration.iteration}}</td>
            <td style="width: 50px;"> <span class="badge customBgBlue customBox" (click)="ruleCountList(record[record.selected].latestIteration.workItemId)">{{record[record.selected].latestIteration.rulesCount}}</span></td>
            <td style="width: 35px;position: relative;">
              <div class="dropdown"><img src=".. /../../../../../../assets/images/dots.png" data-toggle="dropdown"
                  aria-expanded="false" />
                <div class="dropdown-menu">
                  <button class="dropdown-item" type="button" (click)="uploadCorrectedData(record[record.selected].latestIteration)">Upload Corrected Data</button>

                  <button   class="dropdown-item" type="button" (click)="amendRules(record[record.selected].latestIteration.workItemId)">Amend Rules</button>

                  <button *ngIf="record[record.selected].latestIteration.status=='Validation Failed'  && record[record.selected].latestIteration.downloadErrorReportLink"   class="dropdown-item" type="button"><a href="{{record[record.selected].latestIteration.downloadErrorReportLink }}">Error Report</a></button>

                  <button *ngIf="record[record.selected].latestIteration.downloadInputLink" class="dropdown-item" type="button"><a href="{{record[record.selected].latestIteration.downloadInputLink}}">Download Source File</a></button>

                  <button *ngIf="record[record.selected].latestIteration.status=='Validated'" class="dropdown-item" type="button"><a href="{{record[record.selected].latestIteration.downloadOutputLink}}">Download Validated File</a></button>
                 
                  <button *ngIf="userService.checkAccess('metamorph-validation','delete')  &&(record[record.selected].latestIteration.status=='Ready for Validation' ||  record[record.selected].latestIteration.status=='Re-Validation' )" class="dropdown-item" type="button" (click)="deleteValidate(record[record.selected].latestIteration.workItemId)">Delete</button>


                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>