import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/config.service';
import { PricingManageModelService } from '../pricing-manage-model/pricing-manage-model.service';
import { PricingService } from '../pricing.service';const ACTIVE_CLASS = "active";

@Component({
  selector: 'app-pricing-view',
  templateUrl: './pricing-view.component.html',
  styleUrls: ['./pricing-view.component.scss']
})
export class PricingViewComponent implements OnInit {
  activeModule: any;
  pricingModules: any;
  pricingModulesMeta: any;
  clickEventSubscription: Subscription;
  eventSubscription: Subscription;
  next: any = "NEXT";
  prev: any = "PREV";
  tabIdForPrev: any = "#VALIDATION";
  tabIdForNext: any = "";
  currentRoute: any;
  public setSelectModelVisible: Subscription;
  isModelSelectionVisible: boolean = false;
  projectId: string | null;
  activePricingModule: any;
  showNextButton: boolean

  constructor(private pricingService: PricingService, private router: Router, private pricingManageModelService: PricingManageModelService,
    private cdr: ChangeDetectorRef, private configService: ConfigService, private ngxLoader: NgxUiLoaderService) {
    this.clickEventSubscription = this.pricingService.getTabClickEvent().subscribe((activeModule) => {
      this.activeModule = this.pricingService.activePricingModule;
    });

    this.eventSubscription = this.pricingService.getSuccessEvent().subscribe((response: any) => {
      this.tabIdForNext = "#actual"
    });

  }

  ngOnInit(): void {
    this.setSelectModelVisible = this.pricingService.getSelectModelVisible().subscribe(data => {
      this.isModelSelectionVisible = data;
    })
    this.pricingModules = this.pricingService.PRICING_MODULES;
    this.pricingModulesMeta = this.pricingService.PRICING_MODULES_META;
    this.updateActiveModule(this.pricingModules.PROJECTS);
    this.configService.changeHeader('Pricing Analytics');
    if (!this.pricingService.activePricingModule) {
      this.pricingService.activePricingModule = this.pricingModules.PROJECTS;
    }
    if(this.pricingService.activePricingModule === 'VALIDATION') {
      this.showNextButton = false;
    } else {
      this.showNextButton = true
    }
  }
  ngOnDestroy(): void {
    this.setSelectModelVisible.unsubscribe();
  }

  updateActiveModule(activeModule: any) {
    this.activeModule = activeModule;
  }
/***
   * @desc Function set class active on UI
   * @param activeModule
   * check for activePricingModule == activeModule.
   * @returns ACTIVE_CLASS or ''
   */
  isActiveModule(activeModule: any) {
    if (this.pricingService.activePricingModule == activeModule) {
      return ACTIVE_CLASS
    } else if (
      this.pricingService.activePricingModule == this.pricingModules.VALIDATION
      && activeModule == this.pricingModules.ACTUAL
    ) {
      return ACTIVE_CLASS;
    } else {
      return "";
    }
  }

  /***
   * @desc Function detect changes manually.
   * detecting content projection changes using ChengeDetectionRef 
   */
  ngAfterContentInit() {
    
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    if(this.pricingService.activePricingModule === 'VALIDATION') {
      this.showNextButton = false;
    } else {
      this.showNextButton = true
    }
    this.cdr.detectChanges();
  }

  // Direct navigation through tabs
  gotoThisModule(pricingModules) {
    this.makeThisModuleAsActive(pricingModules);
    if (pricingModules == this.pricingModules.PROJECTS) {
      this.router.navigate([this.pricingModulesMeta["projects"].ROUTE]);
      this.showNextButton = true;
    } else if (pricingModules == this.pricingModules.MANAGE) {
      this.showNextButton = true;
      this.router.navigate([this.pricingModulesMeta["manage"].ROUTE]);
    } else if (pricingModules == this.pricingModules.ANALYSIS) {
      this.showNextButton = true;
      this.router.navigate([this.pricingModulesMeta["analysis"].ROUTE]);
    } else if (pricingModules == this.pricingModules.INTER) {
      this.showNextButton = true;
      this.router.navigate([this.pricingModulesMeta["interpretability"].ROUTE]);
    } else if (pricingModules == this.pricingModules.VALIDATION) {
      this.showNextButton = false;
      this.router.navigate([this.pricingModulesMeta["validation"].ROUTE]);
    }
  }

  makeThisModuleAsActive(pricingModules: any) {
    this.pricingService.activePricingModule = pricingModules;
  }

  /***
   * @desc Function Enable next tab on each tab navigation
   * @param navEvent
   * check for each event and redirects to respective route accordingly.
   */
  navigateToAnotherPricingModule(navEvent) {
    const LABEL = `navigateToAnotherPricingModule`;
    if (this.pricingService.activePricingModule === this.pricingModules.PROJECTS) {
      if (navEvent == this.next) {
      this.showNextButton = true;
      this.router.navigate(['/pricing/model-manage'])
        this.pricingService.activePricingModule = this.pricingModules.MANAGE;
        this.pricingService.sendNavClickEvent(navEvent, this.pricingModules.MANAGE);
      } else if (navEvent == this.prev) {
      }
    } else if (this.pricingService.activePricingModule === this.pricingModules.MANAGE) {
      if (navEvent == this.prev) {
      this.showNextButton = true;
      this.router.navigate(['/pricing/project']);
        this.pricingService.activePricingModule = this.pricingModules.PROJECTS;
        this.pricingService.sendNavClickEvent(navEvent, this.pricingModules.PROJECTS);
      } else if (navEvent == this.next) {
        this.pricingService.activePricingModule = this.pricingModules.ANALYSIS;
      this.showNextButton = true;
      this.router.navigate(['/pricing/model-analysis']);
        this.pricingService.sendNavClickEvent(navEvent, this.pricingModules.ANALYSIS)
      }
    } else if (this.pricingService.activePricingModule === this.pricingModules.ANALYSIS) {
      if (navEvent == this.prev) {
      this.showNextButton = true;
      this.router.navigate(['/pricing/model-manage']);
        this.pricingService.sendNavClickEvent(navEvent, this.pricingModules.MANAGE);
        this.pricingService.activePricingModule = this.pricingModules.MANAGE;
      } else if (navEvent == this.next) {
      this.showNextButton = true;
      this.router.navigate(['/pricing/model-interpretability']);
        this.pricingService.sendNavClickEvent(navEvent, this.pricingModules.INTER);
        this.pricingService.activePricingModule = this.pricingModules.INTER;
      }
    } else if (this.pricingService.activePricingModule === this.pricingModules.INTER) {
      if (navEvent == this.prev) {
      this.showNextButton = true;
      this.router.navigate(['/pricing/model-analysis']);
        this.pricingService.sendNavClickEvent(navEvent, this.pricingModules.ANALYSIS);
        this.pricingService.activePricingModule = this.pricingModules.ANALYSIS;
      }
      else if (navEvent == this.next) {
      this.showNextButton = false;
      this.router.navigate(['/pricing/model-validation']);
        this.pricingService.sendNavClickEvent(navEvent, this.pricingModules.VALIDATION);
        this.pricingService.activePricingModule = this.pricingModules.VALIDATION;
      }
    } else if (this.pricingService.activePricingModule === this.pricingModules.VALIDATION) {
      if (navEvent == this.prev) {
      this.showNextButton = true;
      this.router.navigate(['/pricing/model-interpretability']);
        this.pricingService.sendNavClickEvent(navEvent, this.pricingModules.INTER);
        this.pricingService.activePricingModule = this.pricingModules.INTER;
      }
      else if (navEvent == this.next) {
      }
    }
  }

  /**
  * Determines whether clickable is
  *  Return true only if following conditions are satisfied else return false
  *    1. There should be an assignee
  *    2. This module should be previous to last screen(Latest screen visited by user)
  * @param uwModule 
  * @returns  true or false
  */
  isClickable(pricingModule) {

  }


  /***
   * @desc Function to Open Model Popup to select Model
   * First it will call the API to get the model data 
   * @returns {Object} Returns brand List and scenario list.
   * Once we get model data then model will open with modelData.
   * On selecting model and submitting it will redirect to Project List page
   */

  public selectModel() {
    this.ngxLoader.start();
    this.projectId = this.pricingService.getProjectId();
    this.pricingManageModelService.getModelDataAndEmblemModel(this.projectId).subscribe((res) => {
      if (res) {
      // once get the API response loder will stop and select Model popup will open
      this.ngxLoader.stop();
        this.pricingService.openSelectModel(res).then((confirmed) => {
          if (confirmed) {
            // After selecting and confirming model selection, popup will close and redirect to project list page
            this.router.navigate(['/pricing/project']);
            this.pricingService.activePricingModule = this.pricingModules.PROJECTS;
          } else if (!confirmed) {
            // Closing Popup without any change
          }
        });
      } else {
        this.ngxLoader.stopAll();
      }
  });
  }


}
