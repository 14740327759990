import { Component, Input, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotificationService } from '../../shared/services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input() notificationData: any;
  @Input() unReadNotificationData: any;
  localStorage = localStorage;
  userRole: any;

  constructor(private sharedNotification: NotificationService, private ngxLoader: NgxUiLoaderService, public notificationService: NotificationService) { }

  ngOnInit(): void {

    Promise.all([this.localStorage.getItem('user')]).then(values => {
      const userData = values[0];
      if (userData) {
        this.userRole = JSON.parse(userData).currentRoleName;
      }
    });
  }



  getReadableDate(date) {
    return this.sharedNotification.getReadableDate(date);
  }

  getFormatedDate(timeStamp){
    if(new Date().toUTCString().slice(5, 16)==new Date(timeStamp).toUTCString().slice(5, 16)){
      return new Date(timeStamp).toLocaleString().split(",")[1]
    }else{
      return new Date(timeStamp).toJSON().slice(0,10).split("-").reverse().join("-")
    }
  }

 

}
