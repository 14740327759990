import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private settings: any;
  private http: HttpClient;
  localStorage = localStorage;
  public moduleTitle$ = new BehaviorSubject(null);

  private textSource = new BehaviorSubject('Insurance');
  currentText = this.textSource.asObservable();
  
  constructor(private readonly httpHandler: HttpBackend) {
    this.http = new HttpClient(httpHandler);
  }

  init(endpoint: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.http.get(endpoint).pipe(map(res => res))
        .subscribe(value => {
          this.settings = value;
          resolve(true);
        },
        (error) => {
          reject(error);
        });
    });
  }

  getSettings(key?: string | Array<string>): any {
    if (!key || (Array.isArray(key) && !key[0])) {
      return this.settings;
    }

    if (!Array.isArray(key)) {
      key = key.split('.');
    }

    let result = key.reduce((acc: any, current: string) => acc && acc[current], this.settings);

    return result;
  }


  changeHeader(text: string) {
    this.textSource.next(text)
  }

  setModuleTitle(title) {
    this.localStorage.setItem('currentTitle', title)
    this.moduleTitle$.next(title)
  }

  getModuleTitle() {
    return this.moduleTitle$.asObservable();
  }
}