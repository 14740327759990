import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { FileUploadProgressComponent } from 'src/app/shared/component/file-upload-progress/file-upload-progress.component';
import { ViewRulesComponent } from '../bordereaux-management/bordereaux-base/validation/view-rules/view-rules.component';

@Injectable({
  providedIn: 'root'
})
export class MetamorphCommonService {
  private _operationalDataInfo: any;
  private selectedBdxData$: BehaviorSubject<any> = new BehaviorSubject(null);
  private operationalInfo$: BehaviorSubject<any> = new BehaviorSubject(null);
  private ruleConfigInfoInfo$: BehaviorSubject<any> = new BehaviorSubject(null);
  private uploadFileClickEvent$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private modalService: NgbModal, private http: HttpClient) { }

  openFileUploader(isMappingFlow, operationalDetails: any, selectedTab, processFile, existingFileInfo?: any, ): Promise<any> {
    const modalRef = this.modalService.open(FileUploadProgressComponent, { windowClass: 'upload-custome-class', centered: true, backdrop: false });
    modalRef.componentInstance.isMappingFlow = isMappingFlow;
    modalRef.componentInstance.operationalDetails = operationalDetails;
    modalRef.componentInstance.isMappingFlow = isMappingFlow;
    modalRef.componentInstance.fileUploaderConfig = {filePath: selectedTab, processFile: processFile}
    modalRef.componentInstance.existingFileInfo = existingFileInfo;
    return modalRef.result;
  }

  triggerFileUploadEvent(flow: any) {
    this.uploadFileClickEvent$.next(flow);
  }

  captureFileUploadEvent(): Observable<any> {
    return this.uploadFileClickEvent$.asObservable();
  }

  openAmendRules(rulesArray,processId, workItem): Promise<any> {
    const modalData = {
      rulesData: rulesArray,
      workItemData:workItem,
      processId:processId,
      title: 'View Rules',
      buttonSubmitText: 'Submit',
      buttonCancelText: 'Cancel'
    }
    const modalRef = this.modalService.open(ViewRulesComponent, { size: 'md', centered: true, backdrop: true, windowClass:'customWidth' });
    modalRef.componentInstance.modalData = modalData;
    return modalRef.result;
  }

  
  public getDropdownOptionData(): Observable<any> {
    return this.http.get<any>('metamorph/filters/')
  }

  getSelectedBdxData(): Observable<any> {
    return this.selectedBdxData$.asObservable();
  }


  async setSelectedBdxData(bdxData: any): Promise<boolean> {
    await this.selectedBdxData$.next(bdxData);
    return true;
  }


  setOperationalInfoData(operationData) {
    localStorage.setItem('OperationalInfo',JSON.stringify(operationData));
  }

  getOperationalInfoData() {
    return localStorage.getItem('OperationalInfo');
  }

  setRuleConfigInfoData(ruleData) {
    localStorage.setItem('ruleConfigInfo',JSON.stringify(ruleData));
  }

  getRuleConfigInfoData() {
    return localStorage.getItem('ruleConfigInfo');
  }

  setCurrentTemplateId(currentTemplateId) {
    localStorage.setItem('currentTemplateId',currentTemplateId);
  }

  getCurrentTemplateId() {
    return JSON.parse(localStorage.getItem('currentTemplateId'))
  }





}
