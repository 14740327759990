<div class="card-body shadow m-0 p-2">
    <div class="d-flex">
        <div class="flex-grow-1 ">
            <h3 class="section-title d-inline-block">Initiate S&OP Planning 
                <span class="material-icons d-inline-block customTop" *ngIf="userRole === 'S&OP Manager'"
                    aria-haspopup="true" aria-expanded="false" data-html="true" 
                    placement="bottom" [ngbTooltip]="tooltipHtml" tooltipClass="custom-tooltipColor"> info </span>
            </h3>
                <ng-template #tooltipHtml><div>DP published - Modified Baseline Forecast is <span class="text-warning font-weight-bold">Highlighted</span></div></ng-template>
            <div class="float-right">
                <ng-container *ngFor="let item of nextThreeMonths; index as index">
                    <a href="javascript:void(0)" class="btn btn-sm ml-1"
                        [ngClass]="item.id === selectedMonth.id ? 'btn-primary' : 'btn-outline-primary'"
                        (click)="updateMonth(item)">{{item?.name}}</a>
                </ng-container>
            </div>
        </div>
    </div>
    <div id="">
        <div class="chart-container">
            <h3 *ngIf="!saopData?.data?.length && !saopData?.details?.length && userRole === 'S&OP Manager'"
                class="centered-text">No Plan Published</h3>
            <h3 *ngIf="!saopData?.data?.length && !saopData?.details?.length && userRole === 'Demand Planner'"
                class="centered-text">No Data Found</h3>
            <table *ngIf="saopData?.data?.length && saopData?.details?.length"
                class="table table-sm no-footer snop-sku-planning-table" id="snop-planning-table"
                style="font-size: 11px;" role="grid" aria-describedby="snop-planning-table-info">
                <thead>
                    <tr>
                        <th scope="col">SKU</th>
                        <th scope="col" class="text-center">Forecast Type</th>
                        <ng-container *ngFor="let item of tableCoumns; index as index">
                            <th scope="col"  class="text-center">
                                <span placement="left" ngbTooltip="{{item?.value}}" tooltipClass="my-custom-class">Week {{index+1}}</span> 
                            </th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of saopData?.data; index as index">
                        <tr>
                            <ng-template #tipContent style="position: absolute;"><p>{{item?.skuInfo?.skuId}} </p><p>{{item?.skuInfo?.brand}} </p> {{item?.skuInfo?.region}}</ng-template>
                            <td rowspan="2" class="text-left">
                                <span  placement="right"[ngbTooltip]="tipContent" data-html="true" tooltipClass="custom-tooltip ">{{item?.product}}</span></td>
                            <td>
                                <span *ngIf="userRole === 'Demand Planner'">Baseline</span>
                                <span *ngIf="userRole === 'S&OP Manager'">DP Published</span>
                            </td>
                            <ng-container *ngFor="let baseline of item?.baselineData">
                                <td ><span  [class.text-warning]="baseline?.plannerEdited && baseline?.plannerEdited === 1 " [class.font-weight-bold]="baseline?.plannerEdited && baseline?.plannerEdited === 1">{{baseline?.data | number : '1.0-0'}}</span></td>
                            </ng-container>
                        </tr>
                        <tr>
                            <td>Updated</td>
                            <ng-container *ngFor="let element of item?.updatedData; index as ind">
                                <td>
                                    <input type="text" numbersOnly class="form-control form-control-sm centerlized-textbox-50"
                                        (change)="valueUpdate(element,item.product, $event, index, ind)"
                                        [disabled]="disablePublish" [value]="element?.data | number : '1.0-0'" 
                                        [ngClass]="(element?.plannerEdited === 1) || (element?.managerEdited === 1 ) ? 'border-success' : '' ">
                                </td>
                            </ng-container>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div class="text-right border-top bg-light p-1">
            <span class="warning-text" *ngIf="saopData?.savedStatus">Forecast publish is pending</span>
            <button class="btn btn-light  btn-sm" (click)="saveSaOPData()" [disabled]="disablePublish || !saopData?.data?.length">Save</button>
            <button class="btn btn-primary btn-sm" data-toggle="modal" [disabled]="disablePublish || !saopData?.data?.length"
                (click)="goForPublish()" data-target="#confirmPublishModal">Publish</button>
        </div>
    </div>
</div>