import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-context-menu-component',
  templateUrl: './context-menu-component.component.html',
  styleUrls: ['./context-menu-component.component.scss']
})
export class ContextMenuComponentComponent implements OnInit {

  constructor() { }
  @Input() x = 0;
  @Input() y = 0;
  @Input() z: any;
  @Input() k: any;
  @Input() l: any;
  downSourceLink: any;
  downOutputLink: any;
  status: any;

  ngOnInit(): void {
    this.downSourceLink = this.l[this.l.selected].latestIteration.downloadInputLink;
    this.downOutputLink = this.l[this.l.selected].latestIteration.downloadOutputLink;
    this.status = this.l[this.l.selected].latestIteration.status;
  }

}
