<div class="module-nav mt-2 fix-height">
    <ul class="nav nav-tabs mt-0" id="pricingTabs" role="tablist">
        <li class="nav-item">
            <a class="nav-link active sub-module" id="PDP-tab" data-toggle="tab" href="#PDP" role="tab"
                aria-controls="pdp" aria-selected="false">PDP </a>
        </li>
        <li class="nav-item">
            <a class="nav-link sub-module" id="SHAP-tab" data-toggle="tab" href="#SHAP" role="tab"
                aria-controls="shap" aria-selected="false">SHAP</a>
        </li>
    </ul>
 
    <div class="tab-content bg-white" id="pricingTabsContent">
        <!-- vbb {{PDPGlmListData.param|json}} -->
        <div class="p-2">
            <nav aria-label="breadcrumb ">
                <ol class="breadcrumb mb-2">
                    <li class="breadcrumb-item" *ngFor="let item of breadcrumb; last as isLast">{{item.name}} </li>
                </ol>
            </nav> 
        </div>
        <div class="tab-pane fade show active pdp-tab-content" id="PDP" role="tabpanel"
            aria-labelledby="PDP-tab">
            <div class="p-2">
              <app-pricing-model-interpretability-pdp></app-pricing-model-interpretability-pdp>  
            </div>
        </div>
        <div class="tab-pane fade shap-tab-content" id="SHAP" role="tabpanel" aria-labelledby="SHAP-tab">
            <div class="p-2">
                <app-pricing-model-interpretability-shap></app-pricing-model-interpretability-shap>
            </div>
        </div>
    </div>
</div>