import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { CoverholderService } from '../../services/coverholder.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { MetamorphCommonService } from '../../services/metamorph-common.service';
import { BordereauxManagementService } from '../../services/bordereaux-management.service';

@Component({
  selector: 'list-coverholder',
  templateUrl: './list-coverholder.component.html',
  styleUrls: ['./list-coverholder.component.scss']
})
export class ListCoverholderComponent implements OnInit {

  @Input() selectedTab: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  coverholderListData: Array<any> = []
  filterDataResponse: any;
  selectedFileType: any;
  standard: any;
  format:any;
  coverHolder:any;


  constructor(public coverholderService: CoverholderService, private ngxLoader: NgxUiLoaderService, private route: Router,
              public metamorphCommonService: MetamorphCommonService, public BDXManagementService: BordereauxManagementService ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if(this.selectedTab === 'coverHolder') {
      this.getFilterData()
    }
  }

  async getCoverholderListData(coverHolder, selectedFileType,format){
    this.coverholderListData = [];
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      deferRender: true,
      destroy:true
    }
    this.coverholderService.getCoverholderData(coverHolder, selectedFileType,format).subscribe(response => {
      this.coverholderListData = response?.data;
      this.dtTrigger.next();
      this.ngxLoader.stop();
    })
  }

  navigateTo(item) {
    const operationalInfo = [
      {label: 'Process Id', value: item.processId},
      {label: 'Standard', value: item.format},
      {label: 'File Type', value: item.fileType},
      {label: 'CoverHolder', value: item.coverHolder},
      {label: 'No Of Rules', value: item.rulesCount}
    ]
    this.metamorphCommonService.setRuleConfigInfoData(operationalInfo);
    this.route.navigate(['/metamorph/rule-config-base']);
  }

  getFilterData() {
    this.ngxLoader.start();
    this.BDXManagementService.getMetamorphFilterData().subscribe(response => {
      if (response && response.code === 200) {
        this.filterDataResponse = response;
        this.selectedFileType = this.filterDataResponse?.fileType.length ? this.filterDataResponse?.fileType[0].id : ''
        this.standard = this.filterDataResponse?.subFormat.length ? this.filterDataResponse?.subFormat[0].id : ''
        this.format = this.filterDataResponse?.format.length ?this.filterDataResponse?.format[0].id: '';
        this.coverHolder =this.filterDataResponse?.coverHolder.length ? this.filterDataResponse?.coverHolder[0].id : '';
        this.getCoverholderListData(this.coverHolder, this.selectedFileType,this.format);
      }
    })
  }




}
