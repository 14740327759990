import { Component, ComponentRef, ElementRef, Input, OnDestroy, OnInit, QueryList, Renderer2, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { ChildTableComponentComponent } from './child-table-component/child-table-component.component';
import { BordereauxManagementService } from '../../services/bordereaux-management.service';


@Component({
  selector: 'bordereaux-management-outline',
  templateUrl: './bordereaux-management-outline.component.html',
  styleUrls: ['./bordereaux-management-outline.component.scss'],

})

export class BordereauxManagementOutlineComponent implements OnInit, OnDestroy {
  @Input() selectedTab: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  projectListData: Array<any> = [];
  processId: any;
  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;
  private childRow: ComponentRef<ChildTableComponentComponent>;
  @ViewChildren('trRef') trRef: QueryList<ElementRef>;
  filterObject = {
    fileTypeId: 0,
    coverHolderId: 0,
    formatId: 0,
    yearMonth: 0,
    subFormatId: 0,
    modifiedBy: 0,
    lastModifiedStart: 0,
    lastModifiedEnd: 0,
    tagsId: 0
  }
  subformat: any
  constructor(private bordereauxManagementService: BordereauxManagementService, private ngxLoader: NgxUiLoaderService,
    private viewRef: ViewContainerRef, private _renderer: Renderer2) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.selectedTab === 'bordereauxManagementOutline') {
      this.getBDXManagementOutlineData(this.filterObject);
    }
  }

  getBDXManagementOutlineData(filterObject) {
    this.ngxLoader.start();
    this.projectListData = [];
    this.ngxLoader.start();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: false,
      columnDefs: [
        { targets: [0], orderable: false },
        { targets: [5], orderable: false },
        { targets: [6], orderable: false },
      ],
      deferRender: true,
      destroy: true
    }
    this.bordereauxManagementService.getBDXManagementOutlineData(filterObject).subscribe(response => {
      if (response && response.code === 200) {
        this.projectListData = response?.data;
        this.projectListData[0].expended = true;
        this.subformat = this.projectListData[0].details[0].subFormat;
        const process = response?.data[0].processId;
        this.processId = process;
        this.dtTrigger.next();
        this.ngxLoader.stop();
        // Expending first row by default
        this.expandRow(this.trRef.first, this.projectListData[0])
      } else if (response === null) {
        this.projectListData = []
        this.ngxLoader.stop();
      }
    })
  }


  expandRow(trRef, rowData) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      var row = dtInstance.row(trRef);
      if (row.child.isShown()) {
        row.child.hide();
        this._renderer.removeClass(trRef, 'shown');
      }
      else {
        this.childRow = this.viewRef.createComponent(ChildTableComponentComponent);
        this.childRow.instance.data = [rowData];
        row.child(this.childRow.location.nativeElement).show();
        // If current template reference is Undefined then access trRef from QueryList<ElementRef> to add class 'shown'
        // If current template reference is not undefined then smiply add class to expend and make it visible.
        if(trRef) {
          trRef.classList.add('shown')
        } else {
          this.trRef['_results'][0].nativeElement.classList.add('shown');
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  applyFilter() {
    const config = {};
    this.bordereauxManagementService.applyFilter(this.filterObject).then(response => {
      if (response) {
        this.filterObject = response
        this.getBDXManagementOutlineData(this.filterObject);
      }
    })
  }
}
