import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mapping-confirmation-popup',
  templateUrl: './mapping-confirmation-popup.component.html',
  styleUrls: ['./mapping-confirmation-popup.component.scss']
})
export class MappingConfirmationPopupComponent {
  @Input() title: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  constructor(private activeModal: NgbActiveModal) { }
/**
   * Modal Button decline functionality  
   */
  public decline() {
    this.activeModal.close(false);
  }
/**
   * Modal Button accept functionality  
   */
  public accept() {
    this.activeModal.close(true);
  }

}
