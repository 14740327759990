import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { ScmMmmService } from '../scm-mmm.service';

@Component({
  selector: 'app-scm',
  templateUrl: './scm.component.html',
  styleUrls: ['./scm.component.scss']
})
export class ScmComponent implements OnInit {

  selectedTab: string = 'scm-optimize';
  previousTab: string = 'scm-optimize';
  oldSelectedBrand: any;
  selectedScenarioId: any;
  selectedChangeType: string = 'spend_usd'
  oldSelectedScenario: any;
  selectedScenarioType: any;
  localStorage = localStorage
  simulateTableData: any;
  newSelectedBrand: any;
  scmBrandList: Array<any> = [];
  scmScenarioList: Array<any> = [];
  selectedBrandId: any;
  isOptimizeBudgetWrong: boolean = false;
  isPendingState: boolean = false;
  changeBudget = '';
  changeInBudget = '';
  scenarioList: any;
  optimizeChartData: any;
  newSelectedScenario: any;
  saveOptimizeEvents: Subject<void> = new Subject<void>();
  exportOptimizeEvents: Subject<void> = new Subject<void>();
  exportSimulateEvents: Subject<void> = new Subject<void>();
  deleteOptimizeScenarioEvent: Subject<void> = new Subject<void>();
  saveSimulateEvents: Subject<void> = new Subject<void>();
  deleteSimulateEvents: Subject<void> = new Subject<void>();
  evaluateOptimizeEvents: BehaviorSubject<any> = new BehaviorSubject(null);
  changeTypeEvents: BehaviorSubject<any> = new BehaviorSubject(null);
  evaluateSimulateEvents: Subject<void> = new Subject<void>();
  changeScenerioOptimizeEvents: BehaviorSubject<any> = new BehaviorSubject(null);
  changeScenerioSimulateEvents: BehaviorSubject<any> = new BehaviorSubject(null);
  tabChangeEvent: BehaviorSubject<any> = new BehaviorSubject(null);
  saveData: EventEmitter<boolean> = new EventEmitter();



  constructor(private scmMmmService: ScmMmmService, public toastr: ToastrService,
    public confirmationPopupService: ConfirmationPopupService, public router: Router) {
    const selectedTab = this.localStorage.getItem('selectedScmTab')
    this.selectedTab = this.previousTab = selectedTab !== null ? selectedTab : 'scm-optimize';
  }

  ngOnInit(): void {
    this.selectedScenarioType = this.selectedTab === 'scm-optimize' ? 'Optimize Base' : 'Simulate Base';
    this.getBrandAndScenarioList()
    this.scmMmmService.setPendingStatus('NO');
    this.scmMmmService.setGoodToGo('YES');
  }

  // Getting Brand and Scenario List
  /***
   * @desc Function to get Brand and scenrio List
   * @param Integer (Brand Id) 
   * Call service to get brand and scenario list for 1st selected brand
   * @returns {Array} Returns brand List and scenario list.
   *
   */
  getBrandAndScenarioList() {
    this.scmMmmService.openOverlay()
    this.scmMmmService.getBrandList().subscribe((response) => {
      if (!!response && response.code === 200) {
        this.scmBrandList = response.data;
        this.selectedBrandId = this.scmBrandList[0].brandId;
        this.oldSelectedBrand = this.scmBrandList[0].brandId;
        const scenarioType = this.selectedTab === 'scm-optimize' ? 'Optimize' : 'Simulate';
        this.scmMmmService.getScenarioList(this.selectedBrandId, scenarioType).subscribe((scenarioList) => {
          if (!!scenarioList && scenarioList.code === 200) {
            this.scmScenarioList = scenarioList.data;
            const selectedScenarioObject = this.scmScenarioList.filter(e => { return e.scenarioType === "Optimize Base" || e.scenarioType === "Simulate Base" })[0];
            this.selectedScenarioId = selectedScenarioObject.scenarioId;
            this.oldSelectedScenario = selectedScenarioObject.scenarioId;
            this.selectedScenarioType = selectedScenarioObject.scenarioType;
            this.tabChangeEvent.next({ selectedTab: this.selectedTab, selectedScenarioId: this.selectedScenarioId, selectedScenarioType: this.selectedScenarioType })
          }
        })
      }
    })
  }

  // Update selected scenario after adding new scenario
  /***
   * @desc Function to get updated scenrio List and select newly added scnario
   * @param {Object} (Selected Brand Id and newLy Added Scenario Id) 
   * @returns {Array} Returns scenario list.
   *
   */
  updateScenario(event) {
    const scenarioType = this.selectedTab === 'scm-optimize' ? 'Optimize' : 'Simulate';
    this.scmMmmService.getScenarioList(this.selectedBrandId, scenarioType).subscribe((scenarioList) => {
      if (!!scenarioList && scenarioList.code === 200) {
        this.scmScenarioList = scenarioList.data;
        this.selectedScenarioId = event.selectedScenarioId;
        this.oldSelectedScenario = event.selectedScenarioId;
        this.selectedScenarioType = this.scmScenarioList.filter(e => { return e.scenarioId === event.selectedScenarioId })[0].scenarioType;
        this.scmMmmService.closeModel();
      }
    })
  }

  /***
  * @desc Function to get updated Budget Change value with respect to selected scenario
  * @param {Integer} (change budget value) 
  * @returns {Array} Returns changeBudget.
  *
  */
  updateBudgetChangeValue(ev) {
    this.changeBudget = ev;
  }

  // Set base scenario as selected scenario after deleting any custom scenario
  /***
   * @desc Function to get updated scenrio List and select newly added scnario
   * @param {Object} (Selected Brand Id ) 
   * @returns {Array} It select base scenario of selected brand
   *
   */
  updateAfterDeleting(event) {
    this.selectedBrandId = event.selectedBrandId;
    this.oldSelectedBrand = event.selectedBrandId
    this.getScenerioList(this.selectedBrandId);
    this.selectedChangeType = 'spend_usd';
    this.changeBudget = '';
    this.selectedScenarioId = this.oldSelectedScenario = this.scmScenarioList.filter(e => { return e.scenarioType === "Optimize Base" || e.scenarioType === "Simulate Base" })[0].scenarioId;
    this.selectedScenarioType = this.selectedTab === 'scm-optimize' ? 'Optimize Base' : 'Simulate Base';
  }


  /***
   * @desc Function to Emit event on save scenario event
   */
  saveScenario() {
    const brandName = this.scmBrandList.filter(e => {
      return e.brandId === this.selectedBrandId
    })[0].brandName;
    if (this.selectedTab === 'scm-optimize') {
      this.saveOptimizeEvents.next(brandName);
    } else if (this.selectedTab === 'scm-simulate') {
      this.saveSimulateEvents.next(brandName);
    }
  }

  /***
   * @desc Function to Export table data into excel
   */
  exportScenario() {
    this.scmMmmService.getPendingStatus().subscribe(data => {
      if (data === 'NO' || data === null) {
        if (this.selectedTab === 'scm-optimize') {
          this.exportOptimizeEvents.next();
        } else if (this.selectedTab === 'scm-simulate') {
          this.exportSimulateEvents.next();
        }
      } else if (data === 'YES') {
        this.toastr.error("Please save scenario before download!")
      }
    })
  }

  /***
   * @desc Function to Emit event for change simulate type radio button event
   */
  changeType(event) {
    this.changeTypeEvents.next(event);
  }


  /***
   * @desc Function to Emit event on delete scenario event
   */
  deleteScenario() {
    if (this.selectedScenarioType === 'Optimize Base' || this.selectedScenarioType === 'Simulate Base') {
      this.toastr.error('Base Scenario can not be deleted')
    } else {
      if (this.selectedTab === 'scm-optimize') {
        this.deleteOptimizeScenarioEvent.next();
        this.changeBudget = '';
      } else if (this.selectedTab === 'scm-simulate') {
        this.deleteSimulateEvents.next();
      }
    }
  }

  /***
   * @desc Function to remove Validateion error on change budget 
   */
  budgetValueChanged(changeBudget) {
    if (changeBudget <= -100) {
      this.isOptimizeBudgetWrong = true;
      this.toastr.error("Budget should not be less than -99")
    } else {
      this.isOptimizeBudgetWrong = false;
    }
  }

  /***
   * @desc Function to Emit event on Optimize event
   * @param {Integer} (Change Budget ) 
   * it validates for pending change status
   * @returns {Event} - emit event to Optimize
   */
  optimizeScenario(changeBudget) {
    if (this.selectedScenarioType === 'Optimize Custom') {
      this.toastr.error("Please select base scenario to perform optimization!");
    } else {
      this.isOptimizeBudgetWrong = false;
      if (changeBudget < -100 || changeBudget > 100) {
        this.isOptimizeBudgetWrong = true;
        this.toastr.error("Budget should not be less than -99")
      } else {
        this.isOptimizeBudgetWrong = false;
        this.scmMmmService.getPendingStatus().subscribe(pending => {
          if (pending === 'NO' || pending === null) {
            this.evaluateOptimizeEvents.next(changeBudget);
            this.scmMmmService.setGoodToGo('YES');
          } else {
            this.confirmationPopupService.confirm('Confirm', 'Do you want to leave the section without saving your changes?', '', 'Yes', 'Cancel')
              .then((confirmed) => {
                if (confirmed) {
                  this.evaluateOptimizeEvents.next(changeBudget);
                  this.scmMmmService.setPendingStatus('NO');
                  this.scmMmmService.setGoodToGo('YES');
                } else if (!confirmed) {
                  this.scmMmmService.setGoodToGo('YES');
                }
              })
          }
        })
      }
    }
  }

  // Triggering Event for Simulate Action 
  /***
   * @desc Function to Emit event on Simulate Action event
   * @param {Integer} (Change Budget ) 
   * it validates pending change status and emit event
   * @returns {Event} - emit event to simulate
   */
  simulate() {
    if (this.selectedScenarioType === 'Simulate Custom') {
      this.toastr.error("Please select base scenario to perform simulation!");
    } else {
      this.scmMmmService.getPendingStatus().subscribe(pending => {
        if (pending === 'NO' || pending === null) {
          this.evaluateSimulateEvents.next();
          this.scmMmmService.setGoodToGo('YES');
        } else {
          this.confirmationPopupService.confirm('Confirm', 'Do you want to leave the section without saving your changes?', '', 'Yes', 'Cancel')
            .then((confirmed) => {
              if (confirmed) {
                this.scmMmmService.setPendingStatus('NO');
                this.evaluateSimulateEvents.next();
                this.scmMmmService.setGoodToGo('YES');
              } else if (!confirmed) {
                this.scmMmmService.setGoodToGo('YES');
              }
            })
        }
      });
    }
  }


  // Tab switching 
  /***
   * @desc Function to switch tabs
   * @param {String, Event} (Selected Tab,  Event ) 
   * it validates pending change status and change Tab
   * @returns {Event} - On Change tab it call methods to initilize the change in the page
   */
  selectTab(selectedTab, event) {
    this.scmMmmService.getPendingStatus().subscribe(pending => {
      if (this.selectedTab === selectedTab) {
        return;
      }
      if (pending === 'NO' || pending === null) {
        this.selectedTab = selectedTab;
        this.previousTab = selectedTab;
        this.newSelectedScenario = false;
        this.newSelectedBrand = false;
        this.changeBudget = '';
        this.getBrandAndScenarioList()
        this.scmMmmService.setGoodToGo('YES');

      } else if (pending === 'YES') {
        this.scmMmmService.setGoodToGo('NO');
        this.confirmationPopupService.confirm('Confirm', 'Do you want to leave the section without saving your changes?', '', 'Yes', 'Cancel')
          .then((confirmed) => {
            if (confirmed) {
              this.newSelectedScenario = false;
              this.newSelectedBrand = false;
              this.selectedTab = selectedTab;
              this.previousTab = selectedTab;
              this.changeBudget = '';
              this.getBrandAndScenarioList()

              this.scmMmmService.setGoodToGo('YES');
              this.scmMmmService.setPendingStatus('NO');
            } else if (!confirmed) {
              this.selectedTab = this.previousTab;
              this.scmMmmService.setGoodToGo('YES');
              event.stopPropagation();
            }
          })
      }
    })
    this.selectedTab = selectedTab;
    this.localStorage.setItem('selectedScmTab', this.selectedTab)
  }
  // Get scenario List
  /***
   * @desc Function to Get Scenario List
   * @param {Integer} (Selected Brand Id ) 
   * Get scenario List
   * @returns {Array} - Scenario list for the selected Brand
   */
  getScenerioList(brandId) {
    const scenarioType = this.selectedTab === 'scm-optimize' ? 'Optimize' : 'Simulate';
    this.scmMmmService.getScenarioList(brandId, scenarioType).subscribe((scenarioList) => {
      if (!!scenarioList && scenarioList.code === 200) {
        this.scmScenarioList = scenarioList.data;
        this.selectedScenarioType = this.scmScenarioList.filter(e => { return e.scenarioType === 'Optimize Base' || e.scenarioType === 'Simulate Base' })[0].scenarioType;
        this.selectedScenarioId = this.oldSelectedScenario = this.scmScenarioList.filter(e => { return e.scenarioType === 'Optimize Base' || e.scenarioType === 'Simulate Base' })[0].scenarioId;
        if (this.selectedTab === 'scm-optimize') {
          this.changeScenerioOptimizeEvents.next({ selectedScenarioType: this.selectedScenarioType, selectedScenarioId: this.selectedScenarioId });
        } else if (this.selectedTab === 'scm-simulate') {
          this.changeScenerioSimulateEvents.next({ selectedScenarioType: this.selectedScenarioType, selectedScenarioId: this.selectedScenarioId });
        }
      }
    })
  }

  // Change Brand
  /***
   * @desc Function to change selected Brand
   * @param {Integer} (Selected Brand Id ) 
   * Validate for pending change before changing selected brand
   * @returns {Integer} - selects new Brand
   */
  changeBrand(ev): any {
    const selectedBrand = ev;
    this.scmMmmService.getPendingStatus().subscribe(data => {
      if (data === 'YES') {
        this.scmMmmService.setGoodToGo('NO');
        this.confirmationPopupService.confirm('Confirm', 'Do you want to leave the section without saving your changes?', '', 'Yes', 'Cancel')
          .then((confirmed) => {
            if (confirmed) {
              this.scmMmmService.openOverlay();
              this.scmMmmService.setPendingStatus('NO');
              this.selectedBrandId = selectedBrand;
              this.oldSelectedBrand = selectedBrand;
              this.changeBudget = '';
              this.newSelectedScenario = false;
              this.getScenerioList(selectedBrand);
              this.scmMmmService.setGoodToGo('YES')
            } else if (!confirmed) {
              this.selectedBrandId = this.oldSelectedBrand;
            }
          });
      } else if (data === 'NO' || data === null) {
        this.scmMmmService.openOverlay();
        this.selectedBrandId = selectedBrand;
        this.oldSelectedBrand = selectedBrand;
        this.changeBudget = '';
        this.newSelectedScenario = false;
        this.getScenerioList(selectedBrand);
        this.scmMmmService.setGoodToGo('YES');
      }
    });
    return this.selectedBrandId;
  }

  //Change Scenario
  /***
  /***
   * @desc Function to change selected scenario
   * @param {String} (Selected Scenario Id ) 
   * Validate for pending change before changing selected scenario
   * @returns {String} - selects new Brand
   */
  changeScenario(ev): any {
    const event = ev;
    this.scmMmmService.getPendingStatus().subscribe(data => {
      if (data === 'YES') {
        this.scmMmmService.setGoodToGo('NO')
        this.confirmationPopupService.confirm('Confirm', 'Do you want to leave the section without saving your changes?', '', 'Yes', 'Cancel')
          .then((confirmed) => {
            if (confirmed) {
              this.scmMmmService.openOverlay();
              this.selectedScenarioId = event;
              this.oldSelectedScenario = event;
              this.selectedScenarioType = this.scmScenarioList.filter(e => { return e.scenarioId === event })[0].scenarioType;
              this.tabChangeEvent.next({ selectedTab: this.selectedTab, selectedScenarioId: this.selectedScenarioId, selectedScenarioType: this.selectedScenarioType })
              this.changeBudget = '';
              this.scmMmmService.setGoodToGo('YES')
              this.scmMmmService.setPendingStatus('NO');
            } else if (!confirmed) {
              this.selectedScenarioId = this.oldSelectedScenario;
              this.scmMmmService.setGoodToGo('YES')
            }
          });
      } else if (data === 'NO' || data === null) {
        this.scmMmmService.openOverlay();
        this.selectedScenarioId = event;
        this.oldSelectedScenario = event;
        this.changeBudget = '';
        this.selectedScenarioType = this.scmScenarioList.filter(e => { return e.scenarioId === event })[0].scenarioType;
        this.tabChangeEvent.next({ selectedTab: this.selectedTab, selectedScenarioId: this.selectedScenarioId, selectedScenarioType: this.selectedScenarioType })
        this.scmMmmService.setGoodToGo('YES')
      }
    });
    return this.selectedScenarioId
  }

  getScenarioTooltip(selectedValue, dataList) {
    if(this.scmScenarioList && this.scmScenarioList.length) {
      const selectedName = dataList.filter( e => { return e.scenarioId == selectedValue })[0].scenarioName;
      return selectedName.length > 17 ? selectedName : '';
    }
  }

  getBrandTooltip(selectedValue, dataList) {
    if(this.scmBrandList && this.scmBrandList.length) {
      const selectedName = dataList.filter( e => { return e.brandId == selectedValue })[0].brandName;
      return selectedName.length > 17 ? selectedName : '';
    }

  }

}
