import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
const CHART_TMEPLATE_BASE_URL = "../../../../assets/data/chatAnalysis/";
const TEMPLATE_JSON = 'chatAnalysis.json';
const TEMPLATE_Discript_JSON = 'chatAnalysisDiscription.json';
@Injectable({
  providedIn: 'root'
})
export class ChatAnalysisService {
  chatData:any;

  constructor(private http:HttpClient) { }
  getListStatus(): Observable<any> {
    
    return this.http.get<any>('metamorph/chats/');
 }

 getDiscriptionStatus(chatId): Observable<any> {
  return this.http.get<any>('metamorph/chat/'+chatId+'/');
}

 /***
    * @desc Set claim data
    * @param {String} Module claim
    */
 setChat(chat: any) {
  this.chatData = chat;
}
/***
   * @desc Get claim data
   * @returns {Boolean} Returns claims Data
   */
getChat() {
  return this.chatData;
}
}
