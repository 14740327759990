import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MetamorphCommonService } from '../../services/metamorph-common.service';
import { UserServiceService } from 'src/app/shared/services/user-service.service';

@Component({
  selector: 'app-bordereaux-base',
  templateUrl: './bordereaux-base.component.html',
  styleUrls: ['./bordereaux-base.component.scss'],
})
export class BordereauxBaseComponent implements OnInit {
  operationalData: any;
  standardizaDownloadLink: any;
  validationDownloadLink: any;
  amendRulesShow = false;
  yearMonthCheck: any;
  currentMonth: any;
  executionMonth: any;
  execMonth: any;
  uploadDisabled = false;

  constructor(
    private route: Router,
    public metamorphCommonService: MetamorphCommonService,
    public user: UserServiceService
  ) {}

  breadcrumb = ['Bordereaux Management', 'Standardization'];
  selectedTab = 'standardization';

  ngOnInit(): void {
    this.operationalData = JSON.parse(
      this.metamorphCommonService.getOperationalInfoData()
    );

    if (this.operationalData) {
      this.standardizaDownloadLink = this.operationalData.filter(function (el) {
        return el.label == 'Standardize Download Template';
      })[0].value;
      this.validationDownloadLink = this.operationalData.filter(function (el) {
        return el.label == 'Validate Download Template';
      })[0].value;

      this.yearMonthCheck = this.operationalData.filter(function (el) {
        return el.label == 'Execution Month';
      })[0].value;
      this.executionMonth = this.yearMonthCheck.toString().substring(4, 7);
      this.execMonth =
        this.executionMonth[0] == '0'
          ? this.executionMonth.substring(1)
          : this.executionMonth;
      this.currentMonth = new Date().getMonth() + 1;
      if (this.execMonth == this.currentMonth) {
        this.uploadDisabled = true;
      }
    }
  }

  navigateBackTo(url) {
    this.route.navigate([url]);
  }

  selectTab(selectedTab, tabName, event) {
    this.selectedTab = selectedTab;
    this.breadcrumb[1] = tabName;
    this.metamorphCommonService.triggerFileUploadEvent(null);
  }

  openFileUploaderModal() {
    this.metamorphCommonService.triggerFileUploadEvent(this.selectedTab);
  }

  handleAmendRules(event) {
    if (event) {
      this.amendRulesShow = event.amendRulesShow;
    }
  }
}
