import { Component, Input, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { DemandForecastService } from '../demand-forecast.service';

@Component({
  selector: 'app-demand-performance-analysis',
  templateUrl: './demand-performance-analysis.component.html',
  styleUrls: ['./demand-performance-analysis.component.scss']
})
export class DemandPerformanceAnalysisComponent {

  @Input() selectedSKU: any;
  @Input() selectedProductFamily: any;
  @Input() productFamilies: any;
  updateFlag: boolean = false;
  modelWiseForecast: any = [];
  mapeTrend: any = [];
  productFamilyMAPE: any;
  cpiIndexData: any;
  ppiData: any;
  unEmploymentData: any;
  demandDrivesMonthlyForecast: any;
  demandDrivesWeeklyForecast: any;
  chartTemplate: any;
  chartOptionConfig = {
    chartType: 'spline',
    dashStyle: 'dash',
    marker: {
      symbol: 'circle',
      fillColor: '#FFFFFF',
      lineWidth: 2,
      lineColor: ''
    }
  }
  multySeriesColors = ['#188fff', '#ff333a', '#00C073', '#e447ea', '#33ddff', '#ff9c33', '#461a63']
  signleSeriesColors = ['#00C073']
  modelWiseForecastOptionConfig: any;
  selectedProductFamilyForMAPE: any = null;
  discoundOffered: any;
  constructor(private demandForecastService: DemandForecastService, private loader: NgxUiLoaderService, private sharedNotificationService: NotificationService) { }

  ngOnChanges(): void {
    this.getPerformanceAnalysisData(this.selectedProductFamily, this.selectedSKU);
    this.changeMAPEProductFamily(this.selectedProductFamily);
  }

  getPerformanceAnalysisData(selectedProductFamily, selectedSKU) {
    this.loader.start()
    const dataForChart = [];
    this.demandForecastService.getDemandForecastChartTemplateJSON().subscribe((template) => {
      this.chartTemplate = template;
      this.demandForecastService.getPerformanceAnalysisData(selectedProductFamily, selectedSKU).subscribe((performanceResponse) => {
        if (performanceResponse && performanceResponse.length) {
          this.modelWiseForecast = performanceResponse[0]?.data ? [performanceResponse[0].data] : [];
          this.modelWiseForecastOptionConfig = this.chartOptionConfig;
          this.mapeTrend = performanceResponse[1]?.data ? [performanceResponse[1].data] : [];
          this.cpiIndexData = [performanceResponse[3].data[0]];
          this.ppiData = [performanceResponse[3].data[1]];
          this.unEmploymentData = [performanceResponse[3].data[2]];
          this.discoundOffered = performanceResponse[4].data;
          this.updateFlag = true;
          this.loader.stop();
        } else {
          this.loader.stop();
        }
      });
    });
  }

  changeMAPEProductFamily(seletedProductMapeProductFamily) {
    this.loader.start()
    this.productFamilyMAPE = [];
    this.demandForecastService.updateProductFamilyMAPE(seletedProductMapeProductFamily).subscribe(response => {
      if (response && response.code === 200) {
        this.productFamilyMAPE = response.data;
        this.loader.stop();
      } else {
        this.loader.stop()
      }
    });
  }

  toggleAccordian(event) {
    var element = event.target;
    element.classList.toggle("active");
    var panel = element.nextElementSibling;
    if (panel.style.maxHeight === '') {
      panel.style.maxHeight = '0px';
    } else if (panel.style.maxHeight === '0px') {
      panel.style.maxHeight = panel.scrollHeight + "px";
    } else if (panel.style.maxHeight !== '0px') {
      panel.style.maxHeight = '0px';
    }
  }

  getReadableDate(date) {
    return this.sharedNotificationService.getReadableDate(date);
  }

}
