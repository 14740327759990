import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DemandForecastService } from '../demand-forecast/demand-forecast.service';
import UsMap from '@highcharts/map-collection/countries/us/us-all.geo.json';
import Highcharts from 'highcharts/highmaps';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { interval, Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { DemandforecastingService } from '../demandforecasting.service';
@Component({
  selector: 'app-sop-approval',
  templateUrl: './sop-approval.component.html',
  styleUrls: ['./sop-approval.component.scss']
})
export class SopApprovalComponent implements OnInit {

  productFamilies: Array<any> = [];
  selectedProductFamily: any;
  stockKeepingUnits: any;
  Highcharts: typeof Highcharts = Highcharts;
  isHighcharts = typeof Highcharts === 'object';
  chartConstructor: string = 'mapChart';
  updateFlag: boolean = false;
  chartOptions: any = {
    chart: {
      map: UsMap,
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        alignTo: 'spacingBox',
      },
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: true,
    },
    colorAxis: {
      // min: 0,
      minColor: '#EEEEFF',
      maxColor: '#000022',
      stops: [
        [0, '#EFEFFF'],
        [0.67, '#4444FF'],
        [1, '#000022']
      ]
    },
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.properties.region}: <b>{point.value:.0f}'
    },
    series: [
      {
        type: 'map',
        dataLabels: {
          enabled: false,
          format: '{point.name}'
        },
        states: { hover: { enabled: false } },
        borderColor: '',
        borderWidth: -1,
        hover: {
          enabled: false
        },
        allAreas: true,
        data: [
          ['us-ma', 0],
          ['us-wa', 0],
          ['us-ca', 0],
          ['us-or', 0],
          ['us-wi', 0],
          ['us-me', 0],
          ['us-mi', 0],
          ['us-nv', 0],
          ['us-nm', 0],
          ['us-co', 0],
          ['us-wy', 0],
          ['us-ks', 0],
          ['us-ne', 0],
          ['us-ok', 0],
          ['us-mo', 0],
          ['us-il', 0],
          ['us-in', 0],
          ['us-vt', 0],
          ['us-ar', 0],
          ['us-tx', 0],
          ['us-ri', 0],
          ['us-al', 0],
          ['us-ms', 0],
          ['us-nc', 0],
          ['us-va', 0],
          ['us-ia', 0],
          ['us-md', 0],
          ['us-de', 0],
          ['us-pa', 0],
          ['us-nj', 0],
          ['us-ny', 0],
          ['us-id', 0],
          ['us-sd', 0],
          ['us-ct', 0],
          ['us-nh', 0],
          ['us-ky', 0],
          ['us-oh', 0],
          ['us-tn', 0],
          ['us-wv', 0],
          ['us-dc', 0],
          ['us-la', 0],
          ['us-fl', 0],
          ['us-ga', 0],
          ['us-sc', 0],
          ['us-mn', 0],
          ['us-mt', 0],
          ['us-nd', 0],
          ['us-az', 0],
          ['us-ut', 0],
          ['us-hi', 0],
          ['us-ak', 0]
        ]
      }
    ]
  };

  timeInterval: Subscription;
  localStorage = localStorage;
  productFamilyValue: any;
  oldProductFamilyValue: any;
  status: any;
  constructor(public demandForecastService: DemandForecastService,
    private loader: NgxUiLoaderService,private confirmationPopupService: ConfirmationPopupService,
    private demandforecastingService: DemandforecastingService, private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.getProductFamilyList();
    this.getNotificationData();
  }

  getNotificationData() {
    const endPoint = 'managerNotification/'
    this.timeInterval = interval(5000)
      .pipe(
        startWith(0),
        switchMap(() => this.notificationService.getNotification(endPoint))
      ).subscribe(response => {
        if (response && response.data) {
          this.notificationService.sendNotificationData(response.data);
        }
      });
  }

  ngOnDestroy(): void {
    this.timeInterval.unsubscribe();
  }

  getProductFamilyList() {
    this.loader.start();
    this.selectedProductFamily = '';
    this.demandForecastService.getproductFamily().subscribe((productfamilyResponse) => {
      if (productfamilyResponse && productfamilyResponse.code === 200) {
        this.productFamilies = productfamilyResponse.data;
        this.productFamilyValue = this.oldProductFamilyValue = this.productFamilies[0].id;
        this.selectedProductFamily = productfamilyResponse.data[0].id;
        this.onProductFamilyChange(this.productFamilies[0].id);
      } else { this.loader.stop() }
    })
  }

  getMapData(selectedProductFamily) {
    this.demandforecastingService.getMapChartData(selectedProductFamily).subscribe((chartMapData) => {
      if (chartMapData && chartMapData.code === 200) {
        this.populateDataOnMap(chartMapData.data);
        this.loader.stop();
      }
    })
  }
  populateDataOnMap(chartData) {
    if (this.chartOptions?.series?.length) {
      this.chartOptions.series[0].data = this.chartOptions.chart.map.features.map(item => {
        let item2 = chartData.find(i2 => i2.code === item.properties.region);
        return item2 ? [item.properties['hc-key'], item2.value] : [item.properties['hc-key'], 0];
      });
    }
    this.updateFlag = true
  }

  onProductFamilyChange(ev): any {
    const event = Number(ev)
    Promise.all([this.localStorage.getItem('hasPendingSave')]).then(values => {
      const hasPendingSave = values[0];
      if (hasPendingSave === 'NO' || hasPendingSave === null) {
        this.productFamilyValue = event;
        this.oldProductFamilyValue = event;
        this.changeProductFamily(event)
        if (this.productFamilies.length) {
          const returnData = this.productFamilies.find(itemInArray => itemInArray.id === event).name;
          return returnData
        }
      } else if (hasPendingSave === 'YES') {
        this.confirmationPopupService.confirm('Confirm', 'Do you want to leave the section without saving your changes?', '', 'Yes', 'Cancel')
          .then((confirmed) => {
            if (confirmed) {
              this.productFamilyValue = event;
              this.oldProductFamilyValue = event;
              this.localStorage.setItem('hasPendingSave', 'NO');
              this.changeProductFamily(this.productFamilyValue)
              if (this.productFamilies.length) {
                const returnData = this.productFamilies.find(itemInArray => itemInArray.id === this.productFamilyValue).name;
                return returnData
              }
            } else if (!confirmed) {
              this.productFamilyValue = this.oldProductFamilyValue;
              if (this.productFamilies.length) {
                const returnData = this.productFamilies.find(itemInArray => itemInArray.id === this.oldProductFamilyValue).name;
                return returnData.toString();
              }
            }
            });
       }
    });
  }

  changeProductFamily(familyId) {
    this.selectedProductFamily = familyId;
    this.getMapData(this.selectedProductFamily);
  }

}
