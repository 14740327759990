<div class="confirmation-modal-header">
  <h3 class="confirmation-modal-title">Edit User</h3>
</div>

<div class="modal-body">
  <div class="mb-2">
    <form [formGroup]="registerForm">
      <div class="row">
        <div class="col-md-8">
          <div class="row">
            <div class="form-group col-md-6">
              <label>First Name</label>
              <input type="text" formControlName="firstName" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" [value]="userDataView[0].firstName"
                [(ngModel)]="userDataView[0].firstName" />
              <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback txtcolor">
                <div *ngIf="f.firstName.errors.required"> First Name is required </div>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label>Last Name</label>
              <input type="text" formControlName="lastName" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" [value]="userDataView[0].lastName"
                [(ngModel)]="userDataView[0].lastName" />
              <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback txtcolor">
                <div *ngIf="f.lastName.errors.required">Last Name is required</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-6">
              <label>Email</label>
              <input type="text" formControlName="email" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" [value]="userDataView[0].email"
                [(ngModel)]="userDataView[0].email" />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback txtcolor">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email"> Email must be a valid email address </div>
              </div>
            </div>
            <div class="form-group col-6">
              <label>User Alias</label>
              <input type="text" formControlName="userName" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.userName.errors }" [value]="userDataView[0].userName"
                [(ngModel)]="userDataView[0].userName" />
              <div *ngIf="submitted && f.userName.errors" class="invalid-feedback txtcolor">
                <div *ngIf="f.userName.errors.required">User Name is required</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-12">
              <label>Role</label>
              <div>
                <span
                  class="badge badge-pill badge-primary-outline text-dark"><strong>{{defaultrolename}}</strong></span>
                <ng-container *ngIf="otherrolename.length!=0">
                  <span *ngFor="let oroleData of otherrolename;"
                    class="badge badge-pill badge-primary-outline">{{oroleData}}</span>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="row">
            <div class="form-group col-md-12 profileright">
              <img *ngIf="userProfileImage" class="hoverable imgcenter profilehtwt" [src]="imgsrc" alt="user Profile" />
              <div *ngIf="!userProfileImage" class="profileImage"> {{userProfileName | uppercase | shortName}}</div>
              <label>Profile Photo</label>
              <ul class="profileInstruction">
                <li>JPG/PNG extensions are allowed</li>
                <li>Recommended Dimension : </li>
                <p>(128 * 128)px</p>
              </ul>
              <input type="file" (change)="fileChange($event)" accept=".png, .jpeg, .jpg,"
                class="form-control chosepadding" />
              <button *ngIf="userProfileImage" class="remove-photo" (click)="removeProfilePhoto()"> Remove Photo
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light btn-sm addcustom" (click)="onReset()">Cancel</button>
  <button type="button" class="btn btn-primary btn-sm" (click)="onSubmitEdit(userDataView[0])">Save</button>
</div>