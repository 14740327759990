import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScmMmmRoutingModule } from './scm-mmm-routing.module';
import { ScmMmmComponent } from './scm-mmm.component';
import { ScmOptimizeComponent } from './scm/scm-optimize/scm-optimize.component';
import { ScmSimulateComponent } from './scm/scm-simulate/scm-simulate.component';
import { ScmComponent } from './scm/scm.component';
import { FormsModule } from '@angular/forms';
import { HighchartsChartModule } from 'highcharts-angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScenarioNamePopoverComponent } from './scenario-name-popover/scenario-name-popover.component';
@NgModule({
  declarations: [
    ScmMmmComponent,
    ScmOptimizeComponent,
    ScmSimulateComponent,
    ScmComponent,
    ScenarioNamePopoverComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    HighchartsChartModule,
    ScmMmmRoutingModule,
    NgbModule
  ]
})
export class ScmMmmModule { }
