import { Component, OnInit } from '@angular/core';
import { MetamorphCommonService } from '../services/metamorph-common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rule-config',
  templateUrl: './rule-config.component.html',
  styleUrls: ['./rule-config.component.scss']
})
export class RuleConfigComponent implements OnInit {

  selectedTab: string = 'coverHolder';
  constructor(public metamorphCommonService: MetamorphCommonService, public router: Router) { }

  ngOnInit(): void {
  }

  selectTab(selectedTab, event) {
    this.selectedTab = selectedTab
  }

}
