import { Component, ComponentRef, Input, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { MetamorphCommonService } from '../../../services/metamorph-common.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DataTableDirective } from 'angular-datatables';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, Subscription,interval } from 'rxjs';
import { BordereauxManagementService } from '../../../services/bordereaux-management.service';
import { ChildTableStandardComponent } from './child-table-component/child-table-component.component';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { UserServiceService } from 'src/app/shared/services/user-service.service';

@Component({
  selector: 'standardization',
  templateUrl: './standardization.component.html',
  styleUrls: ['./standardization.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('1100ms ease-in-out')),
    ]),
  ],
})
export class StandardizationComponent implements OnInit {
  @Input() selectedTab: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  bordereauxListData: Array<any> = [];
  bordereauxOldIinteration: any;
  bordereauxLatestIinteration: any;
  standardizeResponse: any;
  versionIndex: any;
  selectedVersionInfo: any;
  //Context Menu
  contextmenu = false;
  contextmenuX = 0;
  contextmenuY = 0;
  contextRecord:any;
  contextId:any;
  contextClass="parentClass"
  processId:any;
  yearMonth:any;

  standardizeFlag= false;
  isProgress=false;
  workItemArray:any;
  timeInterval: Subscription;
  timeIntevalSeconds=20;
  iterationFlag=0;
  existingFileInfo: any;
  oprationalDetailsInfo = {
    header: 'Standrization',
    info: [],
    aditionalConfig : { titleText: 'Upload New Data', reportingPeriodStartDate: '', reportingPeriodEndDate: '', versionInfo: ''}
  }


  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;
  private childRow: ComponentRef<ChildTableStandardComponent>;
  private uploadEventsSubscription: Subscription;
  operationalData: any;
  constructor(public metamorphCommonService: MetamorphCommonService, private bordereauxManagementService: BordereauxManagementService, private ngxLoader: NgxUiLoaderService,
    private viewRef: ViewContainerRef, private _renderer: Renderer2, public toastr: ToastrService,
    private route: Router,private confirmpopupservice:ConfirmationPopupService,
    public userService: UserServiceService) { }


  ngOnInit(): void {

    this.operationalData = JSON.parse(this.metamorphCommonService.getOperationalInfoData());
    this.oprationalDetailsInfo.info = this.operationalData;
    this.uploadEventsSubscription = this.metamorphCommonService.captureFileUploadEvent().subscribe((data) => {
      if(data === 'standardization' && data === this.selectedTab) {
        this.openFileUploader(false, this.oprationalDetailsInfo)
      }
    })
    this.getBordereauxListData();
  }

  getBordereauxListData(reloadRequired?) {

    this.ngxLoader.start();
    this.bordereauxListData = [];
    this.ngxLoader.start();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      deferRender: true,
      destroy: true,
      order: [[0, 'desc']],
      scrollY: 'calc(100vh - 375px)',
      scrollCollapse: true,
    }
    this.dtOptions.columnDefs = [
      {
        targets: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        orderable: false
      }]
    this.processId = this.operationalData.filter(function (el) {
      return el.label == 'Process Id';
    })[0].value;
    this.yearMonth = this.operationalData.filter(function (el) {
      return el.label == 'Execution Month';
    })[0].value;
    this.bordereauxManagementService.getBordereauxListData(this.processId, this.yearMonth).subscribe(response => {
      this.bordereauxListData = response?.data;

      if (this.iterationFlag==0) {
      setInterval(()=> {
          if (this.iterationFlag==0) {
            this.checkInProgressCount()
          }
        }, this.timeIntevalSeconds * 1000);
      }
      if (reloadRequired) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
          this.ngxLoader.stop();
        });
      } else {
        this.dtTrigger.next();
        this.ngxLoader.stop();
      }

    })
  }


  checkInProgressCount(){
    this.workItemArray = this.bordereauxListData.filter(function(value, index){
      value.index = index;
      let selectedVersionStatus = value[value.selected].latestIteration.status;
      return selectedVersionStatus=='Standardization In-Progress';
    }

    );
    let workItemCount = this.workItemArray.length;
    if(workItemCount>0){
      this.workItemArray.forEach(element => {
        let selectedVersionWorkItem = element[element.selected].latestIteration.workItemId;
        this.bordereauxManagementService.standardizeProgressProcess(this.processId, this.yearMonth,selectedVersionWorkItem).subscribe(response => {
          let workItemData= response.data[0];
          let workItemStatus = workItemData[workItemData.selected].latestIteration.status;
          if(workItemStatus=='Standardized'){
            let indexCurrent = element.index;
            this.bordereauxListData[indexCurrent][this.bordereauxListData[indexCurrent].selected].latestIteration.status="Standardized";
            this.bordereauxListData[indexCurrent][this.bordereauxListData[indexCurrent].selected].latestIteration.downloadOutputLink=workItemData[workItemData.selected].latestIteration.downloadOutputLink;
          }
          else if(workItemStatus=='System Error'){
            let indexCurrent = element.index;
            this.bordereauxListData[indexCurrent][this.bordereauxListData[indexCurrent].selected].latestIteration.status="System Error";

          }else if(workItemStatus=='Standardization Failed'){
            let indexCurrent = element.index;
            this.bordereauxListData[indexCurrent][this.bordereauxListData[indexCurrent].selected].latestIteration.status="Standardization Failed";

          }
         })

      });
    }else{
      this.iterationFlag =1;
    }

  }

  expandRow(trRef, rowData) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      var row = dtInstance.row(trRef);
      if (row.child.isShown()) {
        row.child.hide();
        this._renderer.removeClass(trRef, 'shown');
      }
      else {
        this.childRow = this.viewRef.createComponent(ChildTableStandardComponent);
        this.childRow.instance.dataRow = [rowData];
        row.child(this.childRow.location.nativeElement).show();
        this._renderer.addClass(trRef, 'shown');
      }
    })
  }

  /***
   * @desc Standardize Process
   * @param Integer (Work Item Id)
   * Call service to standardize process
   *
   */

  standardizeProcess(index, workItemId){
    this.bordereauxManagementService.standardizeProcess(workItemId).subscribe(response => {
      this.standardizeResponse = response?.data;
      this.toastr.success('Standardization In Progress');
      this.bordereauxListData[index][this.bordereauxListData[index].selected].oldIteration.unshift(this.bordereauxListData[index][this.bordereauxListData[index].selected].latestIteration);
      this.bordereauxListData[index][this.bordereauxListData[index].selected].latestIteration = this.standardizeResponse[0][this.standardizeResponse[0].selected].latestIteration;
      this.versionIndex = this.bordereauxListData[index].versions.findIndex(e => { return e == this.bordereauxListData[index].selected });
      this.bordereauxListData[index].versionsInfo[this.versionIndex] = this.standardizeResponse[0][this.standardizeResponse[0].selected].latestIteration.workItemId;
      this.standardizeFlag=true;
      this.iterationFlag =0;
    })
  }

  /***
   * @desc Update Selected Version
   * @param Integer (Index Id, Selected Version)
   * Call service to update user selected version and get version related details
   * @returns {Array} Returns selected version details list
   *
   */
  updateVersion(trRef,indexId, selectedVersion) {
    this.versionIndex = this.bordereauxListData[indexId].versions.findIndex(e => { return e == selectedVersion });
    this.selectedVersionInfo = this.bordereauxListData[indexId].versionsInfo[this.versionIndex];
    this.bordereauxManagementService.updateVersionInfo(this.selectedVersionInfo).subscribe(response => {
      this.standardizeResponse = response?.data;
      this.bordereauxListData[indexId].selected = selectedVersion;
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        var row = dtInstance.row(trRef);
        if (row.child.isShown()) {
          row.child.hide();
          this._renderer.removeClass(trRef, 'shown');
        }
      })
    })
  }
  /***
    * @desc Activates the context menu with the coordinates
    * @param Integer (Brand Id)
    * Call service to get brand and scenario list for 1st selected brand
    * @returns {Array} Returns brand List and scenario list.
    *
    */

  /***
   * @desc Activates the context menu with the coordinates
   * @param event
   *
   */
  onrightClick(event, indexId, recordData) {
    this.contextmenuX = event.clientX;
    this.contextmenuY = event.clientY;
    this.contextId = indexId;
    this.contextRecord = recordData;
    this.contextmenu = true;
  }


  /***
   * @desc Disable Context Menu
   *
   */
  disableContextMenu() {
    this.contextmenu = false;
  }

  landToRespectiveMappingScreen(indexData,uploadId, mappingStatus, workItemId) {
    let step = ''
    if (mappingStatus.toLowerCase() === 'ready for sheet mapping.') {
      step = 'sheet-mapping'
    } else if (mappingStatus.toLowerCase() === 'sheet mapping is saved.') {
      step = 'sheet-mapping'
    } else if (mappingStatus.toLowerCase() === 'sheet mapping is done.') {
      step = 'header-range-selection'
    } else if (mappingStatus.toLowerCase() === 'column mapping is saved.') {
      step = 'column-mapping'
    } else if (mappingStatus.toLowerCase() === 'column mapping is done.') {
      step = 'mapped-column-preview'
    }

    const mappingStatusData =  { step: step, uploadId: uploadId, workItemId: workItemId };
    const isMappingFlow = true
    this.openFileUploader(isMappingFlow, this.oprationalDetailsInfo, mappingStatusData)
  }

  openFileUploader(isMappingFlow,oprationalDetailsInfo: any, mappingStatus?: any) {
    this.metamorphCommonService.openFileUploader(isMappingFlow, oprationalDetailsInfo,this.selectedTab, true, mappingStatus).then(reason => {
      if (reason) {
        this.iterationFlag =0;
        this.getBordereauxListData(true);
      }
    })
  }

  ngOnDestroy(): void {
    this.uploadEventsSubscription.unsubscribe();
  }

  deleteStandard(workItem){
    this.confirmpopupservice.confirm('Confirm', 'Are you sure, you want to Delete the version permanently?', '', 'Yes', 'Cancel').then((res) => {
      if (res) {
        this.bordereauxManagementService.deleteStandardize(workItem).subscribe(resCode => {
          if (resCode && resCode.code === 200) {
            this.toastr.success("The version is deleted successfully");
            this.getBordereauxListData(true);
          }
        })

      }
    })
  }
}
