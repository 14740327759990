import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { PricingService } from '../pricing.service';
import { PricingManageModelService } from './pricing-manage-model.service'
import { ModalComponent } from 'src/app/shared/component/modal/modal.component';
import { ModalConfig } from 'src/app/shared/component/modal/modal.config';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/config.service';


@Component({
  selector: 'app-pricing-manage-model',
  templateUrl: './pricing-manage-model.component.html',
  styleUrls: ['./pricing-manage-model.component.scss']
})
export class PricingManageModelComponent implements OnInit {
  breadcrumb: Array<any> = [];
  modelHeads: any;
  versionHeads: any;
  modelList: any;
  modelData: any;
  vesionData: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  @ViewChild('modal') private modal: ModalComponent;
  public getAvailableVersion: ModalConfig = {
    modalTitle: 'Model Versions',
    onDismiss: () => {
      return true;
    },
    dismissButtonLabel: 'CANCEL',
    onClose: () => {
      this.updateVersion();
      return true;
    },
    closeButtonLabel: 'SAVE',
  };
  pricingModules: any;
  constructor(private pricingService: PricingService,
    private activatedRoute: ActivatedRoute, private pricingModels: PricingManageModelService,
    private ngxLoader: NgxUiLoaderService,
    public toastr: ToastrService,
    private route: Router,
  ) { }

  ngOnInit(): void {
    this.getHeads();
    this.getModelData();
    this.pricingService.activePricingModule = 'MANAGE';
    const item = this.activatedRoute.snapshot.url[0].path.replace('-', ' ').replace(/^([a-z]|[A-Z])$/g, (c, i) => (i ? " " : "") + c.toUpperCase())
    // Adding Level 2 Breadcrumb
    this.pricingService.addBreadcrumbData({ "level": "2", "name": "" }).subscribe(data => {
      this.breadcrumb = data;
    })
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  getHeads() {
    this.modelHeads = this.pricingModels.modelListHead();
  }
  // Fetch model data
  getModelData() {
    this.ngxLoader.start();
    this.dtOptions[0] = {
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [10, 20, 30],
      processing: true,
    }
    this.dtOptions[0].columnDefs = [
      {
        targets: [4],
        orderable: false
      }];
    let lobId = this.pricingService.getLobId();
    let projectId = this.pricingService.getProjectId();

    this.pricingModels.getModelData(projectId).subscribe((modelObj) => {
      if (modelObj) {
        this.modelList = modelObj;
        var modelListData: any = this.modelList.data;
        this.modelData = modelListData;
        this.dtTrigger.next();
        this.ngxLoader.stop();
      } else { this.modelData = []; this.ngxLoader.stop(); }
    });
  }

  dataExplorationRouting() {
    window.open(`pricing/data-exploration`)
  }

  checkHigherVersion(currentSelectedVersion, otherVersion) {
    let result = false;
    otherVersion.forEach(element => {
      if (element.version > currentSelectedVersion && element.isDefault == false) {
        result = true;
      }
    });
    return result;
  }
//   Opening version model popover
  getVersionOpenModal(projectData) {
    let modelTypeId = projectData.modelId;
    this.pricingService.setmodelTypeId(modelTypeId);
    let modelTechniqueId = projectData.modelTypeId;
    this.pricingService.setmodelTechniqueId(modelTechniqueId);
    this.pricingService.setVersion(projectData.version);
    this.versionHeads = this.pricingModels.versionListHead();
    this.vesionData = projectData.other;
    this.modal.open();
  }

  setVersion(version) {
    this.pricingService.setVersion(version);
  }

  // Update version
  updateVersion() {
    let lobId: any = this.pricingService.getLobId();
    let projectId: any = this.pricingService.getProjectId();
    let modelTypeId: any = this.pricingService.getmodelTypeId();
    let modelTechniqueId: any = this.pricingService.getmodelTechniqueId();
    let version: any = this.pricingService.getVersion();
    let modelObject = {
      "lobId": parseInt(lobId),
      "projectId": parseInt(projectId),
      "modelId": parseInt(modelTechniqueId),
      "modelTypeId": parseInt(modelTypeId),
      "version": parseInt(version),
      "isDefault": true
    };
    let modelFinal = [modelObject];
    this.pricingModels
      .updateModelVersion(modelFinal)
      .subscribe((claimsobj) => this.toastr.success('Model Version updated'));
    setTimeout(() => {
      location.reload();
    }, 3000);
  }
}
