import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { SelectModelComponent } from './select-model/select-model.component';

@Injectable({
  providedIn: 'root'
})
export class PricingService {
  private _activeTab: any;
  private _activePricingModule: any;
  private _PRICING_MODULES = {
    "PROJECTS": "PROJECTS",
    "MANAGE": "MANAGE",
    "ANALYSIS": "ANALYSIS",
    "INTER": "INTER",
    "VALIDATION": "VALIDATION"
  }

  private _PRICING_MODULES_META = {
    "projects": {
      ID: 1,
      NAME: 'PROJECTS',
      ROUTE: '/pricing/project'
    },
    "manage": {
      ID: 2,
      NAME: 'MANAGE',
      ROUTE: '/pricing/model-manage'
    },
    "analysis": {
      ID: 3,
      NAME: 'ANALYSIS',
      ROUTE: '/pricing/model-analysis'
    },
    "interpretability": {
      ID: 4,
      NAME: 'INTER',
      ROUTE: '/pricing/model-interpretability'
    },
    "validation": {
      ID: 5,
      NAME: 'VALIDATION',
      ROUTE: '/pricing/model-validation'
    }
  }
  private subject = new Subject<any>();
  private readyToSelectModel$: BehaviorSubject<any> = new BehaviorSubject(false);
  private policyData = {};
  public breadcrumb_data = [] as any;

  constructor(private router: Router, private http: HttpClient, private modalService : NgbModal) { }
  private moduleClickedSubject = new Subject<any>();
  private moduleNextClickedSubject = new Subject<any>();
  private modulePrevClickedSubject = new Subject<any>();
  private successEventSubject = new Subject<any>();
  // LOB ID setter
  setLobId(lobId) {
    localStorage.setItem('lobId', lobId);
  }
 // LOB ID getter
  getLobId() {
    return localStorage.getItem('lobId');
  }
 // project ID setter
  setProjectId(projectId) {
    localStorage.setItem('lobProjectId', projectId);
  }
  // project Id getter
  getProjectId() {
    return localStorage.getItem('lobProjectId');
  }

  setmodelTypeId(modelTypeId) {
    localStorage.setItem('modelTypeId', modelTypeId);
  }

  getmodelTypeId() {
    return localStorage.getItem('modelTypeId');
  }

  setmodelTechniqueId(modelTechniqueId) {
    localStorage.setItem('modelTechniqueId', modelTechniqueId);
  }

  getmodelTechniqueId() {
    return localStorage.getItem('modelTechniqueId');
  }

  setVersion(version) {
    localStorage.setItem('modelVersion', version);
  }

  getVersion() {
    return localStorage.getItem('modelVersion');
  }



  /***
  * @desc Set claim data
  * @param {String} Module claim
  */
  setClaim(lobsdata: any) {
    this.policyData = lobsdata;
  }
  /***
  * @desc Get claim data
  * @returns {Boolean} Returns claims Data
  */
  getClaim() {
    return this.policyData;
  }

  get activePricingModule() {
    return this._activePricingModule;
  }

  set activePricingModule(pricingModules) {
    this._activePricingModule = pricingModules;
  }

  get PRICING_MODULES() {
    return this._PRICING_MODULES;
  }

  get PRICING_MODULES_META() {
    return this._PRICING_MODULES_META;
  }

  sendNavClickEvent(navEvent, navModule: any) {
    this.moduleClickedSubject.next({ navEvent, navModule });
  }
  getNavClickEvent(): Observable<any> {
    return this.moduleClickedSubject.asObservable();
  }

  sendSuccessEvent(response: any) {
    this.successEventSubject.next(response);
  }
  getSuccessEvent(): Observable<any> {
    return this.successEventSubject.asObservable();
  }

  sendTabClickEvent(activeModule: any) {
    this.activePricingModule = activeModule;
    this.moduleClickedSubject.next(activeModule);
  }
  getTabClickEvent(): Observable<any> {
    return this.moduleClickedSubject.asObservable();
  }

  setAssignee(assignee) {
    localStorage.setItem('uwAssignee', assignee);
  }
  get hasAssignee() {
    return localStorage.getItem('uwAssignee');
  }

  // Adding/Updating breadcrumb for each navigation
  addBreadcrumbData(item): Observable<any> {
    let updatedBreadcrumb: any;
    const breadcrumb: any = localStorage.getItem('breadcrumbData');
    this.breadcrumb_data = breadcrumb !== 'null' ? JSON.parse(breadcrumb) : [];
    if (item.level === "0") {
      this.breadcrumb_data = [];
    } else if (item.level === "3") {
      if (this.breadcrumb_data.some(el => el.level === item.level)) {
        this.breadcrumb_data.splice(this.breadcrumb_data.findIndex((el) => el.level === item.level), 1, item);
      } else {
        this.breadcrumb_data.push(item);
      }
    } else if (item.level === "1" || item.level === "2") {
      if (item.name !== "") {
        this.breadcrumb_data.push(item);
      } else {
        this.breadcrumb_data.splice(this.breadcrumb_data.findIndex((el) => el.level === item.level) + 1);
      }
    }
    localStorage.setItem('breadcrumbData', JSON.stringify(this.breadcrumb_data));
    updatedBreadcrumb = localStorage.getItem('breadcrumbData');
    return of(JSON.parse(updatedBreadcrumb));
  }


  get activeTab() {
    return this._activeTab;
  }

  set activeTab(tab) {
    this._activeTab = tab;
  }

  sendTabLoadEvent(tab: any) {
    this.moduleClickedSubject.next({ "tabName": tab });
  }
  getTabLoadEvent(): Observable<any> {
    return this.moduleClickedSubject.asObservable();
  }

  validateAPIInput(filters): Observable<any> {
    let flag = 0;
    let errorString = "";
    filters.forEach(element => {
      if (element.filterType === "") {
        flag++;
      }
    });
    if (flag !== 0) {
      errorString = "Please select at least one filter from each filter type.";
    } else {
      errorString = "";
    }
    return of({ errorString: errorString });
  }

  public openSelectModel(modelObj): Promise<boolean> {
    const modalRef = this.modalService.open(SelectModelComponent, { centered: true });
    modalRef.componentInstance.modelObj = modelObj;
    return modalRef.result;
  }

  setSelectModelVisible(option) {
    this.readyToSelectModel$.next(option)
  }

  getSelectModelVisible(): Observable<any> {
    return this.readyToSelectModel$.asObservable();
  }


}
