import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { ExportToExcelService } from 'src/app/shared/services/export-to-excel.service';
import { ScmMmmService } from '../../scm-mmm.service';
import Highcharts from 'highcharts';

@Component({
  selector: 'app-scm-optimize',
  templateUrl: './scm-optimize.component.html',
  styleUrls: ['./scm-optimize.component.scss']
})
export class ScmOptimizeComponent implements OnInit {
  @Input() scmBrandList: any;
  @Input() selectedScenarioId: any;
  @Input() selectedBrandId: any;
  @Input() scenarioList: any;
  @Input() saveOptimizeEvents: any;
  @Input() exportOptimizeEvents: any;
  @Input() evaluateOptimizeEvents: any;
  @Input() deleteOptimizeScenarioEvent: any;
  @Input() changeScenerioOptimizeEvents: any;
  @Input() tabChangeEvent: any;
  @Input() selectedScenarioType: any;
  selectedTab: any;
  Highcharts: typeof Highcharts = Highcharts;
  localStorage = localStorage;
  @Output() newScenarioAdded = new EventEmitter<any>();
  @Output() scenarioDeleted = new EventEmitter<any>();
  @Output() updateBudgetChange = new EventEmitter<any>()
  public selectedSenarioData: any;
  disableEditing: boolean = false;
  updateFlag: boolean = false;
  isFirstLoad: boolean = true;
  changeInOptimalRange: boolean = false;
  selectedOrginalData: any;
  public incrementalSaleChartTemplate: any;
  public mediaResponseChartTemplate: any;
  public roiMacChartTemplate: any;
  public shareSpendChartTemplate: any;
  public optimizeChartData: any;
  public mediaResponseChartData: any;
  public newScenarioData: Array<any> = [];
  public saveEventsSubscription: Subscription;
  public deleteOptimizeScenarioEventSubscription: Subscription;
  public evaluateOptimizeEventsSubscription: Subscription;
  public exportToExcelEventsSubscription: Subscription;
  public tabChangeSubscription: Subscription;
  public changeScenerioOptimizeSubscription: Subscription;
  public changeInBudget: number = 0;
  public showOptimizeChart: boolean = false;
  public updatedOptimalRangeChangeData: Array<any> = [];
  public mediaResponseDataResponse: any;
  public selectedSenarioTableRecored: Array<any> = [];
  public selectedSenarioTableRecoredDataBuffer: any;
  public selectedSenarioTableFooter: Array<any> = [];
  public incrementalSaleChartData: any;
  public roiMacChartData: any;
  public shareOfSpendData: any;
  public invalidInput: boolean = false;

  mediaResponseChartOptionConfig = {
    chartType: 'line',
    marker: {
      symbol: 'circle',
      fillColor: '#FFFFFF',
      lineWidth: 2,
      lineColor: ''
    }
  }
  roiMacOptionConfig = {
    chartType: 'column',
    marker: {}
  }
  mediaResponseChartColor = ['#fcd203']
  multySeriesColors = ['#188fff', '#ff333a', '#00C073', '#e447ea', '#33ddff', '#ff9c33', '#461a63']


  constructor(public scmMmmService: ScmMmmService, public toastr: ToastrService,
    private confirmationPopupService: ConfirmationPopupService,
    private exportToExcelService: ExportToExcelService) { }

  ngOnInit(): void {
    // subscribing tab change event
    this.tabChangeSubscription = this.tabChangeEvent.subscribe(data => {
      if (data !== null) {
        this.selectedTab = data.selectedTab;
        this.selectedScenarioId = data.selectedScenarioId;
        this.selectedScenarioType = data.selectedScenarioType;
        if (data.selectedTab === 'scm-optimize') {
          this.scmMmmService.openOverlay();
          this.getDataAndChartTemplates();
        }
      }
    });

    // subscribing scenario change event
    this.changeScenerioOptimizeSubscription = this.changeScenerioOptimizeEvents.subscribe((data) => {
      if (data) {
        this.selectedScenarioId = data.selectedScenarioId;
        this.selectedScenarioType = data.selectedScenarioType;
        if (this.selectedBrandId !== '99') {
          this.scmMmmService.openOverlay();
          this.getDataAndChartTemplates();
        } else {
          this.scmMmmService.openOverlay();
          this.getDataForPortfolio();
        }
      }
    });

    //Subscribe Save Scenario Action Event
    this.saveEventsSubscription = this.saveOptimizeEvents.subscribe((brandName) => {
      this.saveScenario(brandName)
    });

    //Subscribe Export Table To Excel Action Event
    this.exportToExcelEventsSubscription = this.exportOptimizeEvents.subscribe(() => {
      this.exportScenarioToExcel()
    });

    //Subscribe Delete Action Event
    this.deleteOptimizeScenarioEventSubscription = this.deleteOptimizeScenarioEvent.subscribe(() => {
      this.deleteScenario()
    });

    //Subscribe Optimze Action Event
    this.evaluateOptimizeEventsSubscription = this.evaluateOptimizeEvents.subscribe(data => {
      // let changedBudget: any
      if (data !== null) {
        const changedBudget = data === '' ? 0 : Number(data);
        if (this.selectedSenarioTableRecoredDataBuffer === JSON.stringify(this.selectedSenarioTableRecored) && data === "") {
          this.toastr.error("Please make change to perform optimization!");
        } else {
          this.performOptimizeAction(changedBudget);
        }
      }
    });
  }

  // Optmize Action Sevice call
  /***
  * @desc Function to perform Optmize Action using API call
  * @param {Integer} Module changedBudget
  * Based on the condition it created Optimize Action API payload and does the service call
  * @returns {function} Returns a function which takes Optiomize Action API response as parameters
  *
  */
  performOptimizeAction(changedBudget) {
    if (this.invalidInput) {
      this.toastr.error("Please use valid input!")
    } else {
      this.changeInBudget = changedBudget;
      this.scmMmmService.openOverlay()
      // constructing Payload for Optimize Action Post API
      const optimizeActionPayload = {
        "brandId": this.selectedBrandId,
        "scenarioId": this.selectedScenarioId,
        "scenarioType": this.selectedScenarioType,
        "year": 2020,
        "budgetChange": changedBudget,
        "optimalRange": this.selectedBrandId === '99' ? this.selectedSenarioTableRecored.filter(e => !e.hasOwnProperty('showBrandNameOnly')).slice(0, -7).map(e => { return { "spendLeverId": e.spendLeverId, "min": e.min ? Number(e.min) : 0, "max": e.max ? Number(e.max) : 0 } }) : this.selectedSenarioTableRecored.slice(1).slice(0, -2).map(e => { return { "spendLeverId": e.spendLeverId, "min": e.min ? Number(e.min) : 0, "max": e.max ? Number(e.max) : 0 } })
      }
      this.selectedSenarioTableRecored = [];
      this.scmMmmService.performOptimization([optimizeActionPayload]).subscribe(optimizedData => {
        this.newScenarioData = optimizedData.data.scenario;
        this.scmMmmService.setPendingStatus('YES');
        this.distributeDataAmongChartsAndTable(this.newScenarioData, this.mediaResponseDataResponse)
      });
    }
  }

  ngOnDestroy() {
    // unsubscribing all the subscription to prevent by-directional subscription
    this.saveEventsSubscription.unsubscribe();
    this.deleteOptimizeScenarioEventSubscription.unsubscribe();
    this.evaluateOptimizeEventsSubscription.unsubscribe();
    this.exportToExcelEventsSubscription.unsubscribe();
    this.tabChangeSubscription.unsubscribe();
    this.changeScenerioOptimizeSubscription.unsubscribe();
  }

  ngOnChanges(): void {
    this.showOptimizeChart = false;
  }

  // Chart and Table data fetch call
  /***
   * @desc Function to Get Optimize Table and Chart using API call
   * Get API to do API call for getting Optimize Table and Charts Data
   * @returns {Objects} Returns API response and does a function call which takes Table and chart data as parameters
   *
   */
  getDataAndChartTemplates() {
    this.selectedSenarioTableRecored = [];
    this.selectedSenarioTableRecoredDataBuffer = '';
    this.selectedSenarioData = []
    // Get API data
    this.scmMmmService.getOptimizeData(this.selectedBrandId, this.selectedScenarioId.toString(), this.selectedScenarioType).subscribe(response => {
      if (response && response.code === 200) {
        this.selectedSenarioData = response.data.scenario;
        if (this.selectedScenarioType === 'Optimize Custom') {
          this.updateBudgetChange.emit(response.data.budgetChange);
        }
        const parentSpendLeverId = this.selectedSenarioData[0].scenarioData.filter(e => { return e.spendLeverType === 'Media' && e.isParent })[0].spendLeverId;
        const mediaSpendLeverIdList = this.selectedSenarioData[0].scenarioData.filter(e => { return e.spendLeverType === 'Media' }).map(el => { return el.spendLeverId }).join().toString();
        this.scmMmmService.getMediaResponseCurveData(this.selectedBrandId, parentSpendLeverId, mediaSpendLeverIdList).subscribe((mediaResponseData) => {
          if (mediaResponseData && mediaResponseData.code === 200) {
            this.mediaResponseDataResponse = mediaResponseData.data.data;
            this.distributeDataAmongChartsAndTable(this.selectedSenarioData, this.mediaResponseDataResponse.reverse());
            this.scmMmmService.closeModel();
          }
        })
      } else {
        this.selectedSenarioData = [];
        this.mediaResponseDataResponse = []
        this.scmMmmService.closeModel();
      }
    })
  }

  // Table data fetching for Portfolio
  /***
   * @desc Function to Get Optimize Table  using API call
   * Get API to do API call for getting Optimize Table for Portfolio
   * @returns {Objects} Returns API response which contains Table data
   *
   */
  getDataForPortfolio() {
    this.selectedSenarioTableRecored = [];
    this.selectedSenarioTableRecoredDataBuffer = '';
    this.selectedSenarioData = []
    // Get API data
    this.scmMmmService.getPortfolioOptimizeData(this.selectedBrandId, this.selectedScenarioId.toString(), this.selectedScenarioType).subscribe(response => {
      if (response && response.code === 200) {
        // Modifying data to show brand names
        let modifiedList = []
        if(this.selectedBrandId === '99') {
          const brands =  this.scmBrandList.filter(e => e.brandId !== this.selectedBrandId);
          brands.forEach(element => {
            modifiedList.push({
              ...element,
              showBrandNameOnly: true
            });
            modifiedList.push(...response.data.scenario[0].scenarioData.filter(e => e.brandId === element.brandId))
          });

          response.data.scenario[0].scenarioData.forEach(element => {
            if(!element.hasOwnProperty('brandId')) {
              modifiedList.push(element);
            }
          })
        }
        this.selectedSenarioTableRecored = modifiedList
        this.selectedSenarioTableRecoredDataBuffer = JSON.stringify(response.data.scenario[0].scenarioData);
        // this.ngxLoader.stop();
        this.scmMmmService.closeModel();
      } else {
        this.selectedSenarioTableRecored = [];
        this.selectedSenarioTableRecoredDataBuffer = '';
        this.scmMmmService.closeModel();
      }
    });
  }

  // Distribute Data in tables and Charts
  /***
  * @desc Function to modify API response and plot it on table and charts
  * @param {Array, Object}
  * Call service to get static chart config JSON and construct final chart Config with data
  * @returns {Array,Object} Returns formatted Array to display data on Table and Chart config to display data.
  *
  */
  distributeDataAmongChartsAndTable(selectedSenarioData, mediaResponseCurvedata) {
    // this.selectedSenarioTableRecored = [];
    this.scmMmmService.getChartTemplateJSON().subscribe(chartTemplates => {
      // Get All the chart templates
      this.incrementalSaleChartTemplate = chartTemplates[0];
      // this.mediaResponseChartTemplate = chartTemplates[1];
      this.roiMacChartTemplate = chartTemplates[2];
      this.shareSpendChartTemplate = chartTemplates[3];
      this.selectedSenarioTableRecored = selectedSenarioData[0].scenarioData;
      this.selectedSenarioTableRecoredDataBuffer = JSON.stringify(selectedSenarioData[0].scenarioData);
      // Setting series data into the chart
      this.mediaResponseChartTemplate = this.scmMmmService.formatMediaResponseChartData(mediaResponseCurvedata, chartTemplates[1]);
      // format and inject data for incremental sales chart data
      this.incrementalSaleChartTemplate = this.scmMmmService.formatIncrementalSalesTemplate(this.incrementalSaleChartTemplate, selectedSenarioData[0].chartData[0].data)
      // ROI Mac Chart configuration
      this.roiMacChartTemplate = this.scmMmmService.getRioMacChartOptionConfigured(this.roiMacChartTemplate, selectedSenarioData[0].chartData[1]);
      // Share of Spend Chart configuration
      this.shareSpendChartTemplate = this.scmMmmService.getShareOfSpendFormatted(this.shareSpendChartTemplate, selectedSenarioData[0].chartData[2])
      this.showOptimizeChart = true
      this.scmMmmService.closeModel();
    });
  }

  // Save custom scenarios
  /***
   * @desc Function to Save Scenario
   * @param {Array} API Payload
   * Opens scenario name popup and gets the scenario name to construct final API payload Array to save scenario
   * @returns {Object} Returns API response on success.
  */
  saveScenario(brandName) {
    if (this.newScenarioData.length) {
      let proposedScenarioName = '';
      if(this.scenarioList.length === 1) {
        proposedScenarioName = brandName + '1'
      } else {
          let lastCharacter = isNaN( Number(this.scenarioList.slice().pop().scenarioName.slice(-1))) ? '1' : (Number(this.scenarioList.slice().pop().scenarioName.slice(-1)) + 1).toString() ;
          proposedScenarioName = isNaN( Number(this.scenarioList.slice().pop().scenarioName.slice(-1))) ? this.scenarioList.slice().pop().scenarioName + lastCharacter : this.scenarioList.slice().pop().scenarioName.slice(0, -1) + lastCharacter;
      }
      // Open Model to get new Scenario Name
      this.scmMmmService.getScenarioName('Scenario', proposedScenarioName.toString(), 'Save', 'Cancel').then((scenarioName) => {
        this.scmMmmService.openOverlay();
        const selectedTableRecord = this.selectedSenarioTableRecored.slice(0, -2);
        if (scenarioName) {
          // constructing payload for saving scenario
          // keeping current year as 2020 because we have data for 2020 only , needs to be changed once data is available for current year
          const newScenarioObject = {
            brandId: this.selectedBrandId,
            scenarioId: this.selectedScenarioId,
            scenarioType: 'Optimize Custom',
            scenarioName: scenarioName,
            currentYear: 2020, // keeping current year as 2020 because we have data for 2020 only
            currentMonth: new Date().getMonth() - 1,
            budgetChange: this.changeInBudget,
            scenarioInput: <any>[]
          }
          selectedTableRecord.forEach(element => {
            newScenarioObject.scenarioInput.push(
              {
                spendLeverId: element.spendLeverId,
                optimalRange: {
                  minSpend: element.minSpend,
                  maxSpend: element.maxSpend,
                  minLimitPercent: element.min,
                  maxLimitPercent: element.max
                },
                optimizeOutput: {
                  newSpend: element.optimizedNewSpend,
                  modelOutput: element.optimizedModelOutput,
                }
              }
            )
          })

          // saveOptimizeNewScenario
          this.scmMmmService.saveNewScenario([newScenarioObject]).subscribe(success => {
            if (success) {
              this.selectedScenarioId = success.data.scenarioId;
              this.scmMmmService.setPendingStatus('NO');
              this.newScenarioAdded.emit({ 'selectedBrandId': this.selectedBrandId, 'selectedScenarioId': this.selectedScenarioId });
              this.newScenarioData = [];
              this.scmMmmService.closeModel();
              this.toastr.success("Scenario has been saved successfully!");
            }
          });
        } else if (!scenarioName) {
          this.scmMmmService.closeModel();
          this.toastr.warning("Scenario changes has not been saved!");
        }
      });
    } else {
      this.toastr.error("There is no change in  scenario to SAVE");
    }
  }



  // Delete scenario (Only custom scenarios can be deleted)
  /***
   * @desc Checks for the scenario type and deletes Custome Scenario
   * @param {string} - Selected Scenario ID
   * Call service to delete the scenario
   * @returns {Object} Returns API response.
   */

  deleteScenario() {
    if (this.selectedScenarioId === 1) {
      this.toastr.error("Base scenario cannot be deleted!")
    } else {
      const selectedScenarioName = this.scenarioList.filter(e => { return e.scenarioId === this.selectedScenarioId })[0].scenarioName;
      this.confirmationPopupService.confirm('Confirm', 'Are you sure you want to delete scenario <b>' + selectedScenarioName + '</b> ?', '', 'Delete', 'Cancel')
        .then((confirmed) => {
          if (confirmed) {
            this.scmMmmService.deleteScenarion(this.selectedScenarioId).subscribe(response => {
              this.scenarioDeleted.emit({ 'selectedBrandId': this.selectedBrandId });
              this.toastr.success("Scenario deleted successfully!")
            })
          } else if (!confirmed) {

          }
        });
    }
  }
  // Handling validation for Optimal Range MIN and MAX value
  /***
   * @desc Validating MIN and MAX value input
   * Checks whether min value range is -1 to -100 and Max value should be >= 1
   * @returns validation check boolean to implement error class in DOM along with toaster.
   */
  optimalMinRangeChanged(ev) { // Handling validation for Optimal Range MIN value
    if (ev < -100 || ev >= 0) {
      this.invalidInput = true;
      this.toastr.error("Optimal range min value should be in the range of -100 to -1");
    } else {
      this.invalidInput = false;
    }
  }
  optimalMaxRangeChanged(ev, min) { // Handling validation for Optimal Range MAX value
    if (ev <= min) {
      this.invalidInput = true;
      this.toastr.error("Optimal range min value can not be less then 1");
    } else {
      this.invalidInput = false;
    }
  }

  // Export table data to excel.
  /***
   * @desc Handling Export to excel functionality
   * Calles expoertToExcel Service to create Excel file.
   * @returns Exported excel .
   */
  exportScenarioToExcel() {
    const selectedScenarioName = this.scenarioList.filter(e => { return e.scenarioId === this.selectedScenarioId })[0].scenarioName;
    this.exportToExcelService.exportTableToExcel('OptmizeTable', selectedScenarioName)
  }

  // toggle  accrodian for the chart panel
  /***
   * @desc Handles chart pannel expend anc collapse
   * @param event - Mouse Event
   * Toggles class 'active' in the class list of event.target(DOM Element)
   * @returns DOM element with updated max-hight and scroll-height .
   */
  toggleAccordian(event) {
    var element = event.target;
    element.classList.toggle("active");
    var panel = element.nextElementSibling;
    if (panel.style.maxHeight === '') {
      panel.style.maxHeight = '0px';
    } else if (panel.style.maxHeight === '0px') {
      panel.style.maxHeight = panel.scrollHeight + "px";
    } else if (panel.style.maxHeight !== '0px') {
      panel.style.maxHeight = '0px';
    }
  }
}
