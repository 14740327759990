<ng-container>
    <div class="dropdown-menu notif ur">
        <p class="notif-info" *ngIf="unReadNotificationData.length">{{unReadNotificationData.length}} Unread
            Notifications</p>
        <div class="notif-list s" id="notif-wrap">
            <ng-container *ngFor="let notify of notificationData; let i=index">
                <div class="notif-msg cf" [ngClass]="!notify.viewed ? 'notificationNew' : ''">
                    <div class="msg-icon"><i class="flaticon-tag" aria-hidden="true"></i></div>
                    <div class="msg-wrap">
                        <div class="msg-time">{{getFormatedDate(notify?.createdAt)}}</div>
                        <div class="msg-txt">{{notify?.productFamily | titlecase}} {{notify?.details | lowercase}} for
                            {{getReadableDate(notify?.yearMonth)}}</div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>