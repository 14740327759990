import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SelectFieldPopupComponent } from '../component/file-upload-progress/select-field-popup/select-field-popup.component';
import { MappingConfirmationPopupComponent } from '../component/file-upload-progress/mapping-confirmation-popup/mapping-confirmation-popup.component';

@Injectable({
  providedIn: 'root'
})

export class FileUploadProgressService {
  fileSizeUnit: number = 1024;
  public isApiSetup = true;

  constructor(private http: HttpClient, private modalService: NgbModal) { }

  getFileSize(fileSize: number): number {
    if (fileSize > 0) {
      if (fileSize < this.fileSizeUnit * this.fileSizeUnit) {
        fileSize = parseFloat((fileSize / this.fileSizeUnit).toFixed(2));
      } else if (
        fileSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSize = parseFloat(
          (fileSize / this.fileSizeUnit / this.fileSizeUnit).toFixed(2)
        );
      }
    }

    return fileSize;
  }

  getFileSizeUnit(fileSize: number) {
    let fileSizeInWords = 'bytes';

    if (fileSize > 0) {
      if (fileSize < this.fileSizeUnit) {
        fileSizeInWords = 'bytes';
      } else if (fileSize < this.fileSizeUnit * this.fileSizeUnit) {
        fileSizeInWords = 'KB';
      } else if (
        fileSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSizeInWords = 'MB';
      }
    }
    return fileSizeInWords;
  }

  uploadMedia(formData: any, filePath) {
    return this.http
      .post(`metamorph/file/` + filePath, formData, {
        reportProgress: true,
        observe: 'events',
      }).pipe(catchError(this.errorMgmt));
  }

  uploadProcess(payLoad, filePath): Observable<any> {
    return this.http.post<any>('metamorph/upload/' + filePath + '/', payLoad)
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => {
      return errorMessage;
    });
  }

  public selectColumn(
    title: string,
    data: Array<any>,
    index: number,
    btnOkText: string,
    btnCancelText: string): Promise<any> {
    const modalRef = this.modalService.open(SelectFieldPopupComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.data = data;
    modalRef.componentInstance.index = index;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    return modalRef.result;
  }

  public mappingConfirmation(
    title: string,
    btnOkText: string,
    btnCancelText: string
  ): Promise<any> {
    const modalRef = this.modalService.open(MappingConfirmationPopupComponent, { centered: true, size: 'md' });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    return modalRef.result;
  }

  getSheetInfoByUploadId(uploadId): Observable<any> {
    return this.http.get<any>('metamorph/fileAllSheetData/' + uploadId + '/');
  }

  getDestinationSheetName(templateId): Observable<any> {
    // const templateId1 = 131;
    return this.http.get<any>('metamorph/templateSheets/'+ templateId + '/Input')
  }
  fileData(id, limit, offset): Observable<any> {
    return this.http.get<any>('metamorph/fileData/' + id + '/' + limit + '/' + offset + '/');
  }
  saveDataSheet(payLoad, uploadId): Observable<any> {
    return this.http.put<any>('metamorph/sheetMapping/' + uploadId + '/', payLoad)

  }
  saveSheetMapping(payLoad): Observable<any> {
    return this.http.put<any>('metamorph/sheetMapping/', payLoad)
  }
  getTemplateSheetColumns(id): Observable<any> {
    return this.http.get<any>('metamorph/templateSheetColumns/' + id + '/')
  }
  getFileReviewedData(id, limit, offset): Observable<any> {
    return this.http.get<any>('metamorph/fileReviewedData/' + id + '/' + limit + '/' + offset + '/')
  }
  finalSubmit(payLoad, workItemId): Observable<any> {
    return this.http.patch<any>('metamorph/standardization/'+ workItemId + '/', payLoad)
  }

  standardization(payLoad, workItemId): Observable<any> {
    return this.http.patch<any>('metamorph/standardization/'+ workItemId + '/', payLoad)
  }

  validateUserfileHeaders(uploadId): Observable<any> {
    return this.http.get<any>('metamorph/validateUserFileHeaders/' + uploadId + '/')
  }

  generateColumnHeaders(uploadId, payload): Observable<any> {
    return this.http.post<any>('metamorph/generateColumnHeaders/'+ uploadId + '/', payload)
  }
  getSheetMapping(uploadId): Observable<any> {
    return this.http.get<any>('metamorph/sheetMapping/' + uploadId + '/')
  }

  getSheetMappingData(uploadId, templateSheetId): Observable<any> {
    const templateSheetColumns = this.http.get('metamorph/templateSheetColumns/' + templateSheetId + '/')
    const userFileSheetColumns = this.http.get('metamorph/userFileSheetColumns/' + uploadId + '/')
    return forkJoin([templateSheetColumns, userFileSheetColumns])
  }

  saveColumnMapping(payload, uploadId, isEditFlow): Observable<any> {
    if (isEditFlow) {
      return this.http.put<any>('metamorph/columnMapping/', payload)
    } else {
      return this.http.post<any>('metamorph/columnMapping/', payload)
    }
  }

  getSavedColumnMapping(sheetId): Observable<any> {
    return this.http.get<any>('metamorph/columnMapping/' + sheetId + '/')
  }


}
