import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PricingModelValidationService } from '../pricing-model-validation/pricing-model-validation.service'
import { PricingService } from '../pricing.service';

@Component({
  selector: 'app-pricing-model-validation',
  templateUrl: './pricing-model-validation.component.html',
  styleUrls: ['./pricing-model-validation.component.scss']
})
export class PricingModelValidationComponent implements OnInit {

  @Output() public onTabChangeEmit: EventEmitter<any> = new EventEmitter<any>();

  pricingModules: any;
  tabName: any;
  breadcrumb: Array<any> = [];
  chartFilters: any;
  public lobProjectId: any;
  localStorage = localStorage;
  public modelTechnique = 1;
  public modelType = 1;
  impactChartFilters: any;
  selectedTab = 'liftChart'

  constructor(private pricingModelValidationService: PricingModelValidationService, private pricingService: PricingService,
    private activatedRoute: ActivatedRoute) {
    this.pricingModules = this.pricingService.PRICING_MODULES;
  }

  ngOnInit(): void {
    this.pricingService.activePricingModule = 'VALIDATION';
    Promise.all([this.localStorage.getItem('lobProjectId')]).then(values => {
      this.lobProjectId = values[0];
      const item = this.activatedRoute.snapshot.url[0].path.replace('-', ' ').replace(/^([a-z]|[A-Z])$/g, (c, i) => (i ? " " : "") + c.toUpperCase())
      // Adding lavel 3 breadcrumb
      this.pricingService.addBreadcrumbData({ "level": "3", "name": item }).subscribe(data => {
        this.breadcrumb = data;
      });
    });
  }

  selectTab(selectedTab) {
  this.selectedTab = selectedTab;
  if(selectedTab === 'impact') {
      this.pricingService.setSelectModelVisible(true);
    } else {
      this.pricingService.setSelectModelVisible(false);
    }
  }
  ngOnDestroy(): void {
    this.pricingService.setSelectModelVisible(false);
  }


  // Setting active tab
  getActiveClass(activeModule) {
    if (this.pricingService.activePricingModule == activeModule) {
      return 'active show';
    } else {
      return '';
    }
  }

  getdata(value) {
    this.tabName = value;
    this.onTabChangeEmit.emit(value);
  }

  // Getting filter data and creating filter object
  getFilterData(lobProjectId) {
    this.pricingModelValidationService.getFilterChartPanelData(lobProjectId).subscribe(data => {
      if (data) {
        this.pricingModelValidationService.formatFilterPanelData(data).subscribe((formattedResponse) => {
          this.chartFilters = formattedResponse;
        });
      }
    });
  }

  // get Impact chart filter data and creating filter object based on type
  getImpactFilterData(lobProjectId) {
    const finalResponse = [] as any;
    this.pricingModelValidationService.getImpactChartFilterData(lobProjectId).subscribe(responseData => {
      if (responseData) {
        responseData.forEach((element, index) => {
          const formattedResponse = {
            ...element,
            title: index === 0 ? "Python" : "Model Types",
            filterType: index === 0 ? "modelTechniques" : "modelTypes",
            data: element.data.map((el, i) => {
              return {
                ...el,
                isSelected: i === 0 ? true : false
              }
            })
          }
          finalResponse.push(formattedResponse);
        });
      }
    });
    this.impactChartFilters = finalResponse;
  }

}
