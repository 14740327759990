import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { ChatAnalysisRoutingModule } from './chat-analysis-routing.module';
import { ChatAnalysisBaseComponent } from './chat-analysis-base/chat-analysis-base.component';
import { ChatAnalysisListingComponent } from './chat-analysis-base/chat-analysis-listing/chat-analysis-listing.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ChatAnalysisDescriptionComponent } from './chat-analysis-base/chat-analysis-description/chat-analysis-description.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DashboardComponent } from './chat-analysis-base/dashboard/dashboard.component';

@NgModule({
  declarations: [
    ChatAnalysisBaseComponent,
    ChatAnalysisListingComponent,
    ChatAnalysisDescriptionComponent,
    DashboardComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DataTablesModule,
    ChatAnalysisRoutingModule,
    NgbModule
  ]
 
})
export class ChatAnalysisModule { }
