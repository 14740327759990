<div class="confirmation-modal-header">
  <h3 class="confirmation-modal-title">{{ title }}</h3>


</div>
<div class="modal-body">

  <ng-container *ngIf="!!data && !!data['recommendation']">
    <h3 class="text-primary">Recommended Values</h3>
    <ng-container *ngFor="let columnName of data['recommendation']">
      <span class="badge badge-pill  mb-2 mr-2"
        [ngClass]=" columnName === selectedColumn ? 'badge-primary' : 'badge-primary-outline'"
        (click)="selectField(columnName)">{{columnName}}</span>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!!data && !!data['other']">
    <h3 class="text-primary">All Values</h3>
    <ng-container *ngFor="let columnName of data['other']">
      <span class="badge badge-pill  mb-2 mr-2"
        [ngClass]=" columnName === selectedColumn ? 'badge-primary' : 'badge-primary-outline'"
        (click)="selectField(columnName)">{{columnName}}</span>
    </ng-container>
    <span class="badge badge-pill  mb-2 mr-2"
      [ngClass]=" 'Share Percent' === selectedColumn ? 'badge-primary' : 'badge-primary-outline'"
      (click)="selectField('Share Percent')">{{'Share Percent'}}</span>

  </ng-container>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light btn-sm addcustom" (click)="decline()">{{ btnCancelText }}</button>
  <button type="button" *ngIf="btnOkText !== 'Delete'" class="btn btn-primary btn-sm" (click)="accept()">{{ btnOkText
    }}</button>
  <button type="button" *ngIf="btnOkText === 'Delete'" class="btn btn-danger btn-sm" (click)="accept()">{{ btnOkText
    }}</button>
</div>