<div class="p-relative">
  <table datatable [dtOptions]="dtOptions" aria-describedby="mydesc">
    <thead>
      <tr>
        <th scope="col" class="custom-th first-th">&nbsp;</th>
        <th scope="col" class="custom-th">Execution Month</th>
        <th scope="col" class="custom-th">LMS Standard</th>
        <th scope="col" class="custom-th">Last Modified</th>
        <th scope="col" class="custom-th">Last Modified By</th>
        <th scope="col" style="width: 30%" class="custom-th">&nbsp;</th>
      </tr>
    </thead>


    <tbody *ngIf="data?.length != 0" style="position: relative;">
      <tr *ngFor="let record of data[0]?.details | slice:0:4; let index = index">
        <td>&nbsp;</td>
        <td><a class="link-button" href="javascript:void(0);" (click)="navigateTo(record, data[0])" >{{record?.yearMonth | customdateformat}}</a></td>
        <td>{{record?.subFormat}}</td>
        <td>{{record?.updatedAt | date: 'd MMMM yy, h:mm:ss a zzzz'}}</td>
        <td>{{record?.updatedBy}}</td>
        <td>&nbsp;</td>
      </tr>
    </tbody>
    <div class="initiate-new" *ngIf="data[0]?.details.length > 50">
      <button class="btn btn-sm btn-outline-primary">View All</button>
    </div>
    <tbody *ngIf="data?.length == 0">
      <tr>
        <td colspan="3" class="no-data-available">No data!</td>
      </tr>
    </tbody>
  </table>
</div>
