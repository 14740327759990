import { Component, Input, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Highcharts from 'highcharts';
import { PricingService } from '../../pricing.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PricingModelInterpretabilityService} from '../pricing-model-interpretability.service';

@Component({
  selector: 'app-pricing-model-interpretability-shap',
  templateUrl: './pricing-model-interpretability-shap.component.html',
  styleUrls: ['./pricing-model-interpretability-shap.component.scss']
})
export class PricingModelInterpretabilityShapComponent implements OnInit {
  chartFilters: any;
  public lobProjectId: any;
  localStorage = localStorage;
  viewName = 'shap';
  public modelType = "1";
  public modelTechnique= "1,2";
  public filterForExpoDataChart: any;
  expoDataChartTemplate: any;
  updatedFilterData: any;
  charts = [1, 2];
  public modelTypeFilters = [1, 2];  
  
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: any;
  chartModelData: any;
  closeResult: string;
  edaChartTemplate: any[];
  updateFlag: boolean;
  actualChartTemplate: any[];
  activeModelTechniqueFilter: any;
  modelTechniqueFilterBuffer: any;
  modelTechniqueCheckbox= [1,2];
  comparisionData: any;
  shapData: any;
  imagePath:any;
 
  constructor(private modalService: NgbModal, private pricingModelInterpretabilityService: PricingModelInterpretabilityService,
              private pricingService: PricingService, private toastr: ToastrService,
              private ngxLoader: NgxUiLoaderService) { }

  ngOnInit(): void {
    Promise.all([this.localStorage.getItem('lobProjectId')]).then(values => {
      this.lobProjectId = values[0];
      this.getFilterData(this.lobProjectId);
      this.getShapData( this.lobProjectId, this.modelType, this.modelTechnique);
    })
    
  }

  // Update chart data on filter change
  updateFilterStatus(checkboxData,i,filterType) {
    if (filterType === 'modelTechniques') {
      this.chartFilters[this.chartFilters.findIndex((obj => obj.filterType === 'modelTechniques'))].data.forEach(e => { if (checkboxData.id === e.id) { e.isSelected = checkboxData.isSelected } });
      if (this.modelTechniqueCheckbox.includes(checkboxData.id) && !checkboxData.isSelected) {
        this.modelTechniqueCheckbox = this.modelTechniqueCheckbox.filter(e => e !== checkboxData.id);
      }
      else if (!this.modelTechniqueCheckbox.includes(checkboxData.id) && checkboxData.isSelected) {
        this.modelTechniqueCheckbox.push(checkboxData.id);
        this.modelTechniqueCheckbox = this.modelTechniqueCheckbox.sort(function (a, b) { return a - b; });
      }
      if(!this.modelTechniqueCheckbox.length) {
        this.toastr.error('Select at least one Model Technique');
        this.comparisionData = [];
      } else {
        this.modelTechnique = this.modelTechniqueCheckbox.join(',');
        this.getShapData( this.lobProjectId, this.modelType, this.modelTechnique);
      }
    } else if (filterType === 'modelTypes') {
      if(!this.modelTechniqueCheckbox.length) {
        this.toastr.error('Select at least one Model Technique');
        this.comparisionData = [];
      } else {
        this.modelType = checkboxData;
        this.getShapData( this.lobProjectId, this.modelType, this.modelTechnique);
      }
    }
  }
  

  // Get Shap Data
  getShapData(lobProjectId, modelType, modelTechnique){
    this.ngxLoader.start();
    this.shapData = []
    this.pricingModelInterpretabilityService.getShapData(lobProjectId, modelType, modelTechnique).subscribe( shapDataResponse => {
        this.shapData = shapDataResponse?.data;
        this.ngxLoader.stop();
    })

  }

  // Getting filter data from API call and constructing filter panel data
  getFilterData( lobProjectId) {
    this.pricingModelInterpretabilityService.geInduvidualFilterData( lobProjectId).subscribe(responseData => {
      const finalResponse = [] as any;
      responseData.forEach((element, index) => {
        const formattedResponse = {
          ...element,
          title: index === 0 ? "Model Techniques" : "Model Types",
          filterType: index === 0 ? "modelTechniques" : "modelTypes",
          data: index === 0 ? element.data.map((el, i) => {
            return {
              ...el,
              isSelected: true
            }
          }): element.data.map((el, i) => {
            return {
              ...el,
              isSelected: i === 0 ? true : false
            }
          })
        }
        finalResponse.push(formattedResponse);
      });
      this.chartFilters = finalResponse;
    })
  }

 
  filterUser(item) {
    return !!item.displayChart;
  }

  // Expanded Chart View
  openXl(content, data) {
    this.imagePath = data;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  
}
