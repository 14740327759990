<div class="container-fluid content-bordeaux">
  <div class="row">
    <div class="col-sm-12" *ngIf="selectedView === 'bordeaux-details'">
      <bordeaux-details-table [selectedTab]='selectedTab' [amendPayLoad]='payLoad' [workItem]='workItemId' [amendReloadFlag]='amendReloadFlag'  (amendRuleEvent)="handleAmendRule($event)"></bordeaux-details-table>
    </div>
    <div class="col-sm-12 p-0" *ngIf="selectedView === 'amend-rule'">
      <validation-rules [selectedTab]='selectedTab' [index]="index" [processId]="processId" [workItemId]="workItemId" (backButtonEvent)="handleAmendRule($event)"  (payLoadAmend)="handlePayload($event)" (amendRules)="handleAmendRules($event)"></validation-rules>
    </div>
    <div class="col-sm-12 p-0" *ngIf="selectedView === 'view-rule'">
      <app-view-rules [selectedTab]='selectedTab' [processId]="processId" [workItemId]="workItemId" (backButtonEvent)="handleAmendRule($event)"></app-view-rules>
    </div>
  </div>
</div>


