import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private pageType = 'new';
  private rolepageType = 'new';
  private permpageType = 'new';
  private covpageType = 'new';
  private userDataView:any = [];
  private roleDataView:any = [];
  private permDataView:any = [];
  private covDataView:any = [];

  constructor(private modalService : NgbModal) {}

  /***
   * @desc Set Pagetype - new/edit
   * @param {String} Module pageType
   * 
   */
  setType(pageType: any) {
    this.pageType=pageType;
  }
  /***
   * @desc Get Page Type
   * @returns {String} Returns Page Type
   * 
   */
  getType() {
    return this.pageType;
  }
  /***
   * @desc Set User Object
   * @param {String} Module user
   * 
   */
  setUser(user: any) {
    this.userDataView = user;
  }
  /***
   * @desc Get User Object
   * @returns {Object} Returns User Object
   * 
   */
  getUser() {
    return this.userDataView;
  } 

  /* Role Specific */
  /***
   * @desc Set Role Page Type - new/edit
   * @param {String} Module rolepageType
   * 
   */
  setRoleType(rolepageType: any) {
    this.rolepageType=rolepageType;
  }
  /***
   * @desc Get Role Page Type
   * @returns {String} Returns Role Page Type
   * 
   */
  getRoleType() {
    return this.rolepageType;
  }
  /***
   * @desc Set Role Object
   * @param {String} Module role
   * 
   */
  setUserRole(role: any) {
    this.roleDataView = role;
  }
  /***
   * @desc Get Role Object
   * @returns {Object} Returns Role Object
   * 
   */
  getUserRole() {
    return this.roleDataView;
  } 

  /* Permission Specific */
   /***
   * @desc Set Permission Page Type - new/edit
   * @param {String} Module permpageType
   * 
   */
  setPermType(permpageType: any) {
    this.permpageType=permpageType;
  }
  /***
   * @desc Get Permission Page Type
   * @returns {String} Returns Permission Page Type
   * 
   */
  getPermType() {
    return this.permpageType;
  }
   /***
   * @desc Set Permission Object
   * @param {String} Module perms
   * 
   */
  setUserPerm(perm: any) {
    this.permDataView = perm;
  }
  /***
   * @desc Get Permission Object
   * @returns {Object} Returns Permission Object
   * 
   */
  getUserPerm() {
    return this.permDataView;
  } 

   /* Coverage Specific */

  setCovType(covpageType: any) {
    this.covpageType=covpageType;
  }

  getCovType() {
    return this.covpageType;
  }

  setUserCov(cov: any) {
    this.covDataView = cov;
  }

  getUserCov() {
    return this.covDataView;
  } 


  public openAsModel(ModelComponent): Promise<boolean> {
    const modalRef = this.modalService.open(ModelComponent, { centered: true, size: 'lg' });
    return modalRef.result;
  }
}


