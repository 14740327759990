import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BordereauxManagementService } from '../../services/bordereaux-management.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CoverholderService } from '../../services/coverholder.service';
import { ToastrService } from 'ngx-toastr';
import { MetamorphCommonService } from '../../services/metamorph-common.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-rule-config-sidepanel-filter',
  templateUrl: './rule-config-sidepanel-filter.component.html',
  styleUrls: ['./rule-config-sidepanel-filter.component.scss'],
})
export class RuleConfigSidepanelFilterComponent implements OnInit {
  @Input() selectedFilters: any;
  @Input() processId: any;
  @Input() receivedData: string;
  selectedFilterData: any;
  adminUsers: any;
  filterData: Array<any> = [];
  filterDataFirst: Array<any> = [];
  lastModifyFilterData: Array<any> = [];
  public daterange: any = {};
  coverholderListData: any;
  selectedFileType: any;
  standard: any;
  ruleConfigData: any;
  daterangeStart: any;
  constructor(
    private activeModal: NgbActiveModal,
    private ngxLoader: NgxUiLoaderService,
    public coverholderService: CoverholderService,
    public BDXManagementService: BordereauxManagementService,
    public toastr: ToastrService,
    public metamorphCommonService: MetamorphCommonService,
    private datePipe: DatePipe
  ) { }

  public dismiss() {
    this.activeModal.close(false);
  }

  public resetFilters() {
    this.filterData.forEach((e) => {
      e.selectedId = 0;
    });
    this.selectedFilters.lastModifiedStart = 0;
    this.selectedFilters.lastModifiedEnd = 0;
  }

  async ngOnInit(): Promise<void> {
    this.ruleConfigData = JSON.parse(this.metamorphCommonService.getRuleConfigInfoData());
    await this.getFilterPanelData();
    this.getFilterAdminData();

  }

  public applyFilter() {

    const activeFilterAttributes = {
      countryId: Number(
        this.filterData.filter((e) => {
          return e.name === 'country';
        })[0].selectedId
      ),

      ruleTypesId: Number(
        this.filterData.filter((e) => {
          return e.name === 'ruleTypes';
        })[0].selectedId
      ),
      lastModifiedById: Number(
        this.filterData.filter((e) => {
          return e.name === 'lastModifiedBy';
        })[0].selectedId
      ),

      lastModifiedStart: this.daterange.start ? this.daterange.start : 0,
      lastModifiedEnd: this.daterange.end ? this.daterange.end : 0

    };

    this.activeModal.close(activeFilterAttributes);
    this.processId = this.ruleConfigData.filter(function (el) {
      return el.label == 'Process Id';
    })[0].value;
    this.BDXManagementService.getSubmitData(this.processId, activeFilterAttributes).subscribe((res) => {
      if (res && res.code === 200) {
        const getFilterData = res;
        this.toastr.success(res.successMessage);
      }
    });
  }

  getFilterAdminData() {
    this.BDXManagementService.getAdminData().subscribe((res) => {
      if (res && res.code === 200) {
        const adminData = res?.data;
        adminData.unshift({ id: 0, firstName: 'ALL', lastName: "" });
        const filterAdminData = adminData.map((e) => {
          return {
            id: e.id,
            name: e.firstName + " " + e.lastName,
          };
        });
        const temp = 'lastModifiedById';
        this.adminUsers = {
          name: "lastModifiedBy",
          label: "Last Modified By",
          data: filterAdminData,
          selectedId: this.selectedFilters[temp]
            ? this.selectedFilters[temp]
            : 0,
        };
        this.filterData.push(this.adminUsers);
      }
    })
  }
  async getFilterPanelData() {
    const response = await this.BDXManagementService.getMetamorphFilterData().toPromise();
    if (response && response.code === 200) {
      const filterDataResponse = response;
      delete filterDataResponse.code;
      delete filterDataResponse.message;
      this.filterData = Object.keys(filterDataResponse).map((e, i) => {
        filterDataResponse[e].unshift({ id: 0, name: 'ALL' });
        const temp = e + 'Id';
        return {
          name: e,
          label:
            e
              .replace(/([A-Z])/g, ' $1')
              .charAt(0)
              .toUpperCase() + e.replace(/([A-Z])/g, ' $1').slice(1),
          data: [...filterDataResponse[e]],
          selectedId: this.selectedFilters[temp]
            ? this.selectedFilters[temp]
            : 0,
        };
      });
      this.filterDataFirst = this.filterData
    }

  }
  public options: any = {
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
    opens: 'left'
  };

  public selectedDate(value: any, datepicker?: any) {
    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;
    // use passed valuable to update state
    this.daterange.start = this.datePipe.transform(value.start, "yyyy-MM-dd");
    this.daterange.end = this.datePipe.transform(value.end, "yyyy-MM-dd");
    this.daterange.label = value.label;
  }
}
