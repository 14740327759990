import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MetamorphCommonService } from '../../../services/metamorph-common.service';


@Component({
  selector: 'app-child-table-component',
  templateUrl: './child-table-component.component.html',
  styleUrls: ['./child-table-component.component.scss']
})
export class ChildTableComponentComponent implements OnInit {
  dtOptions: DataTables.Settings = {};

  @Input() data: any;

  constructor(private route: Router, public metamorphCommonService: MetamorphCommonService) { }

  ngOnInit() {
    this.dtOptions = {
      info: false,
      lengthChange: false,
      processing: false,
      searching: false,
      paging: false,
      destroy: true,
      deferRender: true,
      columnDefs: [
        { targets: [0], orderable: false },
        { targets: [5], orderable: false }
      ]
    }
  }

  navigateTo(rowData, parentData) {
    const operationalInfo = [
      { label: 'Process Id', value: parentData.processId },
      { label: 'Standard', value: rowData?.subFormat },
      { label: 'File Type', value: parentData.fileType },
      { label: 'CoverHolder', value: parentData.coverHolder },
      { label: 'Execution Month', value: rowData.yearMonth },
      { label:'Standardize Download Template',value:parentData.standardizationTemplateLink},
      { label:'Validate Download Template',value:parentData.validationTemplateLink}
    ]
    Promise.resolve(this.metamorphCommonService.setCurrentTemplateId(parentData.templateId)).then(() => {
      this.metamorphCommonService.setOperationalInfoData(operationalInfo);
      this.route.navigate(['/metamorph/bordereaux-details']);
    })
  }

}
