<div class="container-fluid  custom-grid">
  <div class="row">
    <div class="col-sm-3 col-md-2" *ngFor="let bdx of formattedOperationalDetails; let last = last">
      <ng-container *ngIf="bdx.label!='Process Id' && bdx.label!='Download Template'  && bdx.label!='Standardize Download Template' && bdx.label!='Validate Download Template'">
        <div class="dotted-border" [ngClass]="{ 'custom-dotted-border':!last}">
          <p>{{bdx?.label}}</p>
          <ng-container *ngIf="bdx.label=='Execution Month'">
            <h3>{{bdx?.value | customdateformat}}</h3>
          </ng-container>
          <ng-container *ngIf="bdx.label!='Execution Month'">
            <h3>{{bdx?.value}}</h3>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>
