import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetamorphRoutingModule } from './metamorph-routing.module';
import { MetamorphBaseComponent } from './metamorph-base.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BordereauxManagementComponent } from './bordereaux-management/bordereaux-management.component';
import { BordereauxManagementOutlineComponent } from './bordereaux-management/bordereaux-management-outline/bordereaux-management-outline.component';
import { RuleConfigComponent } from './rule-config/rule-config.component';
import { EventLogsComponent } from './event-logs/event-logs.component';
import { MetamorphSideNavComponent } from '../metamorph-side-nav/metamorph-side-nav.component';
import { ChildTableComponentComponent } from './bordereaux-management/bordereaux-management-outline/child-table-component/child-table-component.component';
import { ChildTableStandardComponent } from './bordereaux-management/bordereaux-base/standardization/child-table-component/child-table-component.component';
import { ChildTableValidateComponent } from './bordereaux-management/bordereaux-base/validation/bordeaux-details-table/child-table-component/child-table-component.component';
import { FilterPanelComponent } from './bordereaux-management/bordereaux-management-outline/filter-panel/filter-panel.component';
import { BordereauxBaseComponent } from './bordereaux-management/bordereaux-base/bordereaux-base.component';
import { StandardizationComponent } from './bordereaux-management/bordereaux-base/standardization/standardization.component';
import { ValidationComponent } from './bordereaux-management/bordereaux-base/validation/validation.component';
import { TaxValidationComponent } from './bordereaux-management/bordereaux-base/tax-validation/tax-validation.component';
import { ContextMenuComponentComponent } from './bordereaux-management/bordereaux-base/standardization/context-menu-component/context-menu-component.component';
import { Daterangepicker } from 'ng2-daterangepicker';
import { BordeauxDetailsTableComponent } from './bordereaux-management/bordereaux-base/validation/bordeaux-details-table/bordeaux-details-table.component';
import { ValidationRulesComponent } from './bordereaux-management/bordereaux-base/validation/validation-rules/validation-rules.component';
import { ListCoverholderComponent } from './rule-config/list-coverholder/list-coverholder.component';
import { RuleConfigBaseComponent } from './rule-config/rule-config-base/rule-config-base.component';
import { RuleConfigListComponent } from './rule-config/rule-config-base/rule-config-list/rule-config-list.component';
import { EditGeographyComponent } from './rule-config/rule-config-base/edit-geography/edit-geography.component';
import { RuleConfigSidepanelFilterComponent } from './rule-config/rule-config-sidepanel-filter/rule-config-sidepanel-filter.component';
import { EditTagsComponent } from './rule-config/rule-config-base/edit-tags/edit-tags.component';
import { ViewRulesComponent } from './bordereaux-management/bordereaux-base/validation/view-rules/view-rules.component';



@NgModule({
  declarations: [
    MetamorphBaseComponent,
    MetamorphSideNavComponent,
    BordereauxManagementComponent,
    BordereauxManagementOutlineComponent,
    ChildTableComponentComponent,
    ChildTableStandardComponent,
    RuleConfigComponent,
    EventLogsComponent,
    FilterPanelComponent,
    StandardizationComponent,
    ValidationComponent,
    TaxValidationComponent,
    BordereauxBaseComponent,
    ContextMenuComponentComponent,
    BordeauxDetailsTableComponent,
    ValidationRulesComponent,
    ListCoverholderComponent,
    RuleConfigBaseComponent,
    RuleConfigListComponent,
    ChildTableValidateComponent,
    EditGeographyComponent,
    RuleConfigSidepanelFilterComponent,
    EditTagsComponent,
    ViewRulesComponent
    




  ],
  imports: [
    CommonModule,
    MetamorphRoutingModule,
    SharedModule,
    DataTablesModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
    Daterangepicker

  ]
})
export class MetamorphModule { }
