<div class="confirmation-modal-header">
  <h3 class="confirmation-modal-title">{{ modalData.title }}</h3>
</div>
<div class="modal-body">
  <p class="form-label">Select Geography</p>
  <ng-container *ngFor="let geography of geographyList; let index = index">
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" [(ngModel)]="geography.isSelected"
        [id]="'ruleConfigIsMandatory' + geography.id">
      <label class="custom-control-label" [for]="'ruleConfigIsMandatory' + geography.id">{{ geography.name }}</label>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light btn-sm addcustom" (click)="decline()">{{ modalData.buttonCancelText
    }}</button>
  <button type="button" class="btn btn-primary btn-sm" (click)="save()">{{ modalData.buttonSubmitText }}</button>
</div>