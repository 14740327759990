<div class="container-fluid p-0" style="background-color: #f6f6f6;">

    <div class="">
        <div class="col-sm-12 p-0">
            <div class="filters-panel" style="margin: 50px 7px 0px 7px;">
                <ul class="nav nav-tabs tab-margin" id="supplyChain" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link sub-module" id="scm-optimize-tab"
                            [ngClass]="{ 'active':selectedTab==='scm-optimize'}" role="tab"
                            (click)="selectTab('scm-optimize', $event)">Optimize</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link sub-module" id="scm-simulate-tab"
                            [ngClass]="{ 'active':selectedTab==='scm-simulate'}" role="tab"
                            (click)="selectTab('scm-simulate', $event)">Simulate</a>
                    </li>
                </ul>
                <div class="d-flex justify-content-between">
                    <div class="p-2">
                        <form class="form-inline">
                            <label class="my-1 mr-2 mt-1" for="p-brand">Select Brand</label>
                            <select *ngIf="scmBrandList.length" class="form-control form-control-sm mr-3 mt-1"
                                id="p-brand"  style="width: 160px;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                                [(ngModel)]="selectedBrandId" #selectedBrandValue
                                (ngModelChange)="selectedBrandValue.value = changeBrand($event)"
                                [ngModelOptions]="{standalone: true}"
                                aria-haspopup="true" aria-expanded="false" data-html="true"
                                placement="bottom"
                                tooltipClass="custom-tooltipColor"
                                [ngbTooltip]="getBrandTooltip(selectedBrandId, scmBrandList)"
                                >
                                <option *ngFor="let item of scmBrandList" value="{{item.brandId}}">{{item?.brandName}}
                                </option>
                            </select>
                            <ng-template #tooltipBrand ><div>{{selectedBrandId}}</div></ng-template>

                            <label class="my-1 mr-2 mt-1" for="p-scenario">Select Scenario</label>
                            <select class="form-control form-control-sm mr-3 mt-1" id="p-scenario" style="width: 160px;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                                [(ngModel)]="selectedScenarioId" #selectedScenarioValue
                                (ngModelChange)="selectedScenarioValue.value = changeScenario($event)"
                                [ngModelOptions]="{standalone: true}"
                                aria-haspopup="true" aria-expanded="false" data-html="true"
                                placement="bottom"
                                tooltipClass="custom-tooltipColor"
                                [ngbTooltip]="getScenarioTooltip(selectedScenarioId, scmScenarioList)"
                                 >
                                <option *ngFor="let item of scmScenarioList" value="{{item.scenarioId}}">
                                    {{item?.scenarioName}}</option>
                            </select>
                            <a title="Save Scenario" class=" btn text-success  header-icons " [ngClass]="selectedBrandId === '99' ? 'disable-effect' : ''" ngbTooltip="Save scenario"
                                placement="left">
                                <span class="material-icons" (click)="saveScenario()">save</span>
                            </a>
                            <a title="Download Data" class="btn text-primary header-icons"
                                ngbTooltip="Export scenario to excel" placement="bottom">
                                <span class="material-icons" (click)="exportScenario()"> file_download </span>
                            </a>
                            <a title="Delete Scenario" class="btn text-danger header-icons" [ngClass]="selectedBrandId === '99' ? 'disable-effect' : ''" (click)="deleteScenario()"
                                ngbTooltip="Delete scenario" placement="right">
                                <span class="material-icons">delete_outline</span>
                            </a>

                        </form>
                    </div>

                    <!-- FOR OPTIMIZE -->
                    <div class="p-2 display-flex" *ngIf="selectedTab==='scm-optimize'">
                        <form class="form-inline">
                            <label class="my-1 mr-2 mt-1" for="p-changeperc">Enter change in budget (%)</label>
                            <input type="text" class="form-control form-control-sm mr-3 mt-1" onlyPositiveAndNegativeNumbers
                                [disabled]="selectedScenarioType === 'Optimize Custom'"
                                [ngClass]="isOptimizeBudgetWrong ? 'error-class': ''"
                                [class]="selectedBrandId === '99' ? 'disable-effect' : ''"
                                [ngModelOptions]="{standalone: true}" [(ngModel)]="changeBudget"
                                (ngModelChange)="budgetValueChanged(changeBudget)" id="p-changeperc" style="width: 75px;">
                            <button class="btn btn-sm btn-primary" [disabled]="selectedBrandId === '99'"
                                (click)="optimizeScenario(changeBudget)">Optimize</button>
                        </form>
                    </div>

                    <!-- FOR SIMULATE -->
                    <div class="p-2 display-flex" *ngIf="selectedTab==='scm-simulate'">
                        <form class="form-inline">
                            <div class="form-check">
                                <input class="form-check-input" [ngClass]="selectedBrandId === '99' ? 'disable-effect' : ''" type="radio" name="flexRadioDefault"
                                    id="flexRadioDefault1" [value]="'spend_usd'" [(ngModel)]="selectedChangeType"
                                    (ngModelChange)="changeType($event)" [disabled]="selectedScenarioType === 'Simulate Custom'">
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Spend $
                                </label>
                            </div>
                            <div class="form-check ml-3">
                                <input class="form-check-input" [ngClass]="selectedBrandId === '99' ? 'disable-effect' : ''" type="radio" name="flexRadioDefault"
                                    id="flexRadioDefault2" [value]="'spend_per'" [(ngModel)]="selectedChangeType"
                                    (ngModelChange)="changeType($event)" [disabled]="selectedScenarioType === 'Simulate Custom'">
                                <label class="form-check-label" for="flexRadioDefault2">
                                    Spend %
                                </label>
                            </div>

                            <button class="btn btn-sm btn-primary ml-3" [disabled]="selectedBrandId === '99'" (click)="simulate()">Simulate</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="tab-content bg-white" id="pricingTabsContent">

    <div class="tab-pane " id="scm-optimize" role="tabpanel" [ngClass]="{ 'active show':selectedTab==='scm-optimize'}">
        <div class="">
            <div *ngIf="scmScenarioList?.length; then thenBlock else elseBlock"></div>
            <ng-template #thenBlock>
                <app-scm-optimize (newScenarioAdded)="updateScenario($event)" (updateBudgetChange) = updateBudgetChangeValue($event)
                    (scenarioDeleted)="updateAfterDeleting($event)" [evaluateOptimizeEvents]="(evaluateOptimizeEvents)"
                    [scenarioList]="scmScenarioList" [changeScenerioOptimizeEvents]="(changeScenerioOptimizeEvents)"
                    [deleteOptimizeScenarioEvent]="(deleteOptimizeScenarioEvent)"
                    [saveOptimizeEvents]="(saveOptimizeEvents)" [tabChangeEvent]="(tabChangeEvent)"
                    [exportOptimizeEvents]="(exportOptimizeEvents)" [selectedBrandId]="selectedBrandId"
                    [selectedScenarioId]="selectedScenarioId" [selectedScenarioType]="selectedScenarioType"
                    [scmBrandList]="scmBrandList">
                </app-scm-optimize>
            </ng-template>
            <ng-template #elseBlock>
                <h1 class="text-center">NO DATA</h1>
            </ng-template>
        </div>
    </div>
    <div class="tab-pane" id="scm-simulate" role="tabpanel" [ngClass]="{ 'active show':selectedTab==='scm-simulate'}">
        <div style="padding: 4px;">
            <app-scm-simulate (newSimulateScenarioAdded)="updateScenario($event)"
                (scenarioDeleted)="updateAfterDeleting($event)" [saveSimulateEvents]="(saveSimulateEvents)"
                [scenarioList]="scmScenarioList" [changeTypeEvents]="(changeTypeEvents)"
                [deleteSimulateEvents]="(deleteSimulateEvents)"
                [changeScenerioSimulateEvents]="(changeScenerioSimulateEvents)"
                [evaluateSimulateEvents]="(evaluateSimulateEvents)" [tabChangeEvent]="(tabChangeEvent)"
                [exportSimulateEvents]="(exportSimulateEvents)" [selectedBrandId]="selectedBrandId"
                [selectedScenarioId]="selectedScenarioId" [selectedChangeType]="selectedChangeType"
                [selectedScenarioType]="selectedScenarioType" [scmBrandList]="scmBrandList"> </app-scm-simulate>
        </div>
    </div>
</div>
