<div class="data-table-wrapper" aria-describedby="mydesc">
  <div class="col-sm-12">
    <div class="custom-filter-wrapper form-inline">
      <label class="col-form-label m-r-15">File Type</label>
      <select [(ngModel)]="selectedFileType" (ngModelChange)="getFilterData()" class="form-control form-control-sm m-r-15 min-w-100">
        <option *ngFor="let item of filterDataResponse?.fileType; let i = index" [attr.value]="item.id" [attr.selected]='i == 0 ? true : null'>{{item.name}}</option>
      </select>

      <label class="col-form-label m-r-15">Standard</label>
      <select [(ngModel)]="standard" (ngModelChange)="getFilterData()" class="form-control form-control-sm m-r-15 min-w-100">
        <option *ngFor="let item of filterDataResponse?.subFormat; let i = index" [attr.value]="item.id" [attr.selected]='i == 0 ? true : null'>{{item.name}}</option>
      </select>
    </div>
  </div>
  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table dataTable no-footer">
    <thead>
      <tr>
        <th scope="col">Cover Holder</th>
        <th scope="col">No of Rules</th>
        <th scope="col">Last Modified</th>
        <th scope="col">Last Modified By</th>
        <th scope="col">Enabled</th>
      </tr>
    </thead>
    <tbody *ngIf="coverholderListData?.length != 0">
      <tr #trRef *ngFor="let item of coverholderListData, let index as index">
        <td>
          <a href="javascript:void(0)" (click)="navigateTo(item)">{{item?.coverHolder}}</a>
        </td>
        <td>{{item?.rulesCount}}</td>
        <td>{{item?.updatedAt}}</td>
        <td>{{item?.modifiedBy}}</td>
        <td>

          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="coverholder{{item.processId}}"
              name="coverholder_{{item.processId}}" [checked]="item.isEnabled" (change)="checkStatus($event)">
            <label class="custom-control-label" for="coverholder{{item.processId}}"></label>
          </div>
        </td>

      </tr>
    </tbody>
    <tbody *ngIf="coverholderListData?.length == 0">
      <tr>
        <td colspan="6" class="p-0" class="no-data-available ">No data!</td>
      </tr>
    </tbody>
  </table>
</div>
