import { HttpClient } from '@angular/common/http';
import { Injectable, NgModuleRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Observable, of } from 'rxjs';
import { ScenarioNamePopoverStaticComponent } from './scenario-name-popover-static/scenario-name-popover-static.component';
const BASE_URL = './'
const OPTIMIZE_CHART_DATA = 'scm-mmm-optimizeChartData.json'
const CHART_TMEPLATE_BASE_URL = "../../../../../assets/data/scm-mmm/";
const INCREMENTAL_SALE = 'chart-incremental-sales-template.json';
const MEDIA_RESPONSE = 'chart-media-response-curve-template.json';
const RIO_MAC = 'chart-roi-mac-template.json';
const SHARE_SPEND = 'chart-share-spend-template.json';

@Injectable({
  providedIn: 'root'
})
export class ScmMmmStaticService {

  localStorage = localStorage;
  public optimizeTableData = {
    "data": [
      {
        "brandName": "Fresh & Strong",
        "brandId": 1,
        "scenario": [
          {
            "scenarioName": "Fresh & Strong Base Scenario",
            "scenarioId": 1,
            "mediaResponseCurve": {
              "name": "Media Response Curve",
              "data": [
                {
                  "name": "Media Response Curve",
                  "data": [
                    [
                      1200,
                      2500
                    ],
                    [
                      2400,
                      5000
                    ],
                    [
                      3600,
                      7500
                    ],
                    [
                      4800,
                      10000
                    ],
                    [
                      6000,
                      12500
                    ],
                    [
                      7200,
                      15000
                    ],
                    [
                      8400,
                      16000
                    ],
                    [
                      9600,
                      16500
                    ],
                    [
                      10800,
                      17000
                    ],
                    [
                      12000,
                      17500
                    ],
                    [
                      13200,
                      18000
                    ],
                    [
                      14400,
                      18500
                    ],
                    [
                      15600,
                      19000
                    ],
                    [
                      16800,
                      19000
                    ],
                    [
                      18000,
                      19000
                    ],
                    [
                      19200,
                      19000
                    ],
                    [
                      20400,
                      19000
                    ],
                    [
                      21600,
                      19000
                    ],
                    [
                      22800,
                      19000
                    ],
                    [
                      24000,
                      19000
                    ]
                  ]
                }
              ]
            },
            "scenarioData": [
              {
                "spendLever": "Fresh & Strong Media",
                "spend": "1054",
                "isParent": true,
                "output": "60",
                "margin": "20",
                "profit": "1198",
                "roiCalculation": "1.14",
                "min": "",
                "minSpend": "",
                "max": "",
                "maxSpend": "",
                "optimizedNewSpend": "1054",
                "optimizedModelOutput": "60",
                "optimizedMargin": "20",
                "optimizedProfit": "1198",
                "optimizedRoiCalculation": "1.14",
                "changeInSpend": "0.00%",
                "changeInIncrementalSale": "0.00%"
              },
              {
                "spendLever": "Digital",
                "isParent": false,
                "spend": "164",
                "output": "10",
                "margin": "20",
                "profit": "200",
                "roiCalculation": "1.22",
                "min": "-20",
                "minSpend": "131",
                "max": "20",
                "maxSpend": "197",
                "optimizedNewSpend": "164",
                "optimizedModelOutput": "10",
                "optimizedMargin": "20",
                "optimizedProfit": "200",
                "optimizedRoiCalculation": "1.22",
                "changeInSpend": "0.00%",
                "changeInIncrementalSale": "0.00%"
              },
              {
                "spendLever": "Non-Digital",
                "isParent": false,
                "spend": "890",
                "output": "50",
                "margin": "20",
                "profit": "998",
                "roiCalculation": "1.12",
                "min": "-20",
                "minSpend": "712",
                "max": "20",
                "maxSpend": "1068",
                "optimizedNewSpend": "890",
                "optimizedModelOutput": "50",
                "optimizedMargin": "20",
                "optimizedProfit": "998",
                "optimizedRoiCalculation": "1.12",
                "changeInSpend": "0.00%",
                "changeInIncrementalSale": "0.00%"
              },
              {
                "spendLever": "Fresh & Strong Trade",
                "isParent": true,
                "spend": "182",
                "output": "31",
                "margin": "20",
                "profit": "611",
                "roiCalculation": "3.35",
                "min": "-20",
                "minSpend": "146",
                "max": "20",
                "maxSpend": "219",
                "optimizedNewSpend": "182",
                "optimizedModelOutput": "31",
                "optimizedMargin": "20",
                "optimizedProfit": "611",
                "optimizedRoiCalculation": "3.35",
                "changeInSpend": "0.00%",
                "changeInIncrementalSale": "0.00%"
              },
              {
                "spendLever": "Fresh & Strong Sampling",
                "isParent": true,
                "spend": "0",
                "output": "0",
                "margin": "20",
                "profit": "0",
                "roiCalculation": "",
                "min": "-20",
                "minSpend": "0",
                "max": "20",
                "maxSpend": "0",
                "optimizedNewSpend": "0",
                "optimizedModelOutput": "0",
                "optimizedMargin": "20",
                "optimizedProfit": "0",
                "optimizedRoiCalculation": "",
                "changeInSpend": "0.00%",
                "changeInIncrementalSale": "0.00%"
              },
              {
                "spendLever": "Total",
                "isParent": false,
                "spend": "1236",
                "output": "90",
                "margin": "",
                "profit": "1808",
                "roiCalculation": "1.46",
                "min": "",
                "minSpend": "",
                "max": "",
                "maxSpend": "",
                "optimizedNewSpend": "1236",
                "optimizedModelOutput": "90",
                "optimizedMargin": "",
                "optimizedProfit": "1808",
                "optimizedRoiCalculation": " 1.46",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              },
              {
                "spendLever": "% Changes",
                "isParent": false,
                "spend": "",
                "output": "",
                "margin": "",
                "profit": "",
                "roiCalculation": "",
                "min": "",
                "minSpend": "",
                "max": "",
                "maxSpend": "",
                "optimizedNewSpend": "0.00%",
                "optimizedModelOutput": "0.00%",
                "optimizedMargin": "0.00%",
                "optimizedProfit": "0.00%",
                "optimizedRoiCalculation": "0.00%",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              }
            ],
            "chartData": [
              {
                "name": "Incremental Sales",
                "data": [
                  {
                    "name": "Current",
                    "y": 1808,
                    "color": "#226e94"
                  },
                  {
                    "name": "Media",
                    "y": 0,
                    "color": "#188fff"
                  },
                  {
                    "name": "Trade",
                    "y": 0,
                    "color": "#ffdc48"
                  },
                  {
                    "name": "Sampling",
                    "y": 0,
                    "color": "#00c073"
                  },
                  {
                    "name": "New",
                    "isSum": true,
                    "y": "1809",
                    "color": "#00c073"
                  }
                ]
              },
              {
                "name": "ROI MAC",
                "data": [
                  1.14,
                  3.35,
                  0
                ]
              },
              {
                "name": "Share Of Spend",
                "data": [
                  {
                    "name": "Sampling",
                    "data": [
                      0,
                      0
                    ]
                  },
                  {
                    "name": "Trade",
                    "data": [
                      15,
                      15
                    ]
                  },
                  {
                    "name": "Media",
                    "data": [
                      85,
                      85
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "brandName": "Herbals",
        "brandId": 2,
        "scenario": [
          {
            "scenarioName": "Herbals Base Scenario",
            "scenarioId": 1,
            "mediaResponseCurve": {
              "name": "Media Response Curve",
              "data": [
                {
                  "name": "Weekly Spends",
                  "data": [
                    [
                      1200,
                      2500
                    ],
                    [
                      2400,
                      5000
                    ],
                    [
                      3600,
                      7500
                    ],
                    [
                      4800,
                      10000
                    ],
                    [
                      6000,
                      12500
                    ],
                    [
                      7200,
                      15000
                    ],
                    [
                      8400,
                      16000
                    ],
                    [
                      9600,
                      16500
                    ],
                    [
                      10800,
                      17000
                    ],
                    [
                      12000,
                      17500
                    ],
                    [
                      13200,
                      18000
                    ],
                    [
                      14400,
                      18500
                    ],
                    [
                      15600,
                      19000
                    ],
                    [
                      16800,
                      19000
                    ],
                    [
                      18000,
                      19000
                    ],
                    [
                      19200,
                      19000
                    ],
                    [
                      20400,
                      19000
                    ],
                    [
                      21600,
                      19000
                    ],
                    [
                      22800,
                      19000
                    ],
                    [
                      24000,
                      19000
                    ]
                  ]
                }
              ]
            },
            "scenarioData": [
              {
                "spendLever": "Herbals Media",
                "isParent": true,
                "spend": "849",
                "output": "38",
                "margin": "25",
                "profit": "944",
                "roiCalculation": "1.11",
                "min": "",
                "minSpend": "",
                "max": "",
                "maxSpend": "",
                "optimizedNewSpend": "849",
                "optimizedModelOutput": "38",
                "optimizedMargin": "25",
                "optimizedProfit": "944",
                "optimizedRoiCalculation": "1.11",
                "changeInSpend": "0.00%",
                "changeInIncrementalSale": "0.00%"
              },
              {
                "spendLever": "Digital",
                "isParent": false,
                "spend": "414",
                "output": "17",
                "margin": "25",
                "profit": "431",
                "roiCalculation": "1.04",
                "min": "-20",
                "minSpend": "364",
                "max": "20",
                "maxSpend": "546",
                "optimizedNewSpend": "414",
                "optimizedModelOutput": "17",
                "optimizedMargin": "25",
                "optimizedProfit": "431",
                "optimizedRoiCalculation": "1.04",
                "changeInSpend": "0.00%",
                "changeInIncrementalSale": "0.00%"
              },
              {
                "spendLever": "Non-Digital",
                "isParent": false,
                "spend": "435",
                "output": "21",
                "margin": "25",
                "profit": "513",
                "roiCalculation": "1.18",
                "min": "-20",
                "minSpend": "383",
                "max": "20",
                "maxSpend": "575",
                "optimizedNewSpend": "435",
                "optimizedModelOutput": "21",
                "optimizedMargin": "25",
                "optimizedProfit": "513",
                "optimizedRoiCalculation": "1.18",
                "changeInSpend": "0.00%",
                "changeInIncrementalSale": "0.00%"
              },
              {
                "spendLever": "Herbals Trade",
                "isParent": true,
                "spend": "429",
                "output": "14",
                "margin": "25",
                "profit": "353",
                "roiCalculation": "0.82",
                "min": "-20",
                "minSpend": "378",
                "max": "20",
                "maxSpend": "567",
                "optimizedNewSpend": "429",
                "optimizedModelOutput": "14",
                "optimizedMargin": "25",
                "optimizedProfit": "353",
                "optimizedRoiCalculation": "0.82",
                "changeInSpend": "0.00%",
                "changeInIncrementalSale": "0.00%"
              },
              {
                "spendLever": "Herbals Sampling",
                "isParent": true,
                "spend": "22",
                "output": "0",
                "margin": "25",
                "profit": "9",
                "roiCalculation": "0.4",
                "min": "-20",
                "minSpend": "20",
                "max": "20",
                "maxSpend": "30",
                "optimizedNewSpend": "22",
                "optimizedModelOutput": "0",
                "optimizedMargin": "25",
                "optimizedProfit": "9",
                "optimizedRoiCalculation": "0.4",
                "changeInSpend": "0.00%",
                "changeInIncrementalSale": "0.00%"
              },
              {
                "spendLever": "Total",
                "isParent": true,
                "spend": "1301",
                "output": "52",
                "margin": "",
                "profit": "1306",
                "roiCalculation": "1",
                "min": "",
                "minSpend": "",
                "max": "",
                "maxSpend": "",
                "optimizedNewSpend": "1301",
                "optimizedModelOutput": "52",
                "optimizedMargin": "",
                "optimizedProfit": "1306",
                "optimizedRoiCalculation": "1",
                "changeInSpend": "0.00%",
                "changeInIncrementalSale": "0.00%"
              },
              {
                "spendLever": "% Changes",
                "isParent": true,
                "spend": "",
                "output": "",
                "margin": "",
                "profit": "",
                "roiCalculation": "",
                "min": "",
                "minSpend": "",
                "max": "",
                "maxSpend": "",
                "optimizedNewSpend": "0.00%",
                "optimizedModelOutput": "0.00%",
                "optimizedMargin": "0.00%",
                "optimizedProfit": "0.00%",
                "optimizedRoiCalculation": "0.00%",
                "changeInSpend": "0.00%",
                "changeInIncrementalSale": "0.00%"
              }
            ],
            "chartData": [
              {
                "name": "Incremental Sales",
                "data": [
                  {
                    "name": "Current",
                    "y": 1306,
                    "color": "#226e94"
                  },
                  {
                    "name": "Media",
                    "y": 0,
                    "color": "#188fff"
                  },
                  {
                    "name": "Trade",
                    "y": 0,
                    "color": "#ffdc48"
                  },
                  {
                    "name": "Sampling",
                    "y": 0,
                    "color": "#00c073"
                  },
                  {
                    "name": "New",
                    "y":1306,
                    "isSum": true,
                    "color": "#00c073"
                  }
                ]
              },
              {
                "name": "ROI MAC",
                "data": [
                  1.11,
                  0.82,
                  0.4
                ]
              },
              {
                "name": "Share Of Spend",
                "data": [
                  {
                    "name": "Sampling",
                    "data": [
                      2,
                      2
                    ]
                  },
                  {
                    "name": "Trade",
                    "data": [
                      33,
                      33
                    ]
                  },
                  {
                    "name": "Media",
                    "data": [
                      65,
                      65
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "brandName": "Pro Relief",
        "brandId": 3,
        "scenario": [
          {
            "scenarioName": "Pro Relief Base Scenario",
            "scenarioId": 1,
            "mediaResponseCurve": {
              "name": "Media Response Curve",
              "data": [
                {
                  "name": "Weekly Spends",
                  "data": [
                    [
                      1200,
                      2500
                    ],
                    [
                      2400,
                      5000
                    ],
                    [
                      3600,
                      7500
                    ],
                    [
                      4800,
                      10000
                    ],
                    [
                      6000,
                      12500
                    ],
                    [
                      7200,
                      15000
                    ],
                    [
                      8400,
                      16000
                    ],
                    [
                      9600,
                      16500
                    ],
                    [
                      10800,
                      17000
                    ],
                    [
                      12000,
                      17500
                    ],
                    [
                      13200,
                      18000
                    ],
                    [
                      14400,
                      18500
                    ],
                    [
                      15600,
                      19000
                    ],
                    [
                      16800,
                      19000
                    ],
                    [
                      18000,
                      19000
                    ],
                    [
                      19200,
                      19000
                    ],
                    [
                      20400,
                      19000
                    ],
                    [
                      21600,
                      19000
                    ],
                    [
                      22800,
                      19000
                    ],
                    [
                      24000,
                      19000
                    ]
                  ]
                }
              ]
            },
            "scenarioData": [
              {
                "spendLever": "Pro Relief Media",
                "isParent": true,
                "spend": "2398",
                "output": "178",
                "margin": "15",
                "profit": "2673",
                "roiCalculation": "1.11",
                "min": "",
                "minSpend": "",
                "max": "",
                "maxSpend": "",
                "optimizedNewSpend": "2398",
                "optimizedModelOutput": "178",
                "optimizedMargin": "15",
                "optimizedProfit": "2673",
                "optimizedRoiCalculation": "1.11",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              },
              {
                "spendLever": "Digital",
                "isParent": false,
                "spend": "336",
                "output": "35",
                "margin": "15",
                "profit": "521",
                "roiCalculation": "1.55",
                "optimizedNewSpend": "336",
                "optimizedModelOutput": "35",
                "optimizedMargin": "15",
                "optimizedProfit": "521",
                "optimizedRoiCalculation": "1.55",
                "min": "-20",
                "minSpend": "269",
                "max": "20",
                "maxSpend": "403",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              },
              {
                "spendLever": "Non-Digital",
                "isParent": false,
                "spend": "1198",
                "output": "143",
                "margin": "15",
                "profit": "2152",
                "roiCalculation": "1.04",
                "optimizedNewSpend": "1198",
                "optimizedModelOutput": "143",
                "optimizedMargin": "15",
                "optimizedProfit": "2152",
                "optimizedMoiCalculation": "1.04",
                "min": "-20",
                "minSpend": "1649",
                "max": "20",
                "maxSpend": "2474",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              },
              {
                "spendLever": "Pro Relief Trade",
                "isParent": true,
                "spend": "496",
                "output": "86",
                "margin": "15",
                "profit": "1284",
                "roiCalculation": "2.59",
                "optimizedMewSpend": "496",
                "optimizedModelOutput": "86",
                "optimizedMargin": "15",
                "optimizedProfit": "1284",
                "optimizedRoiCalculation": "2.59",
                "min": "-20",
                "minSpend": "397",
                "max": "20",
                "maxSpend": "595",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              },
              {
                "spendLever": "Pro Relief Sampling",
                "isParent": true,
                "spend": "85",
                "output": "2",
                "margin": "15",
                "profit": "24",
                "roiCalculation": "0.28",
                "optimizedNewSpend": "85",
                "optimizedModelOutput": "2",
                "optimizedMargin": "15",
                "optimizedProfit": "24",
                "optimizedRoiCalculation": "0.28",
                "min": "-20",
                "minSpend": "68",
                "max": "20",
                "maxSpend": "102",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              },
              {
                "spendLever": "Total",
                "isParent": true,
                "spend": "2979",
                "output": "265",
                "margin": "",
                "profit": "3981",
                "roiCalculation": "1.34",
                "optimizedNewSpend": "2979",
                "optimizedModelOutput": "265",
                "optimizedMargin": "",
                "optimizedProfit": "3981",
                "optimizedRoiCalculation": "1.34",
                "min": "",
                "minSpend": "",
                "max": "",
                "maxSpend": "",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              },
              {
                "spendLever": "% Changes",
                "isParent": true,
                "spend": "",
                "output": "",
                "margin": "",
                "profit": "",
                "roiCalculation": "",
                "optimizedNewSpend": "0.00%",
                "optimizedModelOutput": "0.00%",
                "optimizedMargin": "0.00%",
                "optimizedProfit": "0.00%",
                "optimizedRoiCalculation": "0.00%",
                "min": "",
                "minSpend": "",
                "max": "",
                "maxSpend": "",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              }
            ],
            "chartData": [
              {
                "name": "Incremental Sales",
                "data": [
                  {
                    "name": "Current",
                    "y": 3981,
                    "color": "#226e94"
                  },
                  {
                    "name": "Media",
                    "y": 0,
                    "color": "#188fff"
                  },
                  {
                    "name": "Trade",
                    "y": 0,
                    "color": "#ffdc48"
                  },
                  {
                    "name": "Sampling",
                    "y": 0,
                    "color": "#00c073"
                  },
                  {
                    "name": "New",
                    "y": 3981,
                    "isSum": true,
                    "color": "#00c073"
                  }
                ]
              },
              {
                "name": "ROI MAC",
                "data": [
                  1.11,
                  2.59,
                  0.28
                ]
              },
              {
                "name": "Share Of Spend",
                "data": [
                  {
                    "name": "Sampling",
                    "data": [
                      3,
                      3
                    ]
                  },
                  {
                    "name": "Trade",
                    "data": [
                      17,
                      17
                    ]
                  },
                  {
                    "name": "Media",
                    "data": [
                      80,
                      80
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "brandName": "MagicTooth",
        "brandId": 4,
        "scenario": [
          {
            "scenarioName": "MagicTooth Base Scenario",
            "scenarioId": 1,
            "mediaResponseCurve": {
              "name": "Media Response Curve",
              "data": [
                {
                  "name": "Weekly Spends",
                  "data": [
                    [
                      1200,
                      2500
                    ],
                    [
                      2400,
                      5000
                    ],
                    [
                      3600,
                      7500
                    ],
                    [
                      4800,
                      10000
                    ],
                    [
                      6000,
                      12500
                    ],
                    [
                      7200,
                      15000
                    ],
                    [
                      8400,
                      16000
                    ],
                    [
                      9600,
                      16500
                    ],
                    [
                      10800,
                      17000
                    ],
                    [
                      12000,
                      17500
                    ],
                    [
                      13200,
                      18000
                    ],
                    [
                      14400,
                      18500
                    ],
                    [
                      15600,
                      19000
                    ],
                    [
                      16800,
                      19000
                    ],
                    [
                      18000,
                      19000
                    ],
                    [
                      19200,
                      19000
                    ],
                    [
                      20400,
                      19000
                    ],
                    [
                      21600,
                      19000
                    ],
                    [
                      22800,
                      19000
                    ],
                    [
                      24000,
                      19000
                    ]
                  ]
                }
              ]
            },
            "scenarioData": [
              {
                "spendLever": "MagicTooth Media",
                "isParent": true,
                "spend": "2398",
                "output": "178",
                "margin": "15",
                "profit": "2673",
                "roiCalculation": "1.11",
                "min": "",
                "minSpend": "",
                "max": "",
                "maxSpend": "",
                "optimizedNewSpend": "2398",
                "optimizedModelOutput": "178",
                "optimizedMargin": "15",
                "optimizedProfit": "2673",
                "optimizedRoiCalculation": "1.11",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              },
              {
                "spendLever": "Digital",
                "isParent": false,
                "spend": "336",
                "output": "35",
                "margin": "15",
                "profit": "521",
                "roiCalculation": "1.55",
                "optimizedNewSpend": "336",
                "optimizedModelOutput": "35",
                "optimizedMargin": "15",
                "optimizedProfit": "521",
                "optimizedRoiCalculation": "1.55",
                "min": "-20",
                "minSpend": "269",
                "max": "20",
                "maxSpend": "403",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              },
              {
                "spendLever": "Non-Digital",
                "isParent": false,
                "spend": "1198",
                "output": "143",
                "margin": "15",
                "profit": "2152",
                "roiCalculation": "1.04",
                "optimizedNewSpend": "1198",
                "optimizedModelOutput": "143",
                "optimizedMargin": "15",
                "optimizedProfit": "2152",
                "optimizedMoiCalculation": "1.04",
                "min": "-20",
                "minSpend": "1649",
                "max": "20",
                "maxSpend": "2474",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              },
              {
                "spendLever": "MagicTooth Trade",
                "isParent": true,
                "spend": "496",
                "output": "86",
                "margin": "15",
                "profit": "1284",
                "roiCalculation": "2.59",
                "optimizedMewSpend": "496",
                "optimizedModelOutput": "86",
                "optimizedMargin": "15",
                "optimizedProfit": "1284",
                "optimizedRoiCalculation": "2.59",
                "min": "-20",
                "minSpend": "397",
                "max": "20",
                "maxSpend": "595",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              },
              {
                "spendLever": "MagicTooth Sampling",
                "isParent": true,
                "spend": "85",
                "output": "2",
                "margin": "15",
                "profit": "24",
                "roiCalculation": "0.28",
                "optimizedNewSpend": "85",
                "optimizedModelOutput": "2",
                "optimizedMargin": "15",
                "optimizedProfit": "24",
                "optimizedRoiCalculation": "0.28",
                "min": "-20",
                "minSpend": "68",
                "max": "20",
                "maxSpend": "102",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              },
              {
                "spendLever": "Total",
                "isParent": true,
                "spend": "2979",
                "output": "265",
                "margin": "",
                "profit": "3981",
                "roiCalculation": "1.34",
                "optimizedNewSpend": "2979",
                "optimizedModelOutput": "265",
                "optimizedMargin": "",
                "optimizedProfit": "3981",
                "optimizedRoiCalculation": "1.34",
                "min": "",
                "minSpend": "",
                "max": "",
                "maxSpend": "",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              },
              {
                "spendLever": "% Changes",
                "isParent": true,
                "spend": "",
                "output": "",
                "margin": "",
                "profit": "",
                "roiCalculation": "",
                "optimizedNewSpend": "0.00%",
                "optimizedModelOutput": "0.00%",
                "optimizedMargin": "0.00%",
                "optimizedProfit": "0.00%",
                "optimizedRoiCalculation": "0.00%",
                "min": "",
                "minSpend": "",
                "max": "",
                "maxSpend": "",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              }
            ],
            "chartData": [
              {
                "name": "Incremental Sales",
                "data": [
                  {
                    "name": "Current",
                    "y": 3981,
                    "color": "#226e94"
                  },
                  {
                    "name": "Media",
                    "y": 0,
                    "color": "#188fff"
                  },
                  {
                    "name": "Trade",
                    "y": 0,
                    "color": "#ffdc48"
                  },
                  {
                    "name": "Sampling",
                    "y": 0,
                    "color": "#00c073"
                  },
                  {
                    "name": "New",
                    "y": 3981,
                    "isSum": true,
                    "color": "#00c073"
                  }
                ]
              },
              {
                "name": "ROI MAC",
                "data": [
                  1.11,
                  2.59,
                  0.28
                ]
              },
              {
                "name": "Share Of Spend",
                "data": [
                  {
                    "name": "Sampling",
                    "data": [
                      3,
                      3
                    ]
                  },
                  {
                    "name": "Trade",
                    "data": [
                      17,
                      17
                    ]
                  },
                  {
                    "name": "Media",
                    "data": [
                      80,
                      80
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "brandName": "Portfolio",
        "brandId": 99,
        "scenario": [
          {
            "scenarioName": "Portfolio Base Scenario",
            "scenarioId": 1,
            "mediaResponseCurve": {
              "name": "Media Response Curve",
              "data": [
                {
                  "name": "Media Response Curve",
                  "data": [
                    [
                      1200,
                      2500
                    ],
                    [
                      2400,
                      5000
                    ],
                    [
                      3600,
                      7500
                    ],
                    [
                      4800,
                      10000
                    ],
                    [
                      6000,
                      12500
                    ],
                    [
                      7200,
                      15000
                    ],
                    [
                      8400,
                      16000
                    ],
                    [
                      9600,
                      16500
                    ],
                    [
                      10800,
                      17000
                    ],
                    [
                      12000,
                      17500
                    ],
                    [
                      13200,
                      18000
                    ],
                    [
                      14400,
                      18500
                    ],
                    [
                      15600,
                      19000
                    ],
                    [
                      16800,
                      19000
                    ],
                    [
                      18000,
                      19000
                    ],
                    [
                      19200,
                      19000
                    ],
                    [
                      20400,
                      19000
                    ],
                    [
                      21600,
                      19000
                    ],
                    [
                      22800,
                      19000
                    ],
                    [
                      24000,
                      19000
                    ]
                  ]
                }
              ]
            },
            "scenarioData": [
              {
                "spendLever": "Fresh & Strong Media",
                "spend": "1054",
                "isParent": true,
                "output": "60",
                "margin": "20",
                "profit": "1198",
                "roiCalculation": "1.14",
                "min": "",
                "minSpend": "",
                "max": "",
                "maxSpend": "",
                "optimizedNewSpend": "1054",
                "optimizedModelOutput": "60",
                "optimizedMargin": "20",
                "optimizedProfit": "1198",
                "optimizedRoiCalculation": "1.14",
                "changeInSpend": "0.00%",
                "changeInIncrementalSale": "0.00%"
              },
              {
                "spendLever": "Digital",
                "isParent": false,
                "spend": "164",
                "output": "10",
                "margin": "20",
                "profit": "200",
                "roiCalculation": "1.22",
                "min": "-20",
                "minSpend": "131",
                "max": "20",
                "maxSpend": "197",
                "optimizedNewSpend": "164",
                "optimizedModelOutput": "10",
                "optimizedMargin": "20",
                "optimizedProfit": "200",
                "optimizedRoiCalculation": "1.22",
                "changeInSpend": "0.00%",
                "changeInIncrementalSale": "0.00%"
              },
              {
                "spendLever": "Non-Digital",
                "isParent": false,
                "spend": "890",
                "output": "50",
                "margin": "20",
                "profit": "998",
                "roiCalculation": "1.12",
                "min": "-20",
                "minSpend": "712",
                "max": "20",
                "maxSpend": "1068",
                "optimizedNewSpend": "890",
                "optimizedModelOutput": "50",
                "optimizedMargin": "20",
                "optimizedProfit": "998",
                "optimizedRoiCalculation": "1.12",
                "changeInSpend": "0.00%",
                "changeInIncrementalSale": "0.00%"
              },
              {
                "spendLever": "Fresh & Strong Trade",
                "isParent": true,
                "spend": "182",
                "output": "31",
                "margin": "20",
                "profit": "611",
                "roiCalculation": "3.35",
                "min": "-20",
                "minSpend": "146",
                "max": "20",
                "maxSpend": "219",
                "optimizedNewSpend": "182",
                "optimizedModelOutput": "31",
                "optimizedMargin": "20",
                "optimizedProfit": "611",
                "optimizedRoiCalculation": "3.35",
                "changeInSpend": "0.00%",
                "changeInIncrementalSale": "0.00%"
              },
              {
                "spendLever": "Fresh & Strong Sampling",
                "isParent": true,
                "spend": "0",
                "output": "0",
                "margin": "20",
                "profit": "0",
                "roiCalculation": "",
                "min": "-20",
                "minSpend": "0",
                "max": "20",
                "maxSpend": "0",
                "optimizedNewSpend": "0",
                "optimizedModelOutput": "0",
                "optimizedMargin": "20",
                "optimizedProfit": "0",
                "optimizedRoiCalculation": "",
                "changeInSpend": "0.00%",
                "changeInIncrementalSale": "0.00%"
              },
              {
                "spendLever": "Herbals Media",
                "isParent": true,
                "spend": "849",
                "output": "38",
                "margin": "25",
                "profit": "944",
                "roiCalculation": "1.11",
                "min": "",
                "minSpend": "",
                "max": "",
                "maxSpend": "",
                "optimizedNewSpend": "849",
                "optimizedModelOutput": "38",
                "optimizedMargin": "25",
                "optimizedProfit": "944",
                "optimizedRoiCalculation": "1.11",
                "changeInSpend": "0.00%",
                "changeInIncrementalSale": "0.00%"
              },
              {
                "spendLever": "Digital",
                "isParent": false,
                "spend": "414",
                "output": "17",
                "margin": "25",
                "profit": "431",
                "roiCalculation": "1.04",
                "min": "-20",
                "minSpend": "364",
                "max": "20",
                "maxSpend": "546",
                "optimizedNewSpend": "414",
                "optimizedModelOutput": "17",
                "optimizedMargin": "25",
                "optimizedProfit": "431",
                "optimizedRoiCalculation": "1.04",
                "changeInSpend": "0.00%",
                "changeInIncrementalSale": "0.00%"
              },
              {
                "spendLever": "Non-Digital",
                "isParent": false,
                "spend": "435",
                "output": "21",
                "margin": "25",
                "profit": "513",
                "roiCalculation": "1.18",
                "min": "-20",
                "minSpend": "383",
                "max": "20",
                "maxSpend": "575",
                "optimizedNewSpend": "435",
                "optimizedModelOutput": "21",
                "optimizedMargin": "25",
                "optimizedProfit": "513",
                "optimizedRoiCalculation": "1.18",
                "changeInSpend": "0.00%",
                "changeInIncrementalSale": "0.00%"
              },
              {
                "spendLever": "Herbals Trade",
                "isParent": true,
                "spend": "429",
                "output": "14",
                "margin": "25",
                "profit": "353",
                "roiCalculation": "0.82",
                "min": "-20",
                "minSpend": "378",
                "max": "20",
                "maxSpend": "567",
                "optimizedNewSpend": "429",
                "optimizedModelOutput": "14",
                "optimizedMargin": "25",
                "optimizedProfit": "353",
                "optimizedRoiCalculation": "0.82",
                "changeInSpend": "0.00%",
                "changeInIncrementalSale": "0.00%"
              },
              {
                "spendLever": "Herbals Sampling",
                "isParent": true,
                "spend": "22",
                "output": "0",
                "margin": "25",
                "profit": "9",
                "roiCalculation": "0.4",
                "min": "-20",
                "minSpend": "20",
                "max": "20",
                "maxSpend": "30",
                "optimizedNewSpend": "22",
                "optimizedModelOutput": "0",
                "optimizedMargin": "25",
                "optimizedProfit": "9",
                "optimizedRoiCalculation": "0.4",
                "changeInSpend": "0.00%",
                "changeInIncrementalSale": "0.00%"
              },
              {
                "spendLever": "Pro Relief Media",
                "isParent": true,
                "spend": "2398",
                "output": "178",
                "margin": "15",
                "profit": "2673",
                "roiCalculation": "1.11",
                "min": "",
                "minSpend": "",
                "max": "",
                "maxSpend": "",
                "optimizedNewSpend": "2398",
                "optimizedModelOutput": "178",
                "optimizedMargin": "15",
                "optimizedProfit": "2673",
                "optimizedRoiCalculation": "1.11",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              },
              {
                "spendLever": "Digital",
                "isParent": false,
                "spend": "336",
                "output": "35",
                "margin": "15",
                "profit": "521",
                "roiCalculation": "1.55",
                "optimizedNewSpend": "336",
                "optimizedModelOutput": "35",
                "optimizedMargin": "15",
                "optimizedProfit": "521",
                "optimizedRoiCalculation": "1.55",
                "min": "-20",
                "minSpend": "269",
                "max": "20",
                "maxSpend": "403",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              },
              {
                "spendLever": "Non-Digital",
                "isParent": false,
                "spend": "1198",
                "output": "143",
                "margin": "15",
                "profit": "2152",
                "roiCalculation": "1.04",
                "optimizedNewSpend": "1198",
                "optimizedModelOutput": "143",
                "optimizedMargin": "15",
                "optimizedProfit": "2152",
                "optimizedMoiCalculation": "1.04",
                "min": "-20",
                "minSpend": "1649",
                "max": "20",
                "maxSpend": "2474",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              },
              {
                "spendLever": "Pro Relief Trade",
                "isParent": true,
                "spend": "496",
                "output": "86",
                "margin": "15",
                "profit": "1284",
                "roiCalculation": "2.59",
                "optimizedMewSpend": "496",
                "optimizedModelOutput": "86",
                "optimizedMargin": "15",
                "optimizedProfit": "1284",
                "optimizedRoiCalculation": "2.59",
                "min": "-20",
                "minSpend": "397",
                "max": "20",
                "maxSpend": "595",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              },
              {
                "spendLever": "Pro Relief Sampling",
                "isParent": true,
                "spend": "85",
                "output": "2",
                "margin": "15",
                "profit": "24",
                "roiCalculation": "0.28",
                "optimizedNewSpend": "85",
                "optimizedModelOutput": "2",
                "optimizedMargin": "15",
                "optimizedProfit": "24",
                "optimizedRoiCalculation": "0.28",
                "min": "-20",
                "minSpend": "68",
                "max": "20",
                "maxSpend": "102",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              },
                {
                "spendLever": "Media Total",
                "isParent": true,
                "spend": "4301",
                "output": "276",
                "margin": "",
                "profit": "4815",
                "roiCalculation": "1.12",
                "min": "",
                "minSpend": "",
                "max": "",
                "maxSpend": "",
                "optimizedNewSpend": "4301",
                "optimizedModelOutput": "276",
                "optimizedMargin": "",
                "optimizedProfit": "4815",
                "optimizedRoiCalculation": "1.12",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              },
              {
                "spendLever": "Digital Total",
                "isParent": false,
                "spend": "914",
                "output": "62",
                "margin": "",
                "profit": "1152",
                "roiCalculation": "1.27",
                "optimizedNewSpend": "914",
                "optimizedModelOutput": "62",
                "optimizedMargin": "",
                "optimizedProfit": "1152",
                "optimizedRoiCalculation": "1.27",
                "min": "",
                "minSpend": "",
                "max": "",
                "maxSpend": "",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              },
              {
                "spendLever": "Non-Digital Total",
                "isParent": false,
                "spend": "3387",
                "output": "130",
                "margin": "",
                "profit": "3663",
                "roiCalculation": "1.11",
                "optimizedNewSpend": "3387",
                "optimizedModelOutput": "130",
                "optimizedMargin": "",
                "optimizedProfit": "3663",
                "optimizedMoiCalculation": "1.11",
                "min": "",
                "minSpend": "",
                "max": "",
                "maxSpend": "",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              },
              {
                "spendLever": "Trade Total",
                "isParent": true,
                "spend": "1108",
                "output": "130",
                "margin": "",
                "profit": "2247",
                "roiCalculation": "2.25",
                "optimizedNewSpend": "1108",
                "optimizedModelOutput": "130",
                "optimizedMargin": "",
                "optimizedProfit": "2247",
                "optimizedRoiCalculation": "2.25",
                "min": "",
                "minSpend": "",
                "max": "",
                "maxSpend": "",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              },
              {
                "spendLever": "Sampling Total",
                "isParent": true,
                "spend": "45",
                "output": "2",
                "margin": "",
                "profit": "33",
                "roiCalculation": "1.20",
                "optimizedNewSpend": "45",
                "optimizedModelOutput": "2",
                "optimizedMargin": "",
                "optimizedProfit": "33",
                "optimizedRoiCalculation": "1.20",
                "min": "",
                "minSpend": "",
                "max": "",
                "maxSpend": "",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              },
              {
                "spendLever": "Total",
                "isParent": true,
                "spend": "5453",
                "output": "408",
                "margin": "",
                "profit": "7096",
                "roiCalculation": "1.20",
                "min": "",
                "minSpend": "",
                "max": "",
                "maxSpend": "",
                "optimizedNewSpend": "5453",
                "optimizedModelOutput": "408",
                "optimizedMargin": "",
                "optimizedProfit": "7096",
                "optimizedRoiCalculation": " 1.20",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              },
              {
                "spendLever": "% Changes",
                "isParent": true,
                "spend": "",
                "output": "",
                "margin": "",
                "profit": "",
                "roiCalculation": "",
                "min": "",
                "minSpend": "",
                "max": "",
                "maxSpend": "",
                "optimizedNewSpend": "0.00%",
                "optimizedModelOutput": "0.00%",
                "optimizedMargin": "0.00%",
                "optimizedProfit": "0.00%",
                "optimizedRoiCalculation": "0.00%",
                "changeInSpend": "",
                "changeInIncrementalSale": ""
              }
            ],
            "chartData": [
              {
                "name": "Incremental Sales",
                "data": [
                  {
                    "name": "Current",
                    "y": 1808,
                    "color": "#226e94"
                  },
                  {
                    "name": "Media",
                    "y": 0,
                    "color": "#188fff"
                  },
                  {
                    "name": "Trade",
                    "y": 0,
                    "color": "#ffdc48"
                  },
                  {
                    "name": "Sampling",
                    "y": 0,
                    "color": "#00c073"
                  },
                  {
                    "name": "New",
                    "isSum": true,
                    "color": "#00c073"
                  }
                ]
              },
              {
                "name": "ROI MAC",
                "data": [
                  1.14,
                  3.35,
                  0
                ]
              },
              {
                "name": "Share Of Spend",
                "data": [
                  {
                    "name": "Sampling",
                    "data": [
                      0,
                      0
                    ]
                  },
                  {
                    "name": "Trade",
                    "data": [
                      15,
                      15
                    ]
                  },
                  {
                    "name": "Media",
                    "data": [
                      85,
                      85
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }

  public databuffer: any;

  public simulateTableData = {
    "data": [
      {
        "brandName": "Fresh & Strong",
        "brandId": 1,
        "scenario": [
          {
            "scenarioName": "Fresh & Strong Base Scenario",
            "scenarioId": 1,
            "scenarioData": [
              {
                "spendLever": "Fresh & Strong Media",
                "isParent": true,
                "spend": "1054",
                "modelOutput": "60",
                "margin": "20",
                "profit": "1198",
                "roiCalculation": " 1.14",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "1054",
                "simulateOutput": "60",
                "simulateOutputMargin": "20",
                "simulateOutputProfit": "1198",
                "simulateOutputRoiCalculation": "1.14"
              },
              {
                "spendLever": "Digital",
                "isParent": false,
                "spend": "164",
                "modelOutput": "10",
                "margin": "20",
                "profit": "200",
                "roiCalculation": "1.22",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "164",
                "simulateOutput": "10",
                "simulateOutputMargin": "20",
                "simulateOutputProfit": "200",
                "simulateOutputRoiCalculation": "1.22"
              },
              {
                "spendLever": "Non-Digital",
                "isParent": false,
                "spend": "890",
                "modelOutput": "50",
                "margin": "20",
                "profit": "998",
                "roiCalculation": "1.12",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "890",
                "simulateOutput": "50",
                "simulateOutputMargin": "20",
                "simulateOutputProfit": "998",
                "simulateOutputRoiCalculation": "1.12"
              },
              {
                "spendLever": "Fresh & Strong Trade",
                "isParent": true,
                "spend": "182",
                "modelOutput": "31",
                "margin": "20",
                "profit": "611",
                "roiCalculation": "3.35",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "182",
                "simulateOutput": "31",
                "simulateOutputMargin": "20",
                "simulateOutputProfit": "611",
                "simulateOutputRoiCalculation": "3.35"
              },
              {
                "spendLever": "Fresh & Strong Sampling",
                "isParent": true,
                "spend": "0",
                "modelOutput": "0",
                "margin": "20",
                "profit": "0",
                "roiCalculation": "",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "182",
                "simulateOutput": "0",
                "simulateOutputMargin": "20",
                "simulateOutputProfit": "0",
                "simulateOutputRoiCalculation": ""
              },
              {
                "spendLever": "Total",
                "isParent": false,
                "spend": "1236",
                "modelOutput": "90",
                "margin": "",
                "profit": "1808",
                "roiCalculation": "1.46",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "1236",
                "simulateOutput": "90",
                "simulateOutputMargin": "",
                "simulateOutputProfit": "1808",
                "simulateOutputRoiCalculation": "1.46"
              },
              {
                "spendLever": "% Changes",
                "isParent": false,
                "spend": "",
                "modelOutput": "",
                "margin": "",
                "profit": "",
                "roiCalculation": "",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "0.00%",
                "simulateOutput": "0.00%",
                "simulateOutputMargin": "0.00%",
                "simulateOutputProfit": "0.00%",
                "simulateOutputRoiCalculation": "0.00%"
              }
            ],
            "chartData": [
              {
                "name": "Incremental Sales",
                "data": [
                  {
                    "name": "Current",
                    "y": 1808,
                    "color": "#226e94"
                  },
                  {
                    "name": "Media",
                    "y": 0,
                    "color": "#188fff"
                  },
                  {
                    "name": "Trade",
                    "y": 0,
                    "color": "#ffdc48"
                  },
                  {
                    "name": "Sampling",
                    "y": 0,
                    "color": "#00c073"
                  },
                  {
                    "name": "New",
                    "y": 1808,
                    "isSum": true,
                    "color": "#00c073"
                  }
                ]
              },
              {
                "name": "ROI MAC",
                "data": [
                  1.14,
                  3.35,
                  0
                ]
              },
              {
                "name": "Share Of Spend",
                "data": [
                  {
                    "name": "Sampling",
                    "data": [
                      0,
                      0
                    ]
                  },
                  {
                    "name": "Trade",
                    "data": [
                      15,
                      15
                    ]
                  },
                  {
                    "name": "Media",
                    "data": [
                      85,
                      85
                    ]
                  }
                ]
              }
            ],
            "mediaResponseCurve": {
              "name": "Media Response Curve",
              "data": [
                {
                  "name": "Media Response Curve",
                  "data": [
                    [
                      1200,
                      2500
                    ],
                    [
                      2400,
                      5000
                    ],
                    [
                      3600,
                      7500
                    ],
                    [
                      4800,
                      10000
                    ],
                    [
                      6000,
                      12500
                    ],
                    [
                      7200,
                      15000
                    ],
                    [
                      8400,
                      16000
                    ],
                    [
                      9600,
                      16500
                    ],
                    [
                      10800,
                      17000
                    ],
                    [
                      12000,
                      17500
                    ],
                    [
                      13200,
                      18000
                    ],
                    [
                      14400,
                      18500
                    ],
                    [
                      15600,
                      19000
                    ],
                    [
                      16800,
                      19000
                    ],
                    [
                      18000,
                      19000
                    ],
                    [
                      19200,
                      19000
                    ],
                    [
                      20400,
                      19000
                    ],
                    [
                      21600,
                      19000
                    ],
                    [
                      22800,
                      19000
                    ],
                    [
                      24000,
                      19000
                    ]
                  ]
                }
              ]
            }
          }
        ]
      },
      {
        "brandName": "Herbals",
        "brandId": 2,
        "scenario": [
          {
            "scenarioName": "Herbals Base Scenario",
            "scenarioId": 1,
            "scenarioData": [
              {
                "spendLever": "Herbals Media",
                "spend": "849",
                "isParent": true,
                "modelOutput": "38",
                "margin": "25",
                "profit": "944",
                "roiCalculation": "1.11",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "849",
                "simulateOutput": "38",
                "simulateOutputMargin": "25",
                "simulateOutputProfit": "944",
                "simulateOutputRoiCalculation": "1.11"
              },
              {
                "spendLever": "Digital",
                "spend": "414",
                "isParent": false,
                "modelOutput": "17",
                "margin": "25",
                "profit": "431",
                "roiCalculation": "1.04",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "414",
                "simulateOutput": "38",
                "simulateOutputMargin": "25",
                "simulateOutputProfit": "431",
                "simulateOutputRoiCalculation": "1.04"
              },
              {
                "spendLever": "Non-Digital",
                "spend": "435",
                "isParent": false,
                "modelOutput": "21",
                "margin": "25",
                "profit": "513",
                "roiCalculation": "1.18",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "435",
                "simulateOutput": "21",
                "simulateOutputMargin": "25",
                "simulateOutputProfit": "513",
                "simulateOutputRoiCalculation": "1.18"
              },
              {
                "spendLever": "Herbals Trade",
                "isParent": true,
                "spend": "429",
                "modelOutput": "14",
                "margin": "25",
                "profit": "353",
                "roiCalculation": "0.82",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "429",
                "simulateOutput": "14",
                "simulateOutputMargin": "25",
                "simulateOutputProfit": "353",
                "simulateOutputRoiCalculation": "0.82"
              },
              {
                "spendLever": "Herbals Sampling",
                "isParent": true,
                "spend": "22",
                "modelOutput": "0",
                "margin": "25",
                "profit": "9",
                "roiCalculation": "0.40",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "22",
                "simulateOutput": "0",
                "simulateOutputMargin": "25",
                "simulateOutputProfit": "9",
                "simulateOutputRoiCalculation": "0.40"
              },
              {
                "spendLever": "Total",
                "isParent": false,
                "spend": "1301",
                "modelOutput": "52",
                "margin": "",
                "profit": "1306",
                "roiCalculation": "1.00",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "1301",
                "simulateOutput": "1301",
                "simulateOutputMargin": "25",
                "simulateOutputProfit": "1306",
                "simulateOutputRoiCalculation": "1.00"
              },
              {
                "spendLever": "% Changes",
                "isParent": false,
                "spend": "",
                "modelOutput": "",
                "margin": "",
                "profit": "",
                "roiCalculation": "",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "0.00%",
                "simulateOutput": "0.00%",
                "simulateOutputMargin": "0.00%",
                "simulateOutputProfit": "0.00%",
                "simulateOutputRoiCalculation": "0.00%"
              }
            ],
            "mediaResponseCurve": {
              "name": "Media Response Curve",
              "data": [
                {
                  "name": "Media Response Curve",
                  "data": [
                    [
                      1200,
                      2500
                    ],
                    [
                      2400,
                      5000
                    ],
                    [
                      3600,
                      7500
                    ],
                    [
                      4800,
                      10000
                    ],
                    [
                      6000,
                      12500
                    ],
                    [
                      7200,
                      15000
                    ],
                    [
                      8400,
                      16000
                    ],
                    [
                      9600,
                      16500
                    ],
                    [
                      10800,
                      17000
                    ],
                    [
                      12000,
                      17500
                    ],
                    [
                      13200,
                      18000
                    ],
                    [
                      14400,
                      18500
                    ],
                    [
                      15600,
                      19000
                    ],
                    [
                      16800,
                      19000
                    ],
                    [
                      18000,
                      19000
                    ],
                    [
                      19200,
                      19000
                    ],
                    [
                      20400,
                      19000
                    ],
                    [
                      21600,
                      19000
                    ],
                    [
                      22800,
                      19000
                    ],
                    [
                      24000,
                      19000
                    ]
                  ]
                }
              ]
            },
            "chartData": [
              {
                "name": "Incremental Sales",
                "data": [
                  {
                    "name": "Current",
                    "y": 1306,
                    "color": "#226e94"
                  },
                  {
                    "name": "Media",
                    "y": 0,
                    "color": "#188fff"
                  },
                  {
                    "name": "Trade",
                    "y": 0,
                    "color": "#ffdc48"
                  },
                  {
                    "name": "Sampling",
                    "y": 0,
                    "color": "#00c073"
                  },
                  {
                    "name": "New",
                    "y": 1306,
                    "isSum": true,
                    "color": "#00c073"
                  }
                ]
              },
              {
                "name": "ROI MAC",
                "data": [
                  1.11,
                  0.82,
                  0.4
                ]
              },
              {
                "name": "Share Of Spend",
                "data": [
                  {
                    "name": "Sampling",
                    "data": [
                      2,
                      2
                    ]
                  },
                  {
                    "name": "Trade",
                    "data": [
                      33,
                      33
                    ]
                  },
                  {
                    "name": "Media",
                    "data": [
                      65,
                      65
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "brandName": "Pro Relief",
        "brandId": 3,
        "scenario": [
          {
            "scenarioName": "Pro Relief Base Scenario",
            "scenarioId": 1,
            "scenarioData": [
              {
                "spendLever": "Pro Relief Media",
                "isParent": true,
                "spend": "2398",
                "modelOutput": "178",
                "margin": "15",
                "profit": "2673",
                "roiCalculation": "1.11",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "2398",
                "simulateOutput": "178",
                "simulateOutputMargin": "15",
                "simulateOutputProfit": "2673",
                "simulateOutputRoiCalculation": "1.11"
              },
              {
                "spendLever": "Digital",
                "spend": "336",
                "isParent": false,
                "modelOutput": "35",
                "margin": "15",
                "profit": "521",
                "roiCalculation": "1.55",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "336",
                "simulateOutput": "35",
                "simulateOutputMargin": "15",
                "simulateOutputProfit": "521",
                "simulateOutputRoiCalculation": "1.55"
              },
              {
                "spendLever": "Non-Digital",
                "isParent": false,
                "spend": "2062",
                "modelOutput": "143",
                "margin": "15",
                "profit": "2152",
                "roiCalculation": "1.04",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "2062",
                "simulateOutput": "143",
                "simulateOutputMargin": "15",
                "simulateOutputProfit": "2152",
                "simulateOutputRoiCalculation": "1.04"
              },
              {
                "spendLever": "Pro Relief Trade",
                "isParent": true,
                "spend": "496",
                "modelOutput": "86",
                "margin": "15",
                "profit": "1284",
                "roiCalculation": "2.59",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "496",
                "simulateOutput": "86",
                "simulateOutputMargin": "15",
                "simulateOutputProfit": "1284",
                "simulateOutputRoiCalculation": "2.59"
              },
              {
                "spendLever": "Pro Relief Sampling",
                "isParent": true,
                "spend": "85",
                "modelOutput": "2",
                "margin": "15",
                "profit": "24",
                "roiCalculation": "0.28",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "85",
                "simulateOutput": "2",
                "simulateOutputMargin": "15",
                "simulateOutputProfit": "24",
                "simulateOutputRoiCalculation": "0.28"
              },
              {
                "spendLever": "Total",
                "isParent": false,
                "spend": "2979",
                "modelOutput": "265",
                "margin": "",
                "profit": "3981",
                "roiCalculation": "1.34",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "2979",
                "simulateOutput": "265",
                "simulateOutputMargin": "",
                "simulateOutputProfit": "3981",
                "simulateOutputRoiCalculation": "1.34"
              },
              {
                "spendLever": "% Changes",
                "isParent": false,
                "spend": "",
                "modelOutput": "",
                "margin": "",
                "profit": "",
                "roiCalculation": "",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "0.00%",
                "simulateOutput": "0.00%",
                "simulateOutputMargin": "0.00%",
                "simulateOutputProfit": "0.00%",
                "simulateOutputRoiCalculation": "0.00%"
              }
            ],
            "mediaResponseCurve": {
              "name": "Media Response Curve",
              "data": [
                {
                  "name": "Media Response Curve",
                  "data": [
                    [
                      1200,
                      2500
                    ],
                    [
                      2400,
                      5000
                    ],
                    [
                      3600,
                      7500
                    ],
                    [
                      4800,
                      10000
                    ],
                    [
                      6000,
                      12500
                    ],
                    [
                      7200,
                      15000
                    ],
                    [
                      8400,
                      16000
                    ],
                    [
                      9600,
                      16500
                    ],
                    [
                      10800,
                      17000
                    ],
                    [
                      12000,
                      17500
                    ],
                    [
                      13200,
                      18000
                    ],
                    [
                      14400,
                      18500
                    ],
                    [
                      15600,
                      19000
                    ],
                    [
                      16800,
                      19000
                    ],
                    [
                      18000,
                      19000
                    ],
                    [
                      19200,
                      19000
                    ],
                    [
                      20400,
                      19000
                    ],
                    [
                      21600,
                      19000
                    ],
                    [
                      22800,
                      19000
                    ],
                    [
                      24000,
                      19000
                    ]
                  ]
                }
              ]
            },
            "chartData": [
              {
                "name": "Incremental Sales",
                "data": [
                  {
                    "name": "Current",
                    "y": 1808,
                    "color": "#226e94"
                  },
                  {
                    "name": "Media",
                    "y": 0,
                    "color": "#188fff"
                  },
                  {
                    "name": "Trade",
                    "y": 0,
                    "color": "#ffdc48"
                  },
                  {
                    "name": "Sampling",
                    "y": 0,
                    "color": "#00c073"
                  },
                  {
                    "name": "New",
                    "y": 1808,
                    "isSum": true,
                    "color": "#00c073"
                  }
                ]
              },
              {
                "name": "ROI MAC",
                "data": [
                  1.14,
                  3.35,
                  0
                ]
              },
              {
                "name": "Share Of Spend",
                "data": [
                  {
                    "name": "Sampling",
                    "data": [
                      0,
                      0
                    ]
                  },
                  {
                    "name": "Trade",
                    "data": [
                      15,
                      15
                    ]
                  },
                  {
                    "name": "Media",
                    "data": [
                      85,
                      85
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "brandName": "Portfolio",
        "brandId": 99,
        "scenario": [
          {
            "scenarioName": "Portfolio Base Scenario",
            "scenarioId": 1,
            "mediaResponseCurve": {
              "name": "Media Response Curve",
              "data": [
                {
                  "name": "Media Response Curve",
                  "data": [
                    [
                      1200,
                      2500
                    ],
                    [
                      2400,
                      5000
                    ],
                    [
                      3600,
                      7500
                    ],
                    [
                      4800,
                      10000
                    ],
                    [
                      6000,
                      12500
                    ],
                    [
                      7200,
                      15000
                    ],
                    [
                      8400,
                      16000
                    ],
                    [
                      9600,
                      16500
                    ],
                    [
                      10800,
                      17000
                    ],
                    [
                      12000,
                      17500
                    ],
                    [
                      13200,
                      18000
                    ],
                    [
                      14400,
                      18500
                    ],
                    [
                      15600,
                      19000
                    ],
                    [
                      16800,
                      19000
                    ],
                    [
                      18000,
                      19000
                    ],
                    [
                      19200,
                      19000
                    ],
                    [
                      20400,
                      19000
                    ],
                    [
                      21600,
                      19000
                    ],
                    [
                      22800,
                      19000
                    ],
                    [
                      24000,
                      19000
                    ]
                  ]
                }
              ]
            },
            "scenarioData": [
              {
                "spendLever": "Fresh & Strong Media",
                "isParent": true,
                "spend": "1054",
                "modelOutput": "60",
                "margin": "20",
                "profit": "1198",
                "roiCalculation": " 1.14",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "1054",
                "simulateOutput": "60",
                "simulateOutputMargin": "20",
                "simulateOutputProfit": "1198",
                "simulateOutputRoiCalculation": "1.14"
              },
              {
                "spendLever": "Digital",
                "isParent": false,
                "spend": "164",
                "modelOutput": "10",
                "margin": "20",
                "profit": "200",
                "roiCalculation": "1.22",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "164",
                "simulateOutput": "10",
                "simulateOutputMargin": "20",
                "simulateOutputProfit": "200",
                "simulateOutputRoiCalculation": "1.22"
              },
              {
                "spendLever": "Non-Digital",
                "isParent": false,
                "spend": "890",
                "modelOutput": "50",
                "margin": "20",
                "profit": "998",
                "roiCalculation": "1.12",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "890",
                "simulateOutput": "50",
                "simulateOutputMargin": "20",
                "simulateOutputProfit": "998",
                "simulateOutputRoiCalculation": "1.12"
              },
              {
                "spendLever": "Fresh & Strong Trade",
                "isParent": true,
                "spend": "182",
                "modelOutput": "31",
                "margin": "20",
                "profit": "611",
                "roiCalculation": "3.35",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "182",
                "simulateOutput": "31",
                "simulateOutputMargin": "20",
                "simulateOutputProfit": "611",
                "simulateOutputRoiCalculation": "3.35"
              },
              {
                "spendLever": "Fresh & Strong Sampling",
                "isParent": true,
                "spend": "0",
                "modelOutput": "0",
                "margin": "5395",
                "profit": "3050",
                "roiCalculation": "",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "182",
                "simulateOutput": "0",
                "simulateOutputMargin": "20",
                "simulateOutputProfit": "0",
                "simulateOutputRoiCalculation": ""
              },
              {
                "spendLever": "Herbals Media",
                "isParent": true,
                "spend": "849",
                "modelOutput": "38",
                "margin": "25",
                "profit": "944",
                "roiCalculation": "1.11",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "849",
                "simulateOutput": "38",
                "simulateOutputMargin": "25",
                "simulateOutputProfit": "944",
                "simulateOutputRoiCalculation": "1.11"
              },
              {
                "spendLever": "Digital",
                "isParent": false,
                "spend": "414",
                "modelOutput": "2345",
                "margin": "25",
                "profit": "431",
                "roiCalculation": "1.04",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "414",
                "simulateOutput": "38",
                "simulateOutputMargin": "25",
                "simulateOutputProfit": "431",
                "simulateOutputRoiCalculation": "1.04"
              },
              {
                "spendLever": "Non-Digital",
                "isParent": false,
                "spend": "435",
                "modelOutput": "21",
                "margin": "25",
                "profit": "513",
                "roiCalculation": "1.18",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "435",
                "simulateOutput": "21",
                "simulateOutputMargin": "25",
                "simulateOutputProfit": "513",
                "simulateOutputRoiCalculation": "1.18"
              },
              {
                "spendLever": "Herbals Trade",
                "isParent": true,
                "spend": "429",
                "modelOutput": "14",
                "margin": "25",
                "profit": "353",
                "roiCalculation": "0.82",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "0.00%",
                "simulateOutput": "14",
                "simulateOutputMargin": "25",
                "simulateOutputProfit": "353",
                "simulateOutputRoiCalculation": "0.82"
              },
              {
                "spendLever": "Herbals Sampling",
                "isParent": true,
                "spend": "22",
                "modelOutput": "0",
                "margin": "25",
                "profit": "9",
                "roiCalculation": "0.40",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "22",
                "simulateOutput": "0",
                "simulateOutputMargin": "25",
                "simulateOutputProfit": "9",
                "simulateOutputRoiCalculation": "0.40"
              },
              {
                "spendLever": "Pro Relief Media",
                "isParent": true,
                "spend": "2398",
                "modelOutput": "178",
                "margin": "15",
                "profit": "2673",
                "roiCalculation": "1.11",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "2398",
                "simulateOutput": "178",
                "simulateOutputMargin": "15",
                "simulateOutputProfit": "2673",
                "simulateOutputRoiCalculation": "1.11"
              },
              {
                "spendLever": "Digital",
                "isParent": false,
                "spend": "336",
                "modelOutput": "35",
                "margin": "15",
                "profit": "521",
                "roiCalculation": "1.55",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "336",
                "simulateOutput": "35",
                "simulateOutputMargin": "15",
                "simulateOutputProfit": "521",
                "simulateOutputRoiCalculation": "1.55"
              },
              {
                "spendLever": "Non-Digital",
                "isParent": false,
                "spend": "2062",
                "modelOutput": "143",
                "margin": "15",
                "profit": "2152",
                "roiCalculation": "1.04",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "2062",
                "simulateOutput": "143",
                "simulateOutputMargin": "15",
                "simulateOutputProfit": "2152",
                "simulateOutputRoiCalculation": "1.04"
              },
              {
                "spendLever": "Pro Relief Trade",
                "isParent": true,
                "spend": "496",
                "modelOutput": "86",
                "margin": "15",
                "profit": "1284",
                "roiCalculation": "2.59",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "0.00%",
                "simulateOutput": "86",
                "simulateOutputMargin": "15",
                "simulateOutputProfit": "1284",
                "simulateOutputRoiCalculation": "2.59"
              },
              {
                "spendLever": "Pro Relief Sampling",
                "isParent": true,
                "spend": "85",
                "modelOutput": "2",
                "margin": "15",
                "profit": "24",
                "roiCalculation": "0.28",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "newSpend": "85",
                "simulateOutput": "2",
                "simulateOutputMargin": "15",
                "simulateOutputProfit": "24",
                "simulateOutputRoiCalculation": "0.28"
              },
              {
                "spendLever": "Media Total",
                "isParent": true,
                "spend": "4301",
                "output": "276",
                "margin": "",
                "profit": "4815",
                "roiCalculation": "1.12",
               "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "simulateNewSpend": "4301",
                "simulateModelOutput": "276",
                "simulateMargin": "",
                "simulateProfit": "4815",
                "simulateRoiCalculation": "1.12"
              },
              {
                "spendLever": "Media Digital Total",
                "isParent": false,
                "spend": "914",
                "output": "62",
                "margin": "",
                "profit": "1152",
                "roiCalculation": "1.27",
 		"changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "simulateNewSpend": "914",
                "simulateModelOutput": "62",
                "simulateMargin": "",
                "simulateProfit": "1152",
                "simulateRoiCalculation": "1.27"
              },
              {
                "spendLever": "Media Non-Digital Total",
                "isParent": false,
                "spend": "3387",
                "output": "130",
                "margin": "",
                "profit": "3663",
                "roiCalculation": "1.11",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "simulateNewSpend": "3387",
                "simulateModelOutput": "130",
                "simulateMargin": "",
                "simulateProfit": "3663",
                "simulateMoiCalculation": "1.11"
              },
              {
                "spendLever": "Media Trade Total",
                "isParent": true,
                "spend": "1108",
                "output": "130",
                "margin": "",
                "profit": "2247",
                "roiCalculation": "2.25",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "simulateNewSpend": "1108",
                "simulateModelOutput": "130",
                "simulateMargin": "",
                "simulateProfit": "2247",
                "simulateRoiCalculation": "2.25"
              },
              {
                "spendLever": "Media Sampling Total",
                "isParent": true,
                "spend": "45",
                "output": "2",
                "margin": "",
                "profit": "33",
                "roiCalculation": "1.20",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "simulateNewSpend": "45",
                "simulateModelOutput": "2",
                "simulateMargin": "",
                "simulateProfit": "33",
                "simulateRoiCalculation": "1.20"
              },
              {
                "spendLever": "Total",
                "isParent": true,
                "spend": "5453",
                "output": "408",
                "margin": "",
                "profit": "7096",
                "roiCalculation": "1.20",
                 "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "simulateNewSpend": "5453",
                "simulateModelOutput": "408",
                "simulateMargin": "",
                "simulateProfit": "7096",
                "simulateRoiCalculation": " 1.20"
              },
              {
                "spendLever": "% Changes",
                "isParent": true,
                "spend": "",
                "output": "",
                "margin": "",
                "profit": "",
                "roiCalculation": "",
                "changeSpendBy": "0",
                "changeSpendByPercent": "0",
                "simulateNewSpend": "0.00%",
                "simulateModelOutput": "0.00%",
                "simulateMargin": "0.00%",
                "simulateProfit": "0.00%",
                "simulateRoiCalculation": "0.00%"
              }
            ],
            "chartData": [
              {
                "name": "Incremental Sales",
                "data": [
                  {
                    "name": "Current",
                    "y": 1808,
                    "color": "#226e94"
                  },
                  {
                    "name": "Media",
                    "y": 0,
                    "color": "#188fff"
                  },
                  {
                    "name": "Trade",
                    "y": 0,
                    "color": "#ffdc48"
                  },
                  {
                    "name": "Sampling",
                    "y": 0,
                    "color": "#00c073"
                  },
                  {
                    "name": "New",
                    "isSum": true,
                    "color": "#00c073"
                  }
                ]
              },
              {
                "name": "ROI MAC",
                "data": [
                  1.14,
                  3.35,
                  0
                ]
              },
              {
                "name": "Share Of Spend",
                "data": [
                  {
                    "name": "Sampling",
                    "data": [
                      0,
                      0
                    ]
                  },
                  {
                    "name": "Trade",
                    "data": [
                      15,
                      15
                    ]
                  },
                  {
                    "name": "Media",
                    "data": [
                      85,
                      85
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
  public changedBudgetData = [
    {
      "brandName": "Fresh & Strong",
      "brandId": 1,
      "scenarioName": "Budget -10%",
      "budgetChange": "-10",
      "scenarioId": "4",
      "changeBy": "budget",
      "scenario": [
        {
          "optimizedOutput": [
            {
              "spendLever": "Fresh & Strong Media",
              "isParent": true,
              "optimizedNewSpend": "1008",
              "optimizedModelOutput": "59",
              "optimizedMargin": "20",
              "optimizedProfit": "1176",
              "optimizedRoiCalculation": "1.17",
              "changeInSpend": "-4.3%",
              "changeInIncrementalSale": "-2.9%",
              "min": "",
              "minSpend": "",
              "max": "",
              "maxSpend": ""
            },
            {
              "spendLever": "Digital",
              "isParent": false,
              "optimizedNewSpend": "177",
              "optimizedModelOutput": "11",
              "optimizedMargin": "20",
              "optimizedProfit": "212",
              "optimizedRoiCalculation": "1.20",
              "changeInSpend": "8.0%",
              "changeInIncrementalSale": "7.3%",
              "min": "-20",
              "minSpend": "118",
              "max": "20",
              "maxSpend": "177"
            },
            {
              "spendLever": "Non-Digital",
              "isParent": false,
              "optimizedNewSpend": "831",
              "optimizedModelOutput": "48",
              "optimizedMargin": "20",
              "optimizedProfit": "964",
              "optimizedRoiCalculation": "1.16",
              "changeInSpend": "-6.6%",
              "changeInIncrementalSale": "-5.8%",
              "min": "-20",
              "minSpend": "641",
              "max": "20",
              "maxSpend": "961"
            },
            {
              "spendLever": "Fresh & Strong Trade",
              "isParent": true,
              "optimizedNewSpend": "197",
              "optimizedModelOutput": "32",
              "optimizedMargin": "20",
              "optimizedProfit": "633",
              "optimizedRoiCalculation": "3.21",
              "changeInSpend": "8.0%",
              "changeInIncrementalSale": "6.4%",
              "min": "-20",
              "minSpend": "131",
              "max": "20",
              "maxSpend": "197"
            },
            {
              "spendLever": "Fresh & Strong Sampling",
              "isParent": true,
              "optimizedNewSpend": "0",
              "optimizedModelOutput": "0",
              "optimizedMargin": "20",
              "optimizedProfit": "0",
              "optimizedRoiCalculation": "",
              "changeInSpend": "",
              "changeInIncrementalSale": "",
              "min": "-20",
              "minSpend": "0",
              "max": "20",
              "maxSpend": "0"
            },
            {
              "spendLever": "Total",
              "isParent": false,
              "optimizedNewSpend": "1205",
              "optimizedModelOutput": "90",
              "optimizedMargin": "",
              "optimizedProfit": "1809",
              "optimizedRoiCalculation": "1.50",
              "changeInSpend": "",
              "changeInIncrementalSale": "",
              "min": "",
              "minSpend": "",
              "max": "",
              "maxSpend": ""
            },
            {
              "spendLever": "% Changes",
              "isParent": false,
              "optimizedNewSpend": "-2.52%",
              "optimizedModelOutput": "0.00%",
              "optimizedMargin": "",
              "optimizedProfit": "0.01%",
              "optimizedRoiCalculation": "2.60%",
              "changeInSpend": "",
              "changeInIncrementalSale": "",
              "min": "",
              "minSpend": "",
              "max": "",
              "maxSpend": ""
            }
          ]
        }
      ],
      "mediaResponseCurve": {
        "name": "Media Response Curve",
        "data": [
          {
            "name": "Media Response Curve",
            "data": [
              [
                1200,
                2500
              ],
              [
                2400,
                5000
              ],
              [
                3600,
                7500
              ],
              [
                4800,
                10000
              ],
              [
                6000,
                12500
              ],
              [
                7200,
                15000
              ],
              [
                8400,
                16000
              ],
              [
                9600,
                16500
              ],
              [
                10800,
                17000
              ],
              [
                12000,
                17500
              ],
              [
                13200,
                18000
              ],
              [
                14400,
                18500
              ],
              [
                15600,
                19000
              ],
              [
                16800,
                19000
              ],
              [
                18000,
                19000
              ],
              [
                19200,
                19000
              ],
              [
                20400,
                19000
              ],
              [
                21600,
                19000
              ],
              [
                22800,
                19000
              ],
              [
                24000,
                19000
              ]
            ]
          }
        ]
      },
      "chartData": [
        {
          "name": "Incremental Sales",
          "data": [
            {
              "name": "Current",
              "y": 1808,
              "color": "#226e94"
            },
            {
              "name": "Media",
              "y": -22,
              "color": "#188fff"
            },
            {
              "name": "Trade",
              "y": 22,
              "color": "#ffdc48"
            },
            {
              "name": "Sampling",
              "y": 0,
              "color": "#00c073"
            },
            {
              "name": "New",
              "y": 1808,
              "isSum": true,
              "color": "#00c073"
            }
          ]
        },
        {
          "chartName": "ROI MAC",
          "data": [
            1.17,
            3.21,
            0
          ]
        },
        {
          "name": "Share Of Spend",
          "data": [
            {
              "name": "Sampling",
              "data": [
                0,
                0
              ]
            },
            {
              "name": "Trade",
              "data": [
                15,
                16
              ]
            },
            {
              "name": "Media",
              "data": [
                85,
                84
              ]
            }
          ]
        }
      ]
    },
    {
      "brandName": "Fresh & Strong",
      "brandId": 1,
      "scenarioName": "Budget +10%",
      "budgetChange": "10",
      "scenarioId": "9",
      "changeBy": "budget",
      "scenario": [
        {
          "optimizedOutput": [
            {
              "spendLever": "Fresh & Strong Media",
              "isParent": true,
              "optimizedNewSpend": "1119",
              "optimizedModelOutput": "63",
              "optimizedMargin": "20",
              "optimizedProfit": "1254",
              "optimizedRoiCalculation": "1.12",
              "changeInSpend": "6.23%",
              "changeInIncrementalSale": "7.4%",
              "min": "",
              "minSpend": "",
              "max": "",
              "maxSpend": ""
            },
            {
              "spendLever": "Digital",
              "isParent": false,
              "optimizedNewSpend": "217",
              "optimizedModelOutput": "12",
              "optimizedMargin": "20",
              "optimizedProfit": "248",
              "optimizedRoiCalculation": "1.15",
              "changeInSpend": "32.0%",
              "changeInIncrementalSale": "28.7%",
              "min": "-20",
              "minSpend": "144",
              "max": "20",
              "maxSpend": "217"
            },
            {
              "spendLever": "Non-Digital",
              "isParent": false,
              "optimizedNewSpend": "902",
              "optimizedModelOutput": "50",
              "optimizedMargin": "20",
              "optimizedProfit": "1005",
              "optimizedRoiCalculation": "1.11",
              "changeInSpend": "1.4%",
              "changeInIncrementalSale": "1.2%",
              "min": "-20",
              "minSpend": "783",
              "max": "20",
              "maxSpend": "1174"
            },
            {
              "spendLever": "Fresh & Strong Trade",
              "isParent": true,
              "optimizedNewSpend": "241",
              "optimizedModelOutput": "35",
              "optimizedMargin": "20",
              "optimizedProfit": "695",
              "optimizedRoiCalculation": "2.89",
              "changeInSpend": "32.0%",
              "changeInIncrementalSale": "24.4%",
              "min": "-20",
              "minSpend": "160",
              "max": "20",
              "maxSpend": "241"
            },
            {
              "spendLever": "Fresh & Strong Sampling",
              "isParent": true,
              "optimizedNewSpend": "0",
              "optimizedModelOutput": "0",
              "optimizedMargin": "20",
              "optimizedProfit": "0",
              "optimizedRoiCalculation": "",
              "changeInSpend": "",
              "changeInIncrementalSale": "",
              "min": "-20",
              "minSpend": "0",
              "max": "20",
              "maxSpend": "0"
            },
            {
              "spendLever": "Total",
              "isParent": false,
              "optimizedNewSpend": "1360",
              "optimizedModelOutput": "97",
              "optimizedMargin": "",
              "optimizedProfit": "1948",
              "optimizedRoiCalculation": "1.43",
              "changeInSpend": "",
              "changeInIncrementalSale": "",
              "min": "",
              "minSpend": "",
              "max": "",
              "maxSpend": ""
            },
            {
              "spendLever": "% Changes",
              "isParent": false,
              "optimizedNewSpend": "10%",
              "optimizedModelOutput": "7.78%",
              "optimizedMargin": "",
              "optimizedProfit": "7.74%",
              "optimizedRoiCalculation": "-2.05%",
              "changeInSpend": "",
              "changeInIncrementalSale": "",
              "min": "",
              "minSpend": "",
              "max": "",
              "maxSpend": ""
            }
          ]
        }
      ],
      "mediaResponseCurve": {
        "name": "Media Response Curve",
        "data": [
          {
            "name": "Media Response Curve",
            "data": [
              [
                1200,
                2500
              ],
              [
                2400,
                5000
              ],
              [
                3600,
                7500
              ],
              [
                4800,
                10000
              ],
              [
                6000,
                12500
              ],
              [
                7200,
                15000
              ],
              [
                8400,
                16000
              ],
              [
                9600,
                16500
              ],
              [
                10800,
                17000
              ],
              [
                12000,
                17500
              ],
              [
                13200,
                18000
              ],
              [
                14400,
                18500
              ],
              [
                15600,
                19000
              ],
              [
                16800,
                19000
              ],
              [
                18000,
                19000
              ],
              [
                19200,
                19000
              ],
              [
                20400,
                19000
              ],
              [
                21600,
                19000
              ],
              [
                22800,
                19000
              ],
              [
                24000,
                19000
              ]
            ]
          }
        ]
      },
      "chartData": [
        {
          "name": "Incremental Sales",
          "data": [
            {
              "name": "Current",
              "y": 1808,
              "color": "#226e94"
            },
            {
              "name": "Media",
              "y": 56,
              "color": "#188fff"
            },
            {
              "name": "Trade",
              "y": 84,
              "color": "#ffdc48"
            },
            {
              "name": "Sampling",
              "y": 0,
              "color": "#00c073"
            },
            {
              "name": "New",
              "y": 1948,
              "isSum": true,
              "color": "#00c073"
            }
          ]
        },
        {
          "name": "ROI MAC",
          "data": [
            1.12,
            2.89,
            0
          ]
        },
        {
          "name": "Share Of Spend",
          "data": [
            {
              "name": "Sampling",
              "data": [
                0,
                0
              ]
            },
            {
              "name": "Trade",
              "data": [
                15,
                18
              ]
            },
            {
              "name": "Media",
              "data": [
                85,
                82
              ]
            }
          ]
        }
      ]
    },
    {
      "brandName": "Pro Relief",
      "brandId": 3,
      "scenarioName": "Budget +20",
      "budgetChange": "20",
      "scenarioId": "7",
      "changeBy": "budget",
      "scenario": [
        {
          "optimizedOutput": [
            {
              "spendLever": "Pro Relief Media",
              "isParent": true,
              "optimizedNewSpend": "2779",
              "optimizedModelOutput": "196",
              "optimizedMargin": "15",
              "optimizedProfit": "2933",
              "optimizedRoiCalculation": "1.06",
              "changeInSpend": "15.9%",
              "changeInIncrementalSale": "15.9%",
              "min": "",
              "minSpend": "",
              "max": "",
              "maxSpend": ""
            },
            {
              "spendLever": "Digital",
              "isParent": false,
              "optimizedNewSpend": "484",
              "optimizedModelOutput": "43",
              "optimizedMargin": "15",
              "optimizedProfit": "646",
              "optimizedRoiCalculation": "1.33",
              "changeInSpend": "44.0%",
              "changeInIncrementalSale": "37.1%",
              "min": "-20",
              "minSpend": "323",
              "max": "20",
              "maxSpend": "484"
            },
            {
              "spendLever": "Non-Digital",
              "isParent": false,
              "optimizedNewSpend": "2294",
              "optimizedModelOutput": "152",
              "optimizedMargin": "15",
              "optimizedProfit": "2287",
              "optimizedRoiCalculation": "1",
              "changeInSpend": "11.3%",
              "changeInIncrementalSale": "10.4%",
              "min": "-20",
              "minSpend": "1979",
              "max": "20",
              "maxSpend": "2969"
            },
            {
              "spendLever": "Pro Relief Trade",
              "isParent": true,
              "optimizedNewSpend": "714",
              "optimizedModelOutput": "101",
              "optimizedMargin": "15",
              "optimizedProfit": "1518",
              "optimizedRoiCalculation": "2.12",
              "changeInSpend": "44.0%",
              "changeInIncrementalSale": "34%",
              "min": "-20",
              "minSpend": "476",
              "max": "20",
              "maxSpend": "714"
            },
            {
              "spendLever": "Pro Relief Sampling",
              "isParent": true,
              "optimizedNewSpend": "81",
              "optimizedModelOutput": "2",
              "optimizedMargin": "15",
              "optimizedProfit": "24",
              "optimizedRoiCalculation": "0.29",
              "changeInSpend": "-4.0%",
              "changeInIncrementalSale": "-3.9%",
              "min": "-20",
              "minSpend": "81",
              "max": "20",
              "maxSpend": "122"
            },
            {
              "spendLever": "Total",
              "isParent": false,
              "optimizedNewSpend": "3574",
              "optimizedModelOutput": "494",
              "optimizedMargin": "",
              "optimizedProfit": "4474",
              "optimizedRoiCalculation": "1.25",
              "changeInSpend": "",
              "changeInIncrementalSale": "",
              "min": "",
              "minSpend": "",
              "max": "",
              "maxSpend": ""
            },
            {
              "spendLever": "% Changes",
              "isParent": false,
              "optimizedNewSpend": "20",
              "optimizedModelOutput": "86.42%",
              "optimizedMargin": "",
              "optimizedProfit": "12.38%",
              "optimizedRoiCalculation": "-6.35%",
              "changeInSpend": "",
              "changeInIncrementalSale": "",
              "min": "",
              "minSpend": "",
              "max": "",
              "maxSpend": ""
            }
          ]
        }
      ],
      "mediaResponseCurve": {
        "chartName": "Media Response Curve",
        "data": [
          {
            "name": "Weekly Spends",
            "data": [
              [
                1200,
                2500
              ],
              [
                2400,
                5000
              ],
              [
                3600,
                7500
              ],
              [
                4800,
                10000
              ],
              [
                6000,
                12500
              ],
              [
                7200,
                15000
              ],
              [
                8400,
                16000
              ],
              [
                9600,
                16500
              ],
              [
                10800,
                17000
              ],
              [
                12000,
                17500
              ],
              [
                13200,
                18000
              ],
              [
                14400,
                18500
              ],
              [
                15600,
                19000
              ],
              [
                16800,
                19000
              ],
              [
                18000,
                19000
              ],
              [
                19200,
                19000
              ],
              [
                20400,
                19000
              ],
              [
                21600,
                19000
              ],
              [
                22800,
                19000
              ],
              [
                24000,
                19000
              ]
            ]
          }
        ]
      },
      "chartData": [
        {
          "name": "Incremental Sales",
          "data": [
            {
              "name": "Current",
              "y": 3981,
              "color": "#226e94"
            },
            {
              "name": "Media",
              "y": 260,
              "color": "#188fff"
            },
            {
              "name": "Trade",
              "y": 234,
              "color": "#ffdc48"
            },
            {
              "name": "Sampling",
              "y": 0,
              "color": "#00c073"
            },
            {
              "name": "New",
              "y": 4475,
              "isSum": true,
              "color": "#00c073"
            }
          ]
        },
        {
          "name": "ROI MAC",
          "data": [
            1.06,
            2.12,
            0.29
          ]
        },
        {
          "name": "Share Of Spend",
          "data": [
            {
              "name": "Sampling",
              "data": [
                3,
                2
              ]
            },
            {
              "name": "Trade",
              "data": [
                17,
                20
              ]
            },
            {
              "name": "Media",
              "data": [
                80,
                78
              ]
            }
          ]
        }
      ]
    }
  ]
  public changeBudgetChartData = {
    "data": [
      {
        "brandName": "Kitekat",
        "brandId": 1,
        "mediaResponseCurve": {
          "chartName": "Media Response Curve",
          "data": [
            {
              "name": "Media Response Curve",
              "data": [
                [
                  1200,
                  2500
                ],
                [
                  2400,
                  5000
                ],
                [
                  3600,
                  7500
                ],
                [
                  4800,
                  10000
                ],
                [
                  6000,
                  12500
                ],
                [
                  7200,
                  15000
                ],
                [
                  8400,
                  16000
                ],
                [
                  9600,
                  16500
                ],
                [
                  10800,
                  17000
                ],
                [
                  12000,
                  17500
                ],
                [
                  13200,
                  18000
                ],
                [
                  14400,
                  18500
                ],
                [
                  15600,
                  19000
                ],
                [
                  16800,
                  19000
                ],
                [
                  18000,
                  19000
                ],
                [
                  19200,
                  19000
                ],
                [
                  20400,
                  19000
                ],
                [
                  21600,
                  19000
                ],
                [
                  22800,
                  19000
                ],
                [
                  24000,
                  19000
                ]
              ]
            }
          ]
        },
        "scenario": [
          {
            "scenarioName": "",
            "scenarioChange": "10",
            "scenarioId": 1,
            "chartData": [
              {
                "name": "Incremental Sales",
                "data": [
                  {
                    "name": "Current",
                    "y": 1808,
                    "color": "#226e94"
                  },
                  {
                    "name": "Media",
                    "y": 56,
                    "color": "#188fff"
                  },
                  {
                    "name": "Trade",
                    "y": 84,
                    "color": "#ffdc48"
                  },
                  {
                    "name": "Sampling",
                    "y": 0,
                    "color": "#00c073"
                  },
                  {
                    "name": "New",
                    "y":1948,
                    "isSum": true,
                    "color": "#00c073"
                  }
                ]
              },
              {
                "name": "ROI MAC",
                "data": [
                  1.12,
                  2.89,
                  0
                ]
              },
              {
                "name": "Share Of Spend",
                "data": [
                  {
                    "name": "Sampling",
                    "data": [
                      0,
                      0
                    ]
                  },
                  {
                    "name": "Trade",
                    "data": [
                      15,
                      18
                    ]
                  },
                  {
                    "name": "Media",
                    "data": [
                      85,
                      82
                    ]
                  }
                ]
              }
            ]
          },
          {
            "scenarioName": "",
            "scenarioChange": "-10",
            "scenarioId": 1,
            "chartData": [
              {
                "name": "Incremental Sales",
                "data": [
                  {
                    "name": "Current",
                    "y": 1808,
                    "color": "#226e94"
                  },
                  {
                    "name": "Media",
                    "y": -22,
                    "color": "#188fff"
                  },
                  {
                    "name": "Trade",
                    "y": 22,
                    "color": "#ffdc48"
                  },
                  {
                    "name": "Sampling",
                    "y": 0,
                    "color": "#00c073"
                  },
                  {
                    "name": "New",
                    "y": 1808,
                    "isSum": true,
                    "color": "#00c073"
                  }
                ]
              },
              {
                "chartName": "ROI MAC",
                "data": [
                  1.17,
                  3.21,
                  0
                ]
              },
              {
                "name": "Share Of Spend",
                "data": [
                  {
                    "name": "Sampling",
                    "data": [
                      0,
                      0
                    ]
                  },
                  {
                    "name": "Trade",
                    "data": [
                      15,
                      16
                    ]
                  },
                  {
                    "name": "Media",
                    "data": [
                      85,
                      84
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "brandName": "Pro Relief",
        "brandId": 3,
        "mediaResponseCurve": {
          "chartName": "Media Response Curve",
          "data": [
            {
              "name": "Weekly Spends",
              "data": [
                [
                  1200,
                  2500
                ],
                [
                  2400,
                  5000
                ],
                [
                  3600,
                  7500
                ],
                [
                  4800,
                  10000
                ],
                [
                  6000,
                  12500
                ],
                [
                  7200,
                  15000
                ],
                [
                  8400,
                  16000
                ],
                [
                  9600,
                  16500
                ],
                [
                  10800,
                  17000
                ],
                [
                  12000,
                  17500
                ],
                [
                  13200,
                  18000
                ],
                [
                  14400,
                  18500
                ],
                [
                  15600,
                  19000
                ],
                [
                  16800,
                  19000
                ],
                [
                  18000,
                  19000
                ],
                [
                  19200,
                  19000
                ],
                [
                  20400,
                  19000
                ],
                [
                  21600,
                  19000
                ],
                [
                  22800,
                  19000
                ],
                [
                  24000,
                  19000
                ]
              ]
            }
          ]
        },
        "scenario": [
          {
            "scenarioName": "",
            "scenarioChange": "20",
            "scenarioId": 1,
            "chartData": [
              {
                "name": "Incremental Sales",
                "data": [
                  {
                    "name": "Current",
                    "y": 3981,
                    "color": "#226e94"
                  },
                  {
                    "name": "Media",
                    "y": 260,
                    "color": "#188fff"
                  },
                  {
                    "name": "Trade",
                    "y": 234,
                    "color": "#ffdc48"
                  },
                  {
                    "name": "Sampling",
                    "y": 0,
                    "color": "#00c073"
                  },
                  {
                    "name": "New",
                    "y": 4475,
                    "isSum": true,
                    "color": "#00c073"
                  }
                ]
              },
              {
                "name": "ROI MAC",
                "data": [
                  1.06,
                  2.12,
                  0.29
                ]
              },
              {
                "name": "Share Of Spend",
                "data": [
                  {
                    "name": "Sampling",
                    "data": [
                      3,
                      2
                    ]
                  },
                  {
                    "name": "Trade",
                    "data": [
                      17,
                      20
                    ]
                  },
                  {
                    "name": "Media",
                    "data": [
                      80,
                      78
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }

  public simulateChanges = [
    {
      "brandName": "Herbals",
      "brandId": 2,
      "scenario": [
        {
          "scenarioName": "10%increase-Herbals Toothpaste",
          "scenarioId": null,
          "changeBy": "10",
          "scenarioData": [
            {
              "spendLever": "Herbals Media",
              "isParent": true,
              "spend": "849",
              "modelOutput": "38",
              "margin": "25",
              "profit": "944",
              "roiCalculation": "1.11",
              "changeSpendBy": "",
              "changeSpendByPercent": "",
              "newSpend": "891",
              "simulateOutput": "39",
              "simulateOutputMargin": "25",
              "simulateOutputProfit": "967",
              "simulateOutputRoiCalculation": "1.09"
            },
            {
              "spendLever": "Digital",
              "isParent": false,
              "spend": "414",
              "modelOutput": "17",
              "margin": "25",
              "profit": "431",
              "roiCalculation": "1.04",
              "changeSpendBy": "10",
              "changeSpendByPercent": "10",
              "newSpend": "455",
              "simulateOutput": "18",
              "simulateOutputMargin": "25",
              "simulateOutputProfit": "454",
              "simulateOutputRoiCalculation": "1.00"
            },
            {
              "spendLever": "Non-Digital",
              "isParent": false,
              "spend": "435",
              "modelOutput": "21",
              "margin": "25",
              "profit": "513",
              "roiCalculation": "1.18",
              "changeSpendBy": "0",
              "changeSpendByPercent": "0",
              "newSpend": "435",
              "simulateOutput": "21",
              "simulateOutputMargin": "25",
              "simulateOutputProfit": "513",
              "simulateOutputRoiCalculation": "1.18"
            },
            {
              "spendLever": "Herbals Trade",
              "isParent": true,
              "spend": "429",
              "modelOutput": "14",
              "margin": "25",
              "profit": "353",
              "roiCalculation": "0.82",
              "changeSpendBy": "0",
              "changeSpendByPercent": "0",
              "newSpend": "429",
              "simulateOutput": "14",
              "simulateOutputMargin": "25",
              "simulateOutputProfit": "353",
              "simulateOutputRoiCalculation": "0.82"
            },
            {
              "spendLever": "Herbals Sampling",
              "isParent": true,
              "spend": "22",
              "modelOutput": "0",
              "margin": "25",
              "profit": "9",
              "roiCalculation": "0.40",
              "changeSpendBy": "0",
              "changeSpendByPercent": "0",
              "newSpend": "22",
              "simulateOutput": "0",
              "simulateOutputMargin": "25",
              "simulateOutputProfit": "9",
              "simulateOutputRoiCalculation": "0.40"
            },
            {
              "spendLever": "Total",
              "isParent": false,
              "spend": "1301",
              "modelOutput": "52",
              "margin": "",
              "profit": "1306",
              "roiCalculation": "1.00",
              "changeSpendBy": "",
              "changeSpendByPercent": "",
              "newSpend": "1342",
              "simulateOutput": "53",
              "simulateOutputMargin": "",
              "simulateOutputProfit": "1329",
              "simulateOutputRoiCalculation": "0.99"
            },
            {
              "spendLever": "% Changes",
              "isParent": false,
              "spend": "0.00",
              "modelOutput": "0.00",
              "margin": "",
              "profit": "0.00",
              "roiCalculation": "0.00",
              "changeSpendBy": "",
              "changeSpendByPercent": "",
              "newSpend": "3.15%",
              "simulateOutput": "1.92%",
              "simulateOutputMargin": "",
              "simulateOutputProfit": "1.76%",
              "simulateOutputRoiCalculation": "-1.35%"
            }
          ],
          "chartData": [
            {
              "name": "Incremental Sales",
              "data": [
                {
                  "name": "Current",
                  "y": 1306,
                  "color": "#226e94"
                },
                {
                  "name": "Media",
                  "y": 23,
                  "color": "#188fff"
                },
                {
                  "name": "Trade",
                  "y": 0,
                  "color": "#ffdc48"
                },
                {
                  "name": "Sampling",
                  "y": 0,
                  "color": "#00c073"
                },
                {
                  "name": "New",
                  "y": 1329,
                  "isSum": true,
                  "color": "#00c073"
                }
              ]
            },
            {
              "name": "ROI MAC",
              "data": [
                1.09,
                0.82,
                0.4
              ]
            },
            {
              "name": "Share Of Spend",
              "data": [
                {
                  "name": "Sampling",
                  "data": [
                    2,
                    1.5
                  ]
                },
                {
                  "name": "Trade",
                  "data": [
                    33,
                    32.5
                  ]
                },
                {
                  "name": "Media",
                  "data": [
                    65,
                    66
                  ]
                }
              ]
            }
          ],
          "mediaResponseCurve": {
            "name": "Media Response Curve",
            "data": [
              {
                "name": "Media Response Curve",
                "data": [
                  [
                    1200,
                    2500
                  ],
                  [
                    2400,
                    5000
                  ],
                  [
                    3600,
                    7500
                  ],
                  [
                    4800,
                    10000
                  ],
                  [
                    6000,
                    12500
                  ],
                  [
                    7200,
                    15000
                  ],
                  [
                    8400,
                    16000
                  ],
                  [
                    9600,
                    16500
                  ],
                  [
                    10800,
                    17000
                  ],
                  [
                    12000,
                    17500
                  ],
                  [
                    13200,
                    18000
                  ],
                  [
                    14400,
                    18500
                  ],
                  [
                    15600,
                    19000
                  ],
                  [
                    16800,
                    19000
                  ],
                  [
                    18000,
                    19000
                  ],
                  [
                    19200,
                    19000
                  ],
                  [
                    20400,
                    19000
                  ],
                  [
                    21600,
                    19000
                  ],
                  [
                    22800,
                    19000
                  ],
                  [
                    24000,
                    19000
                  ]
                ]
              }
            ]
          }
        },
        {
          "scenarioName": "Media spend decreased by 15%",
          "changeBy": "-15",
          "scenarioId": 1,
          "scenarioData": [
            {
              "spendLever": "Herbals Media",
              "isParent": true,
              "spend": "849",
              "modelOutput": "38",
              "margin": "25",
              "profit": "944",
              "roiCalculation": "1.11",
              "changeSpendBy": "",
              "changeSpendByPercent": "",
              "newSpend": "849",
              "simulateOutput": "38",
              "simulateOutputMargin": "25",
              "simulateOutputProfit": "944",
              "simulateOutputRoiCalculation": "1.11"
            },
            {
              "spendLever": "Digital",
              "isParent": false,
              "spend": "414",
              "modelOutput": "17",
              "margin": "25",
              "profit": "431",
              "roiCalculation": "1.04",
              "changeSpendBy": "0",
              "changeSpendByPercent": "0",
              "newSpend": "414",
              "simulateOutput": "38",
              "simulateOutputMargin": "25",
              "simulateOutputProfit": "431",
              "simulateOutputRoiCalculation": "1.04"
            },
            {
              "spendLever": "Non-Digital",
              "isParent": false,
              "spend": "435",
              "modelOutput": "21",
              "margin": "25",
              "profit": "513",
              "roiCalculation": "1.18",
              "changeSpendBy": "0",
              "changeSpendByPercent": "0",
              "newSpend": "435",
              "simulateOutput": "21",
              "simulateOutputMargin": "25",
              "simulateOutputProfit": "513",
              "simulateOutputRoiCalculation": "1.18"
            },
            {
              "spendLever": "Herbals Trade",
              "isParent": true,
              "spend": "429",
              "modelOutput": "14",
              "margin": "25",
              "profit": "353",
              "roiCalculation": "0.82",
              "changeSpendBy": "0",
              "changeSpendByPercent": "-15",
              "newSpend": "365",
              "simulateOutput": "17",
              "simulateOutputMargin": "25",
              "simulateOutputProfit": "318",
              "simulateOutputRoiCalculation": "0.87"
            },
            {
              "spendLever": "Herbals Sampling",
              "isParent": true,
              "spend": "22",
              "modelOutput": "0",
              "margin": "25",
              "profit": "9",
              "roiCalculation": "0.40",
              "changeSpendBy": "0",
              "changeSpendByPercent": "0",
              "newSpend": "22",
              "simulateOutput": "0",
              "simulateOutputMargin": "25",
              "simulateOutputProfit": "9",
              "simulateOutputRoiCalculation": "0.40"
            },
            {
              "spendLever": "Total",
              "isParent": false,
              "spend": "1301",
              "modelOutput": "52",
              "margin": "",
              "profit": "1306",
              "roiCalculation": "1.00",
              "changeSpendBy": "",
              "changeSpendByPercent": "",
              "newSpend": "1237",
              "simulateOutput": "50",
              "simulateOutputMargin": "",
              "simulateOutputProfit": "1271",
              "simulateOutputRoiCalculation": "1.03"
            },
            {
              "spendLever": "% Changes",
              "isParent": false,
              "spend": "0.00",
              "modelOutput": "0.00",
              "margin": "",
              "profit": "0.00",
              "roiCalculation": "0.00",
              "changeSpendBy": "",
              "changeSpendByPercent": "",
              "newSpend": "-4.94%",
              "simulateOutput": "-2.9%",
              "simulateOutputMargin": "",
              "simulateOutputProfit": "-2.68%",
              "simulateOutputRoiCalculation": "2.37%"
            }
          ],
          "chartData": [
            {
              "name": "Incremental Sales",
              "data": [
                {
                  "name": "Current",
                  "y": 1306,
                  "color": "#226e94"
                },
                {
                  "name": "Media",
                  "y": 0,
                  "color": "#188fff"
                },
                {
                  "name": "Trade",
                  "y": -35,
                  "color": "#ffdc48"
                },
                {
                  "name": "Sampling",
                  "y": 0,
                  "color": "#00c073"
                },
                {
                  "name": "New",
                  "y": 1271,
                  "isSum": true,
                  "color": "#00c073"
                }
              ]
            },
            {
              "name": "ROI MAC",
              "data": [
                1.11,
                0.87,
                0.4
              ]
            },
            {
              "name": "Share Of Spend",
              "data": [
                {
                  "name": "Sampling",
                  "data": [
                    2,
                    1
                  ]
                },
                {
                  "name": "Trade",
                  "data": [
                    33,
                    30
                  ]
                },
                {
                  "name": "Media",
                  "data": [
                    65,
                    69
                  ]
                }
              ]
            }
          ],
          "mediaResponseCurve": {
            "name": "Media Response Curve",
            "data": [
              {
                "name": "Media Response Curve",
                "data": [
                  [
                    1200,
                    2500
                  ],
                  [
                    2400,
                    5000
                  ],
                  [
                    3600,
                    7500
                  ],
                  [
                    4800,
                    10000
                  ],
                  [
                    6000,
                    12500
                  ],
                  [
                    7200,
                    15000
                  ],
                  [
                    8400,
                    16000
                  ],
                  [
                    9600,
                    16500
                  ],
                  [
                    10800,
                    17000
                  ],
                  [
                    12000,
                    17500
                  ],
                  [
                    13200,
                    18000
                  ],
                  [
                    14400,
                    18500
                  ],
                  [
                    15600,
                    19000
                  ],
                  [
                    16800,
                    19000
                  ],
                  [
                    18000,
                    19000
                  ],
                  [
                    19200,
                    19000
                  ],
                  [
                    20400,
                    19000
                  ],
                  [
                    21600,
                    19000
                  ],
                  [
                    22800,
                    19000
                  ],
                  [
                    24000,
                    19000
                  ]
                ]
              }
            ]
          }
        }
      ]
    }
  ]

  changedOptimalRangeData = {
    "brandName": "Herbals",
    "brandId": 2,
    "scenario": [
      {
        "scenarioName": "Herbals Base Scenario 2",
        "scenarioId": null,
        "mediaResponseCurve": {
          "name": "Media Response Curve",
          "data": [
            {
              "name": "Weekly Spends",
              "data": [
                [
                  1200,
                  2500
                ],
                [
                  2400,
                  5000
                ],
                [
                  3600,
                  7500
                ],
                [
                  4800,
                  10000
                ],
                [
                  6000,
                  12500
                ],
                [
                  7200,
                  15000
                ],
                [
                  8400,
                  16000
                ],
                [
                  9600,
                  16500
                ],
                [
                  10800,
                  17000
                ],
                [
                  12000,
                  17500
                ],
                [
                  13200,
                  18000
                ],
                [
                  14400,
                  18500
                ],
                [
                  15600,
                  19000
                ],
                [
                  16800,
                  19000
                ],
                [
                  18000,
                  19000
                ],
                [
                  19200,
                  19000
                ],
                [
                  20400,
                  19000
                ],
                [
                  21600,
                  19000
                ],
                [
                  22800,
                  19000
                ],
                [
                  24000,
                  19000
                ]
              ]
            }
          ]
        },
        "scenarioData": [
          {
            "spendLever": "Herbals Media",
            "isParent": true,
            "spend": "849",
            "output": "38",
            "margin": "25",
            "profit": "944",
            "roiCalculation": "1.11",
            "min": "",
            "minSpend": "",
            "max": "",
            "maxSpend": "",
            "optimizedNewSpend": "768",
            "optimizedModelOutput": "36",
            "optimizedMargin": "25",
            "optimizedProfit": "901",
            "optimizedRoiCalculation": "1.17",
            "changeInSpend": "-9.60%",
            "changeInIncrementalSale": "-7.00%"
          },
          {
            "spendLever": "Digital",
            "isParent": false,
            "spend": "414",
            "output": "17",
            "margin": "25",
            "profit": "431",
            "roiCalculation": "1.04",
            "min": "-30",
            "minSpend": "290",
            "max": "30",
            "maxSpend": "538",
            "optimizedNewSpend": "290",
            "optimizedModelOutput": "14",
            "optimizedMargin": "25",
            "optimizedProfit": "398",
            "optimizedRoiCalculation": "1.24",
            "changeInSpend": "-30.00%",
            "changeInIncrementalSale": "-26.20"
          },
          {
            "spendLever": "Non-Digital",
            "isParent": false,
            "spend": "435",
            "output": "21",
            "margin": "25",
            "profit": "513",
            "roiCalculation": "1.18",
            "min": "-20",
            "minSpend": "348",
            "max": "20",
            "maxSpend": "522",
            "optimizedNewSpend": "478",
            "optimizedModelOutput": "22",
            "optimizedMargin": "25",
            "optimizedProfit": "543",
            "optimizedRoiCalculation": "1.14",
            "changeInSpend": "9.80%",
            "changeInIncrementalSale": "8.90%"
          },
          {
            "spendLever": "Trade",
            "isParent": true,
            "spend": "429",
            "output": "14",
            "margin": "25",
            "profit": "353",
            "roiCalculation": "0.82",
            "min": "-20",
            "minSpend": "343",
            "max": "20",
            "maxSpend": "515",
            "optimizedNewSpend": "515",
            "optimizedModelOutput": "16",
            "optimizedMargin": "25",
            "optimizedProfit": "399",
            "optimizedRoiCalculation": "0.77",
            "changeInSpend": "20.00%",
            "changeInIncrementalSale": "19.90%"
          },
          {
            "spendLever": "Sampling",
            "isParent": true,
            "spend": "22",
            "output": "0",
            "margin": "25",
            "profit": "9",
            "roiCalculation": "0.4",
            "min": "-20",
            "minSpend": "18",
            "max": "20",
            "maxSpend": "27",
            "optimizedNewSpend": "18",
            "optimizedModelOutput": "0",
            "optimizedMargin": "25",
            "optimizedProfit": "8",
            "optimizedRoiCalculation": "0.45",
            "changeInSpend": "-20.00%",
            "changeInIncrementalSale": "-19.90%"
          },
          {
            "spendLever": "Total",
            "isParent": false,
            "spend": "1301",
            "output": "52",
            "margin": "",
            "profit": "1306",
            "roiCalculation": "1",
            "min": "",
            "minSpend": "",
            "max": "",
            "maxSpend": "",
            "optimizedNewSpend": "1301",
            "optimizedModelOutput": "52",
            "optimizedMargin": "",
            "optimizedProfit": "1308",
            "optimizedRoiCalculation": "1",
            "changeInSpend": "",
            "changeInIncrementalSale": ""
          },
          {
            "spendLever": "% Changes",
            "isParent": false,
            "spend": "",
            "output": "",
            "margin": "",
            "profit": "",
            "roiCalculation": "",
            "min": "",
            "minSpend": "",
            "max": "",
            "maxSpend": "",
            "optimizedNewSpend": "0.00%",
            "optimizedModelOutput": "0.00%",
            "optimizedMargin": "0.00%",
            "optimizedProfit": "0.16%",
            "optimizedRoiCalculation": "0.16%",
            "changeInSpend": "0.00%",
            "changeInIncrementalSale": "0.00%"
          }
        ],
        "chartData": [
          {
            "name": "Incremental Sales",
            "data": [
              {
                "name": "Current",
                "y": 1306,
                "color": "#226e94"
              },
              {
                "name": "Media",
                "y": -43,
                "color": "#188fff"
              },
              {
                "name": "Trade",
                "y": 46,
                "color": "#ffdc48"
              },
              {
                "name": "Sampling",
                "y": -1,
                "color": "#00c073"
              },
              {
                "name": "New",
                "y": 1308,
                "isSum": true,
                "color": "#00c073"
              }
            ]
          },
          {
            "name": "ROI MAC",
            "data": [
              1.17,
              0.77,
              0.45
            ]
          },
          {
            "name": "Share Of Spend",
            "data": [
              {
                "name": "Sampling",
                "data": [
                  2,
                  1
                ]
              },
              {
                "name": "Trade",
                "data": [
                  33,
                  40
                ]
              },
              {
                "name": "Media",
                "data": [
                  65,
                  59
                ]
              }
            ]
          }
        ]
      }
    ]
  }
  constructor(private http: HttpClient, private modalService: NgbModal) { }

  setDataIntoBuffer(data) {
    this.databuffer = data
  }

  getChangedSimulatedData(brand, changes) {
    return of(this.simulateChanges.filter(e => {
      return e.brandId === brand
    })[0]?.scenario.filter(x => {
      return x.changeBy === changes
    })[0])
  };

  setDataForOptimizeScreen() {
    this.localStorage.setItem('optimizeTableData', JSON.stringify(this.optimizeTableData));
  }
  setDataForSimulateScreen() {
    this.localStorage.setItem('simulateTableData', JSON.stringify(this.simulateTableData));
  }


  getBrandList(): Observable<any> {
    return this.http.get<any>('getBrandDetails/');
  }
  getScenarioList(brandId): Observable<any> {
    return this.http.get<any>('getAllScenario/' + brandId + '/');
  }

  public getScenarioName(
      title: string,
      scenarioNamePreview: string,
      btnOkText: string,
      btnCancelText: string
    ): Promise<boolean> {
    //For installing see: https://ng-bootstrap.github.io/#/getting-started
    //For all options see: https://ng-bootstrap.github.io/#/components/modal/api
    const modalRef = this.modalService.open(ScenarioNamePopoverStaticComponent, { centered: true });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.scenarioNamePreview = scenarioNamePreview,
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

  getChangedBudgetData(change, brand) {
    return of(this.changedBudgetData.filter(element => {
      return element.brandId === brand && element.budgetChange === change
    })[0]);
  }

  getChangedChartData(brand) {
    return of(this.changeBudgetChartData.data.filter(element => {
      return element.brandId === brand;
    })[0])
  }

  getOptimalRangeChange() {
    return of(this.changedOptimalRangeData);
  }

  getMockOptimizeTableData() {
    return of(JSON.parse(this.localStorage.getItem('optimizeTableData') || '{}'));
  }

  getMockSimulateData() {
    return of(JSON.parse(this.localStorage.getItem('simulateTableData') || '{}'))
  }

  getChartTemplateJSON(): Observable<any> {
    const incrementalSaleTemplate = this.http.get<any>(`${CHART_TMEPLATE_BASE_URL}${INCREMENTAL_SALE}`);
    const mediaResponseTemplate = this.http.get<any>(`${CHART_TMEPLATE_BASE_URL}${MEDIA_RESPONSE}`);
    const roiMacTemplate = this.http.get<any>(`${CHART_TMEPLATE_BASE_URL}${RIO_MAC}`);
    const shareSpendTemplate = this.http.get<any>(`${CHART_TMEPLATE_BASE_URL}${SHARE_SPEND}`);
    return forkJoin([incrementalSaleTemplate, mediaResponseTemplate, roiMacTemplate, shareSpendTemplate]);
  }

  saveOptimizedBudgetScenario(selectedBrand, selectedScenario, newScenario, scenarioName): Observable<any> {
    let originalData = JSON.parse(this.localStorage.getItem('optimizeTableData') || '{}')
    originalData.data.filter(item => {
      return item.brandId === selectedBrand
    })[0].scenario.push({
      "scenarioName": scenarioName,
      "scenarioId": originalData.data.filter(item => {
        return item.brandId === selectedBrand
      })[0].scenario.slice(-1)[0].scenarioId + 1,
      "chartData": newScenario.chartData,
      "mediaResponseCurve": newScenario.mediaResponseCurve,
      "scenarioData": newScenario.scenarioData
    });
    this.localStorage.setItem('optimizeTableData', JSON.stringify(originalData))
    return of({
      newSenarioId: originalData.data.filter(item => {
        return item.brandId === selectedBrand
      })[0].scenario.slice(-1)[0].scenarioId,
    });
  }

  saveSimulateSpendScenario(selectedBrand, newScenario, scenarioName): Observable<any> {
    let originalData = JSON.parse(this.localStorage.getItem('simulateTableData') || '{}')
    originalData.data.filter(item => {
      return item.brandId === selectedBrand
    })[0].scenario.push({
      "scenarioName": scenarioName,
      "scenarioId": originalData.data.filter(item => {
        return item.brandId === selectedBrand
      })[0].scenario.slice(-1)[0].scenarioId + 1,
      "chartData": newScenario.chartData,
      "mediaResponseCurve": newScenario.mediaResponseCurve,
      "scenarioData": newScenario.scenarioData
    });
    this.localStorage.setItem('simulateTableData', JSON.stringify(originalData))
    return of({
      newSenarioId: originalData.data.filter(item => {
        return item.brandId === selectedBrand
      })[0].scenario.slice(-1)[0].scenarioId,
      newScenarioName: scenarioName
    });
  }


  deleteOptimizeScenario(brandId, scenarioId): Observable<any> {
    let originalData = JSON.parse(this.localStorage.getItem('optimizeTableData') || '{}')
    originalData.data.filter(item => {
      return item.brandId === brandId
    })[0].scenario.splice(JSON.parse(this.localStorage.getItem('optimizeTableData') || '{}').data.filter(item => {
      return item.brandId === brandId
    })[0].scenario.findIndex(el => el.scenarioId === scenarioId), 1);
    this.localStorage.setItem('optimizeTableData', JSON.stringify(originalData))
    return of({ deleted: true })
  }

  deleteSimulateScenario(brandId, scenarioId): Observable<any> {
    let originalData = JSON.parse(this.localStorage.getItem('simulateTableData') || '{}')
    originalData.data.filter(item => {
      return item.brandId === brandId
    })[0].scenario.splice(JSON.parse(this.localStorage.getItem('simulateTableData') || '{}').data.filter(item => {
      return item.brandId === brandId
    })[0].scenario.findIndex(el => el.scenarioId === scenarioId), 1);
    this.localStorage.setItem('simulateTableData', JSON.stringify(originalData))
    return of({ deleted: true })
  }

  setPendingStatus(hasPending) {
    this.localStorage.setItem("hasPending", hasPending)
  }

  getPendingStatus() {
    const pending = this.localStorage.getItem('hasPending');
    return of(pending);
  }

  setGoodToGo(value) {
    this.localStorage.setItem('goodToGo', value)
  }

  getGoodToGo() {
    const goodToGo = this.localStorage.getItem('goodToGo');
    return of(goodToGo);
  }
}
