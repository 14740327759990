import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DemandforecastingService {
  private reloadObject$: BehaviorSubject<any> = new BehaviorSubject(false);
  private selectedSKU$: BehaviorSubject<any> = new BehaviorSubject(null);
  private isSaOPDataLoaded$: BehaviorSubject<any> = new BehaviorSubject(false);
  constructor(private http: HttpClient) { }

  getReloadObject(): Observable<any> {
    return this.reloadObject$.asObservable();
  }

  setReloadObject(reload: any) {
    this.reloadObject$.next(reload);
  }

  getSaOPDataLoadStatus(): Observable<any> {
    return this.isSaOPDataLoaded$.asObservable();
  }

  setSaOPDataLoadStatus(status: boolean) {
    return this.isSaOPDataLoaded$.next(status);
  }


  getSelectedSKU(): Observable<any> {
    return this.selectedSKU$.asObservable();
  }

  setSelectedSKU(sku: any) {
    this.selectedSKU$.next(sku);
  }

  getLastExecutionMonth(): Observable<any> {
    return this.http.get<any>('lastExecutionMonth/')
  }

  getInitialSaOPData(productFamilyId, lastExecutionMonthYear, month, userRole): Observable<any> {
    let endpoint: string;
    if(userRole === "Demand Planner") {
      endpoint = 'demandPlannerPlannings/'
    } else {
      endpoint = 'managerApprovalPlannings/'
    }
    return this.http.get<any>(endpoint + lastExecutionMonthYear + '/' + productFamilyId + '/' + month + '/');
  }

  saveSaOPData(productFamilyId,lastExecutionMonthYear, updatedData, userRole): Observable<any> {
    let endpoint: string;
    if(userRole === "Demand Planner") {
      endpoint = 'demandPlannerPlannings/'
    } else {
      endpoint = 'managerApprovalPlannings/'
    }
    return this.http.post(endpoint + lastExecutionMonthYear + '/' + productFamilyId, updatedData);
  }

  publishSaOPData(productFamilyId,lastExecutionMonthYear, updatedData, userRole): Observable<any> {
    let endpoint: string;
    if(userRole === "Demand Planner") {
      endpoint = 'demandPlannerPlannings/'
    } else {
      endpoint = 'managerApprovalPlannings/'
    }
    return this.http.put(endpoint + lastExecutionMonthYear + '/' + productFamilyId, updatedData);
  }

  getMapChartData(productFamilyId): Observable<any> {
    return this.http.get<any>('managerApprovalMap/' + productFamilyId + '/');
  }

 
}
