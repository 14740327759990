<div class="confirmation-modal-header">
    <h3 class="confirmation-modal-title">{{ title }}</h3>
</div>
<div class="modal-body custom-body-content">
    <h4><img src="../../../../../assets/images/icon-double-tick.png">Uploaded file matches with the required template.
        Do you still want to manually verify?</h4>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-primary btn-sm addcustom" (click)="decline()">{{ btnCancelText
        }}</button>
    <button type="button" class="btn btn-primary btn-sm" (click)="accept()">{{ btnOkText }}</button>
</div>