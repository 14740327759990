import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss'],
})
export class ValidationComponent implements OnInit {
  @Input() selectedTab: any;
  @Input() existingFileInfo: any;
  @Output() amendRulesTab = new EventEmitter<any>();
  parentData: any;
  selectedView = 'bordeaux-details';
  processId: any;
  index: any;
  amendReloadFlag:any;
  workItemId: any;
  amendRules: false;
  payLoad: any;
  constructor() {}

  ngOnInit(): void {

    this.amendReloadFlag = true;
  }
 
  handleAmendRule(event) {
    if (event) {
      this.processId = event.processId ? event.processId : this.processId;
      this.workItemId = event.workItemId ? event.workItemId : this.workItemId;
      this.selectedView = event.selectedView;
      this.amendRules = event.amendRules;
      this.amendRulesTab.next({ amendRulesShow: this.amendRules });
    }
  }

  handlePayload(event) {
    if (event) {
      this.payLoad = event.payLoad ? event.payLoad : this.payLoad;
      this.workItemId = event.workItemId;
      this.selectedView = event.selectedView;
      this.amendRules = event.amendRules;
      this.amendReloadFlag = false; 
      this.amendRulesTab.next({ amendRulesShow: this.amendRules });
    }
  }

  handleAmendRules(event) {
    if (event) {
      this.amendRules = event.amendRules ? event.amendRules : this.amendRules;
      this.amendRulesTab.next({ amendRulesShow: this.amendRules });
    }
  }
}
