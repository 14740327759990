import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CsrRoutingModule } from './csr-routing.module';
import { CsrComponent } from './csr.component';
import { CsrBaseComponent } from './csr-base/csr-base.component';
import { DataTablesModule } from 'angular-datatables';
import { AutoPersonalLinesAComponent } from './auto-personal-lines-a/auto-personal-lines-a.component';
import { AutoPersonalLinesBComponent } from './auto-personal-lines-b/auto-personal-lines-b.component';
import { AutoPersonalLinesCComponent } from './auto-personal-lines-c/auto-personal-lines-c.component';
import { AutoPersonalLinesDComponent } from './auto-personal-lines-d/auto-personal-lines-d.component';
import { AgentComponent } from './agent/agent.component';
import { FormsModule } from '@angular/forms';
import { AgentNewComponent } from './agent-new/agent-new.component';
import { Agent3Component } from './agent3/agent3.component';
import { Agent4Component } from './agent4/agent4.component';
import { Agent5Component } from './agent5/agent5.component';
import { AutoPersonalLinesEComponent } from './auto-personal-lines-e/auto-personal-lines-e.component';
import { AutoPersonalLinesFComponent } from './auto-personal-lines-f/auto-personal-lines-f.component';


@NgModule({
  declarations: [
    CsrComponent,
    CsrBaseComponent,
    AutoPersonalLinesAComponent,
    AutoPersonalLinesBComponent,
    AutoPersonalLinesCComponent,
    AutoPersonalLinesDComponent,
    AgentComponent,
    AgentNewComponent,
    Agent3Component,
    Agent4Component,
    Agent5Component,
    AutoPersonalLinesEComponent,
    AutoPersonalLinesFComponent
  ],
  imports: [
    CommonModule,
    CsrRoutingModule,
    DataTablesModule,
    FormsModule
  ]
})
export class CsrModule { }
