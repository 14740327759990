<!-- <div class="d-flex align-items-center min-vh-100 py-3 py-md-0 admBg">
    <div class="container p-3 login-width">
        <div class="login-card">
            <div class="row no-gutters">
                <div class="col-md-8 col-6">
                    <div class="sk-bar p-logo pl-2"><i aria-hidden="true"></i></div>
                </div>
                <div class="col-md-4 col-6 bg-Dark">
                    <div class="brand-wrapper c-logo text-right p-2">
                    </div>
                </div>
            </div>
            <div class="row no-gutters">
                <div class="col-lg-8 col-sm-12 d-lg-block d-sm-block d-none">
                    <div class="login-banner-bg"></div>
                </div>
                <div class="col-lg-4 col-12">
                    <div class="login-form pt-5">
                        <div class="p-4 mt-5">
                            <h3 class="login-card-description pt-3 pb-3" *ngIf="!loggedIn">Sign in to your account</h3>
                            <h3 class="login-card-description pt-3 pb-3" *ngIf="loggedIn && isNotAuthorized">You are not authorized to view this application. Please contact Admin</h3>
                            <button class="btn btn-block login-btn mb-4 btn-primary btn-sm" *ngIf="!loggedIn"
                                (click)="login()">SIGN IN</button>
                             <button  class="btn btn-block login-btn mb-4 btn-primary btn-sm" *ngIf="loggedIn && isNotAuthorized" (click)="logout()">SIGN OUT</button> 
                        </div>
                    </div>
                </div>
            </div>
            <div class="row no-gutters">
                <div class="col-md-8 col-6">
                    <div class="copyrights p-3">Copyright © 2021 WNS. All rights reserved.</div>
                </div>
                <div class="col-md-4 col-6 bg-Dark">
                    <div class="brand-wrapper w-logo text-right p-2">
                        <i aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="skn-login-body">
<div class="d-flex align-items-center min-vh-100 py-3 py-md-0">
    <div class="container p-3 login-width">
        <div class="login-card login-banner-bg">
            <div class="row no-gutters">
                <div class="col-8">
                    <div class="d-flex align-items-start flex-column mb-3" style="height: 500px;">
                        <div class="sk-bar p-3">
                            <img src="assets/images/uap-logo.png" style="height: 32px;"/>                  
                        </div>
                        <div class="login-hero-msg text-white d-none">
                            <h1>Enterprise-grade Cognitive Data Capture and Processing Platform</h1>
                            <h2>Convert unstructured data into contextualized information for actionable business insights</h2>
                        </div>
                        <div class="mt-auto copyrights p-2">© 2024 WNS.</div>
                      </div>
                </div>
                <div class="col-4 login-wrapper">
                    <div class="brand-wrapper c-logo text-right p-2">
                        <i></i>
                    </div>

                    <div class="login-form p-2">
                        

                        <h3 class="login-card-description pt-2 pb-4" *ngIf="!loggedIn">Sign in to your account</h3>
                            <h3 class="login-card-description pt-2 pb-4" *ngIf="loggedIn && isNotAuthorized">You are not authorized to view this application. Please contact Admin</h3>
                        <p class="mb-3" *ngIf="!loggedIn">Click on the below link to initiate Signin.</p>
                      
                        <button class="w-100 btn btn-primary" *ngIf="!loggedIn"
                        (click)="login()">SSO Signin</button>
                     <button  class="w-100 btn btn-primary" *ngIf="loggedIn && isNotAuthorized" (click)="logout()">SIGN OUT</button> 
                    </div>
                    <div class="brand-wrapper w-logo text-right p-2">
                        <i></i>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</div>
</div>