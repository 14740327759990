import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FileUploadProgressService } from 'src/app/shared/services/file-upload-progress.service';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SharedService } from 'src/app/shared/services/shared.service';

export interface SheetMappingGroup {
  sourceSheetName: string;
  destinationSheetName: string;
  destinationSheetId: number;
}
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sheet-mapping',
  templateUrl: './sheet-mapping.component.html',
  styleUrls: ['./sheet-mapping.component.scss'],
})
export class SheetMappingComponent implements OnInit {
  @Input() fileData: any;
  @Input() isMappingFlow: any;
  @Input() uploadId: any;
  @Input() BDXManagementData: any;
  @Output() nextButtonEvent = new EventEmitter<any>();
  @Output() backButtonEvent = new EventEmitter<any>();
  @Output() setDestinationTemplateDataToParent = new EventEmitter<any>();
  @Output() sheetMappingSucessRespone = new EventEmitter<any>();
  @Output() emitExistingSheetMappingData = new EventEmitter<any>();
  @Output() exitButtonEvent = new EventEmitter<any>();
  sheetsList: any;
  selectedSheet: any;
  destinationSheetList: Array<any> = [];
  selectedDestinationSheetName: any;
  sheetMappingRows: SheetMappingGroup[] = [];
  isValidButton: boolean = false;
  submitClicked: boolean = false;
  isEditFllow: boolean = false;
  submitted = false;
  sheetNumber: any;
  isSubmitted = false;
  sheetMappingForm: FormGroup;
  checkValidation: boolean = false;
  rowCounter = Array;
  exitingSheetMappingData: any;
  sheetIdUpdated: any;
  templateId: number;
  filterObject = {
    fileTypeId: 0,
    coverHolderId: 0,
    formatId: 0,
    yearMonth: 0,
    subFormatId: 0,
    modifiedBy: 0,
    lastModifiedStart: 0,
    lastModifiedEnd: 0,
  };
  constructor(
    private ngxLoader: NgxUiLoaderService,
    private fileUploadProgressService: FileUploadProgressService,
    public toastr: ToastrService,
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private sharedService: SharedService
  ) {
    // Initilize form at constructor lavel
    this.sheetMappingForm = this.fb.group({
      sheets: new FormArray([]),
    });
  }

  /**
   * ngOnInit() - page initilization
   * Construct rows as per the file/sheet to create sheet mappong form
   * @returns - it will call the getdestinationSheetData method
   */

  ngOnInit(): void {
    this.ngxLoader.start();
    this.sheetsList = Object.keys(this.fileData.sheetInfo);
    const sheetRows = [];
    this.sheetsList.forEach((element, i) => {
      const sheetMappingRowsObj = {
        sourceSheetName: '',
        destinationSheetName: '',
        destinationSheetId: null,
        headerStart: 1,
        headerEnd: 1,
      };
      sheetRows.push(sheetMappingRowsObj);
    });
    this.sheetMappingRows = [sheetRows[0]];
    this.selectedSheet = this.sheetsList[0];
    this.getdestinationSheetData();
  }

  /**
   * getting previusly saved data for patyiculer upload ID and map it to the sheet and header selection form
   */
  getSheetMappingData() {
    this.fileUploadProgressService.getSheetMapping(this.uploadId).subscribe((response) => {
        if (response && response.code === 200) {
          this.isEditFllow = true;
          response.data.forEach((element, index) => {
            this.sheetMappingForm.get('sheets')['controls'][index].get('sourceSheetName').patchValue(element.sheetName);
            this.sheetMappingForm.get('sheets')['controls'][index].get('headerStart').patchValue(element.headerStart);
            // Commented as of now because header end selection will be taken later
            this.sheetMappingForm.get('sheets')['controls'][index].get('headerEnd').patchValue(element.headerEnd);
          });
          this.exitingSheetMappingData = response?.data;
        }
        this.ngxLoader.stop();
      });
  }

  // Getter function to return the formArray 'Sheets'
  get sheetMappingFormRows(): FormArray {
    return <FormArray>this.sheetMappingForm.get('sheets');
  }

  /**
   * Initilize formGroup for each rows with initial data
   * @param sheetMappingRowsRef
   */
  createSheetMappingFormRows(sheetMappingRowsRef) {
    const formRows = new FormGroup({});
    const sheetArray = sheetMappingRowsRef.forEach((element, index) => {
      this.sheetMappingFormRows.push(
        this.fb.group({
          templateSheetId: [this.destinationSheetList[index]?.sheetId,[Validators.required]],
          sourceSheetName: ['', [Validators.required]],
          headerStart: ['1', [Validators.required]],
          headerEnd: [''],
        })
      );
    });
    this.getSheetMappingData();
  }

  /**
   * Set destinationSheetName on the for each rows on the
   * selection/change event of destination sheet
   * @param i (Index)
   * @param event (change event)
   */
  destinationSheetIdSelection(i, event) {
    const changedValue = Number((event.target as HTMLInputElement).value.slice(-1));
    (<FormArray>((<FormGroup>this.sheetMappingFormRows.controls[i]).controls['destinationSheetName'])).patchValue(this.destinationSheetList.filter((e) => {
        return e.id === changedValue;
      })[0].sheetName
    );
  }
  /**
   * get destination Sheets list
   * after getting destination sheet list it will call the createSheetMappingFormRows() method and set destination templete to the parent component
   * @returns createSheetMappingFormRows()
   */
  getdestinationSheetData() {
    this.ngxLoader.start();
    this.sharedService.getCurrentTemplateId().subscribe((id) => {
      this.templateId = id;
      this.fileUploadProgressService.getDestinationSheetName(this.templateId).subscribe((response) => {
          if (response && response.code === 200) {
            this.destinationSheetList = response?.data;
            this.sheetIdUpdated = this.destinationSheetList[0].sheetId;
            this.createSheetMappingFormRows(this.sheetMappingRows);
            this.setDestinationTemplateDataToParent.next(this.destinationSheetList);
          }
        });
    });
  }

  /**
   * Save or Save and next method based on the event type
   * @param eventType
   * construct the payload and hit save API
   * if event type is save then just show the success toaster
   * if event type is next then save data and after showing success toater navigate to next screen.
   */
  saveSheetSelection(eventType) {
    if (this.sheetMappingForm.invalid) {
      this.checkValidation = true;
    } else {
      this.ngxLoader.start();
      const payLoad = [];
      this.sheetMappingForm.value.sheets.forEach((element, i) => {
        let dataMapping = {};
        if (this.isEditFllow) {
          dataMapping = {
            templateSheetId: this.sheetIdUpdated,
            sheetName: element.sourceSheetName,
            headerStart: element.headerStart,
            headerEnd: element.headerStart,
            id: this.exitingSheetMappingData[i].id,
            status: eventType === 'save' ? 'Sheet Mapping is saved.' : 'Sheet Mapping is done.',
          };
        } else {
          dataMapping = {
            templateSheetId: this.sheetIdUpdated,
            sheetName: element.sourceSheetName,
            headerStart: element.headerStart,
            headerEnd: element.headerStart,
            status: eventType === 'save' ? 'Sheet Mapping is saved.' : 'Sheet Mapping is done.',
          };
        }
        payLoad.push(dataMapping);
      });
      this.fileUploadProgressService.saveDataSheet(payLoad, this.uploadId).subscribe((response) => {
          if (response && response.code === 200) {
            if (response.data) {
              this.sheetMappingSucessRespone.next(response?.data);
              let sheetInfo = [];
              let sheetRelatedData = [];
              if (this.isEditFllow) {
                sheetRelatedData = this.exitingSheetMappingData;
              } else {
                sheetRelatedData = response.data;
              }
              sheetInfo = sheetRelatedData.map((e) => {
                return {
                  id: e.id,
                  sheetName: e.sheetName,
                  templateSheetId: this.sheetIdUpdated,
                  uploadId: e.uploadId,
                };
              });
              this.emitExistingSheetMappingData.next(sheetInfo);
            }
            if (eventType === 'save') {
              if (this.isEditFllow) {
                this.toastr.success('Sheet selection updated sucessfully');
              } else {
                this.toastr.success('Sheet selection saved sucessfully');
              }
              this.ngxLoader.stopAll();
            } else if (eventType === 'saveAndNext') {
              if (this.isEditFllow) {
                this.toastr.success('Sheet selection updated sucessfully');
              } else {
                this.toastr.success('Sheet selection saved sucessfully');
              }
              this.ngxLoader.stopAll();
              this.nextButtonEvent.next('sheet-mapping');
            }
          }
        });
    }
  }

  // Next button Event
  goToNext(sheetSelectionFormObject) {
    this.saveSheetSelection('saveAndNext');
  }

  // Save button event
  goForSave() {
    this.saveSheetSelection('save');
  }

  // Back button event
  goToBack() {
    this.backButtonEvent.next('sheet-mapping');
  }

  getExited() {
    this.exitButtonEvent.next('exit');
  }

  // Remove Sheet data from the sheet list and form
  removeSheetData(index) {
    if (this.sheetMappingForm.get('sheets')['controls'].length > 1) {
      this.sheetMappingForm.get('sheets')['controls'].splice(index, 1);
    }
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }
}
