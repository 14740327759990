<div class="confirmation-modal-header">
    <h3 class="confirmation-modal-title">{{ modalTagsData.title }}</h3>
  </div>
  <div class="modal-body">
    <p class="form-label">Select Tags</p>
          <ng-select [items]="tagsList" [multiple]="true" [editableSearchTerm]="true" [(ngModel)]="selectedTagList">
          </ng-select>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light btn-sm addcustom" (click)="decline()">{{ modalTagsData.buttonCancelText }}</button>
    <button type="button" class="btn btn-primary btn-sm" (click)="save()" [disabled]="modalTagsData.enabled==false">{{ modalTagsData.buttonSubmitText }}</button>
  </div>