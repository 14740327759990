<ul class="topNav">
  <li><a routerLink="/chat/analysis-list" class="active">Chat Summary</a></li>
  <li><a routerLink="/chat/dashboard">Dashboard</a></li>
</ul>
<div class="data-table-wrapper mt-5 pt-4" aria-describedby="mydesc">
    <div class="col-sm-12">
<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table dataTable no-footer">
    <thead>
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Date</th>
        <th scope="col">Agent Name</th>
        <th scope="col" style="width:140px!important;">Duration</th>
        <th scope="col" style="width:80px!important;">FCR</th>
        <th scope="col"> Consumer Rating</th>
        <th scope="col"> Model Rating</th>
        <th scope="col" style="width:180px!important;">Topics Triggered</th>
       


      </tr>
    </thead>
    <tbody *ngIf="chatListData?.length != 0">
        <tr #trRef *ngFor="let item of chatListData, let index as index">
        <td>
          {{item?.chatTranscriptionId}}
        </td>
        <td>{{item?.chatDate | date: 'dd MMM, yyyy'}}</td>
        <td>{{item?.agent}}</td>
        <td>{{item?.chatDuration}} m</td>
        <td [ngClass]="{'customNo': item?.fcr === true, 'customYes': item?.fcr === false}">
            {{ item?.fcr === true ? 'No' : 'Yes' }}
          </td>
        <td>
            <!-- {{item?.consumer_rating}} -->
            <span *ngFor="let star of [1, 2, 3, 4, 5]"
            [ngClass]="{
              'active-star': star <= item?.consumerRating,
              'inactive-star': star > item?.consumerRating
            }">
          &#9733;
      </span>
        </td>
        <td>
            <!-- {{item?.model_rating}} -->
            <span *ngFor="let star of [1, 2, 3, 4, 5]"
            [ngClass]="{
              'active-star': star <= item?.modelRating,
              'inactive-star': star > item?.modelRating
            }">
          &#9733;
      </span>

        </td>
        <td class="text-center" style="padding-right: 91px;" >{{item?.topicsTriggered}}&nbsp;&nbsp;<span class="view"><a (click)="getChatData(item)"><img src="assets/images/icon-vew.svg"/></a></span>
        </td>

        <!-- <td class="text-center">{{item?.topicsTriggered}}  
          <span class="view"><a (click)="getChatData(item)"><img src="assets/images/eye.png"/></a></span>
      </td> -->

        

      </tr>

    </tbody>
    <tbody *ngIf="chatListData?.length == 0">
      <tr>
        <td colspan="6" class="p-0" class="no-data-available ">No data!</td>
      </tr>
    </tbody>
  </table>

  </div>
  </div>
<router-outlet></router-outlet>