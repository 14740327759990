import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PricingLobService {

  constructor(private http: HttpClient) { }

  /***
  * @desc Get Lobs List API
  * @returns {Object} Returns Get Lobs list API Data
  * 
  */
  getLobsLanding(): Observable<any[]> {
    return this.http.get<any>('lobs/');
  }
}
