import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { ExportToExcelService } from 'src/app/shared/services/export-to-excel.service';
import { ScmMmmStaticService } from '../../scm-mmm-static.service';


@Component({
  selector: 'app-scm-simulate-static',
  templateUrl: './scm-simulate-static.component.html',
  styleUrls: ['./scm-simulate-static.component.scss']
})
export class ScmSimulateStaticComponent implements OnInit {
  simulateListtData: Array<any> = [];
  @Input() scenarioList: any;
  @Input() selectedScenario: any;
  @Input() selectedBrand: any;
  @Input() evaluateSimulateEvents: any;
  @Input() exportSimulateEvents: any;
  @Input() saveSimulateEvents: any;
  @Input() deleteSimulateEvents: any;
  @Input() selectedTab: any;
  localStorage = localStorage
  updateFlag: boolean = false;
  public saveEventsSubscription: Subscription;
  public simulateEventSubscription: Subscription;
  public deleteSimulateEventsSubscription: Subscription;
  public exportSimulateToExcelEventsSubscription: Subscription;
  public scenarioBuffer : Array<any> = [];
  @Output() newSimulateScenarioAdded = new EventEmitter<any>();
  mediaResponseChartTemplate: any;
  incrementalSaleChartTemplate: any;
  roiMacChartTemplate: any;
  shareSpendChartTemplate: any;
  recentChangeIndex: any;
  constructor(private scmMmmStaticService: ScmMmmStaticService, 
              public confirmationPopupService: ConfirmationPopupService,
              public toastr: ToastrService, private ngxLoader: NgxUiLoaderService,
              private exportToExcelService: ExportToExcelService) { }

  ngOnInit(): void {
    this.scmMmmStaticService.setDataForSimulateScreen();
    // Save event Subscription
    this.saveEventsSubscription = this.saveSimulateEvents.subscribe(() => {
      this.saveScenario()
    });
    // Simulate event subscription
    this.simulateEventSubscription = this.evaluateSimulateEvents.subscribe(data => {
      this.simulateChanges()
    });
    // Delete event subscription
    this.deleteSimulateEventsSubscription = this.deleteSimulateEvents.subscribe(() => {
      this.deleteScenario()
    });
    // Export to excel event subcription
    this.exportSimulateToExcelEventsSubscription = this.exportSimulateEvents.subscribe(() => {
      this.exportSimulateScenarioTable()
    });
  }
  ngOnDestroy() {
    this.saveEventsSubscription.unsubscribe();
    this.deleteSimulateEventsSubscription.unsubscribe();
    this.evaluateSimulateEvents.unsubscribe();
    this.exportSimulateToExcelEventsSubscription.unsubscribe();
  }
  ngOnChanges(): void {
    this.scmMmmStaticService.getGoodToGo().subscribe(data => {
      if(data === 'YES') {
        if(this.selectedTab === 'scm-simulate') {
          this.getSimulateMockData();
        }
      }
    });
  }

  /***
   * @desc Function to Get Simulate Table and Chart using local storage
   * Get data from localstorage for getting Simulate Table and Charts Data
   * @returns {Objects} Returns chart and table data set chart data into chart template and show chart and table
   *
   */

  getSimulateMockData() {
    this.ngxLoader.start()
    this.scmMmmStaticService.getChartTemplateJSON().subscribe(chartTemplates => {
      this.incrementalSaleChartTemplate = chartTemplates[0];
      this.mediaResponseChartTemplate = chartTemplates[1];
      this.roiMacChartTemplate = chartTemplates[2];
      this.shareSpendChartTemplate = chartTemplates[3];
      let response = JSON.parse(this.localStorage.getItem('simulateTableData')|| '{}')
      if (response) {
        const filteredResponse = response.data.filter(ele => {
          return ele.brandId === this.selectedBrand;
        })[0].scenario.filter(item => {
          return item.scenarioId === this.selectedScenario;
        })[0];
        this.simulateListtData = filteredResponse.scenarioData;
        this.mediaResponseChartTemplate.series = filteredResponse.mediaResponseCurve.data;
        this.incrementalSaleChartTemplate.series[0].data = filteredResponse.chartData[0].data;
        this.roiMacChartTemplate.series = [filteredResponse.chartData[1]];
        this.shareSpendChartTemplate.series = filteredResponse.chartData[2].data;
        this.updateFlag = true
        this.scmMmmStaticService.setPendingStatus('NO');
        this.ngxLoader.stop();
      }
    });
  }
  
  updateChangeValues(index) {
    this.recentChangeIndex = index;
  }

  /***
   * @desc Function to Get Simulate Table and Chart using local storage for updated budget
   * Get data from localstorage for getting Simulate Table and Charts Data
   * @returns [object] Returns chart and table data set chart data into chart template and show chart and table
   *
   */
  simulateChanges() {
    if(this.selectedBrand !== 2) {
      this.toastr.error("Select correct brand to simulate spend!")
    } else {
      const changedData = this.simulateListtData[this.recentChangeIndex].changeSpendByPercent;
      
      if (!changedData) {
        this.toastr.error('Enter valid changes to simulate spend!');
      } else if(changedData !== '10' && changedData !== '-15' ) {
        this.toastr.error('Enter valid change to simulate the spend!')
      } else {
  
        this.scmMmmStaticService.getChangedSimulatedData(this.selectedBrand, changedData).subscribe(changedDataResponse => {
          const responseData = changedDataResponse;
          if(changedDataResponse) {
            this.ngxLoader.start();
            this.scmMmmStaticService.setPendingStatus('YES');
            this.scmMmmStaticService.getChartTemplateJSON().subscribe(chartTemplates => {
              this.incrementalSaleChartTemplate = chartTemplates[0];
              this.mediaResponseChartTemplate = chartTemplates[1];
              this.roiMacChartTemplate = chartTemplates[2];
              this.shareSpendChartTemplate = chartTemplates[3];
            this.simulateListtData = changedDataResponse.scenarioData;
            this.mediaResponseChartTemplate.series = changedDataResponse.mediaResponseCurve.data;
            this.incrementalSaleChartTemplate.series[0].data = changedDataResponse.chartData[0].data;
            this.roiMacChartTemplate.series = [changedDataResponse.chartData[1]];
            this.shareSpendChartTemplate.series = changedDataResponse.chartData[2].data;
            this.scenarioBuffer['scenarioData'] = this.simulateListtData; 
            this.scenarioBuffer['chartData'] = changedDataResponse.chartData; 
            this.scenarioBuffer['mediaResponseCurve'] = changedDataResponse.mediaResponseCurve; 
            this.ngxLoader.stop();
          })
        }
        })
      }
    }
  }

    /***
   * @desc Function to Save Scenario
   * @param {Array} API Payload 
   * Opens scenario name popup and gets the scenario name to construct final API payload Array to save scenario
   * @returns {Object} Returns API response on success.
  */
  saveScenario() {
    if(!!this.scenarioBuffer['scenarioData'] && this.scenarioBuffer['scenarioData'].length) {
      let proposedScenarioName = '';
      if(isNaN(this.scenarioList.slice().pop().scenarioName.slice(-1))) {
        if(this.scenarioList.slice().pop().scenarioName.slice(-4) === '_New') {
          proposedScenarioName = this.scenarioList.slice().pop().scenarioName + '1'
        } else {
          proposedScenarioName = this.scenarioList.slice().pop().scenarioName + '_New'
        }
      } else {
        let lastCharacter = Number(this.scenarioList.slice().pop().scenarioName.slice(-1)) + 1;
        proposedScenarioName = this.scenarioList.slice().pop().scenarioName.slice(0,-1) + lastCharacter;
      }

      this.scmMmmStaticService.getScenarioName('Scenario', proposedScenarioName , 'Save', 'Cancel').then((scenarioName) => {
        if (scenarioName) {
          this.scmMmmStaticService.saveSimulateSpendScenario(this.selectedBrand, this.scenarioBuffer, scenarioName).subscribe(success => {
            if (success) {
              this.selectedScenario = success.newSenarioId;
            
              this.scmMmmStaticService.setPendingStatus('NO');
              this.newSimulateScenarioAdded.emit({'selectedBrand': this.selectedBrand, 'selectedScenario': this.selectedScenario, 'newScenarioName':success.newScenarioName});
              this.scenarioBuffer = [];
              this.selectedBrand = 1;
              this.selectedScenario = 1;
              this.toastr.success("New scenario saved successfully !");
            }
          });
        } else if (!scenarioName) {
          this.scenarioBuffer = [];
          this.scmMmmStaticService.setPendingStatus('NO');
          this.toastr.info("New Optimization has been discarded!");
        }
      });
    } else {
      this.toastr.error("No change in scenario to save");
    }
  }

  // Delete scenario (Only custom scenarios can be deleted)
  /***
   * @desc Checks for the scenario type and deletes Custome Scenario
   * @param {string} - Selected Scenario ID
   * Call service to delete the scenario
   * @returns {Object} Returns API response.
   */

  deleteScenario(){
    if(this.selectedScenario === 1) {
      this.toastr.error("Base scenario cannot be deleted!")
    } else {
      const selectedScenarioName = this.scenarioList.filter(e => { return e.scenarioId === this.selectedScenario})[0].scenarioName;
      this.confirmationPopupService.confirm('Confirm', 'Are you sure you want to delete scenario' + selectedScenarioName + '?', '', 'Delete', 'Cancel')
        .then((confirmed) => {
        if (confirmed) {
          this.scmMmmStaticService.deleteSimulateScenario(this.selectedBrand, this.selectedScenario).subscribe(data => {
            this.newSimulateScenarioAdded.emit({ 'selectedBrand': this.selectedBrand, 'selectedScenario': 1 });
            this.toastr.success("Scenario deleted successfully!")
          })
        } else if (!confirmed) {

        }
      });
    }
  }

  
 // Export table data to excel.
  /***
   * @desc Handling Export to excel functionality
   * Calles expoertToExcel Service to create Excel file.
   * @returns Exported excel .
   */
  exportSimulateScenarioTable() {
  const selectedScenarioName = this.scenarioList.filter(e => { return e.scenarioId === this.selectedScenario})[0].scenarioName;
    this.exportToExcelService.exportTableToExcel('SimulateTable', selectedScenarioName)
  }

}
