import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PricingManageModelService {

  constructor(private http: HttpClient) { }
  modelListHead() {
    return [
      {
        modelTechnique: 'Model Technique', modelType: 'Model Type', version: 'Version', createdDate: 'Created Date', action: 'Action'
      }
    ]
  }

  versionListHead() {
    return [
      {
        versionNo: 'Version No.', fileName: 'File Name', createdDate: 'Created Date', setDefault: 'Set Default'
      }
    ]
  }

  getModelData(projectId: any): Observable<any> {
    return this.http.get<any[]>('lobProjectModels/' + projectId + "/");
  }

  getModelDataAndEmblemModel(projectId: any): Observable<any> {
    const modelData = this.http.get<any[]>('lobProjectModels/' + projectId + "/");
    const allFilters = this.http.get<any[]>('pricing/allFilters/' + projectId + "/");
    return forkJoin([modelData,allFilters]);
  }

  updateModelVersion(modelObject: any): Observable<any> {
    return this.http.put('lobProjectModels/', modelObject);
  }

  selectOrUpdateModel(modelObject: any): Observable<any> {
    return this.http.put('modelSelection/', modelObject);
  }
}
