<div class="exploratory-metrics">
    <div class="row no-gutters chart-wrapper">
        <div class="col-sm-2 filter-padding">
            <div class="metric-options">
                <div class="metrics-selection-wrapper mb-3" *ngFor="let filter of chartFilters; index as i">
                    <div class="form-group mt-3" *ngIf="filter.filterType === 'modelTechniques'">
                        <h4 class="m-0 mt-2 filter-header">{{filter.title}}</h4>
                        <ng-container *ngFor="let filterOption of filter.data;  index as index">
                            <div class="custom-control custom-checkbox checkbox-margin">
                                <input type="checkbox" id="{{viewName}}-{{filter.filterType}}-{{i}}{{index}}"
                                    name="{{viewName}}-{{filter.filterType}}"
                                    (change)="updateFilterStatus(filterOption,i,filter.filterType)"
                                    class="custom-control-input" [value]="filterOption.name"
                                    [checked]="index === 0 ? true : false" [(ngModel)]="filterOption.isSelected">
                                <label class="custom-control-label checkbox-label-margin"
                                    for="{{viewName}}-{{filter.filterType}}-{{i}}{{index}}">{{filterOption.name}}</label>
                            </div>
                        </ng-container>
                    </div>
                    <div class="form-group mt-3" *ngIf="filter.filterType === 'modelTypes'">
                        <h4 class="m-0 mt-2 filter-header">{{filter.title}}</h4>
                        <select class="custom-select form-control form-control-sm my-1 mr-sm-2 shap-custom-select"
                            id="{{viewName}}-{{filter.filterType}}" [(ngModel)]="modelType"
                            (change)="updateFilterStatus(modelType,i,filter.filterType)">
                            <option *ngFor="let filterOption of filter?.data;  index as index"
                                [value]="filterOption?.id" [selected]="filterOption?.id === 1">{{filterOption?.name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-10">
            <div class="exploratory-metrics" style="height: calc(100vh - 305px); overflow-y: auto; overflow-x: hidden;">
                <div class="row no-gutters">
                    <div class="col-sm-12 display-flex">
                        <ng-container *ngFor="let item of shapData ;index as i; let last = last">
                            <div class="col-6 p-0" [ngClass]="(i % 2 === 0 && last )?'col-sm-12':'col-sm-6'">
                                <div class="card shadow m-2 border-0">
                                    <div class="zoomPopup">
                                        <a href="#" class="mr-2 has-tooltip" title="Expand" data-toggle="modal"
                                            data-target="#expandKPIModal" (click)="openXl(longContent,item.shapData)">
                                            <span class="material-icons">open_in_new</span>
                                        </a>
                                       
                                    </div>
                                    <img [src]="'data:image/jpeg;base64,' +item.shapData" alt="shape">
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Model -->
<ng-template #longContent let-modal>
    <div class="modal-header">
        <!-- <h3 class="section-title">{{chartModelData?.title?.text | titlecase}} </h3> -->
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
       
         <!-- <img [src]="'data:image/jpeg;base64,' +shapData.shapData"> -->
         <img src="{{'data:image/jpg;base64,' + imagePath}}"  class="img-fluid" alt="shape"/>
         
       
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Save click')">Cancel</button>
    </div>
</ng-template>