import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PricingModelValidationService } from '../pricing-model-validation/pricing-model-validation.service'
import { PricingService } from '../pricing.service';

@Component({
  selector: 'app-pricing-filter-chart',
  templateUrl: './pricing-filter-chart.component.html',
  styleUrls: ['./pricing-filter-chart.component.scss']
})
export class PricingFilterChartComponent implements OnInit {
  @Input() tabName: any;
  @Input() chartFilters: any;
  subscription: Subscription;
  tab: any;

  constructor(private fb: FormBuilder, private pricingModelValidationService: PricingModelValidationService, private pricingService: PricingService) { }

  ngOnInit() {
    this.tab = this.pricingService.activeTab;
  }

  /**
   * get update or change status value through change event 
   */
  updateStatus(checkboxData: any, index: any, filterType: any) {
    const updatedFilterValue = {
      checkboxData: checkboxData, index: index, filterType: filterType
    };
    this.pricingModelValidationService.setUpdatedChartFilterData(updatedFilterValue);
  }

}
