import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PricingManageModelService } from '../pricing-manage-model/pricing-manage-model.service';
import { PricingService } from '../pricing.service';

@Component({
  selector: 'app-select-model',
  templateUrl: './select-model.component.html',
  styleUrls: ['./select-model.component.scss']
})
export class SelectModelComponent implements OnInit {

  @Input() modelObj: any;
  public modelData
  selectedModel: any;
  selectedModelTypeAndVersion: any;
  loadEverything: boolean = false;
  projectId: any;
  emblemModels: any;
  localStorage = localStorage;

  constructor(private activeModal: NgbActiveModal, private pricingManageModelService: PricingManageModelService,
    private pricingService: PricingService, private ngxLoader: NgxUiLoaderService) { }

  ngOnInit(): void {
    this.getModelData(this.modelObj[0]);
    this.emblemModels = this.modelObj[1].pythonModels;
  }
  getModelData(modelObj) {
    this.loadEverything = false;
    this.projectId = this.pricingService.getProjectId();
      this.modelData = Object.values(modelObj.data.reduce((acc, { model, modelId, modelType, modelTypeId, version }) => {
          acc[model] = acc[model] || { model, modelId, modelTypeAndVersion: [] }
          acc[model].modelTypeAndVersion.push({ modelType: modelType, modelTypeId: modelTypeId, version: version })
          return acc
        }, {}));
        const emblem = this.modelObj[1].pythonModels.filter(el => {
          return !this.modelData.find(element => {
              return element.modelId === el.modelId;
          });
        })[0];
        this.modelData.push({
          model: emblem.name,
          modelId: emblem.modelId,
          modelTypeAndVersion: []
        })
        const selectedModel = this.localStorage.getItem('selectedModel')
      this.selectedModel = selectedModel === null || selectedModel === 'null' ? this.modelData[0].modelId : this.modelData.filter( el => { return el.model === selectedModel})[0].modelId;
      this.selectedModelTypeAndVersion = selectedModel === null || selectedModel === 'null' ? this.modelData[0].modelTypeAndVersion : this.modelData.filter( el => { return el.model === selectedModel})[0].modelTypeAndVersion;
      this.loadEverything = true;
  }

  selectAnotherModel(selectedModel) {
    const selectedNewModel = this.modelData.filter(e => {
      return e.modelId === selectedModel
    })[0];
    this.selectedModel = selectedNewModel.modelId;
    this.selectedModelTypeAndVersion = selectedNewModel.modelTypeAndVersion;
  }

  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    const modelObj = {
      projectId: this.projectId,
      modelId: this.selectedModel,
      status: "Model Selected"
    }
    this.pricingManageModelService.selectOrUpdateModel(modelObj).subscribe(res => {
      if (res && res.code === 200) {
        this.activeModal.close(true);
      }
    })
  }

}
