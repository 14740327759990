import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { FilterPanelComponent } from '../bordereaux-management/bordereaux-management-outline/filter-panel/filter-panel.component';
const PROJECT_LIST_DATA = "../../../../../assets/data/metamorph/projectList.json";

@Injectable({
  providedIn: 'root'
})
export class BordereauxManagementService {

  constructor(private modalService: NgbModal, private http: HttpClient) { }


  /***
   * @desc Get Project List
   * @returns {Object} Returns Project List
   *
   */
  getProjectListData(): Observable<any> {
    return this.http.get<any>(`${PROJECT_LIST_DATA}`)
  }

  /***
   * @desc Get Bordereaux Standardize List Data as per passed process Id and selected year month
   * @param Integer (Process Id)
   * @param Integer (yearMonth)
   * @returns {Object} Returns bordereaux Standardization Data   *
   */    
  getBordereauxListData(processId, yearMonth): Observable<any> {
    return this.http.get<any>('metamorph/bordereauxDetails/'+processId+'/'+yearMonth+'/');
  }
  
  /***
   * @desc Patch Call to initiate standardize process for passed workItemId.
   * @param Integer (WorkItemId)
   * @returns {Object} Returns bordereaux Standardize data with latest iteration data and its current status   *
   */ 
  standardizeProcess(workId):Observable<any>{
    const bodyJson={
      "status": "Standardization In-Progress",
      "workItemId": workId
    };
    return this.http.patch<any>('metamorph/standardization/'+workId+'/',bodyJson);
  }
  
   /***
   * @desc Get Call to check if process has been standardized.
   * @param Integer (processId)
   * @param Integer (yearMonth)
   * @param Integer (workId)
   * @returns {Object} Returns bordereaux latest iteration data and its current status 
   */ 
  standardizeProgressProcess(processId,yearMonth,workId):Observable<any>{    
    return this.http.get<any>('metamorph/bordereauxDetails/'+processId+'/'+yearMonth+'/'+workId+'/');
  }

  /***
   * @desc Patch Call to update selected version of the process
   * @param Integer (WorkItemId)
   * @returns {Object} Returns bordereaux Standardize data with latest iteration data and its current status of selected version  *
   */ 
  updateVersionInfo(workId):Observable<any>{
    const bodyJson={
      "workItemId": workId
    };
    return this.http.patch<any>('metamorph/standardization/'+workId+'/',bodyJson);
  }
  
  getMetamorphFilterData(): Observable<any> {
    return this.http.get<any>('metamorph/filters/');
  }
  getSubmitData(processId,filterSelected): Observable<any> {
    return this.http.get<any>('metamorph/rules/'+processId+'/'+filterSelected.ruleTypesId+'/'+filterSelected.countryId+'/'+filterSelected.lastModifiedById+ '/' + filterSelected.lastModifiedStart + '/' + filterSelected.lastModifiedEnd + '/')
  }
  getAdminData(): Observable<any> {
    return this.http.get<any>('metamorph/admins/')
  }
  getBDXManagementOutlineData(params): Observable<any> {
    const formattedYearMonth = params.yearMonth === 0 ? params.yearMonth : Number(params.yearMonth.split('-').join('').slice(0,6))
    return this.http.get<any>('metamorph/BMO/'+ params.fileTypeId + '/' + params.coverHolderId + '/' + params.formatId + '/' + formattedYearMonth + '/' + params.subFormatId + '/'+ params.modifiedBy + '/' + params.lastModifiedStart + '/' + params.lastModifiedEnd + '/');
  }

  public applyFilter(selectedFilters): Promise<any> {
    const modalRef = this.modalService.open(FilterPanelComponent, { windowClass:'upload-custome-class' });
    modalRef.componentInstance.selectedFilters = selectedFilters;
    return modalRef.result;
  } 
  

  /***
   * @desc Delete Call to delete selected work Item
   * @param Integer (WorkItemId)
   * @returns {Object} Returns API data with status and code
   */ 
  deleteStandardize(workId){
    return this.http.delete<any>('metamorph/standardization/'+workId+'/');
  }

  /***
   * @desc Get Call for getting Coverholder, Filetype and Format data against a role
   * @param Integer (roleId)
   * @returns {Object} Returns Coverholder, Filetype and Format details 
   */ 
  getBordereauxRoleMapData(roleId){
    return this.http.get<any>('metamorph/roleMap/'+roleId+'/')
  }
}
