import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditGeographyComponent } from '../rule-config/rule-config-base/edit-geography/edit-geography.component';
import { EditTagsComponent } from '../rule-config/rule-config-base/edit-tags/edit-tags.component';
import { RuleConfigSidepanelFilterComponent } from '../rule-config/rule-config-sidepanel-filter/rule-config-sidepanel-filter.component';

@Injectable({
  providedIn: 'root'
})
export class RuleConfigService {

  constructor(private modalService: NgbModal,private http: HttpClient) { }

  saveRuleConfig(payLoad, processId): Observable<any>{
    return this.http.put<any>('metamorph/processRules/'+ processId, payLoad)
  }

  
  getRuleConfigListData(processID,params) : Observable<any> {
    return this.http.get<any>('metamorph/rules/'+processID+'/'+params.ruleTypesId+ '/' +params.countryId+'/'+params.lastModifiedById+ '/' + params.lastModifiedStart + '/' + params.lastModifiedEnd + '/')
  }

  openEditGeography(selectedGeography): Promise<any> {
    const modalData = {
      selectedGeography: selectedGeography,
      title: 'Edit Geography',
      buttonSubmitText: '',
      buttonCancelText: 'Cancel'
    }
    const modalRef = this.modalService.open(EditGeographyComponent, { size: 'md', centered: true, backdrop: true });
    modalRef.componentInstance.modalData = modalData;
    return modalRef.result;
  }

  openEditTags(isEnabled,selectedTag): Promise<any> {
    const modalTagsData = {
      enabled:isEnabled,
      selectedTag: selectedTag,
      title: 'Edit Tags',
      buttonSubmitText: 'Submit',
      buttonCancelText: 'Cancel'
    }
    const modalRef = this.modalService.open(EditTagsComponent, { size: 'md', centered: true, backdrop: true });
    modalRef.componentInstance.modalTagsData = modalTagsData;
    return modalRef.result;
  }

  public configRuleApplyFilter(selectedFilters): Promise<any> {
    const modalRef = this.modalService.open(RuleConfigSidepanelFilterComponent, { windowClass:'upload-custome-class' });
    modalRef.componentInstance.selectedFilters = selectedFilters;
    return modalRef.result;
  }
}
