<a (click)="dataExplorationRouting()" href="javascript:void(0)" class="customBtn btn btn-sm btn-outline-primary">Data Exploration</a>
<div class="p-2 module-nav bg-white shadow fix-height"> 
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb mb-2">
            <li class="breadcrumb-item" *ngFor="let item of breadcrumb; last as isLast">{{item.name}} </li>
        </ol>
    </nav>             
    <div class="data-table-wrapper">
        <table class="table table-hover table-striped dataTable no-footer" id="doc-list-table" style="font-size: 11px;" role="grid" aria-describedby="doc-list-table_info"  datatable [dtOptions]="dtOptions[0]" [dtTrigger]="dtTrigger" >
            <thead>
                <tr role="row" *ngFor="let mheads of modelHeads">
                   
                    <th scope="col">{{mheads.modelTechnique}}</th>
                    <th scope="col">{{mheads.modelType}}</th>
                    <th scope="col">{{mheads.version}}</th>
                    <th scope="col">{{mheads.createdDate }}</th>
                    <th scope="col" style="width: 75px!important;">{{mheads.action}}</th>
                </tr>
            </thead>
            <tbody>
               
               
                <tr role="row" class="versionC" *ngFor="let mdatass of modelData" >
                   
                    <td>{{mdatass.model}}</td>
                    <td>{{mdatass.modelType}}</td>
                    <td><a href="#" class="has-tooltip" title="Model Version" data-toggle="modal" data-target="#AddEditModelFileModalNN"
                        (click)="getVersionOpenModal(mdatass)">
                        <span class="oldver1b">Ver {{mdatass.version}} 
                            <ng-container *ngIf="checkHigherVersion(mdatass.version,mdatass.other)==true">
                                <strong class="text-success h6">^</strong> 
                            </ng-container>
                            
                            
                        </span> 
                      </a></td>                                       
                    <td>{{mdatass.createdAt}}</td>
                    <td class="action-icons">
                        <a href="#" class="text-danger has-tooltip" title="Delete Project">
                            <span class="material-icons">delete_outline</span>
                        </a>
                      
                    </td>
                </tr>
                
            </tbody>
        </table>
    </div>   
</div>  

<!-- Recommendation Modal -->
<app-modal #modal [modalConfig]="getAvailableVersion">
    <div>Click on the Version number to download the file</div>
                <table class="table table-hover table-striped dataTable" aria-describedby="mydesc">
                    <thead>
                        <tr *ngFor="let vhead of versionHeads">
                            <th scope="col">{{vhead.versionNo}}</th>
                            <th scope="col">{{vhead.fileName}}</th>
                            <th scope="col">{{vhead.createdDate}}</th>
                            <th scope="col">{{vhead.setDefault}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let vdata of vesionData; let i = index;">
                            <td><a href="#" class="has-tooltip" title="Download">{{vdata.version}}</a></td>
                            <td>{{vdata.fileName}}</td>
                            <td>{{vdata.createdAt | date:'d/M/yy,h:mm:ss a z'}}</td>
                            <td>
                                <div class="custom-control custom-radio text-center">
                                    <input type="radio" id="customRadio{{i}}" name="customRadio5" class="custom-control-input" 
                                    [checked]="vdata.isDefault==true"
                                    (click)="setVersion(vdata.version)"
                                    >
                                    <label class="custom-control-label" for="customRadio{{i}}"
                                    ></label>
                                </div>

                                
                            </td>
                        </tr>
                        </tbody>
                </table>
   
</app-modal>
