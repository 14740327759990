import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MetamorphBaseComponent } from './metamorph-base.component';
import { BordereauxManagementComponent } from './bordereaux-management/bordereaux-management.component';
import { RuleConfigComponent } from './rule-config/rule-config.component';
import { EventLogsComponent } from './event-logs/event-logs.component';
import { BordereauxBaseComponent } from './bordereaux-management/bordereaux-base/bordereaux-base.component';
import { RuleConfigBaseComponent } from './rule-config/rule-config-base/rule-config-base.component';

const routes: Routes = [
  {
    path: 'metamorph',
    component: MetamorphBaseComponent,
    children: [
      {
        path: 'bordereaux-management',
        component: BordereauxManagementComponent
      },
      {
        path: 'rule-config',
        component: RuleConfigComponent
      },
      {
        path: 'event-log',
        component: EventLogsComponent
      },
      {
        path: 'bordereaux-details',
        component: BordereauxBaseComponent
      },
      {
        path: 'rule-config-base',
        component: RuleConfigBaseComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MetamorphRoutingModule { }
