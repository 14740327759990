import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ScmSimulateComponent } from './scm/scm-simulate/scm-simulate.component';
import { ScmComponent } from './scm/scm.component';


const routes: Routes = [
  {
    path: 'supplychain',
    children: [
      {
        path: 'scm',
        component: ScmComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ScmMmmRoutingModule { }
