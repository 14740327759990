<div class="card border-0">
    <div class="card-body shadow m-1 chartSpacingCustom">
        <div class="d-flex">
            <div class="flex-grow-1">
                <h3 class="section-title">{{chartOption?.title?.text}}</h3>
            </div>
            <div class="">
                <a href="javascript:void(0)" (click)="downloadChart()" style="margin-right: 5px;" ngbTooltip="Export to Excel"
                placement="left">
                    <span class="material-icons">file_download</span>
                </a>
                <a href="#" class="mr-2" data-toggle="modal" data-target="#expandKPIModal_d" (click)="openXl(longContent,chartOption)" ngbTooltip="Expand Chart"
                placement="left">
                    <span class="material-icons">open_in_new</span>
                </a>
            </div>
        </div>
        <div class="text-center chart-container" id="kp-2">
            <highcharts-chart #chartRef class="highcharts" [Highcharts]="Highcharts" [(update)]="updateFlag" [options]="chartOption">
            </highcharts-chart>
        </div>
    </div>
</div>

<ng-template #longContent let-modal>
    <div class="modal-header">
        <h3 class="section-title">{{chartOption?.title?.text}} </h3>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <highcharts-chart class="highcharts-modal" [(update)]="updateFlag" [Highcharts]="Highcharts" [options]="chartModelData">
        </highcharts-chart>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Save click')">Cancel</button>
    </div>
</ng-template>
