
<div class="exploratory-metrics">
    <div class="row no-gutters chart-wrapper">
        <div class="col-2 filter-padding">
            <div *ngFor="let filter of chartFilters; index as i">
                <h4 class="m-0 mt-2 filter-header">{{filter.title.name}}</h4>
                <div class="checkbox-scroll">
                    <ng-container *ngFor="let checkbox of filter.values;  index as ind">
                        <div class="custom-control custom-checkbox checkbox-margin">
                            <input type="checkbox" class="custom-control-input" id="actual-{{filter.title.name}}-{{ind}}" name = "actual-{{filter.title.name}}-{{ind}}" 
                                [(ngModel)]="checkbox.isSelected" (change)="updateActualChartFilterStatus(checkbox,i,filter.filterType)">
                            <label class="custom-control-label letter checkbox-label-margin" for="actual-{{filter.title.name}}-{{ind}}"> {{checkbox.name}}
                            </label>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        
        <div class="col-10 chart-container-wrapper">
            <ng-container *ngFor="let item of chartOptions | pricingFilter: filterUser; index as i; let last = last">
                <div class="col-6 float-left p-0"  *ngIf="item.displayChart">
                    <!-- [ngClass]="(i % 2 === 0 && last )?'col-sm-12':'col-sm-6'" -->
                    <div class="card shadow m-1 border-0" style="width: calc(100% -  10px); margin-bottom: 10px; ">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <h3 class="section-title">{{item?.title?.text | titlecase}}</h3>
                                </div>
                                <div class="">
                                    <a href="#" class="mr-2 has-tooltip" title="Expand" data-toggle="modal"
                                        data-target="#expandKPIModal" (click)="openXl(longContent,item)">
                                        <span class="material-icons">open_in_new</span>
                                    </a>
                                    <!-- <a href="#" class="has-tooltip" title="Download in Excel">
                                        <span class="material-icons">file_download</span>
                                    </a> -->
                                </div>
                            </div>
                            <div class="text-center chart-container">
                                <highcharts-chart class="highcharts" [(update)]="updateFlag" [Highcharts]="Highcharts"
                                    [options]="item">
                                </highcharts-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<!-- Start Button trigger modal -->
<ng-template #longContent let-modal>
    <div class="modal-header">

        <h3 class="section-title">{{chartModelData?.title?.text | titlecase}} </h3>

        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <highcharts-chart class="highcharts-modal" [Highcharts]="Highcharts" [options]="chartModelData">
        </highcharts-chart>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Save click')">Cancel</button>
    </div>
</ng-template>