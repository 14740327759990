<div class="p-relative-custom">
    <table datatable [dtOptions]="dtOptions" aria-describedby="mydesc" class="child_table"
        style="font-size: 11px!important;">
        <thead>
            <tr>
                <th scope="col"></th>
                <th scope="col">Version</th>
                <th scope="col">File/Data Name</th>
                <th scope="col">Reporting Period</th>
                <th scope="col">Ingestion Type</th>
                <th scope="col">Uploaded By</th>
                <th scope="col">Last Modified</th>
                <th scope="col">Last Modified By</th>
                <th scope="col">Iteration</th>
                <th scope="col">Rules</th>
                <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody *ngIf="dataRow?.length != 0">

            <tr *ngFor="let recordx of dataRow[0];">

                <td scope="first-col" style="width: 25px;">&nbsp;</td>
                <td scope="first-col" style="width: 166px;">&nbsp;</td>
                <td scope="first-col" style="width: 276px;">
                    <p class="custom-ellipsis" placement="right" [ngbTooltip]="tooltipOptimizeC">{{recordx?.fileName}}
                    </p>
                    <ng-template #tooltipOptimizeC>
                        <div>{{recordx?.fileName}} - {{recordx.fileSizeInMb | number : '1.2-2'}} MB</div>
                    </ng-template>
                    <span class="badge " [ngClass]="{

                        'customBgBlue': recordx.status=='Ready for Validation' ,
                                'badge-info': recordx.status=='Re-Validation' ,
                                'badge-success': recordx.status=='Validated',
                                'customBgRed':  recordx.status=='Validation Failed',
                                'badge-danger':  recordx.status=='System Error',
                                'customBgYellow':  recordx.status=='Validation In-Progress',
                                'customBgGrey':recordx.status=='Rules Amended',
                                'customBgDarkGrey':recordx.status=='Corrected Data Uploaded'

                    }">{{recordx.status}}</span>
                </td>
                <td scope="first-col" style="width: 164px;">{{recordx.reportStartDate | date: 'd MMMM yy'}} to
                    {{recordx.reportEndDate | date:
                    'd MMMM yy'}}</td>
                <td scope="first-col" style="width: 73px;">{{recordx.ingestionType}}</td>

                <td scope="first-col" style="width: 89px;"><p class="custom-ellipsisName">{{recordx.uploadedBy}}</p></td>
                <td scope="first-col" style="width: 96px;">{{recordx.updatedAt | date: 'd MMMM yy h:mm:ss a'}}</td>
                <td scope="first-col" style="width: 89px;">
                    <p class="custom-ellipsisName"> {{recordx.modifiedBy}}</p></td>
                <td scope="first-col" style="width: 51px;">{{recordx.iteration}}</td>
                <td scope="first-col" style="width: 49px;"><span
                        class="badge customBgBlue customBox" (click)="ruleCountList(recordx.workItemId)">{{recordx.rulesCount}}</span></td>
                <td scope="first-col" style="width: 40px;">
                    <div class="dropdown"><img src=".. /../../../../../../assets/images/dots.png" data-toggle="dropdown"
                            aria-expanded="false" />
                        <div class="dropdown-menu">

                            <button *ngIf="recordx.status=='Validation Failed' ||  recordx.status=='Re-Validation' "
                                class="dropdown-item" type="button">Upload Corrected Data</button>

                            <button *ngIf="recordx.status!='Validated' && recordx.status!='Validation In-Progress'"
                                class="dropdown-item" type="button" (click)="amendRules(recordx.workItemId)">Amend
                                Rules</button>

                            <button *ngIf="recordx.status=='Validation Failed'  && recordx.status!='Validated'"
                                class="dropdown-item" type="button"><a href="{{recordx.downloadErrorReportLink }}">Error
                                    Report</a></button>

                            <button *ngIf="recordx.downloadInputLink"
                                class="dropdown-item" type="button"><a href="{{recordx.downloadInputLink}}">Download
                                    Source File</a></button>

                            <button *ngIf="recordx.status=='Validated'" class="dropdown-item" type="button"><a
                                    href="{{recordx.downloadOutputLink}}">Download Validated File</a></button>

                            <button *ngIf="userService.checkAccess('metamorph-validation','delete')  && (recordx.status=='Ready for Validation' ||  recordx.status=='Re-Validation') "
                                class="dropdown-item" type="button">Delete</button>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>