
<div class="confirmation-modal-header">
    <h3 class="confirmation-modal-title">{{recommendationData.model | titlecase}}</h3>
  </div>
<div class="modal-body p-4">
    <div class="row">    
         <ng-container *ngFor="let msgdetail of msgArray;let i = index;">
            <button class="accordion {{i}}" [ngClass]="{'active':i==0}"  (click)="toggleAccordian($event)">{{msgdetail.monthName}} {{msgdetail.year }}</button>
                <div class="panel"   [ngStyle]="{'max-height': (i!=0) ? '0px' : ''}">
                     <ul class="customColor" *ngFor="let msg of msgdetail.text">
                        <li>{{msg}}</li>
                    </ul>
                </div>
                
        </ng-container>
    </div>
   
        
</div>
<div class="modal-footer" *ngIf="recommendationData?.btnCancelText || recommendationData?.btnSaveText">
    <button type="button" class="btn btn-light btn-sm addcustom" (click)="closeModal()" *ngIf="recommendationData?.btnCancelText">{{ recommendationData?.btnCancelText }}</button>
    <button type="button" class="btn btn-primary btn-sm"  *ngIf="recommendationData?.btnSaveText">{{ recommendationData?.btnSaveText }}</button>
  </div>
