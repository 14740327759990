import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'info-container',
  templateUrl: './info-container.component.html',
  styleUrls: ['./info-container.component.scss']
})
export class InfoContainerComponent implements OnInit {
  @Input() operationalData: any;
  formattedOperationalDetails: any;
  constructor() { }

  ngOnInit(): void {

    this.formattedOperationalDetails = this.operationalData.filter(e => {
      if(e.label !== 'Standardize Download Template' && e.label !== 'Validate Download Template') {
        return e;
      }
    })

  }

}
