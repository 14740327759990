<div class="full-screen">
  <div class="right-aligned-container">
    <div class="d-flex bottom-border">
      <div class="col-sm-10 p-0">
        <span class="header-text">Filters</span>
      </div>
      <div class="col-sm-2">
        <i class="material-icons close-icon" (click)="dismiss()">close</i>
      </div>
    </div>
    <div class="filter-panel-scroll-container">
      <p class="text-center" *ngIf="isLoadingFilters">Loading filters...</p>
      <ng-container *ngIf="filterData.length && !isLoadingFilters">
        <div class="row" *ngFor="let filterAppributes of filterData">
          <div class="col-sm-12">
            <div class="form-group">
              <label class="form-label" for="fileTypes">{{filterAppributes.label}}</label>
              <select class="form-control" [(ngModel)]="filterAppributes.selectedId"
                [ngModelOptions]="{standalone: true}">
                <option *ngFor="let item of filterAppributes.data" [ngValue]="item?.id">{{item?.name}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label class="form-label" for="fileTypes">Execution Month</label>
              <input class="form-control datepicker" [(ngModel)]="selectedFilters.yearMonth" type="date">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label class="form-label" for="fileTypes">Last Modified Date</label>
              <!-- Date range picker -->
              <div class="form-control datepicker" tabindex="-1" name="daterangeInput" daterangepicker #dateRangePickerInput [options]="options"
                (selected)="selectedDate($event, daterange)" type="text">
                <span *ngIf="lastModifiedStart !== 0">{{daterange.start | date: 'dd/MM/yyyy'}}</span>
                <span class="date-placeholder-text" *ngIf="!daterange.start">dd-mm-yyyy</span>
                <span>&nbsp;-&nbsp;</span>
                <span *ngIf="lastModifiedEnd !== 0">{{daterange.end | date: 'dd/MM/yyyy'}}</span>
                <span class="date-placeholder-text" *ngIf="!daterange.end">dd-mm-yyyy</span>
                <i aria-hidden="true" class="ui-icons-calendar-1 calander-icon-position"></i>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="row">
      <div class="col-sm-12 text-center">
        <button class="btn btn-link reset-button" (click)="resetFilters()">Reset Filters</button>
      </div>
      <div class="col-sm-12">
        <button class="btn btn-primary btn-block no-border-radius" (click)="applyFilter()">SUBMIT</button>
      </div>
    </div>
  </div>
</div>
