import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './module/auth/login/login.component';
import { ProfileComponent } from './module/profile/profile.component';
import { AuthGuard } from './core/guards/auth.guard';
import { DemandforecastingModule } from './module/supplychain/demand-forecasting/demandforecasting.module';
import { ScmMmmModule } from './module/supplychain/scm-mmm/scm-mmm.module';
import { ScmMmmStaticModule } from './module/supplychain/scm-mmm-static/scm-mmm-static.module';
import { MetamorphModule } from './module/metamorph/metamorph-base/metamorph.module';
import { CsrModule } from './module/insurance/csr/csr.module';
import { ChatAnalysisModule } from './module/insurance/chat-analysis/chat-analysis.module';



const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent},
  { path: 'home', canActivate: [AuthGuard],data: { module: "Home" ,action:"view"}, loadChildren: () => import('./module/home/home.module').then(mod => mod.HomeModule) },
  { path: 'underwriting', canActivate: [AuthGuard],data: { module: "Underwriter" ,action:"view"}, loadChildren: () => import('./module/insurance/underwriting/underwriting.module').then(mod => mod.UnderwritingModule) },
  { path: 'pricing', canActivate: [AuthGuard],data: { module: "Pricing" ,action:"view"}, loadChildren: () => import('./module/insurance/pricing/pricing.module').then(mod => mod.PricingModule) },
  { path: 'claim', canActivate: [AuthGuard],data: { module: "Claim",action:"view" }, loadChildren: () => import('./module/insurance/claims/claims.module').then(mod => mod.ClaimsModule) },
  { path: 'admin', canActivate: [AuthGuard],data: { module: "Admin" ,action:"view"}, loadChildren: () => import('./module/admin/admin.module').then(mod => mod.AdminModule) },
  { path: 'profile', component: ProfileComponent },
  { path: 'demandforecasting', canActivate: [AuthGuard],data: { module: "Demand_Planning" ,action:"view"}, loadChildren: () => import('./module/supplychain/demand-forecasting/demandforecasting.module').then(mod => mod.DemandforecastingModule) },
  { path: 'scm-mmm', canActivate: [AuthGuard],data: { module: "scm-mmm" ,action:"view"}, loadChildren: () => import('./module/supplychain/scm-mmm/scm-mmm.module').then(mod => mod.ScmMmmModule) },
  { path: 'scm-mmm-static', canActivate: [AuthGuard],data: { module: "scm-mmm-static" ,action:"view"}, loadChildren: () => import('./module/supplychain/scm-mmm-static/scm-mmm-static.module').then(mod => mod.ScmMmmStaticModule) },
  { path: 'metamorph', canActivate: [AuthGuard], data: {module: "metamorph", action: "view"}, loadChildren: () => import('./module/metamorph/metamorph-base/metamorph.module').then(mod => mod.MetamorphModule)},
  { path: 'csr',  canActivate: [AuthGuard],data: { module: "csr" ,action:"view"}, loadChildren: () => import('./module/insurance/csr/csr.module').then(m => m.CsrModule) },
  { path: 'csr',  canActivate: [AuthGuard],data: { module: "chat" ,action:"view"}, loadChildren: () => import('./module/insurance/chat-analysis/chat-analysis.module').then(m => m.ChatAnalysisModule) },
  { path: '**', canActivate: [AuthGuard],data: { module: "Home" ,action:"view"}, loadChildren: () => import('./module/home/home.module').then(mod => mod.HomeModule) }
 ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule,DemandforecastingModule, ScmMmmModule, ScmMmmStaticModule, MetamorphModule,CsrModule,ChatAnalysisModule]

})
export class AppRoutingModule { }
