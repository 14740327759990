import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { MetamorphCommonService } from '../../../services/metamorph-common.service';
import { RuleConfigService } from '../../../services/rule-config.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'rule-config-list',
  templateUrl: './rule-config-list.component.html',
  styleUrls: ['./rule-config-list.component.scss'],
})
export class RuleConfigListComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  ruleConfigList: any[];
  enabled: boolean = false;
  selectedRuleId: any;
  selectedRuleName: any;
  processId: any;
  selectedGeography: any;
  selectedTag: any;
  selectedUser: any;
  ruleConfigInfo: any;
  selectedModifiedDate: any;
  selectedIsMandatory: boolean = false;
  selectedIsDefault: boolean = false;
  ruleIdList: any[];
  ruleNameList: any[];
  userList: any[];
  geographyList = ['India', 'Italy', 'Australia'];
  tagList = ['Tag1', 'Tag2', 'Tag3'];
  daterange: any;
  daterangeStart: string;
  daterangeEnd: string;
  ruleConfigData: any;
  filterObject = {
    coverHolderId: 0,
    countryId: 0,
    ruleTypesId: 0,
    tagsId: 0,
    lastModifiedById: 0,
    lastModifiedStart: 0,
    lastModifiedEnd: 0,
  };
  updatedRuleConfigList: Array<any> = [];
  selectedRuleIdList: Array<any> = [];
  isCheckStatusClicked: boolean = false;
  isDefaultEnabled: boolean[] = [];
  constructor(
    public ruleConfigService:RuleConfigService,
    private ngxLoader: NgxUiLoaderService,
    public metamorphCommonService: MetamorphCommonService,
    public toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.ruleConfigData = JSON.parse(this.metamorphCommonService.getRuleConfigInfoData());
    this.getCoverholderListData(this.filterObject);
    this.isDefaultEnabled = this.ruleConfigList.map(
      (item) => item.isEnabled || item.isDefault
    );
  }

  /**
   * Get Rule Config list data
   * @param filterObject
   */
  getCoverholderListData(filterObject) {
    this.ruleConfigList = [];
    this.ngxLoader.start();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      deferRender: true,
      destroy: true,
    };
    this.processId = this.ruleConfigData.filter(function (el) {
      return el.label == 'Process Id';
    })[0].value;
    this.ruleConfigService
      .getRuleConfigListData(this.processId, filterObject)
      .subscribe((response) => {
        this.ruleConfigList = response?.data;
        this.ngxLoader.stop();
        this.dtTrigger.next();
      });
  }

  checkFilterStatus(event) {}
  // Options for DateRangePicker
  public options: any = {
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
    opens: 'left',
  };
  // getting date range selected and assigning formatted startDate/endDate to global variable
  public selectedDate(value: any, datepicker?: any) {
    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;
    // use passed valuable to update state
    this.daterange.start = value.start;
    this.daterange.end = value.end;
    this.daterange.label = value.label;
    this.daterangeStart = new Date(
      new Date(this.daterange.start).toString().split('GMT')[0] + ' UTC'
    ).toISOString();
    this.daterangeEnd = new Date(
      new Date(this.daterange.end).toString().split('GMT')[0] + ' UTC'
    ).toISOString();
  }

  editGeography(selectedGeography, index) {
    this.ruleConfigService
      .openEditGeography(selectedGeography)
      .then((data) => {
        if (data && data.length) {
          this.ruleConfigList[index].geoJson = data.map((e) => e.name);
        }
      });
  }

  editTags(isEnabled, selectedTag, index, ruleId) {
    this.ruleConfigService
      .openEditTags(isEnabled, selectedTag)
      .then((data) => {
        if (data && data.length) {
          this.ruleConfigList[index].tagJson = data;
          this.getUpdatedRuleConfigRow(ruleId);
        }
      });
  }

  /**
   * Apply Filter to get filered Rule Config List
   */
  applyFilter() {
    const config = {};
    this.ruleConfigService.configRuleApplyFilter(this.filterObject).then(
      (response) => {
        if (response) {
          this.filterObject = response;
          this.getCoverholderListData(this.filterObject);
        }
      }
    );
  }
  /**
   * Capture Is Mandatory checkbox status change
   * @param event
   * @param ruleId
   */

  updateIsMandatoryStatus(event: any, ruleId: any, index: number) {
    const ruleConfig = this.ruleConfigList.find((item) => item.ruleId === ruleId);
    if (ruleConfig) {
      ruleConfig.isDefault = !ruleConfig.isDefault;
      if (ruleConfig.isMandatory) {
        ruleConfig.isDefault = true;
      }
    }
    this.getUpdatedRuleConfigRow(ruleId);
  }
  /**
   * Capture Is Default  checkbox status change
   * @param event
   * @param ruleId
   */
  updateIsDefaultStatus(event: any, ruleId: any, index: number) {
    this.getUpdatedRuleConfigRow(ruleId);
  }
  /**
   * Capture Is Enabled slider status change
   * @param event
   * @param ruleId
   */
  checkStatus(event: any, ruleId: any, index: number) {
    this.ruleConfigList[index].isEnabled = event.target.checked;

    const ruleConfig = this.ruleConfigList.find(
      (item) => item.ruleId === ruleId
    );
    if (ruleConfig) {
      if (ruleConfig.isEnabled) {
        this.isCheckStatusClicked = true;
        this.isDefaultEnabled[index] = true;
      } else {
        this.isCheckStatusClicked = false;
        this.isDefaultEnabled[index] = false;
      }
    }
    this.getUpdatedRuleConfigRow(ruleId);
  }

  /**
   * Method to capture all the updated row's rule id
   * @param ruleId
   */
  getUpdatedRuleConfigRow(ruleId) {
    if (!this.selectedRuleIdList.length) {
      this.selectedRuleIdList.push(ruleId);
    } else {
      if (this.selectedRuleIdList.indexOf(ruleId) === -1) {
        this.selectedRuleIdList.push(ruleId);
      }
    }
  }

  /**
   * Saving updated rule config list
   */
  saveRuleConfig() {
    this.ngxLoader.start();
    this.updatedRuleConfigList = this.ruleConfigList
    .filter((item) => this.selectedRuleIdList.includes(item.ruleId))
    .map((item) => ({ ...item }));
    const payload = this.updatedRuleConfigList.map((e) => {
      return {
        ruleId: e.ruleId,
        isMandatory: e.isMandatory,
        isEnabled: e.isEnabled,
        isDefault: e.isDefault,
        tagJson: e.tagJson != null ? e.tagJson : [],
        geoJson: e.geoJson != null ? e.geoJson : [],
      };
    });
    this.ruleConfigService
      .saveRuleConfig(payload, this.processId)
      .subscribe((response) => {
        if (response && response.code === 200) {
          this.selectedRuleIdList = [];
          this.ngxLoader.stop();
          this.toastr.success(response.successMessage);
          this.getCoverholderListData(this.filterObject);
        }
      });
  }
}
