import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChatAnalysisBaseComponent } from './chat-analysis-base/chat-analysis-base.component';
import { ChatAnalysisListingComponent } from './chat-analysis-base/chat-analysis-listing/chat-analysis-listing.component';
import { ChatAnalysisDescriptionComponent } from './chat-analysis-base/chat-analysis-description/chat-analysis-description.component';
import { DashboardComponent } from './chat-analysis-base/dashboard/dashboard.component';

const routes: Routes = [
  {
    path: 'chat',
    component: ChatAnalysisBaseComponent,
    children: [
      {
        path: 'analysis-list',
        component:ChatAnalysisListingComponent
      },
      {
        path: 'description',
        component:ChatAnalysisDescriptionComponent
      },
      {
        path: 'dashboard',
        component:DashboardComponent
      },
    ]
    
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChatAnalysisRoutingModule { }
