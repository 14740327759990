import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }

  private _currentTemplateId$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  getCurrentTemplateId(): Observable<any> {
    this._currentTemplateId$ = JSON.parse(localStorage.getItem('currentTemplateId'))
    return of(JSON.parse(localStorage.getItem('currentTemplateId')));
  }

  setCurrentTemplateId(id: any) {
    this._currentTemplateId$.next(id);
  }
}
