<app-header></app-header>
<div class="virtual-backdrop" *ngIf="step === 'upload-progress' || step === 'readyToUpload' ">
  <!-- <app-header></app-header> -->
  <div class="upload-container">
    <div [ngClass]="submittedFile ? 'disabled-effect' : ''">


      <div class="row">
        <div class="col-sm-12">
          <div class="uploader-header">
            <h3 class="uploader-modal-title">{{operationalDetails?.aditionalConfig?.titleText}}</h3>
          </div>
        </div>
      </div>
      <div class="row customDate" *ngIf="operationalDetails?.aditionalConfig?.titleText === 'Upload New Data'">
        <div class="col-sm-12">
          <p>Select the reporting period for which you want to upload the new data
          </p>
        </div>
        <div class="col-md-12">
          <div class="customDatepicker">
            <div class="form-control datepicker" tabindex="-1" name="daterangeInput" daterangepicker [options]="options"
              (selected)="selectedDate($event, daterange)" type="text">
              <span *ngIf="daterangeStart!== ''">{{daterangeStart | date: 'dd/MM/yyyy'}}</span>
              <span class="date-placeholder-text" *ngIf="daterangeStart === ''">DD/MM/YYYY</span>
              <span>&nbsp;-&nbsp;</span>
              <span *ngIf="daterangeEnd!== ''">{{daterange.end | date: 'dd/MM/yyyy'}}</span>
              <span class="date-placeholder-text" *ngIf="daterangeEnd === ''">DD/MM/YYYY</span>
              <i aria-hidden="true" class="ui-icons-calendar-1"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex date-text-container"
        *ngIf="operationalDetails?.aditionalConfig?.titleText === 'Upload Corrected Data'">
        <div class="col-sm-6">
          <p class="date-text-label">Reporting Period</p>
          <p class="date-text-data">{{operationalDetails?.aditionalConfig?.reportingPeriodStartDate | date:
            'dd-MMMM-yy'}} to {{operationalDetails?.aditionalConfig?.reportingPeriodEndDate | date: 'dd-MMMM-yy'}}</p>
        </div>
        <div class="col-sm-6">
          <p class="date-text-label">Data Version</p>
          <p class="date-text-data">Ver {{operationalDetails?.aditionalConfig?.versionInfo}}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="dropzone-wrapper">
            <ngx-dropzone (change)="onSelect($event)"
              style="height: 140px;    border: 1px dashed rgba(55, 41, 72, 0.5);" accept=".xlsx"
              [disabled]="daterangeStart == '' || daterangeEnd == '' ">
              <ngx-dropzone-label style="margin:0;" class="col-sm-12 border-removed">
                <div class="dz-message needsclick centered-row">
                  <div class="col-sm-2">
                    <img class="file-icon" src="../../../../assets/images/file-upload.png" alt="file" />
                  </div>
                  <div class="col-sm-7">
                    <ng-container *ngIf="fileNotSupported">
                      <p class="m-0 font-20"> {{ rejectedFileName }}</p>
                      <p class="sub-text" >{{ rejectedFileSize }}</p>
                    </ng-container>
                    <ng-container *ngIf="!fileNotSupported">
                      <p class="m-0 font-20" *ngIf="!uploadedFile.length">Select file to import(.xlsx)</p>
                      <p class="m-0 font-20" *ngIf="uploadedFile.length && uploadedFile[0]?.FileName.length < 20"> {{ uploadedFile[0].FileName }}</p>
                      <p class="m-0 font-20" *ngIf="uploadedFile.length && uploadedFile[0]?.FileName.length > 19" aria-hidden="true"  placement="bottom" ngbTooltip="{{uploadedFile[0].FileName}}" > {{ uploadedFile[0].FileName | slice:0:19}} ...</p>
                      <p class="sub-text" *ngIf="!uploadedFile.length">or drag drop it here</p>
                      <p class="sub-text" *ngIf="uploadedFile.length">{{ uploadedFile[0]?.FileProgessSize }} of {{ uploadedFile[0]?.FileSize }}</p>
                    </ng-container>
                  </div>
                  <div class="col-sm-3">
                    <p class="m-0 left-aligned"><a class="btn btn-outline-secondary">Choose File</a></p>
                  </div>
                </div>
              </ngx-dropzone-label>
            </ngx-dropzone>

            <div class="col-sm-12" *ngIf="uploadStarted  && step === 'upload-progress'">
              <div class="centered-row mt-4" *ngIf="step === 'upload-progress'">
                <div class="col-sm-11">
                  <div class="progress-bar">
                    <span [style.width.%]="progress"></span>
                  </div>
                  <div class="progress-status" *ngIf="uploadedFile.length"> {{ uploadedFile[0]?.FileProgessSize }} of {{
                    uploadedFile[0]?.FileSize }}</div>
                </div>
                <div class="col-sm-1">
                  <button type="button" class="close" aria-label="Close" (click)="removeFile()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-sm-12" *ngIf="fileNotSupported">
              <p class="text-center text-danger file-format-error-text">File format not supported. Allowed excel format - .xlsx</p>
            </div>
            <div class="col-sm-11" *ngIf="readyToUploadSubmit">
              <p class="text-center text-success">File upload successful</p>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="uploader-model-footer">
          <button type="button" class="btn btn-light btn-sm addcustom" aria-label="Close"
            (click)="decline()">Cancel</button>
          <button *ngIf="submittedFile" type="button" class="btn btn-primary btn-sm ng-star-inserted"
            (click)="goToNext()">Next</button>
          <button *ngIf="!submittedFile" type="button" class="btn btn-primary btn-sm ng-star-inserted"
            (click)="submitUpload()"
            [disabled]="!readyToUploadSubmit || daterangeStart == '' || daterangeEnd == ''">Submit</button>
        </div>
      </div>



    </div>

  </div>
</div>





<div class="file-processing-container" *ngIf="uploadStarted && step !== 'upload-progress'">
  <div class="col-sm-12 p-t-15">
    <div class="row " *ngIf="step !== 'upload-progress' && fileData?.sheetInfo">
      <div class="col-sm-12">
        <info-container [operationalData]="operationalData"></info-container>
        <file-progress-breadcrumb [progressSteps]=progressSteps></file-progress-breadcrumb>
        <div></div>
      </div>
      <div class="col-sm-12" *ngIf="step === 'sheet-mapping'">
        <sheet-mapping [fileData]="fileData" [uploadId]="uploadId" [isMappingFlow]="isMappingFlow"
          [BDXManagementData]="BDXManagementData" (sheetMappingSucessRespone)="sheetMappingSucessRespone($event)"
          (nextButtonEvent)="handleNextEvent($event)" (emitExistingSheetMappingData)="captureSheetMappingData($event)"
          (backButtonEvent)="handleBackEvent($event)"
          (setDestinationTemplateDataToParent)="setDestinationTemplateData($event)"
          (exitButtonEvent)="handleExitEvent($event)"></sheet-mapping>
      </div>
      <div class="col-sm-12" *ngIf=" step === 'header-range-selection'">
        <select-header-range [fileData]="fileData" [uploadId]="uploadId" [daterangeStart]="daterangeStart"
          (exitUploadFlow)="handleExitEvent($event)" [daterangeEnd]="daterangeEnd" [workItemId]="workItemId"
          [workItemId]="workItemId" (nextButtonEvent)="handleNextEvent($event)"
          (backButtonEvent)="handleBackEvent($event)" (exitButtonEvent)="handleExitEvent($event)"
          (fileAutoSubmitted)="fileAutoSubmitted($event)"></select-header-range>
      </div>
      <div class="col-sm-12" *ngIf=" step === 'column-mapping'">
        <column-mapping [fileData]="fileData" [uploadId]="uploadId"
          [sheetMappingSucessResponeData]="sheetMappingSucessResponeData" (exitButtonEvent)="handleExitEvent($event)"
          [existingSheetMappingData]="existingSheetMappingData" [destinationTemplateData]="destinationTemplateData"
          (nextButtonEvent)="handleNextEvent($event)" (backButtonEvent)="handleBackEvent($event)"></column-mapping>
      </div>
      <div class="col-sm-12" *ngIf=" step === 'mapped-column-preview'">
        <file-upload-review [fileData]="fileData" [uploadId]="uploadId" [workItemId]="workItemId"
          (nextButtonEvent)="handleNextEvent($event)" (backButtonEvent)="handleBackEvent($event)"
          (fileAutoSubmitted)="fileAutoSubmitted($event)"
          (exitButtonEvent)="handleExitEvent($event)"></file-upload-review>
      </div>
    </div>
  </div>
</div>
