<div class="container-fluid p-0" style="background-color: #f6f6f6;">

    <div class="">
        <div class="col-sm-12 p-0">
            <div class="filters-panel" style="margin: 50px 7px 0px 7px;">
                <ul class="nav nav-tabs tab-margin" id="supplyChain" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link sub-module" id="scm-optimize-tab"
                            [ngClass]="{ 'active':selectedTab==='scm-optimize'}" role="tab"
                            (click)="selectTab('scm-optimize', $event)">Optimize</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link sub-module" id="scm-simulate-tab"
                            [ngClass]="{ 'active':selectedTab==='scm-simulate'}" role="tab"
                            (click)="selectTab('scm-simulate', $event)">Simulate</a>
                    </li>
                </ul>
                <div class="d-flex justify-content-between">
                    <div class="p-2">
                        <form class="form-inline">
                            <label class="my-1 mr-2 mt-1" for="p-brand">Select Brand</label>
                            <select *ngIf="brandList.length" class="form-control form-control-sm mr-3 mt-1" id="p-brand"
                                [(ngModel)]="selectedBrand" #selectedBrandValue
                                (ngModelChange)="selectedBrandValue.value = changeBrand($event)"
                                [ngModelOptions]="{standalone: true}">
                                <option *ngFor="let item of brandList" value="{{item.brandId}}">{{item?.brandName}}
                                </option>
                            </select>
                            <label class="my-1 mr-2 mt-1" for="p-scenario">Select Scenario</label>
                            <select class="form-control form-control-sm mr-3 mt-1" id="p-scenario" style="width: 150px;"
                                [(ngModel)]="selectedScenario" #selectedScenarioValue
                                (ngModelChange)="selectedScenarioValue.value = changeScenario($event)"
                                [ngModelOptions]="{standalone: true}">
                                <option *ngFor="let item of scenarioList" value="{{item.scenarioId}}">
                                    {{item?.scenarioName}}</option>
                            </select>

                            <a title="Save Scenario" class=" btn text-success  header-icons" ngbTooltip="Save scenario"
                                placement="left">
                                <span class="material-icons" (click)="saveScenario()">save</span>
                            </a>
                            <a title="Download Data" class="btn text-primary header-icons"
                                ngbTooltip="Export scenario to excel" placement="bottom">
                                <span class="material-icons" (click)="exportScenario()"> file_download </span>
                            </a>
                            <a title="Delete Scenario" class="btn text-danger header-icons" (click)="deleteScenario()"
                                ngbTooltip="Delete scenario" placement="right">
                                <span class="material-icons">delete_outline</span>
                            </a>

                        </form>
                    </div>

                    <!-- FOR OPTIMIZE -->
                    <div class="p-2 display-flex" *ngIf="selectedTab==='scm-optimize'">
                        <form class="form-inline">
                            <label class="my-1 mr-2 mt-1" for="p-changeperc">Enter change in budget (%)</label>
                            <input type="text" class="form-control form-control-sm mr-3 mt-1"
                                [ngModelOptions]="{standalone: true}" [(ngModel)]="changeBudget" id="p-changeperc"
                                style="width: 75px;">
                            <button class="btn btn-sm btn-primary"
                                (click)="optimizeChange(changeBudget)">Optimize</button>
                        </form>
                    </div>

                    <!-- FOR SIMULATE -->
                    <div class="p-2 display-flex" *ngIf="selectedTab==='scm-simulate'">
                        <form class="form-inline">
                            <div class="form-check">
                                <input class="form-check-input disable-effect" type="radio" name="flexRadioDefault"
                                    id="flexRadioDefault1">
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Spend $
                                </label>
                            </div>
                            <div class="form-check ml-3">
                                <input class="form-check-input disable-effect" type="radio" name="flexRadioDefault"
                                    id="flexRadioDefault2" checked>
                                <label class="form-check-label" for="flexRadioDefault2">
                                    Spend %
                                </label>
                            </div>

                            <button class="btn btn-sm btn-primary ml-3" (click)="simulate($event)">Simulate</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="tab-content bg-white" id="pricingTabsContent">

    <div class="tab-pane " id="scm-optimize" role="tabpanel" [ngClass]="{ 'active show':selectedTab==='scm-optimize'}">
        <div class="">
            <div *ngIf="scenarioList.length; then thenBlock else elseBlock"></div>
            <ng-template #thenBlock>
                <app-scm-optimize-static [selectedTab]="selectedTab" (newScenarioAdded)="updateScenario($event)"
                    [evaluateOptimizeEvents]="(evaluateOptimizeEvents)" [scenarioList]="scenarioList"
                    [changeScenerioOptimizeEvents]="(changeScenerioOptimizeEvents)"
                    [deleteOptimizeEvents]="(deleteOptimizeEvents)" [saveOptimizeEvents]="(saveOptimizeEvents)"
                    [exportOptimizeEvents]="(exportOptimizeEvents)" [selectedBrand]="selectedBrand"
                    [selectedScenario]="selectedScenario">
                </app-scm-optimize-static>
            </ng-template>
            <ng-template #elseBlock>
                <h1>NO DATA</h1>
            </ng-template>
        </div>
    </div>
    <div class="tab-pane" id="scm-simulate" role="tabpanel" [ngClass]="{ 'active show':selectedTab==='scm-simulate'}">
        <div style="padding: 4px;">
            <app-scm-simulate-static [selectedTab]="selectedTab" (newSimulateScenarioAdded)="updateScenario($event)"
                [saveSimulateEvents]="(saveSimulateEvents)" [scenarioList]="scenarioList"
                [deleteSimulateEvents]="(deleteSimulateEvents)" [evaluateSimulateEvents]="(evaluateSimulateEvents)"
                [exportSimulateEvents]="(exportSimulateEvents)" [selectedBrand]="selectedBrand"
                [selectedScenario]="selectedScenario"> </app-scm-simulate-static>
        </div>
    </div>
</div>