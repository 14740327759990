<div class="mt-5 pt-0" style="background: #FAFAFA;">
    <div class="container-fluid">



        <div class="row ">
            <div class="col-md-10 mt-2 pl-1 pr-1">
                <div class="policy-type">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12 pl-3 mb-0">
                                    <div class="policy-list">
                                        <h5>Agent Name : <span>David Alaba</span></h5>

                                    </div>
                                </div>
                                <div class="col-md-2 mb-0 pl-4 pr-0 customWidth">
                                    <div class="policy-details">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input"  checked onclick="return false;" type="checkbox" id="inlineCheckbox1"
                                                value="option1">
                                            <label class="form-check-label" for="inlineCheckbox1">Individual</label>
                                        </div>

                                        <!-- <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1"
                                                value="option1">
                                            <label class="form-check-label" for="inlineCheckbox1">(Male/Female)
                                                </label>
                                        </div> -->

                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input"  type="checkbox" id="inlineCheckbox1"
                                                value="option1">
                                            <label class="form-check-label"  for="inlineCheckbox1">Corporate
                                                </label>
                                        </div>
<!-- 
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1"
                                                value="option1">
                                            <label class="form-check-label" for="inlineCheckbox1">Primary</label>
                                        </div>

                                        <div class="form-check form-check-inline pad-2">
                                            <input class="form-check-input"  checked onclick="return false;" type="checkbox" id="inlineCheckbox1"
                                                value="option1">
                                            <label class="form-check-label" for="inlineCheckbox1">Secondary</label>
                                        </div> -->


                                    </div>
                                </div>

                                <div class="col-md-10 mb-0 pl-4 customWidt2">

                                    <form>
                                        <div class="form-group row mt-1 mb-0">
                                            <div class="col-md-4">
                                                <label class="col-form-label">Business Address:
                                                    <span>2333 W Jackson Blvd
                                                        Chicago, Illinois(IL), 60612</span>
                                                </label>
                                                <label class="col-form-label"> Home Address:
                                                    <span>2045 W Jackson Blvd
                                                        Chicago, Illinois(IL), 60612</span>
                                                </label>
                                            </div>

                                            <div class="col-md-3">
                                                <label class="col-form-label">Telephone No:
                                                    <span>(727) 551-6755

                                                    </span>
                                                </label>
                                                <label class="col-form-label">Email:
                                                    <span>alaba_rm@gmail.com

                                                    </span>
                                                </label>
                                            </div>

                                            <div class="col-md-3">

                                                <label class="col-form-label">Academic Qualification:
                                                    <span>Bachelor of Business Administration

                                                    </span>
                                                </label>
                                                <label class="col-form-label">Professional Qualification:
                                                    <span>Certified Insurance Counselor

                                                    </span></label>
                                            </div>

                                            <div class="col-md-2">
                                                <label class="col-form-label">Years of association:
                                                    <span class="text-center">11</span></label>
                                            </div>
                                            
                                           
                                           
                                            
                                           
                                          
                                           
                                        </div>
                                    </form>
                                </div>
                               
                            </div>

                        </div>

                    </div>

                </div>
            </div>

            <div class="col-md-2 mt-2 pl-1 pr-1">
                <div class="policy-type">
                    

                        <div class="form-group search-box">
                            <label class="form-check-label" for="autoSizingCheck">Policy Number Search</label>
                            <input class="form-control form-control-sm" type="text" id="autoSizingCheck"
                                [(ngModel)]="policyId">
                            <button class="btn btn-primary btn-sm mt-2"
                                (click)="searchPolicy(policyId)">Search</button>
                        </div>

                </div></div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="auto-personal-column">


                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Policies Sold</h4>
                            <div class="metric-val">200</div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">GWP - ('000)</h4>
                            <div class="metric-val">$23
                            </div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Pending Collection ('000)
                            </h4>
                            <div class="metric-val">$5 </div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Retention Rate
                            </h4>
                            <div class="metric-val">75%-90%
                            </div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Claim Ratio
                            </h4>
                            <div class="metric-val"> 80%-90% </div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Conversion Rate

                            </h4>
                            <div class="metric-val">90%</div>
                        </div>
                    </div>

                    <!-- <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Total</h4>
                            <div class="metric-val">4000
                            </div>
                        </div>
                    </div> -->

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Agent Sat Score</h4>
                            <div class="metric-val">6
                            </div>
                        </div>
                    </div>




                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">CSAT</h4>
                            <div class="metric-val">6</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row mt-2">

            <div class="col-md-3 pr-3">
                <div class="metric features chat-box">
                    <h4 class="groupHeading"><span><img src="assets/images/chat-bot.png" /></span>CSR in Call

                    </h4>
                    <ul class="reasons chat-content">
                        <li class="question"> Customer has a question on endorsement in IMT 29.CAn you explain? 
                        </li>
                        <li class="answer"> It is the legal liability to employees other than paid drivers traveling in the employer's car.</li>
                        <li class="question"> Clarify the additional premium mentioned, `50/- per employee insured?</li>
                        <li class="answer">The `50/- per employee is an additional premium that the insured pays for coverage under this endorsement. It extends the insurer's indemnification to cover liability for death or bodily injury to employees (other than paid drivers) traveling in or using the insured vehicle.
                        </li>
                        <!-- <li class="question">Does that exclude paid drivers?
                        </li>
                        <li class="answer"> Yes. The endorsement doesn't cover paid drivers. It's for other employees, like office staff or salespeople, traveling in the company's vehicle.
                        </li> -->
                        <!-- <li class="question"> Is he eligible for any discounts?
                                </li> -->

                    </ul>
                    <ul class="send-message">
                        <li>
                            <input type="text" placeholder="Start Typing Here..." class="form-control">
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-9">
                <div class="row">


                    <div class="col-md-12 pr-3">
                        <div class="metric features charts">
                            <h4 class="groupHeading"><span><img
                                        src="assets/images/Segment Recommendation.png" /></span>Sales Performance
                            </h4>
                            <div class="row">
                                <div class="col-md-6 pl-3 mt-2">
                                    <div class="card">
                                    <h5>Policies Sold</h5>
                                    <div id="lineChartContainer" style="width: 100%; height: 100px;"></div>
                                    <h5>GWP ( USD)
                                    </h5>
                                    <div id="gwdlineChartContainer" style="width: 100%; height: 100px;"></div>
                                </div>

                                </div>
                                <div class="col-md-6 mt-2">
                                    <div class="card">
                                    <h5>Product Diversity vs Avg. GWP</h5>
                                    <div id="donutChartContainer" style="width: 100%;height: 220px;"></div>
                                    </div>
                                </div>

                                <div class="col-md-6 pl-3">
                                    <div class="card">
                                    <h5>Expected Premium from Hot Leads
                                        <span style="float:right;width:170px; margin-right: 10px;"><select  (change)="changeChart($event.target.value)" class="form-control form-control-sm">
                                            <option *ngFor="let ePrem of expectedPremium" [value]="ePrem.value">{{ePrem.name}}</option>
                                            
                                        </select></span>
                                    </h5>
                                    
                                    <div class="row">
                                        <div class="col-md-6 pl-4">
                                            <p class="mb-0" style="font-size: 9px;">Total Premium $1.694.0</p>
                                            <div id="pieChartContainer" style="width: 100%;
                                            height: 200px;
                                            overflow: hidden;
                                            margin: 0 auto;"></div>
                                        </div>
                                        <div class="col-md-6 pr-5">
                                           <div class="row">
                                            <div class="col-md-6 p-0">
                                                <div id="columnChartContainer" style="width:100%;height: 200px;"></div>
                                            </div>
                                            <div class="col-md-6 p-0">
                                                <div id="columnChartContainer2" style="width:100%;height: 200px;"></div>
                                            </div>
                                           </div>
                                            
                                           
                                        </div>
                                    </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="card">
                                    <h5>Quote Volume and % won & Loss by Month</h5>
                                    <div id="combinationChartContainer" style="width: 100%; height: 225px;"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>