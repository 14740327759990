<div class="container-fluid content-bordeaux">
  <div class="row">
    <div class="table-responsive custom-table">
      <h4>Bordereaux Details</h4>
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table display" style="width:100%">
        <thead>
          <tr class="header">
            <th scope="col"></th>
            <th scope="col">Version</th>
            <th scope="col">File/Data Name</th>
            <th scope="col">Reporting Period</th>
            <th scope="col">Ingestion Type</th>
            <th scope="col">Size</th>
            <th scope="col">Uploaded By</th>
            <th scope="col">Last Modified</th>
            <th scope="col">Last Modified By</th>
            <th scope="col">Iteration</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody *ngIf="bordereauxListData?.length != 0">
          <tr #trRef *ngFor="let record of bordereauxListData; let i = index;"
            [ngClass]="{ 'expended-outline': record.expended }">
            <td style="width: 20px;">
              <i class="material-icons cursor-pointer" [ngClass]="{ 'expended-icon': record.expended }"
                (click)="expandRow(trRef, record[record.selected].oldIteration); record.expended = !record.expended">keyboard_arrow_down</i>
            </td>
            <td style="width: 170px;">
              <select [(ngModel)]="record.selected" class="form-control form-control-sm custom-width-control"
                (change)="updateVersion(trRef,i,record.selected)">
                <option *ngFor="let version of record.versions" [value]="version">{{version}}</option>
              </select>
              <ng-container *ngIf="record[record.selected].latestIteration.status=='Data uploaded'">
                <button type="button" class="btn btn-primary btn-sm inline-button-sm" data-dismiss="modal"
                  (click)="landToRespectiveMappingScreen(i,record[record.selected].latestIteration.uploadId, record[record.selected].latestIteration.mappingStatus, record[record.selected].latestIteration.workItemId)">Continue Mapping</button>
              </ng-container>
              <ng-container
                *ngIf="record[record.selected].latestIteration.status=='Ready For Standardization' || record[record.selected].latestIteration.status=='Standardization In-Progress' ||  record[record.selected].latestIteration.status=='System Error'">
                <button type="button" class="btn btn-primary btn-sm inline-button-sm" data-dismiss="modal"
                  (click)="standardizeProcess(i,record[record.selected].latestIteration.workItemId)"
                  [disabled]="record[record.selected].latestIteration.status=='Standardization In-Progress'">
                  <ng-container *ngIf="record[record.selected].latestIteration.status=='Standardization In-Progress'">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </ng-container>
                  Standardize
                </button>
              </ng-container>
            </td>
            <td style="width: 280px;">
              <p class="custom-ellipsis" title="{{record[record.selected].latestIteration.fileName}}" placement="right" [ngbTooltip]="tooltipOptimizeD" tooltipClass="custom-tooltipColor">{{record[record.selected].latestIteration.fileName}}</p>
              <ng-template #tooltipOptimizeD>
                <div>{{record[record.selected].latestIteration.fileName}}</div>
              </ng-template>
              <span class="badge "
                [ngClass]="{
                                'customBgBlue': record[record.selected].latestIteration.status=='Ready For Standardization' ,
                                'badge-success': record[record.selected].latestIteration.status=='Standardized',
                                'customBgRed':  record[record.selected].latestIteration.status=='Standardization Failed',
                                'customBgRedBack':  record[record.selected].latestIteration.status=='Mapping Failed',
                                'badge-danger':  record[record.selected].latestIteration.status=='System Error', 
                                'customBgYellow':  record[record.selected].latestIteration.status=='Standardization In-Progress',
                                'customBgGreen':  record[record.selected].latestIteration.status=='Data uploaded'}">{{record[record.selected].latestIteration.status}}
              </span>
            </td>
            <td style="width: 145px;"><p class="custom-ellipsisDate">
              {{record[record.selected].latestIteration.reportStartDate | date: 'd MMMM yy'}} to {{record[record.selected].latestIteration.reportEndDate | date: 'd MMMM yy'}}
            </p> </td>
            <td style="width: 40px;">{{record[record.selected].latestIteration.ingestionType}}</td>
            <td style="width: 63px;">{{record[record.selected].latestIteration.fileSizeInMb | number : '1.2-2'}} MB</td>
            <td style="width: 65px;"><p class="custom-ellipsisName">{{record[record.selected].latestIteration.uploadedBy}}</p></td>
            <td style="width: 90px;">{{record[record.selected].latestIteration.updatedAt | date: 'd MMMM yy h:mm:ss a'}}</td>
            <td style="width: 65px;"><p class="custom-ellipsisName">{{record[record.selected].latestIteration.modifiedBy}}</p></td>
            <td style="width: 50px;">{{record[record.selected].latestIteration.iteration}}</td>
            <td style="position: relative;width:35px">
                <div class="dropdown"><img src=".. /../../../../../../assets/images/dots.png" data-toggle="dropdown" aria-expanded="false" />
                  <div class="dropdown-menu">
                    <button *ngIf="userService.checkAccess('metamorph-standardization','delete')  && record[record.selected].latestIteration.status=='Data uploaded'" class="dropdown-item" type="button" (click)="deleteStandard(record[record.selected].latestIteration.workItemId)">Delete</button>
                    <button class="dropdown-item" type="button"><a href="{{record[record.selected].latestIteration.downloadInputLink}}">Download Source Data</a></button>
                  <button *ngIf="record[record.selected].latestIteration.status!='Data uploaded' && record[record.selected].latestIteration.status!='Ready For Standardization'" class="dropdown-item" type="button"> <a href="{{record[record.selected].latestIteration.downloadOutputLink}}">Download Standardized Data</a></button>
                  </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
  </div>
</div>