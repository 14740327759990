<div class="container-fluid mt-5 pt-4">
    <div class="row">
       
        <div class="col-md-3">

            <div class="card">
                <div>
                <div class="card-header">
                 <img src="assets/images/chatIcon.png"/> Agent: <span>{{agentname}}</span>
                </div>
            </div>
                <div class="card-body scrolling">
                  <h5 class="card-title">
                    <ul>
                    <li class="dateDetail">{{discriptionData[0]?.chatDate | date: 'dd MMM yyyy'}}</li>
                    <li class="timeDetail">Duration: <span class="duration">{{discriptionData[0]?.chatDuration}} m</span></li>
                    </ul>
                    
                </h5>
                 <div class="chatBox">
                    <ul *ngFor="let chat of chatListData">
                        <li [ngClass]="{
                            'agentDetail': chat.chatBy == 'agent',
                            'consumerDetail':  chat.chatBy == 'consumer'
                          }" >
                            <p  [ngClass]="{
                                'agentDate': chat.chatBy == 'agent',
                                'consumerDate': chat.chatBy == 'consumer'
                              }" >
                                {{getFormattedDate(chat.chatTime)}}
                            </p>
                            <p [ngClass]="{
                                'agentChat': chat.chatBy == 'agent',
                                'consumerChat':chat.chatBy == 'consumer'
                              }" >
                                {{chat.chatDetail}}
                            </p>
                        </li>
                        
                    </ul>
                 </div>
                 
                </div>
              </div>
            
        </div>
        <div class="col-md-9">

            <div class="card" >
                <div class="card-header spacing">
                    <a (click)="goBack()" class="backButton"><img src="assets/images/chatBack.png"/></a>
                  <ul class="tiles">
                    <li><strong>ID:</strong> <span class="id">{{chatTransciptId}}</span></li>
                    <li><strong>FCR: </strong><span [ngClass]="{ 'fcrNo': fcr === true, 'fcrYes': fcr === false }">{{ fcr === true ? 'No' : 'Yes' }}
                      </span>
                    <ng-container *ngIf="this.fcrReason !='' && this.fcrReason !='-'">
                        <span class="knowWhy">know Why? <img src="assets/images/knowwhy.png" aria-haspopup="true" aria-expanded="false" data-html="true"
                            placement="bottom" [ngbTooltip]="tooltipOptimize" tooltipClass="custom-tooltipColor"/>
                        
                        <ng-template #tooltipOptimize><div>{{this.fcrReason}}</div></ng-template>
                        </span>
                    </ng-container>
                </li>
                    <li><strong class="strong">Consumer Rating</strong>
                        <span *ngFor="let star of [1, 2, 3, 4, 5]"
                            [ngClass]="{
                            'active-star': star <= consumerRating,
                            'inactive-star': star > consumerRating
                            }">
                            &#9733;
                        </span>
                    </li>

                    <li><strong class="strong">Model Rating</strong>
                        <span *ngFor="let star of [1, 2, 3, 4, 5]"
                                [ngClass]="{
                                'active-star': star <= moduleRating,
                                'inactive-star': star > moduleRating
                                }">
                            &#9733;
                        </span>

                    </li>

                    <li><span class="strong">Topics <span class="break">Triggered</span></span>
                        <span class="Triggered"> {{topicTrigger}} </span>
                    </li>

                  </ul>
                </div>
                <div class="card-body mb-5" style="background: rgba(250, 250, 250, 1);
                ">
                 <div class="summary">
                    <h3><img src="assets/images/chatSummary.png"/><strong> Detractor analysis summary</strong></h3>
                    <ul class="summaryListing" >
                        <ng-container *ngFor="let analysisData of analysis; let i = index">

                            <!-- <li *ngIf="i>1"> <a href="javascript:void(0);" style="text-decoration: none;" (click)="changeSelectedView(i)">{{analysisData.title}}</a></li>
                            

                            <li style="cursor: pointer;"> {{analysisData.title}}</li> -->

                            <li> {{analysisData.title}}</li>
                        </ng-container>
                       
                       
                    </ul>
                 </div>

                 <ng-container *ngFor="let analysisData of analysis; let i = index">
                 <div class="transcript" id="analysis_{{i}}">
                    <h3><strong>{{analysisData.title}}:</strong></h3>
                    <ul>
                        <li class="reason" *ngIf="analysisData.reason!='' && analysisData.reason!=' ' && analysisData.reason!='-'">
                            <img src="assets/images/reason.png"/>
                            <span>Reason</span> 
                            {{analysisData.reason}}
                        </li>
                        <li class="transcriptChat" *ngIf="analysisData.transcript!='' && analysisData.transcript!=' ' && analysisData.transcript!='-'"> 
                            <img src="assets/images/transcript.png"/>
                           <span>Transcript</span> 
                           {{analysisData.transcript}}
                        </li>
                    </ul>
                 </div>


                
                </ng-container>
                </div>
              </div>
        </div>

    </div>
</div>
