import { Component, Input, OnInit } from '@angular/core';
import { MetamorphCommonService } from '../../../../services/metamorph-common.service';
import { UserServiceService } from 'src/app/shared/services/user-service.service';

@Component({
  selector: 'app-child-table-component',
  templateUrl: './child-table-component.component.html',
  styleUrls: ['./child-table-component.component.scss']
})
export class ChildTableStandardComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  @Input() dataRow: any;
   //Context Menu
   contextmenu = false;
   contextmenuX = 0;
   contextmenuY = 0;
   contextId:any;
   contextRecord:any;
   contextClass="childClass"

  constructor( public metamorphCommonService: MetamorphCommonService,
    public userService: UserServiceService) { }

  ngOnInit(): void {
    this.dtOptions = {
      info: false,
      lengthChange: false,
      processing: true,
      searching: false,
      paging: false,
      destroy:true,
      deferRender: true,
      
    }
  }

   /***
   * @desc Activates the context menu with the coordinates
   * @param event
   *
   */
   onrightClick(event,indexId,recordData){
    this.contextmenuX=event.clientX;
    this.contextmenuY=event.clientY;
    this.contextId = indexId;
    this.contextRecord = recordData;
    this.contextmenu=true;
  }


  /***
   * @desc Disable Context Menu
   *
   */
  disableContextMenu(){
    this.contextmenu= false;
  }

}
