import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Highcharts from 'highcharts';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PricingService } from '../../pricing.service';
import { PricingModelValidationService } from '../pricing-model-validation.service';

@Component({
  selector: 'app-pricing-model-validation-impact',
  templateUrl: './pricing-model-validation-impact.component.html',
  styleUrls: ['./pricing-model-validation-impact.component.scss']
})
export class PricingModelValidationImpactComponent implements OnInit {
  chartFilters: Array<any> = [];
  public lobProjectId: any;
  localStorage = localStorage;
  public modelTechnique = "";
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: any;
  chartModelData: any;
  closeResult: string;
  edaChartTemplate: any[];
  updateFlag: boolean;
  actualChartTemplate: any[];
  public modelTechniquesFilter: Array<any> = []

  constructor(private modalService: NgbModal, private pricingModelValidationService: PricingModelValidationService,
    private pricingService: PricingService, private toastr: ToastrService,
    private ngxLoader: NgxUiLoaderService) { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    Promise.all([this.localStorage.getItem('lobProjectId')]).then(values => {
      this.lobProjectId = values[0];
      this.getFilterData(this.lobProjectId);
    });
  }

  ngOnDestroy(): void {
    this.pricingService.setSelectModelVisible(false);
  }

  // Updateing chart data on filter change 
  updateFilterStatus(checkbox, i, filterType) {
    if (this.modelTechniquesFilter.includes(checkbox.id)) {
      this.modelTechniquesFilter = this.modelTechniquesFilter.filter(e => e !== checkbox.id);
    } else {
      this.modelTechniquesFilter.push(checkbox.id);
      this.modelTechniquesFilter = this.modelTechniquesFilter.sort(function (a, b) { return a - b; });
    }
    if (!this.modelTechniquesFilter.length) {
      this.toastr.error('Select at least one filter form each filter group');
      this.chartOptions = [];
    } else {
      this.modelTechnique = this.modelTechniquesFilter.join(",");
      this.getImpactChartData(this.lobProjectId, this.modelTechnique);
    }
  }

  // Getting impact chart data and formatting chartOption
  getImpactChartData(lobProjectId, modelTechnique) {
    // Fetching chart template
    this.pricingModelValidationService.getImpactChartTemplateJSON().subscribe(template => {
      if (template) {
        this.actualChartTemplate = template;
        // Fetching chart API data
        this.pricingModelValidationService.getImpactChartData(lobProjectId, modelTechnique).subscribe(apiResponce => {
          if (apiResponce) {
            // merging API data
            this.pricingModelValidationService.mergeImpactChartData(apiResponce).subscribe((simplifiedResponse) => {
              // Constructing impact chart data
              this.pricingModelValidationService.formatImpactChartData(simplifiedResponse, this.actualChartTemplate).subscribe(finalResponse => {
                if (finalResponse) {
                  this.chartOptions = finalResponse;
                  this.ngxLoader.stopAll();
                }
              });
            })
          } else { this.ngxLoader.stopAll(); }
        });
      };
    });
  }

  getFilterData(lobProjectId) {
    this.ngxLoader.start();
    this.pricingModelValidationService.getFilterChartPanelData(lobProjectId).subscribe(data => {
      if (data) {
        this.pricingModelValidationService.formatImpactChartFilterPanelData(data).subscribe((formattedResponse) => {
          this.formatFilterData(formattedResponse);
        });
      }
    });
  }

  // Formatting filter panel data into filter onject
  formatFilterData(formattedResponse) {
    const filterType1 = ["pythonModels"];
    let chartFilters: any;
    if (formattedResponse) {
      chartFilters = formattedResponse.filter((item) => filterType1.includes(item.filterType));
      chartFilters.forEach((item) => {
        item.values.forEach(element => {
          if(element.isSelected) {
            this.modelTechniquesFilter.push(element.id);
          }
        });
        this.modelTechnique = this.modelTechniquesFilter.join(',');
      });
      this.customizeImpactChartFilter(chartFilters[0]);
      this.getImpactChartData(this.lobProjectId, this.modelTechnique);
    }
  }

  // Customize impact chart filter panel data
  customizeImpactChartFilter(filters){
    const impactFilters = ['Python', 'Emblem'];
    const formattedFilter: Array<any> = [];
    const filterConfig = {
      filterType: "pythonModels",
      isMultySelect: true,
      title: {name: "Emblem"},
      values: []
    }
   impactFilters.forEach(element => {
    const modifiedFilter =  {
        ...filterConfig,
        title: {
          name: element
        },
        values: element === 'Python' ? filters.values.filter(el => el.modelId === 1 || el.modelId === 2): filters.values.filter(el => el.modelId === 6)
      }
      this.chartFilters.push(modifiedFilter);
    });
  }

  filterUser(item) {
    return !!item.displayChart;
  }

  // Expand chart
  openXl(content, data) {
    this.chartModelData = data;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
