<nav aria-label="breadcrumb ">
  <ol class="breadcrumb">
    <li class="" (click)="navigateBackTo('/metamorph/rule-config')"><i class="material-icons">keyboard_backspace</i>
    </li>
    <li class="breadcrumb-item" *ngFor="let item of breadcrumb; last as isLast">
      <a *ngIf="!isLast" href="javascript:void(0);" (click)="navigateBackTo('/metamorph/rule-config')">{{item}}</a>
      <span *ngIf="isLast">{{item}}</span>
    </li>
  </ol>
</nav>

<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <!-- tabs -->
      <div class="module-nav">
        <ul class="nav nav-tabs" id="supplyChain" role="tablist">
          <li class="nav-item">
            <a class="nav-link sub-module" (click)="selectTab('rule-config', 'Rule Config', $event)" role="tab" [ngClass]="{ 'active':selectedTab==='rule-config'}">Rule Config</a>
          </li>
        </ul>
      </div>
      <!-- don't remove this is Add New Rule -->
      <!-- <div class="add-btn-wrapper">
        <button class=" btn btn-sm btn-outline-primary">Add New Rule</button>
      </div> -->
      <div class="shadow w-bg">
        <!-- Info panel -->
        <div class="container-fluid  custom-grid">
          <div class="row info-panel">
            <ng-container  *ngFor="let item of operationalData, let last = last">
            <div class="col-sm-2" *ngIf="item.label!='Process Id'">
              <div [ngClass]="{ 'dotted-border': !last }" class="custom-dotted-border">
                  <p>{{item?.label}}</p>                 
                  <h3>{{item?.value}}</h3>
                </div>
            </div>
            </ng-container>
          </div>
          
        </div>
         <!-- don't remove this is replicate from -->
        <!-- <div class="replicate-from-wrapper form-inline">
          <label class="form-label">Replicate from: </label>
          <select class="form-control form-control-sm m-r-15 min-w-100">
          <option>Select</option>
          <option *ngFor="let item of [1,2,3,4,5]; let i = index" [attr.value]="item">CoverHolder {{item}}</option>
        </select>
        </div> -->



        <div class="bg-white  rounded p-2 container-scroll">
          <!-- tab pannel -->
          <div class="tab-content bg-white" id="pricingTabsContent">
            <div  class="tab-pane" id="" role="tabpanel" [ngClass]="{ 'active show':selectedTab==='rule-config'}">
              <div class="p-2">
                
                <rule-config-list ></rule-config-list>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row footer-panel">
        <div class="col-sm-12">
         
        </div>
      </div>
    </div>
  </div>
</div>