import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PricingRoutingModule } from './pricing-routing.module';
import { PricingViewComponent } from './pricing-view/pricing-view.component';
import { PricingLobComponent } from './pricing-lob/pricing-lob.component';
import { PricingProjectComponent } from './pricing-project/pricing-project.component';
import { PricingManageModelComponent } from './pricing-manage-model/pricing-manage-model.component';
import { PricingModelAnalysisComponent } from './pricing-model-analysis/pricing-model-analysis.component';
import { PricingDataExplorationComponent } from './pricing-data-exploration/pricing-data-exploration.component';
import { PricingModelInterpretabilityComponent } from './pricing-model-interpretability/pricing-model-interpretability.component';
import { PricingModelValidationComponent } from './pricing-model-validation/pricing-model-validation.component';
import { PricingFilterChartComponent } from './pricing-filter-chart/pricing-filter-chart.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PricingModelValidationLiftComponent } from './pricing-model-validation/pricing-model-validation-lift/pricing-model-validation-lift.component';
import { PricingModelValidationActualComponent } from './pricing-model-validation/pricing-model-validation-actual/pricing-model-validation-actual.component';
import { PricingModelValidationImpactComponent } from './pricing-model-validation/pricing-model-validation-impact/pricing-model-validation-impact.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { DataTablesModule } from 'angular-datatables';
import { PricingFilterPipe } from './pricing-filter.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PricingModelAnalysisOverviewComponent } from './pricing-model-analysis/pricing-model-analysis-overview/pricing-model-analysis-overview.component';
import { PricingModelAnalysisComparisionComponent } from './pricing-model-analysis/pricing-model-analysis-comparision/pricing-model-analysis-comparision.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PricingModelInterpretabilityPdpComponent } from './pricing-model-interpretability/pricing-model-interpretability-pdp/pricing-model-interpretability-pdp.component';
import { PricingModelInterpretabilityShapComponent } from './pricing-model-interpretability/pricing-model-interpretability-shap/pricing-model-interpretability-shap.component';
import { SelectModelComponent } from './select-model/select-model.component';

@NgModule({
  declarations: [PricingViewComponent, PricingLobComponent, PricingProjectComponent, PricingManageModelComponent, PricingModelAnalysisComponent, PricingDataExplorationComponent, PricingModelInterpretabilityComponent, PricingModelValidationComponent, PricingFilterChartComponent, PricingModelValidationLiftComponent, PricingModelValidationActualComponent, PricingModelValidationImpactComponent, PricingFilterPipe, PricingModelAnalysisOverviewComponent, PricingModelAnalysisComparisionComponent, PricingModelInterpretabilityPdpComponent, PricingModelInterpretabilityShapComponent, SelectModelComponent],
  imports: [
    SharedModule,
    CommonModule,
    PricingRoutingModule,
    FormsModule, ReactiveFormsModule ,
    HighchartsChartModule,
    DataTablesModule,
    NgbModule
  ],
  providers: []
})
export class PricingModule { }
