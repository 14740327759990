import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LeftBarComponent } from './left-bar/left-bar.component';
import { ShortNamePipe } from './header/short-name.pipe';
import { NotificationComponent } from './notification/notification.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [HeaderComponent, FooterComponent,LeftBarComponent,ShortNamePipe, NotificationComponent],
  imports: [
    CommonModule,NgbModule
  ],
  exports:[LeftBarComponent,HeaderComponent,FooterComponent]
})
export class CoreModule { }
