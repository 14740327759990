import { Component, Input, OnChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-select-field-popup',
  templateUrl: './select-field-popup.component.html',
  styleUrls: ['./select-field-popup.component.scss']
})
export class SelectFieldPopupComponent implements OnChanges {

  @Input() title: string;
  @Input() data: Array<any>;
  @Input() btnOkText: string;
  @Input() index: number;
  @Input() btnCancelText: string;
  Object = Object;
  selectedColumn = '';

  constructor(private activeModal: NgbActiveModal) {

  }

  ngOnChanges(): void {
    this.data['other'].push('Share Percent');
  }

  selectField(columnName) {
    this.selectedColumn = columnName
  }


  public decline() {
    this.activeModal.close({
      isSelected : false, selectedColumn: '', index: null
    });
  }

  public accept() {
    this.activeModal.close({
      isSelected : true, selectedColumn: this.selectedColumn, index: this.index
    });
  }

}
