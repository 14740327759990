import { Component, Input, OnInit } from "@angular/core";
import { MetamorphCommonService } from "../../services/metamorph-common.service";
import { Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { BordereauxManagementService } from "../../services/bordereaux-management.service";

@Component({
  selector: "rule-config-base",
  templateUrl: "./rule-config-base.component.html",
  styleUrls: ["./rule-config-base.component.scss"]
})
export class RuleConfigBaseComponent implements OnInit {
  public selectedTab = "rule-config";
  operationalData: any;
  breadcrumb = ["Coverholder", "Rule Config"];
  filterObject = {
    coverHolderId: 0,
    countryId: 0,
    ruleTypesId: 0,
    tagsId:0,
    lastModifiedById: 0,
  };

  constructor(
    private metamorphCommonService: MetamorphCommonService,
    private route: Router,
    private bordereauxManagementService: BordereauxManagementService,
    private ngxLoader: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.operationalData = JSON.parse(this.metamorphCommonService.getRuleConfigInfoData());
  } 
  selectTab(selectedTab, tabName, event) {
    this.selectedTab = selectedTab;
    this.breadcrumb[1] = tabName;
  }

  navigateBackTo(url) {
    this.route.navigate([url]);
  }

  
}
