import { Component, OnInit,ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { ChatAnalysisService } from '../../chat-analysis.service';
import { CDK_CONNECTED_OVERLAY_SCROLL_STRATEGY_PROVIDER } from '@angular/cdk/overlay/overlay-directives';
@Component({
  selector: 'app-chat-analysis-description',
  templateUrl: './chat-analysis-description.component.html',
  styleUrls: ['./chat-analysis-description.component.scss']
})
export class ChatAnalysisDescriptionComponent implements OnInit {

  discriptionData: Array<any> = []
  chatData:any;
  chatId:any;
  agentname:any;
  chatDate:any;
  chatDuration:any;
  chatListData:any;
  chatTransciptId:any;
  fcr:any;
  fcrReason:any;
  consumerRating:any;
  moduleRating:any;
  topicTrigger:any;
  analysis:any;
  currentSelectedView:any;
  constructor(public chatAnalysisService: ChatAnalysisService,private ngxLoader: NgxUiLoaderService, private route: Router,private elementRef: ElementRef) { }

  ngOnInit(): void {
   
    this.chatData = this.chatAnalysisService.getChat();
    console.log("this.chatData",this.chatData)
    this.chatId = this.chatData?.chatId;
    this.topicTrigger = this.chatData?.topicsTriggered;
    this.getdiscriptionData();
    
   
  }
  getdiscriptionData(): void {
    this.discriptionData = [];
    console.log("this.chatId",this.chatId)
    this.chatAnalysisService.getDiscriptionStatus(this.chatId).subscribe(response => {
      console.log(response);
      if (response && response.data) { 
        this.discriptionData = response.data;
        console.log("data", this.discriptionData[0]);
        this.agentname = this.discriptionData[0]?.agent;
        this.chatDate = this.discriptionData[0]?.chatDate;
        this.chatDuration = this.discriptionData[0]?.chatDuration;
        this.chatListData = this.discriptionData[0]?.transcriptions;
        this.chatTransciptId =this.discriptionData[0]?.chatTranscriptionId;
        this.fcr =this.discriptionData[0]?.fcr;
        this.fcrReason =this.discriptionData[0]?.fcrReason;
        this.consumerRating =this.discriptionData[0]?.consumerRating;
        this.moduleRating =this.discriptionData[0]?.modelRating;
        this.analysis = this.discriptionData[0]?.analysis;
        console.log("analysis", this.analysis);
        

        this.ngxLoader.stop(); // If ngxLoader is used to stop loader, make sure ngxLoader is properly initialized
      } else {
        console.error('Invalid response format:', response);
      }
    });
  }

  goBack(){
    this.route.navigate(['/chat/analysis-list']);

  }

  changeSelectedView(analysisId){
    console.log("analysisId",analysisId);
    this.currentSelectedView = "analysis_"+analysisId;
    const targetDiv = this.elementRef.nativeElement.querySelector('#analysis_'+analysisId);
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  getFormattedDate(dateString) {

    // Create a new Date object with the input date string
    const date = new Date(dateString);

    // Get the time components (hours, minutes, seconds)
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();

    // Format the time with leading zeros
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

    console.log(formattedTime);
    return formattedTime
}

}
