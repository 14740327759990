import { Component, Input, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Highcharts from 'highcharts';
import { PricingService } from '../../pricing.service';
import { PricingModelValidationService } from '../pricing-model-validation.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pricing-model-validation-actual',
  templateUrl: './pricing-model-validation-actual.component.html',
  styleUrls: ['./pricing-model-validation-actual.component.scss']
})
export class PricingModelValidationActualComponent implements OnInit {
  chartFilters: any;
  public lobProjectId: any;
  localStorage = localStorage;
  public exploratoryVariables = "";
  public modelType = "";
  public modelTechnique = "1,2";
  public filterForExpoDataChart: any;
  expoDataChartTemplate: any;
  updatedFilterData: any;
  charts = [1, 2];
  public modelTypeFilters: Array<any> = [];
  public exploratoryVariablesFilters: Array<any> = [];
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: any;
  chartModelData: any;
  closeResult: string;
  edaChartTemplate: any[];
  updateFlag: boolean;
  actualChartTemplate: any[];
  activeModelTechniqueFilter: any;
  modelTechniqueFilterBuffer: any;

  constructor(private modalService: NgbModal, private pricingModelValidationService: PricingModelValidationService,
    private pricingService: PricingService, private toastr: ToastrService,
    private ngxLoader: NgxUiLoaderService) { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    Promise.all([this.localStorage.getItem('lobProjectId')]).then(values => {
      this.lobProjectId = values[0];
      this.getFilterData(this.lobProjectId);
    });
  }

  // Update data on filter update
  updateActualChartFilterStatus(checkbox, i, filterType) {
    this.modelTechniqueFilterBuffer = this.chartFilters.filter(e => e.filterType === 'modelTechniques');
    if (filterType === 'modelTechniques') {
      this.modelTechniqueFilter(checkbox);
    } else {
      if (filterType === 'modelTypes') {
        if (this.modelTypeFilters.includes(checkbox.id)) {
          this.modelTypeFilters = this.modelTypeFilters.filter(e => e !== checkbox.id);
        } else {
          this.modelTypeFilters.push(checkbox.id);
          this.modelTypeFilters = this.modelTypeFilters.sort(function (a, b) { return a - b; });
        }
      }
      else if (filterType === 'exploratoryVariables') {
        if (this.exploratoryVariablesFilters.includes(checkbox.id)) {
          this.exploratoryVariablesFilters = this.exploratoryVariablesFilters.filter(e => e !== checkbox.id);
        } else {
          this.exploratoryVariablesFilters.push(checkbox.id);
          this.exploratoryVariablesFilters = this.exploratoryVariablesFilters.sort(function (a, b) { return a - b; });
        }
      }
      if (!this.modelTypeFilters.length || !this.exploratoryVariablesFilters.length) {
        this.toastr.error('Select at least one filter form each filter group');
        this.chartOptions = [];
      } else {
        this.modelType = this.modelTypeFilters.join(",");
        this.exploratoryVariables = this.exploratoryVariablesFilters.join(",");
        this.getActualChartData(this.lobProjectId, this.modelType, this.modelTechnique, this.exploratoryVariables);
      }
    }
  }

  // API call and chart option construction
  getActualChartData(lobProjectId, modelType, modelTechnique, exploratoryVariables) {
    // Fetching Actual vs Predicted chart template
    this.pricingModelValidationService.getActualChartTemplateJSON().subscribe(template => {
      if (template) {
        this.actualChartTemplate = template;
        // Fetch Actual vs Predicted chart API data
        this.pricingModelValidationService.getLobModelValidationActualChart(lobProjectId, modelType, modelTechnique, exploratoryVariables).subscribe(apiResponce => {
          if (apiResponce) {
            //  Formatting Actual vs Predicted chart API data into chartOption
            this.pricingModelValidationService.formateActualChartAPIData(apiResponce.data, this.actualChartTemplate).subscribe(finalResponse => {
              if (finalResponse) {
                // Updating chart Options based on filter
                if (this.modelTechniqueFilterBuffer && this.modelTechniqueFilterBuffer.length) {
                  this.pricingModelValidationService.modelTechniqueFilter(this.modelTechniqueFilterBuffer, finalResponse).subscribe(data => {
                    if (data) {
                      this.updateFlag = data.updateFlag;
                      this.chartOptions = data.chartOptions;
                    } else {
                      this.ngxLoader.stopAll();
                    }
                  })
                } else {
                  this.chartOptions = finalResponse;
                }
                this.ngxLoader.stopAll();
              } else { this.ngxLoader.stopAll(); }
            });
          } else { this.ngxLoader.stopAll(); }
        });
      };
    });
  }

  modelTechniqueFilter(item) {
    this.chartOptions.forEach((chartOption) => {
      chartOption.series.forEach((element) => {
        if (element.name.split(' ')[0].toLowerCase() === item.name.toLowerCase()) {
          element.visible = item.isSelected;
        }
      });
    });
    this.updateFlag = true;
  }

  // Fetching filter panel and formatting into filter object
  getFilterData(lobProjectId) {
    this.ngxLoader.start();
    this.pricingModelValidationService.getFilterChartPanelData(lobProjectId).subscribe(data => {
      if (data) {
        this.pricingModelValidationService.formatFilterPanelData(data).subscribe((formattedResponse) => {
          this.formatFilterData(formattedResponse);
        });
      }
    });
  }
// Converting filter API data into filter object 
  formatFilterData(formattedResponse) {
    const filterType1 = ["modelTechniques", "modelTypes", "exploratoryVariables"];
    if (formattedResponse) {
      this.chartFilters = formattedResponse.filter((item) => filterType1.includes(item.filterType));
      this.chartFilters.forEach((element, index) => {
        if(element.filterType === 'modelTypes') {
          element.values.forEach((item) => {
            if (item.isSelected) {
              this.modelTypeFilters.push(item.id);
            }})
          this.modelType = this.modelTypeFilters.join(',') 
        }else if(element.filterType === 'exploratoryVariables') {
          element.values.forEach((item) => {
            if (item.isSelected) {
              this.exploratoryVariablesFilters.push(item.id);
            }})
          this.exploratoryVariables = this.exploratoryVariablesFilters.join(',') 
        }
      });
      this.getActualChartData(this.lobProjectId, this.modelType, this.modelTechnique, this.exploratoryVariables);
    }
  }



  filterUser(item) {
    return !!item.displayChart;
  }

// Expand chart
  openXl(content, data) {
    this.chartModelData = data;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
