import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tax-validation',
  templateUrl: './tax-validation.component.html',
  styleUrls: ['./tax-validation.component.scss']
})
export class TaxValidationComponent implements OnInit {
  @Input() selectedTab: any;
  constructor() { }

  ngOnInit(): void {
  }

}
