import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MetamorphCommonService } from 'src/app/module/metamorph/metamorph-base/services/metamorph-common.service';
import { FileUploadProgressService } from 'src/app/shared/services/file-upload-progress.service';
import { SharedService } from 'src/app/shared/services/shared.service';
@Component({
  selector: 'column-mapping',
  templateUrl: './column-mapping.component.html',
  styleUrls: ['./column-mapping.component.scss'],
})
export class ColumnMappingComponent implements OnInit {
  @Input() fileData: any;
  @Input() uploadId: any;
  @Input() destinationTemplateData: any;
  @Input() existingSheetMappingData: any;
  @Input() sheetMappingSucessResponeData: any;
  @Output() nextButtonEvent = new EventEmitter<any>();
  @Output() backButtonEvent = new EventEmitter<any>();
  @Output() exitButtonEvent = new EventEmitter<any>();
  Object = Object;
  disabled = false;
  selectedSheet: any;
  selectedColumnMapping: number;
  custompanel_1: string[] = [];
  isCollapsedAll = true;
  invalidSheetOrHeader: boolean = false;
  sheetIdUpdated: any;
  destinationTemplateSheetId: any;
  ColumnMappingData: any;
  selectedUserSheetId: any;
  selectedTemplateSheetId: number;
  destinationTemplateColumnData: any;
  existingColumnMappingData = [];
  isEditFlow: boolean = false;
  userFileSheetData: any;
  selectedSearchField: number;
  fieldList = [
    { id: 1, name: 'search Field 1' },
    { id: 2, name: 'search Field 2' },
    { id: 3, name: 'search Field 3' },
    { id: 4, name: 'search Field 4' },
  ];
  saveUpdatePayload: Array<any> = [];
  romanSerialNos = ['i', 'ii', 'iii', 'iv', 'v'];
  isOldView: boolean = false;
  formattedSheetMappingData: Array<any> = [];
  sheetsList: string[];
  userFileSheetDataBuffer: any;
  constructor(
    private ngxLoader: NgxUiLoaderService,
    private fileUploadProgressService: FileUploadProgressService,
    public toastr: ToastrService,
    private sharedService: SharedService,
    private metamorphCommonService: MetamorphCommonService
  ) { }

  ngOnInit(): void {
    this.sharedService.getCurrentTemplateId().subscribe((id) => {
      this.selectedTemplateSheetId = id;
      this.fileUploadProgressService
        .getSheetMapping(this.uploadId)
        .subscribe((response) => {
          if (response && response.code === 200) {
            this.sheetsList = response.data.map((e) => {
              return e.sheetName;
            });
            this.selectedSheet = this.sheetsList[0];
            this.selectedUserSheetId = response.data[0].sheetId;
            this.getExistingColumnMappingData(this.selectedUserSheetId);
          }
        });
    });
  }

  /**
   * get destination Sheets list
   * after getting destination sheet list it will call the createSheetMappingFormRows() method and set destination templete to the parent component
   * @returns createSheetMappingFormRows()
   */
  getdestinationSheetData() {
    this.ngxLoader.start();
    this.fileUploadProgressService.getDestinationSheetName(this.selectedTemplateSheetId).subscribe((response) => {
        if (response && response.code === 200) {
          this.destinationTemplateSheetId = response?.data[0].sheetId;
          this.getColumnMappingData( this.uploadId, this.destinationTemplateSheetId);
        }
      });
  }

  /**
   * Method to fetch previously saved column mapping data
   * @param id (selectedUserSheetId)
   * based on the response we decide wheather its a New mapping or Editing existing mapping
   * once we get the response of getSavedColumnMapping , then we call the subsequent API to get template and userfile sheet data
   */
  getExistingColumnMappingData(id) {
    this.fileUploadProgressService.getSavedColumnMapping(id).subscribe((response) => {
      if (response && response.data) {
        this.existingColumnMappingData = response.data.sort((a, b) => a.templateColumnName.localeCompare(b.templateColumnName));
        this.getdestinationSheetData();
        this.isEditFlow = true;
      } else if (response === null) {
        this.isEditFlow = false;
        this.getdestinationSheetData();
      }
    });
  }

  selectTab(selectedTab, event) {
    this.selectedSheet = selectedTab.sheetName;
  }

  goToNext() {
    this.saveAndNextColumnMapping('saveAndNext');
  }

  goForSave() {
    this.saveColumnMapping('save');
  }

  goToBack() {
    this.backButtonEvent.next('column-mapping');
  }

  getExited() {
    this.exitButtonEvent.next('exit');
  }

  /**
   * Method to fetch data for destination template sheet and UserFile sheet
   * @param selectedUploadId
   * @param selectedTemplateSheetId
   * This method will fetch the destination template sheet and UserFile sheet and construct the
   */
  getColumnMappingData(selectedUploadId, selectedTemplateSheetId) {
    this.ngxLoader.start();
    this.fileUploadProgressService.getSheetMappingData(selectedUploadId, selectedTemplateSheetId).subscribe((response) => {
      if (response) {
        this.destinationTemplateColumnData = response[0].data.sort((a, b) => a.columnName.localeCompare(b.columnName));
        this.userFileSheetData = response[1].data.filter((e) => e !== null);
        this.userFileSheetDataBuffer = response[1].data.filter((e) => e !== null);
        // if existingColumnMappingData has length the we construct formatted json based on existing data
        // else we construct based on destination Template sheet data to plot data on the UI
        if (this.existingColumnMappingData.length) {
          this.formattedSheetMappingData = this.existingColumnMappingData.map((item, index) => {
            const recomendedColumn = this.destinationTemplateColumnData.filter((e) => e.columnId === item.templateColumnId)[0].recommendation.map((element) => {
              return {
                columnName: element,
                isRecomendation: 'Recomendations',
              };
            });
            const nonRecomendedColumns = this.userFileSheetData.map((element) => {
              return { columnName: element.name, isRecomendation: 'All' };
            }
            );
            return {
              destinationTemplateData: this.destinationTemplateColumnData.filter((e) => e.columnId === item.templateColumnId)[0],
              userFileSheetData: this.userFileSheetData.filter((e) => {
                if (e && e.data) { return e.name.toString() === item.columnName.toString(); }
              }).length ? this.userFileSheetData.filter((e) => {
                if (e && e.data) {
                  return (
                    e.name.toString() === item.columnName.toString()
                  );
                }
              })[0]
                : { name: '', data: [], type: '' },
              isExpended: false,
              hasRquiredFieldError: false,
              userFileColumnId: item.columnId,
              isDuplicate: false,
              mappedUserSheetColumn: this.userFileSheetData.filter((e) => {
                if (e && e.data) {
                  return e.name.toString() === item.columnName.toString();
                }
              }).length
                ? this.userFileSheetData
                  .filter((e) => e.name.toString() === item.columnName)[0]
                  .name.toString()
                : null,
              userDropDownOption: this.mergeArraysAndRemoveDuplicates(
                recomendedColumn,
                nonRecomendedColumns,
                'columnName'
              ),
            };
          }
          );
        } else {
          this.formattedSheetMappingData = this.destinationTemplateColumnData.map((item, index) => {
            const recomendedColumn = item.recommendation.map((element) => {
              return {
                columnName: element,
                isRecomendation: 'Recomendations',
              };
            });
            const nonRecomendedColumns = this.userFileSheetData.map((element) => { return { columnName: element.name, isRecomendation: 'All' } });
            return {
              destinationTemplateData: item,
              userFileSheetData: this.userFileSheetData.filter((e) => {
                if (e && e.data) {
                  return e.name.toString() === item.columnName.toString();
                }
              }).length
                ? this.userFileSheetData.filter((e) => {
                  if (e && e.data) {
                    return (
                      e.name.toString() === item.columnName.toString()
                    );
                  }
                })[0]
                : { name: '', data: [], type: '' },
              isExpended: false,
              hasRquiredFieldError: false,
              userFileColumnId: null,
              isDuplicate: false,
              mappedUserSheetColumn: this.userFileSheetData.filter((e) => {
                if (e && e.data) {
                  return e.name.toString() === item.columnName.toString();
                }
              }).length
                ? this.userFileSheetData
                  .filter((e) => e.name.toString() === item.columnName)[0]
                  .name.toString()
                : null,
              userDropDownOption: this.mergeArraysAndRemoveDuplicates(
                recomendedColumn,
                nonRecomendedColumns,
                'columnName'
              ),
            };
          });
        }
      }
      this.ngxLoader.stop();
    });
  }

  /**
   * Method to track changes in the mapping dropdown value and update accordingly in formattedSheetMappingData
   * @param event (changed name of sheet)
   * @param index (index of changed sheet name)
   */
  mappingChanged(event, index) {
    if (event === null) {
      this.formattedSheetMappingData[index].userFileSheetData = null;
      this.formattedSheetMappingData[index].mappedUserSheetColumn = null;
      this.formattedSheetMappingData[index].isDuplicate = false;
      this.formattedSheetMappingData[index].hasRquiredFieldError = true;
    } else {
      this.formattedSheetMappingData[index].userFileSheetData = this.userFileSheetDataBuffer.filter((e) => e.name === event)[0];
      this.formattedSheetMappingData[index].mappedUserSheetColumn = this.userFileSheetDataBuffer.filter((e) => e.name === event)[0].name;
      this.formattedSheetMappingData[index].isDuplicate = this.formattedSheetMappingData.filter((x) => x.mappedUserSheetColumn === event).length > 1 ? true : false;
    }
    this.saveUpdatePayload = [];
  }

  /**
   * Method to merge recommended and other sheet names Array and removing duplicates.
   */
  mergeArraysAndRemoveDuplicates(arr1, arr2, key) {
    // Extract unique values of the key from the first array
    const uniqueValues = new Set(arr1.map((obj) => obj[key]));
    // Filter the second array based on the uniqueness of the key
    const filteredArr2 = arr2.filter((obj) => !uniqueValues.has(obj[key]));
    // Merge the filtered second array with the first array
    const mergedArray = [...arr1, ...filteredArr2];
    return mergedArray;
  }

  /**
   * Method to toggle collapse All
   */
  toggleCollapseAll() {
    if (this.isCollapsedAll) {
      this.formattedSheetMappingData.forEach((e) => {
        e.isExpended = true;
      });
    } else {
      this.formattedSheetMappingData.forEach((e) => {
        e.isExpended = false;
      });
    }
  }

  /**
   * Method to check validity of mapped columns on UI
   * it checks for duplicate mapping as well as not mapped fields
   * */
  checkMappingValidity() {
    let duplicateCount = 0;
    let notMappedCount = 0;
    this.formattedSheetMappingData.forEach((item, index) => {
      if (item.isDuplicate) {
        duplicateCount++;
      } else if (item.mappedUserSheetColumn == null) {
        item.hasRquiredFieldError = true;
        notMappedCount++;
      }
    });
    return { duplicateCount: duplicateCount, notMappedCount: notMappedCount };
  }

  checkDuplicate() {
    let duplicateCount = 0;
    this.formattedSheetMappingData.forEach((item, index) => {
      if (item.isDuplicate) { duplicateCount++; }
    });
    return duplicateCount;
  }

  saveColumnMapping(eventType) {
    this.ngxLoader.start();
    const validationCheck = this.checkDuplicate();
    if (validationCheck === 0) {
      if (!this.saveUpdatePayload.length) {
        this.saveUpdatePayload = this.formattedSheetMappingData.map((e, i) => {
          if (this.isEditFlow) {
            return {
              status: 'Column Mapping is saved.',
              templateColumnId: e.destinationTemplateData.columnId,
              columnId: e.userFileColumnId,
              columnName: e.mappedUserSheetColumn !== null ? encodeURI(e.userFileSheetData.name).replace(/'/g, '%27') : '',
              columnDataType: e.userFileSheetData !== null ? e.userFileSheetData.type : '',
            };
          } else {
            return {
              sheetId: this.selectedUserSheetId,
              status: 'Column Mapping is saved.',
              templateColumnId: e.destinationTemplateData.columnId,
              columnName: e.mappedUserSheetColumn !== null ? encodeURI(e.userFileSheetData.name).replace(/'/g, '%27') : '',
              columnDataType: e.userFileSheetData !== null ? e.userFileSheetData.type : '',
            };
          }
        });
      }
      this.fileUploadProgressService.saveColumnMapping(this.saveUpdatePayload, this.uploadId, this.isEditFlow).subscribe((response) => {
        if (response) {
          if (response && response.code === 200) {
            this.saveUpdatePayload = [];
            if (eventType === 'save') {
              const statusPayload = [{ status: 'Column Mapping is saved.' }];
              this.fileUploadProgressService.saveDataSheet(statusPayload, this.uploadId).subscribe((res) => {
                if (res && res.code === 200) {
                  this.isEditFlow = true;
                  this.getExistingColumnMappingData(this.selectedUserSheetId);
                  this.toastr.success('Column mapping saved sucessfully');
                }
              });
            }
          }
        }
      });
    } else {
      this.ngxLoader.stopAll();
      this.toastr.error('Please map columns correctly!');
    }
  }

  saveAndNextColumnMapping(eventType) {
    this.ngxLoader.start();
    const validationCheck = this.checkMappingValidity();
    if (validationCheck.duplicateCount === 0 && validationCheck.notMappedCount === 0) {
      if (!this.saveUpdatePayload.length) {
        this.saveUpdatePayload = this.formattedSheetMappingData.map((e, i) => {
          if (this.isEditFlow) {
            return {
              status: 'Column Mapping is done.',
              templateColumnId: e.destinationTemplateData.columnId,
              columnId: e.userFileColumnId,
              columnName: encodeURI(e.userFileSheetData.name).replace(/'/g, '%27'),
              columnDataType: e.userFileSheetData.type,
            };
          } else {
            return {
              sheetId: this.selectedUserSheetId,
              status: 'Column Mapping is done.',
              templateColumnId: e.destinationTemplateData.columnId,
              columnName: encodeURI(e.userFileSheetData.name).replace(/'/g, '%27'),
              columnDataType: e.userFileSheetData.type,
            };
          }
        });
      }
      this.fileUploadProgressService.saveColumnMapping(this.saveUpdatePayload, this.uploadId, this.isEditFlow).subscribe((response) => {
        if (response) {
          if (response && response.code === 200) {
            this.saveUpdatePayload = [];
            if (eventType === 'saveAndNext') {
              const statusPayload = [{ status: 'Column Mapping is done.' }];
              this.fileUploadProgressService.saveDataSheet(statusPayload, this.uploadId).subscribe((res) => {
                if (res && res.code === 200) {
                  this.toastr.success('Column mapping saved sucessfully');
                  this.nextButtonEvent.next('column-mapping');
                }
              });
            }
          }
        }
      });
    } else {
      this.ngxLoader.stopAll();
      this.toastr.error('Please map columns correctly!');
    }
  }
}
