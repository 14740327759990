<div class="mt-5 pt-0" style="background: #FAFAFA;">
    <div class="container-fluid">

        

        <div class="row ">
            <div class="col-md-9 mt-2">
                <div class="policy-type">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4 pl-3 mb-0">
                                    <div class="policy-list">
                                        <h5>Policy Type: <span>Auto</span></h5>
                                        <div class="perils-list">
                                            <p class="peril accident active">
                                                <img src="assets/images/Auto.png" class="policy-icon" />
                                                <!-- <span>Auto</span> -->
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-0">
                                    <div class="policy-details">
                                        <h5>Customer ID <span> C17449</span></h5>
                                        <h5>Policy Number<span>POL/2023/AUTO/83486</span>  </h5>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-0">
                                    <div class="policy-details status">
                                        <h5>Renewal Status<span>Renewed</span></h5>
                                        <h5>Renewal Date <span>20 Sep 2023</span> </h5>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        <div class="col-md-6">
                            <div class="policy-coverages">
                                <h5>Policy Coverages</h5>
                                <div class="perils-list">
                                    <p class="peril accident active">
                                        <img src="assets/images/peril-accident.png" />
                                        <span>Accidental Damage</span>
                                    </p>
                                    <p class="peril accident active">
                                        <img src="assets/images/peril-theft.png" />
                                        <span>Theft</span>
                                    </p>
                                    <p class="peril accident active">
                                        <img src="assets/images/property-demage.png" />
                                        <span>Property Damage</span>
                                    </p>
                                    <p class="peril accident active">
                                        <img src="assets/images/peril-fire.png" />
                                        <span>Fire</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-md-3 mt-2">
                <div class="policy-rating">
                    <h5>Key Features</h5>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="policy-list">
                                <div class="perils-list pb-0">
                                    <p class="peril accident active">
                                        <img src="assets/images/Key Features.png" />
                                        <!-- <span>Key Features</span> -->
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                           

                            <span class="badge badge-features"> High Income Group
                            </span>
                            <span class="badge badge-features"> Renter
                            </span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="auto-personal-column">
                    <div class="auto-personal-item">
                        <h2 class="groupHeading">Group B</h2>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Head of household age</h4>
                            <div class="metric-val">46-50</div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Type of Property</h4>
                            <div class="metric-val">Multi-family

                            </div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Household Income</h4>
                            <div class="metric-val">$200,000 - $250,000
                            </div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Household Size</h4>
                            <div class="metric-val">2+ Persons

                            </div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Home ownership</h4>
                            <div class="metric-val">Renter</div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Age of
                                Children
                            </h4>
                            <div class="metric-val">16-18</div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Renewal Probability</h4>
                            <div class="metric-val">70%

                            </div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">CSAT</h4>
                            <div class="metric-val">7</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row mt-2">

            <div class="col-md-3 pr-3">
                <div class="metric features chat-box">
                    <h4 class="groupHeading"><span><img src="assets/images/chat-bot.png" /></span>CSR in Call

                    </h4>
                    <ul class="reasons chat-content">
                        <li class="question">Why is there a difference in invoice compared to the previous one?
                        </li>
                        <li class="answer">There was a lapse in payment </li>

                        <li class="question"> Why did the premium increase for this customer?

                        </li>
                        <li class="answer">Statewide rate revision applied.
                        </li>

                        <li class="question">Is he eligible for any discounts?</li>


                    </ul>
                    <ul class="send-message">
                        <li >
                            <input type="text" placeholder="Start Typing Here..." class="form-control">
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-6">
                        <div class="metric features">
                            <h4 class="groupHeading"><span><img src="assets/images/Billing History.png" /></span>Billing
                                History/Policy/Claim Experience
        
                            </h4>
                            <ul class="reasons">
                                <li>Late fee applied due to missed payment.</li>
                                <li>State Regulations applied. </li></ul>
                        </div>
                    </div>
        
        
                    <div class="col-md-6 pr-3">
                        <div class="metric features">
                            <h4 class="groupHeading"><span><img src="assets/images/Segment Recommendation.png" /></span>Segment
                                Recommendations </h4>
                            <ul class="reasons">
                                <li>Educate clients about automatic renewal options.</li>
                            </ul>
                        </div>
                    </div>
        
        
                    <div class="col-md-4">
                        <div class="metric features">
                            <h4 class="groupHeading"><span><img src="assets/images/Driver Profile.png" /></span>Driver Profile
                            </h4>
                            <ul class="reasons">
 

                                <li>Frequent night driver- Regular and consistent vehicle operation during nighttime hours</li>
                               
                            </ul>
                        </div>
                    </div>
        
                    <div class="col-md-4">
                        <div class="metric features">
                            <h4 class="groupHeading"><span><img src="assets/images/Cross-sell.png" /></span>Cross-sell/Upsell
                                Recommendations
        
                            </h4>
                        

                            <ul class="reasons">
                                <li>Uninsured/Underinsured Motorist Coverage.</li>
                                <li>Personal Injury Protection.</li>
                            </ul>
                        </div>
                    </div>
        
                    <div class="col-md-4 pr-3">
                        <div class="metric features">
                            <h4 class="groupHeading"><span><img src="assets/images/Customer Feedback.png" /></span>Customer
                                Feedback
                            </h4>
                            <ul class="reasons">
                                <li>Unable to get information regarding billing errors, nobody returned call</li>
                            </ul>
                        </div>
                    </div>
        
                </div>
            </div>

          
        </div>


    </div>
</div>