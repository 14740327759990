import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject, of, forkJoin } from 'rxjs';
const CHART_TMEPLATE_BASE_URL = "../../../../../assets/data/pricing/";
const PDP_CHART_TEMPLATE_JSON = 'pdpChartTemplate.json';
const ACTUAL_CHART_TEMPLATE_JSON = 'actualChartTemplate.json';
const IMPACT_CHART_TEMPLATE_JSON = 'impactChartTemplate.json';

@Injectable({
  providedIn: 'root'
})
export class PricingModelInterpretabilityService {


  constructor(private http: HttpClient) { }

  // Getting Filter panel data(All FILTERS)
  getFilterChartPanelData(lobProjectId): Observable<any> {
    return this.http.get<any>('pricing/allFilters/' + lobProjectId + '/');
  }

  // Constructing filter object for filter panel
  formatFilterPanelData(data): Observable<any> {
    const chartFilters = [] as any;
    const chartFilterObj = {
      "title": {
        "name": "Model Techniques"
      },
      "filterType": "universal",
      "isMultySelect": true,
      "values": [
        {
          "name": "GLM",
          "isSelected": true
        },
        {
          "name": "XGB",
          "isSelected": true
        }
      ]
    };

    const res = data;
    Object.keys(data).forEach(key => {
      if (typeof (data[key]) === 'object') {
        const x = {
          ...chartFilterObj,
          title: {
            ...chartFilterObj.title,
            name: key.replace(/^([a-z]|[A-Z])$/g, (c, i) => (i ? " " : "") + c.toUpperCase())
          },
          filterType: key,
          values: res[key].map((item, index) => {
            return {
              ...item,
              isSelected: true
            }
          })
        }
        chartFilters.push(x);
      }
    })
    return of(chartFilters)
  }

  // Getting Individual filter data , using fork join
  geInduvidualFilterData(lobProjectId): Observable<any> {
    const getModelTechniques = this.http.get<any>('modelTechniques/' + lobProjectId + '/');
    const getModalType = this.http.get<any>('modelTypes/' + lobProjectId + '/');
    return forkJoin([getModelTechniques, getModalType]);
  }

  // Fetching PDP filter data
  getPDPFilterData(lobProjectId): Observable<any> {
    const getModelTechniques = this.http.get<any>('modelTechniques/' + lobProjectId + '/');
    const getModalType = this.http.get<any>('modelTypes/' + lobProjectId + '/');
    const getExploratoryVariable = this.http.get<any>('exploratoryVariable/' + lobProjectId + '/');
    return forkJoin([getModelTechniques, getModalType, getExploratoryVariable]);
  }

  // Fetching PDP chart JSON
  getPDPChartTemplateJSON(): Observable<any[]> {
    return this.http.get<any>(`${CHART_TMEPLATE_BASE_URL}${PDP_CHART_TEMPLATE_JSON}`);
  }

  // PDP chart data API call
  getPdpChartData(lobProjectId, modelType, modelTechnique, exploratoryVariable): Observable<any> {
    return this.http.get<any>('lobModelInteroperabilityPDP/' + lobProjectId + '/' + modelTechnique + '/' + modelType + '/' + exploratoryVariable + '/');
  }

  // Formating PDP chart API data to chart Options using chart template
  formatPdpData(pdpData, template): Observable<any> {
    const finalresponse = [] as any;
    const seriesData = [[]];
    pdpData.forEach((element, index) => {
      const config = {
        ...template,
        title: {
          ...template.title,
          text: element.model.toUpperCase() + " " + element.modelType,
        },
        xAxis: {
          ...template.xAxis,
          categories: element.pdpData.length ? [...element.pdpData[0].data].map((e) => {
            return e[0] ;
          }): [],
        },
        series: seriesData.map((item, itemIndex) => {
          let areaData: Array<any> = [];
          let lineData: Array<any> = [];
          if(element.pdpData.length) {
            element.pdpData[0].data.forEach((el, i) => {
              lineData.push(element.pdpData[0].data[i])
              areaData.push([...element.pdpData[1].data[i], element.pdpData[2].data[i][1]])
  
            });
          } else {
            lineData = [];
            areaData = [];
          }
          
          const chartData = []
          return [
            {
              name: element.modelType,
              type: "line",
              data: lineData,
              zIndex: 1,
              tooltip: {
                valueDecimals: 2
              },
              marker: {
                fillColor: 'white',
                lineWidth: 2,
                lineColor: 'rgb(124, 181, 236)'
              }
            },
            {
              type: "arearange",
              data: areaData,
              lineWidth: 0,
              linkedTo: ':previous',
              fillColor: "#b6d7f5fa",
              fillOpacity: 0.3,
              zIndex: 0,
              tooltip: {
                valueDecimals: 2
              },
              marker: {
                enabled: false
              }
            }
          ]
        })[0]
      }
      finalresponse.push(config);
    });
    return of(finalresponse);
  }

  // Getting ACTUAL vs PREDICTED chart template
  getActualChartTemplateJSON(): Observable<any[]> {
    return this.http.get<any>(`${CHART_TMEPLATE_BASE_URL}${ACTUAL_CHART_TEMPLATE_JSON}`);
  }

  // Fetching Actual vs Predicted chart API data
  getLobModelValidationActualChart(lobProjectId, modelType, modelTechnique, exploratoryVariables): Observable<any> {
    return this.http.get<any>('lobModelValidationActualVsPredicted/' + lobProjectId + '/' + modelTechnique + '/' + modelType + '/' + exploratoryVariables + '/');
  }
// Fetching SHAP chart API data
  getShapData(lobProjectId, modelType, modelTechnique): Observable<any> {
    return this.http.get<any>('lobModelInteroperabilitySHAP/' + lobProjectId + '/' + modelTechnique + '/' + modelType + '/');
  }

}
