import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ValidationBordereauxManagementService {

  constructor(private modalService: NgbModal, private http: HttpClient) { }


  /***
   * @desc Get Bordereaux Valdiation List Data as per passed process Id and selected year month
   * @param Integer (Process Id)
   * @param Integer (yearMonth)
   * @returns {Object} Returns bordereaux Validation Data   *
   */ 
  getBordereauxValidationListData(processId, yearMonth): Observable<any> {
    return this.http.get<any>('metamorph/validations/'+processId+'/'+yearMonth+'/');
  }

  /***
   * @desc Get Call to check if process has been validated.
   * @param Integer (processId)
   * @param Integer (yearMonth)
   * @param Integer (workId)
   * @returns {Object} Returns bordereaux latest iteration data and its current status 
   */ 
  validateProgressProcess(processId,yearMonth,workId):Observable<any>{    
    return this.http.get<any>('metamorph/validations/'+processId+'/'+yearMonth+'/'+workId+'/');
  }

  /***
   * @desc Patch Call to update selected version of the process
   * @param Integer (WorkItemId)
   * @returns {Object} Returns bordereaux Validated data with latest iteration data and its current status of selected version  *
   */ 
  updateVersionInfo(workId):Observable<any>{
    const bodyJson={
      "workItemId": workId
    };
    return this.http.patch<any>('metamorph/validation/'+workId+'/',bodyJson);
  }

  /***
   * @desc Patch Call to initiate validate process for passed workItemId.
   * @param Integer (WorkItemId)
   * @returns {Object} Returns bordereaux Validated data with latest iteration data and its current status   *
   */ 
  validateProcess(workId):Observable<any>{
    const bodyJson={
      "status": "Validation In-Progress"      
    };
    return this.http.post<any>('metamorph/validation/'+workId+'/',bodyJson);
  }

  /***
   * @desc Delete Call to delete selected work Item
   * @param Integer (WorkItemId)
   * @returns {Object} Returns API data with status and code
   */ 
  deleteValidate(workId){
    return this.http.delete<any>('metamorph/validation/'+workId+'/');
  }
}
