<img src="../../../../../../../assets/images/slider-icon.svg" alt="filter" class="filter-icon" (click)="applyFilter()">
<div class="data-table-wrapper customHeight" aria-describedby="mydesc">
  <div class="col-sm-12">
  </div>
  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table dataTable no-footer">
    <thead>
      <tr>
        <th scope="col">Rule Id</th>
        <th scope="col">Rule Name</th>
        <th scope="col">Rule Description</th>
        <th scope="col">Rule Type</th>
        <th scope="col">Last Modified</th>
        <th scope="col">Last Modified By</th>
        <th scope="col">Mandatory</th>
        <th scope="col">Set Default</th>
        <th scope="col">Enabled</th>
      </tr>
    </thead>
    <tbody *ngIf="ruleConfigList?.length != 0">
      <tr *ngFor="let item of ruleConfigList, let index = index">
        <td style="width: 50px;">{{item?.ruleId}}</td>
        <td>
          <p class="multiline-ellipsis" *ngIf="item.ruleName.length <= 30" data-html="true" placement="top"
            [ngbTooltip]="tooltipOptimize" tooltipClass="custom-tooltipColor">{{item?.ruleName}}</p>
          <ng-template #tooltipOptimize>
            <div>{{item?.ruleName}}</div>
          </ng-template>
          <p class="multiline-ellipsis" *ngIf="item.ruleName.length > 30" data-html="true" placement="top"
            [ngbTooltip]="tooltipOptimize" tooltipClass="custom-tooltipColor">{{item?.ruleName | slice: 0: 30}}...</p>
          <ng-template #tooltipOptimize>
            <div>{{item?.ruleName}}</div>
          </ng-template>

        </td>
        <td style="width: 210px;"> 
          <p class="multiline-ellipsis" *ngIf="item.description && item.description.length <= 80" data-html="true" placement="top"
            [ngbTooltip]="tooltipOptimizeD" tooltipClass="custom-tooltipColor">{{item?.description}}</p>
          <ng-template #tooltipOptimizeD>
            <div>{{item?.description}}</div>
          </ng-template>

          <p class="multiline-ellipsis" *ngIf="item.description && item.description.length > 80" data-html="true" placement="top"
            [ngbTooltip]="tooltipOptimizeD" tooltipClass="custom-tooltipColor">{{item?.description | slice: 0: 80}}...
          </p>
          <ng-template #tooltipOptimizeD>
            <div>{{item?.description}}</div>
          </ng-template>
        </td>
        <td style="width: 120px;">{{item?.ruleType}}</td>
        <td>{{item?.lastModified | date: 'd MMMM yy h:mm:ss a'}}</td>
        <td style="width: 120px;">{{item?.modifiedBy}}</td>
        <td style="width: 80px;">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="ruleConfigIsMandatory{{item.ruleId}}"
              [(ngModel)]="item.isMandatory" (change)="updateIsMandatoryStatus($event, item?.ruleId,index)"
              [disabled]="item.isEnabled==false">
            <label class="custom-control-label" for="ruleConfigIsMandatory{{item.ruleId}}"></label>
          </div>
        </td>
        

        <td style="width: 70px;">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="ruleConfigIsDefault{{item.ruleId}}"
              [(ngModel)]="item.isDefault" (change)="updateIsDefaultStatus($event, item?.ruleId,index)"
              [disabled]="!item.isEnabled || item.isMandatory">
            <label class="custom-control-label" for="ruleConfigIsDefault{{item.ruleId}}"></label>
          </div>
        </td>
        

        <td style="width: 70px;" class="isEnabled">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="ruleConfigIsEnabled{{item.ruleId}}"
              name="ruleConfigIsEnabled_{{item.ruleId}}" [(ngModel)]="item.isEnabled"
              (change)="checkStatus($event, item?.ruleId, index)">
            <label class="custom-control-label" for="ruleConfigIsEnabled{{item.ruleId}}"></label>
          </div>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="ruleConfigList?.length == 0">
      <tr>
        <td colspan="6" class="p-0" class="no-data-available ">No data!</td>
      </tr>
    </tbody>
  </table>
</div>
<div class="bottom-button-container">
  <button class="btn btn-sm btn-outline-primary" [disabled]="!selectedRuleIdList.length"
    (click)="saveRuleConfig()">Save</button>
</div>