import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auto-personal-lines-d',
  templateUrl: './auto-personal-lines-d.component.html',
  styleUrls: ['./auto-personal-lines-d.component.scss']
})
export class AutoPersonalLinesDComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
