<div class="row no-gutters">
    <div class="col-sm-12 p-1">
        <div class="demand-planner-metrics">
            <div class="row no-gutters dp-metrics" >
                <div class="col-sm-7 border-right pr-1">
                   
                    <form class="form-inline">
                        <label class="my-1 mr-2 mt-1" for="p-sku">SKU</label>
                        <select class="form-control form-control-sm  mt-1" id="p-sku" (change)="changeSKU(selectedSKU)"
                            [(ngModel)]="selectedSKU" [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let item of stockKeepingUnits" [ngValue]="item.id">{{item?.name}}</option>
                        </select>
                    </form>
                   
                    <div class="analysisButton" *ngIf="settingEnabled==true" >
                        <a href="javascript:void(0)"  class="btn btn-sm ml-1 btn-outline-primary" (click)="modelAnalysis('summary')">Summary</a>
                        <a href="javascript:void(0)" class="btn btn-sm ml-1 btn-outline-primary" (click)="modelAnalysis('anomalies')" >Anomalies</a>
                      
                    </div>
                    
                    
                    <div class="card border-0 mt-2">
                        <div class="card-body  m-1">
                            <div class="row no-gutters dp-ribbon-row">
                                <div *ngIf="!performanceData.length">
                                    <h2>No Data Found</h2>
                                </div>
                                <ng-container *ngIf="performanceData.length">
                                    <ng-container *ngFor="let item of performanceData; last as isLast; index as index">
                                        <div class="col" [ngClass]="isLast ? '' : 'border-right'">
                                            <h3>{{item?.name}}</h3>
                                            <div class="dp-ribbon-val" *ngIf="index !== 0">{{item?.data ? item?.data :
                                                'NA'}}</div>
                                            <div class="dp-ribbon-val" *ngIf="index === 0">{{item?.data | number :
                                                '1.0-0'}}</div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="row no-gutters dp-metrics demandForCast">
                        <div class="col-sm-12">
                            <app-generic-chart *ngIf="!!demandForecastData && demandForecastData.length"
                                [chartData]="demandForecastData" [chartTemplate]="chartTemplate"
                                [chartOptionConfig]="chartOptionConfig" [seriesColors]="multySeriesColors"
                                [updateFlag]="updateFlag" [chartHeight]="300">
                            </app-generic-chart>
                        </div>
                        <div class="col-sm-12">
                            <h3 class="mt-3 mb-2 ">Demand Drivers</h3>
                        </div>
                        <div class="col-sm-6">
                            <app-generic-chart *ngIf="!!cpiIndexData && cpiIndexData.length" [chartData]="cpiIndexData"
                                [chartTemplate]="chartTemplate" [chartOptionConfig]="chartOptionConfig"
                                [seriesColors]="signleSeriesColors" [updateFlag]="updateFlag" [chartHeight]="200">
                            </app-generic-chart>
                        </div>
                        <div class="col-sm-6">
                            <app-generic-chart *ngIf="!!discoundOfferedData && discoundOfferedData.length"
                                [chartData]="discoundOfferedData" [chartTemplate]="chartTemplate"
                                [chartOptionConfig]="chartOptionConfig" [seriesColors]="signleSeriesColors"
                                [updateFlag]="updateFlag" [chartHeight]="200">
                            </app-generic-chart>
                        </div>
                    </div>
                </div>
                <div class="col-sm-5 pl-1 table-section">
                    <div class="card border-0">
                        <app-initial-saop-planing [selectedProductFamily]="selectedProductFamily" [productFamilies]="productFamilies">
                        </app-initial-saop-planing>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
