import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor( private router: Router ) { }

  /**
   * Gets module title
   * @returns title of the module based on the web route
   */
  getModuleTitle() {
    if(this.router.url.includes('/underwriting')) {
      return "Underwriting"
    } else if(this.router.url.includes('/claim')) {
      return "Claims"
    } else {
      return "Insurance"
    }
  }
}
