import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { FileUploadProgressService } from 'src/app/shared/services/file-upload-progress.service';

@Component({
  selector: 'select-header-range',
  templateUrl: './select-header-range.component.html',
  styleUrls: ['./select-header-range.component.scss']
})
export class SelectHeaderRangeComponent implements OnInit {
  @Input() fileData: any;
  @Input() uploadId: any;
  @Input() daterangeStart: any;
  @Input() daterangeEnd: any;
  @Input() workItemId: any;
  @Output() nextButtonEvent = new EventEmitter<any>();
  @Output() backButtonEvent = new EventEmitter<any>();
  @Output() exitButtonEvent = new EventEmitter<any>();
  @Output() fileAutoSubmitted = new EventEmitter<any>();
  @Output() exitUploadFlow = new EventEmitter<any>();
  Object = Object;
  dataFromSheets: any;
  selectedSheet: any;
  updatedFileData: any;
  headerSelectionRangeArray = []
  //invalidForm: boolean = false;
  limit = 10;
  offset = 0;
  selectedHeaderRangeArray = []
  constructor(private fileUploadProgressService: FileUploadProgressService, private ngxLoader: NgxUiLoaderService, public toastr: ToastrService, public confirmationPopupService: ConfirmationPopupService) { }

  ngOnInit(): void {
    this.getFileData(this.uploadId, this.limit, this.offset);
  }

  selectSheet(sheetName, event) {
    this.selectedSheet = sheetName;
  }

  /**
   * Construct data in right formate to place it on tabs dynamicly
   */
  ngOnChanges(): void {
    this.dataFromSheets = Object.keys(this.fileData.sheetInfo).map(item => {
      return {
        sheetName: item,
        sheetData: this.fileData.sheetInfo[item].data
      }
    });
    
  }


  /**
   * Get file-data through API
   * @param uploadId
   * @param limit
   * @param offset
   * @param headerEndUpdate
   * Constructing headerSelectionRangeArray for capturing selected rows for each tab.
   * Constructing headerSelectionRangeArray for handling row selection functionality.
   */
  getFileData(uploadId, limit, offset) {
    this.ngxLoader.start();
    this.fileUploadProgressService.fileData(uploadId, limit, offset).subscribe(response => {
      if (response && response.code === 200) {
        const apiResponse = response?.data;
        this.updatedFileData = Object.keys(apiResponse).map(e => {
          return {
            ...apiResponse[e],
            sheetName: e,
          }
        })
      }
      this.selectedSheet = this.updatedFileData[0].sheetName;
      this.ngxLoader.stop();
    });
  }
  /**
   * Confirmation Mapping popup
   */
  confirmationMappingPopup() {
    const payload = {
      status: "Ready For Standardization",
      workItemId: this.workItemId
    }
    this.fileUploadProgressService.mappingConfirmation('Confirmation', 'Submit & Continue', 'Verify Manually').then((res) => {
      if (res) {
        this.fileUploadProgressService.generateColumnHeaders(this.uploadId, {}).subscribe(resCode => {
          if (resCode && resCode.code === 200) {
            this.toastr.success("File uploaded and mapped successfully");
          }
        })
        this.fileUploadProgressService.standardization(payload, this.workItemId).subscribe(response => {
          if (response && response.code === 200) {
            this.toastr.success(response.successMessage);
            this.fileAutoSubmitted.next('submitted')
          }
        })
      } else {
        this.nextButtonEvent.next('header-range-selection')
      }
    })
  }
  /**
   * Save functionaly for header selection
   * @param eventType
   * if event type is save then just show the success toaster
   * if event type is next then save data and after showing success toater navigate to next screen.
   */
  saveHeaderSelection(eventType) {
    this.fileUploadProgressService.validateUserfileHeaders(this.uploadId).subscribe(response => {
      if (response && response.code === 200) {
        if (response.validationStatus == "Success") {
          this.toastr.success("User File Headers Validated");
          this.confirmationMappingPopup()
        } else if (response.validationStatus == "Failed") {
          this.nextButtonEvent.next('header-range-selection')
        }
      }
    })
  }

  // Next button event
  goToNext() {
    this.saveHeaderSelection('saveAndNext')
  }

  // Save button event
  goForSave() {
    this.saveHeaderSelection('save')
  }

  // Back Button event
  goToBack() {
    this.backButtonEvent.next('header-range-selection')
  }

  getExited() {
    this.exitButtonEvent.next('exit');
  }

  /**
   * Header range selection functionality
   * on the selection of more then one rows, all the intermediate rows will get selected automaticaly.
   * @param event
   */
  selectedAsHeader(event) {
    this.headerSelectionRangeArray.forEach((item, index) => {
      const headerStart = item.headerRange.findIndex((element) => element.isHeader);
      const headerEnd = item.headerRange.reduce((acc, cur, idx) => cur.isHeader ? idx : acc, -1);
      this.selectedHeaderRangeArray[index].headerStart = headerStart;
      this.selectedHeaderRangeArray[index].headerEnd = headerEnd;
      for (let i = headerStart; i < headerEnd; i++) {
        item.headerRange[i].isHeader = true;
      }
    })
  }

  exit() {
    this.exitUploadFlow.next()
  }


}
