<div class="card border-0">
    <div class="card-body  m-1 chartSpacingCustom">
        <div class="d-flex">
            <div class="flex-grow-1">
                <h3 class="section-title display-inline-block"
                    *ngIf="chartData[0]?.data?.length && !chartData[0]?.chartInfo">{{chartOption[0]?.title?.text}}</h3>
                <h3 class="section-title display-inline-block" placement="right"
                    ngbTooltip="{{chartData[0]?.chartInfo?.tooltip}}"
                    *ngIf="chartData[0]?.data?.length && chartData[0]?.chartInfo">{{chartData[0]?.chartInfo?.title}}
                </h3>
            </div>
            <div class="">
                <a href="#" class="mr-2 has-tooltip" title="Expand" data-toggle="modal" placement="left"
                ngbTooltip="Expand"
                    [ngClass]="!chartData[0]?.data?.length ? 'disabled-click' : ''" data-target="#expandKPIModal_d"
                    (click)="openXlChart(longContent,chartOption[0])"><span
                        class="material-icons">open_in_new</span></a>
                <!-- <a href="#" class="has-tooltip" title="Download in Excel">
                            <span class="material-icons">file_download</span>
                        </a> -->
            </div>
        </div>
        <div class="text-center chart-container" id="kp-2" *ngIf="!chartData[0]?.data?.length">
            <h3 class="no-data">No Data Found</h3>
        </div>
        <div class="text-center chart-container" id="kp-2" *ngIf="chartData[0]?.data?.length">
            <highcharts-chart class="highcharts" [Highcharts]="Highcharts" [(update)]="updateFlag"
                [options]="chartOption[0]">
            </highcharts-chart>
        </div>
    </div>
</div>

<ng-template #longContent let-modal>
    <div class="modal-header">
        <h3 class="modal-header-title">{{chartModelData?.title?.text}} </h3>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <highcharts-chart class="highcharts-modal" [Highcharts]="Highcharts" [options]="chartModelData">
        </highcharts-chart>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Save click')">Close</button>
    </div>
</ng-template>