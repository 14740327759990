import { Component, EventEmitter, Input, OnInit, Output,OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-view-rules',
  templateUrl: './view-rules.component.html',
  styleUrls: ['./view-rules.component.scss']
})
export class ViewRulesComponent implements  OnInit, OnDestroy, AfterViewInit   {
  @Input() modalData: any;
  @Output() backButtonEvent = new EventEmitter<any>();
  ruleListArray:any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  constructor( private activeModal: NgbActiveModal, private ngxLoader: NgxUiLoaderService,) { }

  ngOnInit(): void {
    this.getAmendRuleListData();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  getAmendRuleListData() {
    this.ruleListArray = [];
    this.ngxLoader.start();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      deferRender: true,
      order: [[]],
      destroy: true,
    }
    if(this.modalData?.rulesData){this.ruleListArray = this.modalData?.rulesData;}
    this.ngxLoader.stop();
    this.dtTrigger.next();

  }
  public decline() {
    this.activeModal.close(false);
  }


 

}
