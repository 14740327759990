<div class="module-nav mt-2 fix-height">
    <ul class="nav nav-tabs " id="myTab" role="tablist">
        <li class="nav-item">
            <a class="nav-link sub-module" id="liftChart-tab" data-toggle="tab" role="tab" href="#liftChart"
                aria-controls="liftChart" aria-selected="false" (click)="selectTab('liftChart')"
                [ngClass]="getActiveClass(pricingModules.VALIDATION)">Lift Chart
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link  sub-module" id="actual-tab" data-toggle="tab" href="#actual" role="tab" 
                aria-controls="actual" aria-selected="false"  (click)="selectTab('actual')"> Actual vs Predicted
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link  sub-module" id="impact-tab" data-toggle="tab" role="tab" href="#impact"
                aria-controls="impact" aria-selected="false"  (click)="selectTab('impact')">Impact Analysis
            </a>
        </li>
    </ul>
    <div class="tab-content bg-white shadow-sm btn-footer ">
        <div class="p-2">
        <nav aria-label="breadcrumb ">
            <ol class="breadcrumb mb-2">
                <li class="breadcrumb-item" *ngFor="let item of breadcrumb; last as isLast">{{item.name}} </li>
            </ol>
        </nav>
    </div>
        <div class="tab-pane fade liftChart-tab-content" [ngClass]="getActiveClass(pricingModules.VALIDATION)"
            id="liftChart" role="tabpanel" aria-labelledby="liftChart-tab">
            <div class="p-2" *ngIf="selectedTab === 'liftChart'">
                <div class="form-row">
                    <div class="col">
                        <div class="form-group-sm">
                            <div class="col-12 p-0">
                                <app-pricing-model-validation-lift></app-pricing-model-validation-lift>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="tab-pane fade actual-tab-content" id="actual" role="tabpanel" aria-labelledby="actual-tab">
            <div class="p-2" *ngIf="selectedTab === 'actual'">
                <div class="form-row">
                    <div class="col">
                        <div class="form-group-sm">
                            <div class="col-12 p-0">
                                <app-pricing-model-validation-actual></app-pricing-model-validation-actual>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="tab-pane fade impact-tab-content" id="impact" role="tabpanel" aria-labelledby="impact-tab">
            <ng-container>
                <div class="p-2" *ngIf="selectedTab === 'impact'">
                    <div class="col-12 p-0">
                        <app-pricing-model-validation-impact></app-pricing-model-validation-impact>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>