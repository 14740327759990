<div class="row">
    <div class="col-sm-2">
        <div class="metric-options">
            <div class="metrics-selection-wrapper mb-3" *ngFor="let filter of chartFilters; index as i">
                <div class="form-group mt-3 border-0" *ngIf="filter.filterType === 'modelTechniques'">
                    <label class="d-block filter-header ">{{filter.title}}</label>
                    <ng-container *ngFor="let filterOption of filter.data;  index as index">
                        <div class="custom-control custom-checkbox custom-control "> 
                            <input type="checkbox" id="{{viewName}}-{{filter.filterType}}-{{i}}{{index}}"
                                name="{{viewName}}-{{filter.filterType}}"
                                (change)="updateComparisionData(filterOption,filter.filterType)"
                                class="custom-control-input" [value]="filterOption.name"
                                [checked]="index === 0 ? true : false" [(ngModel)]="filterOption.isSelected">
                            <label class="custom-control-label"
                                for="{{viewName}}-{{filter.filterType}}-{{i}}{{index}}">{{filterOption.name}}</label>
                        </div>
                    </ng-container>
                </div>
                <div class="form-group mt-3" *ngIf="filter.filterType === 'modelTypes'">
                    <label class="d-block filter-header">{{filter.title}}</label>
                    <ng-container *ngFor="let filterOption of filter.data;  index as index">
                        <div class="custom-control custom-radio custom-control-inline border-0">
                            <input type="radio" id="{{viewName}}-{{filter.filterType}}-{{i}}{{index}}"
                                name="{{viewName}}-{{filter.filterType}}"
                                (change)="updateComparisionData(filterOption,filter.filterType)"
                                class="custom-control-input" [value]="filterOption.name"
                                [checked]="index === 0 ? true : false">
                            <label class="custom-control-label"
                                for="{{viewName}}-{{filter.filterType}}-{{i}}{{index}}">{{filterOption.name}}</label>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="col-sm-10">
        <div class="exploratory-metrics" style="height: calc(100vh - 305px); overflow-y: auto; overflow-x: hidden;">
            <div class="row no-gutters">
                <div class="col-sm-12">
                    <div class="card shadow m-2 border-0">
                        <div class="card-body" id="frequency">
                            <h3>Model Diagnostics</h3>
                            <table class="table table-striped dataTable" style="font-size: 12px;" role="grid" aria-describedby="model-diagnostics">
                                <thead>
                                    <tr role="row">
                                        <th scope="col">Model Techniques</th>
                                        <th scope="col">Model Type</th>
                                        <th scope="col">RMSE</th>
                                        <th scope="col">R Square</th>
                                        <th scope="col">MAE</th>
                                        <th scope="col">Deviance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr role="row" *ngFor="let comparison of comparisionData">
                                        <td>{{comparison.model}}</td>
                                        <td>{{comparison.modelType}}</td>
                                        <td>{{comparison.RMSE|number}}</td>
                                        <td>{{comparison.rSquare|number}}</td>
                                        <td>{{comparison.MAE|number}}</td>
                                        <td>{{comparison.deviance}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>