<div class="m-2 pr-2 metric-options filter-scroll">
    <div class="metrics-selection-wrapper mb-3">
        <div *ngFor="let filter of chartFilters; index as i">
            <h4 class="m-0 mt-2">{{filter.title.name}}</h4>
            <div class="checkbox-scroll">
                <ng-container *ngFor="let checkbox of filter.values;  index as ind">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="{{filter.title.name}}-{{ind}}"
                            [(ngModel)]="checkbox.isSelected" (change)="updateStatus(checkbox,i,filter.filterType)">
                        <label class="custom-control-label" for="{{filter.title.name}}-{{ind}}"> {{checkbox.name}}
                        </label>
                    </div>
                    <!-- <hr class="m-0"> -->
                </ng-container>
            </div>
        </div>
    </div>
</div>