import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';

import { DemandforecastingService } from '../demandforecasting.service';

@Component({
  selector: 'app-initial-saop-planing',
  templateUrl: './initial-saop-planing.component.html',
  styleUrls: ['./initial-saop-planing.component.scss']
})
export class InitialSaopPlaningComponent implements OnInit {

  @Input() selectedProductFamily: any;
  @Input() productFamilies: any;

  public currentMonth = new Date().getSeconds();
  public monthList = [
    { id: 1, name: 'Jan' },
    { id: 2, name: 'Feb' },
    { id: 3, name: 'Mar' },
    { id: 4, name: 'Apr' },
    { id: 5, name: 'May' },
    { id: 6, name: 'Jun' },
    { id: 7, name: 'Jul' },
    { id: 8, name: 'Aug' },
    { id: 9, name: 'Sep' },
    { id: 10, name: 'Oct' },
    { id: 11, name: 'Nov' },
    { id: 12, name: 'Dec' },
  ];
  public saopData: any = {};
  nextThreeMonths: any;
  selectedMonth: any;
  tableCoumns: any;
  Highcharts: typeof Highcharts = Highcharts;
  nextMonthYear: number;
  localStorage = localStorage;
  userRole: any;
  updatedData: Array<any> = [];
  disablePublish: boolean = false;
  lastExecutionMonthYear: any;
  nextThreeMonthWithYear: any;
  selectedProductFamilyBuffer: any;

  unsubscribe$: Subject<boolean> = new Subject();

  constructor(private demandforecastingService: DemandforecastingService, private loader: NgxUiLoaderService,
    public toastr: ToastrService, public confirmationPopupService: ConfirmationPopupService) { }

  ngOnChanges() {
    this.updatedData = [];
    this.getNext3Months();
  }

  ngOnInit() {
    this.localStorage.setItem('hasPendingSave', 'NO');
    this.demandforecastingService.getReloadObject().subscribe(reload => {
      if (reload) {
        this.updatedData = [];
        this.getNext3Months();
      }
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  getLastExecutionMonth() {
    this.demandforecastingService.getLastExecutionMonth().subscribe((response) => {
      if (response && response.code === 200 && response.data) {
        return response.data[0].yearMonth
      }
    })
  }

  getNext3Months() {
    this.demandforecastingService.getLastExecutionMonth().subscribe((response) => {
      if (response && response.code === 200 && response.data) {
        this.lastExecutionMonthYear = response.data[0].yearMonth;
        const currentMonth = this.lastExecutionMonthYear.toString().substring(4); // getMonth() is zero-indexed; add 1 to give conventional month number.
        const currentYear = this.lastExecutionMonthYear.toString().substring(0, 4);
        this.nextMonthYear = this.getNextMonthsYear(currentMonth, currentYear)
        const next3months = [1, 2, 3].map(n => (currentMonth + n) % 12); // returns array of next three conventional month numbers; %12 caters for end of year wrap-around.
        this.nextThreeMonths = this.monthList.filter((el) => {
          return next3months.some((f) => {
            return f === el.id;
          });
        });
        this.nextThreeMonthWithYear = this.nextThreeMonths.map(el => {
          return {
            id: el.id,
            name: el.name,
            year: this.nextMonthYear.toString().substring(2)
          }
        });
        this.selectedMonth = this.nextThreeMonths[0];
        this.getInitialSOAPData(this.selectedProductFamily, this.lastExecutionMonthYear, next3months[0], this.nextMonthYear)
      }
    });
  }

  updateMonth(monthObj) {
    this.selectedMonth = monthObj;
    this.getInitialSOAPData(this.selectedProductFamily, this.lastExecutionMonthYear, this.selectedMonth.id, this.nextMonthYear)
  }

  getNextMonthsYear(month, year) {
    const date = year + month
    const d = new Date(year);
    d.setMonth(month)
    return d.getFullYear();
  }
  getCurrentMonthYear(month, year) {
    const date = year + (Number(month) - 1).toString();
    const d = new Date(year);
    d.setMonth(month)
    return d.getFullYear();
  }

  getInitialSOAPData(productFamilyId, lastExecutionMonthYear, selectedMonth, selectedYear) {
    this.loader.start();
    this.saopData = {};
    let selectedYearAndMonth: any;
    if (selectedMonth < 10) {
      selectedYearAndMonth = '' + selectedYear + '0' + selectedMonth
    } else {
      selectedYearAndMonth = '' + selectedYear + selectedMonth
    }

    Promise.all([this.localStorage.getItem('user')]).then(values => {
      const userData = values[0];
      if (userData) {
        this.userRole = JSON.parse(userData).defaultrolename;
        this.demandforecastingService.getInitialSaOPData(productFamilyId, lastExecutionMonthYear, selectedYearAndMonth, this.userRole).subscribe((data) => {
          if (data && data.code === 200) {
            this.saopData = data;
            this.demandforecastingService.setSaOPDataLoadStatus(true);
            if (this.updatedData.length) {
              this.checkForTheUpdate();
            }
            if (this.saopData.data && this.saopData.data.length && this.saopData.data[0].updatedData.length) {
              if ((this.userRole === 'Demand Planner' && this.saopData.data[0].updatedData[0].status === 'DPPUBLISH' || this.saopData.data[0].updatedData[0].status === 'MPPUBLISH') || (this.userRole === 'S&OP Manager' && this.saopData.data[0].updatedData[0].status === 'MPPUBLISH')) {
                this.disablePublish = true
              }
              else {
                this.disablePublish = false;
              }
              this.tableCoumns = this.saopData.details.map((e, i) => {
                const x = (i + 1);
                return {
                  'id': Object.keys(e)[0],
                  'value': Object.values(e)[0]
                }
              });
            }
            
            this.loader.stop();

          } else { this.loader.stop() }
        });
      }
    });
  }

  valueUpdate(changeObj, product, event, index, ind) {
    changeObj.data = changeObj.stateValue = Number(event.target.value);
    this.saopData.data[index].updatedData[ind].data = this.saopData.data[index].updatedData[ind].stateValue = Number(event.target.value);
    const i = this.updatedData.findIndex(_element => _element.skuId === changeObj.skuId && _element.name === changeObj.name);
    if (i > -1) this.updatedData[i] = changeObj;
    else this.updatedData.push(changeObj);
    if (this.updatedData.length) this.localStorage.setItem('hasPendingSave', 'YES');
  }

  saveSaOPData() {
    this.loader.start();
    this.demandforecastingService.saveSaOPData(this.selectedProductFamily, this.lastExecutionMonthYear, this.updatedData, this.userRole).subscribe((response) => {
      if (response && response.code === 200) {
        this.getInitialSOAPData(this.selectedProductFamily, this.lastExecutionMonthYear, this.selectedMonth.id, this.nextMonthYear);
        this.updatedData = [];
        this.localStorage.setItem('hasPendingSave', 'NO');
        this.toastr.success('Value Saved Successfully');
      }
    });
  }


  public goForPublish() {
    let str = '';
    this.nextThreeMonthWithYear.forEach((el, i) => {
      if (i < this.nextThreeMonthWithYear.length - 1) {
        str = str + el.name + '-' + el.year + ', ';
      } else {
        str = str + ' and ' + el.name + '-' + el.year;
      }
    })
    const productFamilyName = this.productFamilies.filter(itemInArray => itemInArray.id === this.selectedProductFamily)[0].name;
    this.confirmationPopupService.confirm('Confirm', 'Are you sure you want to publish it?', 'Forecast will be published for ' + str + ' for all the SKU’s of the product family ' + `<b>` + productFamilyName + `</b>`, 'Publish', 'Cancel')
      .then((confirmed) => {
        if (confirmed) {
          this.publishSaOPData();
        }
      });
  }

  publishSaOPData() {
    this.loader.start()
    const payloadData = this.updatedData.length ? this.updatedData : [this.saopData.data[0].updatedData[0]]
    this.demandforecastingService.publishSaOPData(this.selectedProductFamily, this.lastExecutionMonthYear, payloadData, this.userRole).subscribe((response) => {
      if (response && response.code === 200) {
        this.getInitialSOAPData(this.selectedProductFamily, this.lastExecutionMonthYear, this.selectedMonth.id, this.nextMonthYear);
        this.updatedData = [];
        this.localStorage.setItem('hasPendingSave', 'NO');
        this.loader.stop();
        this.toastr.success('Value Published Successfully');
      } else {
        this.loader.stop();
      }
    });
  }

  // checking data for previous update on month change before save or publish
  checkForTheUpdate() {
    this.updatedData.forEach(item => {
      this.saopData.data.forEach(row => {
        row.updatedData.forEach(element => {
          if (element.skuId === item.skuId && element.name === item.name && element.week === item.week && element.workFlowId === item.workFlowId) {
            element.data = element.stateValue = item.data
          }
        })
      })
    })
  }
}
