import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Router, NavigationEnd } from '@angular/router';
import { UserServiceService } from 'src/app/shared/services/user-service.service';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from 'src/app/config.service';
import { Subscription } from 'rxjs';
import { HeaderService } from './header.service'
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { AdminService } from 'src/app/module/admin/admin.service';
import { ProfileComponent } from 'src/app/module/profile/profile.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  loggedIn = false;
  userName = '';
  userRole = '';
  userImage: any = '';
  moduleTitle:any='';
  role_id:any='';
  copy:any;
  landingTitle:any='';
  role_permissions:any='';
  moduleName:any;
  usernewrole: any = '';
  userData: any = [];
  localStorage=localStorage;
  userOtherRoles : any;
  userobj: any = [];
  headerText: any;
  subscription: Subscription;
  unReadNotificationData: Array<any> = [];
  notificationData: any;
  displayContent: boolean = false;

  constructor(
    private authService: MsalService,
    private route: Router,
    public userService: UserServiceService,
    public toastr: ToastrService,
    public headerService: HeaderService,
    public notificationService: NotificationService,
    private confirmationPopupService: ConfirmationPopupService,
    private configService: ConfigService,
    private cdRef : ChangeDetectorRef,
    private adminService: AdminService,
    private modalService: NgbModal,
    private location: Location
  ) {
    route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentRoute = event.url;
        this.displayContent = currentRoute === '/csr/csr-list' || currentRoute === '/csr/auto-personal-linesA' || currentRoute === '/csr/auto-personal-linesB' || currentRoute === '/csr/auto-personal-linesC' || currentRoute === '/csr/auto-personal-linesD' || currentRoute === '/csr/agent-detail2' || currentRoute === '/csr/agent-detail' || currentRoute === '/csr/agent-detail3' || currentRoute === '/csr/agent-detail4';
      }
    });
   }


  /***
   * @desc Check if user is logged in
   *
   */
  checkAccount() {
    this.loggedIn = !!this.authService.getAccount();
  }
  /***
   * @desc Logs out user  and remove user local storage object
   *
   */
  logout() {
    this.userService.removeUser();
    this.authService.logout();
  }
  /***
   * @desc Search Role name by Role Id
   * @param {Integer} Module nameKey
   * @param {Array} Module myArray
   * @returns {String} Returns Role Name
   *
   */
  searchbyroleid(nameKey, myArray) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].roles[0].roleId === nameKey) {
        return myArray[i];
      }
    }
  }
  /***
   * @desc Filter Roles other than passed Role Id
   * @param {Integer} Module nameKey
   * @param {Array} Module myArray
   * @returns {Array} Returns Filtered Role Array
   *
   */
  searchotherroles(nameKey, myArray) {
    const obj = [] as any;
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].roles[0].roleId != nameKey) {
        var dname = [] as any;
        dname["id"] = myArray[i].roles[0].roleId;
        dname["roleName"] = myArray[i].roles[0].title;
        obj.push(dname);
      }
    }
    return obj;
  }
  ngOnInit(): void {
    this.checkAccount()
    this.userobj = this.userService.getUser();
    this.userData = this.userobj;
    this.moduleName = this.userData?.defaultRolePermissionInfo[0].landingPageTitle;
    this.configService.getModuleTitle().subscribe(title => {
      if(title !== null) {
        this.moduleName = title;
      } else {
        this.moduleName = this.localStorage.getItem('currentTitle')
      }
    });
    if(this.userData) {
      if (this.userData.defaultRoleId || this.userData.user_roles) {
        var otherRolesObject = this.searchotherroles(this.userData.defaultRoleId, this.userData.user_roles);
        this.userRole = this.userService.getrolename(this.userData.user_roles, this.userData.defaultRoleId);
      } else { this.userRole = "Super Admin"; otherRolesObject = ''; }
      this.userOtherRoles = otherRolesObject;
      if (this.userData.userImage) { this.userImage = this.userData.userImage; } else {
        this.userImage = "";
      }

      this.userName = this.userData.firstName + " " + this.userData.lastName;
      this.userService.setUser(this.userobj);
    }
    this.notificationService.notificationData.subscribe((data) => {
      if (data) {
        this.notificationData = data;
        this.unReadNotificationData = data.filter((element) => {
          return !element.viewed
        })
      }
    })
  }

  configureUserDetails() {
    this.checkAccount()
    this.userobj = this.userService.getUser();
    this.userData = this.userobj;
    if(this.userData) {
      if (this.userData.defaultRoleId || this.userData.user_roles) {
        var otherRolesObject = this.searchotherroles(this.userData.defaultRoleId, this.userData.user_roles);
        this.userRole = this.userService.getrolename(this.userData.user_roles, this.userData.defaultRoleId);
      } else { this.userRole = "Super Admin"; otherRolesObject = ''; }
      this.userOtherRoles = otherRolesObject;
      if (this.userData.userImage) { this.userImage = this.userData.userImage; } else {
        this.userImage = "";
      }
      this.userName = this.userData.firstName + " " + this.userData.lastName;
      this.userService.setUser(this.userobj);
    }
  }

  ngAfterContentInit() {
    this.subscription = this.configService.currentText.subscribe(text => {
      this.headerText = text;
      this.cdRef.detectChanges();
      if(this.subscription) {
        this.subscription.unsubscribe();
      }
    });
  }

  /***
   * @desc Change Default Role
   * @param {Object} Module usernewrole
   * @returns {Object} Returns API Response
   *
   */
  changedefaultrole(usernewrole) {
    this.modalService.dismissAll();
    Promise.all([this.localStorage.getItem('hasPendingSave')]).then(values => {
      const hasPendingSave = values[0];
     if (hasPendingSave === 'YES') {
        this.confirmationPopupService.confirm('Confirm', 'Do you want to leave the section without saving your changes?','','Yes', 'Cancel')
            .then((confirmed) => {
              if(confirmed) {
                this.goForRoleChange(usernewrole);
                this.localStorage.setItem('hasPendingSave', 'NO');
              } else if(!confirmed) {
                // Closing popup without any change
              }
            });
      }  else {
        this.goForRoleChange(usernewrole);
      }
    });

  }


 goForRoleChange(usernewrole){
    this.userService.changedefaultrole(usernewrole).subscribe(rolesObj => {
      this.toastr.success('User Role Changed');
      setTimeout(async () => {
        var userdefaultrole: any = this.userService.getUser();
        this.userRole = this.userService.getrolename(userdefaultrole.user_roles, usernewrole);
        this.userOtherRoles = this.searchotherroles(userdefaultrole.defaultRoleId, userdefaultrole.user_roles);
        this.moduleTitle = rolesObj.data.defaultRolePermissionInfo[0].moduleTitle;
        this.landingTitle = rolesObj.data.defaultRolePermissionInfo[0].landingPageTitle;
        this.role_permissions = rolesObj.data.defaultRolePermissionInfo[0].role_permissions;
        this.role_id = rolesObj.data.defaultRolePermissionInfo[0].id;
        userdefaultrole.defaultRolePermissionInfo[0].landingPageTitle = this.landingTitle;
        userdefaultrole.defaultRolePermissionInfo[0].moduleTitle =  this.moduleTitle;
        userdefaultrole.defaultRolePermissionInfo[0].role_permissions =  this.role_permissions;
        userdefaultrole.user_roles.forEach(function(element){
          const role_id = rolesObj.data.defaultRolePermissionInfo[0].id;
          if(element.roleId===role_id){
            element.roles[0].role_permissions = rolesObj.data.defaultRolePermissionInfo[0].role_permissions;
          }
        })
        await this.userService.setUser(userdefaultrole);
        this.configService.setModuleTitle(this.landingTitle);
        this.route.navigate(['/home']);
      }, 3000);
    });
  }


  navigateToOtherRoute(path) {
    this.modalService.dismissAll();
    Promise.all([this.localStorage.getItem('hasPendingSave')]).then(values => {
      const hasPendingSave = values[0];
      if(hasPendingSave === 'NO' || hasPendingSave === null) {
        if(this.userRole === 'Super Admin') {
          this.configService.setModuleTitle('')
        } else {
          this.configService.setModuleTitle(this.userData.defaultRolePermissionInfo[0].landingPageTitle)
        }
        this.route.navigate([path]);
      } else if (hasPendingSave === 'YES') {
        this.confirmationPopupService.confirm('Confirm', 'Do you want to leave the section without saving your changes?','','Yes', 'Cancel')
            .then((confirmed) => {
              if(confirmed) {
                if(this.userRole === 'Super Admin') {
                  this.configService.setModuleTitle('')
                } else {
                  this.configService.setModuleTitle(this.userData.defaultRolePermissionInfo[0].landingPageTitle)
                }

                this.route.navigate([path]);
                this.localStorage.setItem('hasPendingSave', 'NO');
              } else if(!confirmed) {
                // Closing Popup without any change
              }
            });
      }
    });
  }


  /**
   * Gets module title using header service
   * @returns  module title as String
   */
  getModuleTitle() {
    return this.headerService.getModuleTitle();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();

  }

  readNotification() {
    if(!this.unReadNotificationData.length){
      return
    }
    let endpoint: string;
    if (this.userRole === "Demand Planner") {
      endpoint = 'demandPlannerNotification/'
    } else {
      endpoint = 'managerNotification/'
    }
    const payload:Array<any> = [];
    this.unReadNotificationData.forEach(element => {
      payload.push({
        'id': element.id,
        'viewed': 1
      })
    });


    this.notificationService.readNotification(payload, endpoint).subscribe((data) => {
      if (data.status === 200) {
        this.notificationData.forEach(element => {
          element.viewed=true;
        });
        this.unReadNotificationData=[]
      }
    })
  }

  openAsModel() {
    this.adminService.openAsModel(ProfileComponent).then((confirmed) => {
      if (confirmed) {
        setTimeout(() => {
          this.configureUserDetails();
        }, 1000)
      } else if (!confirmed) {
        // Model will be closed without any changes
      }
    })
  }

     /***
   * @desc window.history.back
   *
   */
     goBack() {
      this.location.back();
    }
}
