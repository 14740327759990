import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BordereauxManagementService } from '../../../services/bordereaux-management.service';

@Component({
  selector: 'app-edit-tags',
  templateUrl: './edit-tags.component.html',
  styleUrls: ['./edit-tags.component.scss'],
})
export class EditTagsComponent implements OnInit {
  @Input() modalTagsData: any;
  filterData: any;
  tagsList = [];
  selectedTagList: any;
  constructor(
    private activeModal: NgbActiveModal,
    public BDXManagementService: BordereauxManagementService
  ) {}
  ngOnInit(): void {
    this.getFilterData();
    this.selectedTagList = this.modalTagsData.selectedTag;
  }

  getFilterData() {
    this.BDXManagementService.getMetamorphFilterData().subscribe((res) => {
      if (res && res.code === 200) {
        this.tagsList = res.tags.map((e) => e.name);
      }
    });
  }

  public decline() {
    this.activeModal.close(false);
  }

  public save() {
    this.activeModal.close(this.selectedTagList);
  }
}
