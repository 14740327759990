import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { timeStamp } from 'console';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/config.service';
import { PricingService } from '../pricing.service';
import { PricingLobService } from './pricing-lob.service';

@Component({
  selector: 'app-pricing-lob',
  templateUrl: './pricing-lob.component.html',
  styleUrls: ['./pricing-lob.component.scss']
})
export class PricingLobComponent implements OnInit {
  lobsList: any;
  pmj: any;
  lobData: any = [];
  localStorage = localStorage;
  subscription: Subscription;
  headerText: any;
  breadcrumb: Array<any> = [];

  constructor(private pricingLobService: PricingLobService, private pricingService: PricingService, private configService: ConfigService, private ngxLoader: NgxUiLoaderService, private route: Router) { }

  ngOnInit(): void {
    this.getLobsLanding() // Get LOB 
    // Adding breadcrumb 
    this.pricingService.addBreadcrumbData({ "level": "0", "name": '' }).subscribe(data => {
      this.breadcrumb = data;
    })
    this.configService.changeHeader('Pricing Analytics'); // Updating Header text
  }
  /***
  * @desc Edit Claim
  * @param {String} Module claim all data show
  */
  getClaimsData(policyData) {
    this.localStorage.setItem('lobId', policyData.id);
    this.ngxLoader.start();
    this.pricingService.setClaim(policyData);
    const param = { id: policyData.id }
    this.route.navigate(['/pricing/project']);
    this.pricingService.addBreadcrumbData({ "level": "1", "name": policyData.name }).subscribe(data => {
      this.breadcrumb = data;
    });
    this.ngxLoader.stop();
  }
  /***
    * @desc Lobs get name and image
    */
  getLobsLanding() {
    this.ngxLoader.start();
    this.pricingLobService.getLobsLanding().subscribe((lobObj) => {
      if (lobObj) {
        this.lobsList = lobObj;
        this.pmj = "pmj";
        var lobsListData: any = this.lobsList.data;
        this.lobData = lobsListData;
        this.ngxLoader.stop();
      } else { this.lobData = []; this.ngxLoader.stop(); }
    });
  }
  
}
