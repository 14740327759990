import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScmMmmStaticRoutingModule } from './scm-mmm-static-routing.module';
import { ScmMmmStaticComponent } from './scm-mmm-static.component';
import { ScmOptimizeStaticComponent } from './scm-static/scm-optimize-static/scm-optimize-static.component';
import { ScmSimulateStaticComponent } from './scm-static/scm-simulate-static/scm-simulate-static.component';
import { ScmStaticComponent } from './scm-static/scm-static.component';
import { FormsModule } from '@angular/forms';
import { HighchartsChartModule } from 'highcharts-angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScenarioNamePopoverStaticComponent } from './scenario-name-popover-static/scenario-name-popover-static.component';


@NgModule({
  declarations: [
    ScmMmmStaticComponent,
    ScmOptimizeStaticComponent,
    ScmSimulateStaticComponent,
    ScmStaticComponent,
    ScenarioNamePopoverStaticComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    HighchartsChartModule,
    ScmMmmStaticRoutingModule,
    NgbModule
  ]
})
export class ScmMmmStaticModule { }
