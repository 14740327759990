import { Component, Input, OnInit } from '@angular/core';
import { MetamorphCommonService } from '../../../../../services/metamorph-common.service';
import { UserServiceService } from 'src/app/shared/services/user-service.service';
import { AmendRuleService } from '../../../../../services/amend-rule.service';

@Component({
  selector: 'app-child-table-component',
  templateUrl: './child-table-component.component.html',
  styleUrls: ['./child-table-component.component.scss']
})
export class ChildTableValidateComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  @Input() dataRow: any;
  amendRuleListData: Array<any> = [];
  amendFiltered: any;
  processId: any;
  operationalData: any;
  

  constructor( public metamorphCommonService: MetamorphCommonService,    
    public userService: UserServiceService,
    private amendRuleService: AmendRuleService) { }

  ngOnInit(): void {
    this.operationalData = JSON.parse(
      this.metamorphCommonService.getOperationalInfoData()
    );
    this.processId = this.operationalData.filter(function (el) {
      return el.label == 'Process Id';
    })[0].value;
    this.dtOptions = {
      info: false,
      lengthChange: false,
      processing: true,
      searching: false,
      paging: false,
      destroy:true,
      deferRender: true,
      
    }
  }

  ruleCountList(workItem) {
    this.amendRuleService
      .getAmendRuleDataPrevious(this.processId, workItem)
      .subscribe((response) => {
        this.amendRuleListData = response?.data;
        this.amendRuleListData.filter;
        this.amendFiltered = this.amendRuleListData.filter(function (e) {
          return e.isSelected == true;
        });
        this.metamorphCommonService
          .openAmendRules(this.amendFiltered, this.processId, workItem)
          .then((data) => {});
      });
  }

  

}
