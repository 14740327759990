import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'file-progress-breadcrumb',
  templateUrl: './file-progress-breadcrumb.component.html',
  styleUrls: ['./file-progress-breadcrumb.component.scss']
})
export class FileProgressBreadcrumbComponent implements OnInit {

  @Input() progressSteps: Array<any>;

  constructor() { }

  ngOnInit(): void {
  }

}
