import { Component, Input, OnInit } from '@angular/core';
import { PricingModelValidationService } from '../pricing-model-validation/pricing-model-validation.service';
import { PricingService } from '../pricing.service';
import { PricingDataExplorationService } from './pricing-data-exploration.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Highcharts from "highcharts";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from 'src/app/config.service';

@Component({
  selector: 'app-pricing-data-exploration',
  templateUrl: './pricing-data-exploration.component.html',
  styleUrls: ['./pricing-data-exploration.component.scss']
})
export class PricingDataExplorationComponent implements OnInit {
  chartFilters: any;
  public lobProjectId: any;
  localStorage = localStorage;
  public exploratoryVariables = "";
  public modelType = "";
  public filterForExpoDataChart: any;
  expoDataChartTemplate: any;
  updatedFilterData: any;
  charts = [1, 2];
  public modelTypeFilters: Array<any> = [];
  public exploratoryVariablesFilters: Array<any> = [];

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: any;
  chartModelData: any;
  closeResult: string;
  edaChartTemplate: any[];

  constructor(private pricingDataExplorationService: PricingDataExplorationService, private modalService: NgbModal,
    private pricingService: PricingService, private pricingModelValidationService: PricingModelValidationService,
    private ngxLoader: NgxUiLoaderService, private toastr: ToastrService, private configService: ConfigService) { }

  ngOnInit(): void {
    this.configService.changeHeader('Pricing Analytics');
    Promise.all([this.localStorage.getItem('lobProjectId')]).then(values => {
      this.lobProjectId = values[0];
      this.getFilterData(this.lobProjectId);
      // this.getExpoDataChartData(this.lobProjectId, this.modelType, this.exploratoryVariables)
    })
  }

  // Updating chart data using filter panel
  updateStatus(checkbox, i, filterType) {
    if (filterType === 'exploratoryVariables') {
      if (this.exploratoryVariablesFilters.includes(checkbox.id)) {
        this.exploratoryVariablesFilters = this.exploratoryVariablesFilters.filter(e => e !== checkbox.id)
      } else {
        this.exploratoryVariablesFilters.push(checkbox.id);
        this.exploratoryVariablesFilters = this.exploratoryVariablesFilters.sort(function (a, b) { return a - b; });
      }
    } else if (filterType === 'modelTypes') {
      if (this.modelTypeFilters.includes(checkbox.id)) {
        this.modelTypeFilters = this.modelTypeFilters.filter(e => e !== checkbox.id)
      } else {
        this.modelTypeFilters.push(checkbox.id);
        this.modelTypeFilters = this.modelTypeFilters.sort(function (a, b) { return a - b; });
      }
    }
    if (!this.modelTypeFilters.length || !this.exploratoryVariablesFilters.length) {
      this.toastr.error('Select at least one filter form each filter group');
      this.chartOptions = []
    } else {
      this.modelType = this.modelTypeFilters.join(",");
      this.exploratoryVariables = this.exploratoryVariablesFilters.join(",");
      this.getExpoDataChartData(this.lobProjectId, this.modelType, this.exploratoryVariables)
    }
  }


  //fetching data-exploration chart data and constructing API data to chart option
  getExpoDataChartData(lobProjectId, modelType, exploratoryVariables) {
    this.ngxLoader.start();
    // geting chart template JSON for data-exploration chart
    this.pricingDataExplorationService.getEDAChartTemplateJSON().subscribe(template => {
      if (template) {
        this.edaChartTemplate = template;
        // API Call
        this.pricingDataExplorationService.getLobModelValidationExpoDataChart(lobProjectId, modelType, exploratoryVariables).subscribe(apiResponce => {
          if (apiResponce) {
            //constructing chart option (we need to pass API response and Chart template as parameter)
            this.pricingDataExplorationService.formateEDAAPIData(apiResponce.data, this.edaChartTemplate).subscribe(finalResponce => {
              this.chartOptions = finalResponce;
              this.ngxLoader.stop()
            })
          } else { this.chartOptions = []; this.ngxLoader.stop(); }
        });
      }
    });
  }

  // Fetching Filter panel data and formating as par filter type
  getFilterData(lobProjectId) {
    this.pricingModelValidationService.getFilterChartPanelData(lobProjectId).subscribe(data => {
      if (data) {
        this.pricingModelValidationService.formatFilterPanelData(data).subscribe((formattedResponse) => {
          this.formatFilterData(formattedResponse);
        });
      }
    });
  }

  // Formating Filter panel data based on filter type
  formatFilterData(formattedResponse) {
    const filterType1 = ["modelTypes", "exploratoryVariables"];
    if (formattedResponse) {
      this.chartFilters = formattedResponse.filter((item) => filterType1.includes(item.filterType));
      this.chartFilters.forEach((element, index) => {
        if (element.filterType === 'modelTypes') {
          element.values.forEach((item) => {
            if (item.isSelected) {
              this.modelTypeFilters.push(item.id);
            }
          })
          this.modelType = this.modelTypeFilters.join(',')
        } else if (element.filterType === 'exploratoryVariables') {
          element.values.forEach((item) => {
            if (item.isSelected) {
              this.exploratoryVariablesFilters.push(item.id);
            }
          })
          this.exploratoryVariables = this.exploratoryVariablesFilters.join(',')
        }
      });
      this.getExpoDataChartData(this.lobProjectId, this.modelType, this.exploratoryVariables);
    }
  }

  revertCheckbox(checkbox, i, filterType) {
    this.chartFilters.forEach((element, index) => {
      if (element.filterType === filterType) {
        element.value[i].isSelected = true;
      }
    })
  }

  filterUser(item) {
    return !!item.displayChart;
  }

  // Open chart in expended view 
  openXl(content, data) {
    this.chartModelData = data;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
