import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
const GLM_TMEPLATE_BASE_URL = '../../../../../assets/data/pricing/';
const GLM_TEMPLATE_JSON = 'glm.json';

@Injectable({
  providedIn: 'root'
})
export class PricingModelAnalysisService {

  constructor(private http: HttpClient) { }

  /** Author Varun
* get ModelAnalysisOverview List API
*/
  getlobModelAnalysisOverviewGlm(): Observable<any[]> {
    return this.http.get<any>(`${GLM_TMEPLATE_BASE_URL}${GLM_TEMPLATE_JSON}`);
  }

  /** Author Varun
 * get ModelAnalysisOverview List API
 */
  getlobModelAnalysisOverview(lobProjectId, modelType, modelTechnique): Observable<any[]> {
    return this.http.get<any>('lobModelAnalysisOverview/' + lobProjectId + '/' + modelTechnique + '/' + modelType + '/');
  }

  /** Author Varun
* get ModelAnalysisOverview List API
*/
  getlobModelAnalysisComparision(lobProjectId, modelType, modelTechnique): Observable<any[]> {
    return this.http.get<any>('lobModelAnalysisComparison/' + lobProjectId + '/' + modelTechnique + '/' + modelType + '/');
  }

  // Getting ModelType and ModelTechnique filter data indivisual API call using forkJoin
  geInduvidualFilterData(lobProjectId): Observable<any> {
    const getModelTechniques = this.http.get<any>('modelTechniques/' + lobProjectId + '/');
    const getModalType = this.http.get<any>('modelTypes/' + lobProjectId + '/');
    return forkJoin([getModelTechniques, getModalType]);
  }

  // Getting all type of chart filters
  getFilterChartPanelData(lobProjectId): Observable<any> {
    return this.http.get<any>('pricing/allFilters/' + lobProjectId + '/');
  }


  // Constructing filter pannel data based on type
  formatFilterPanelData(data): Observable<any> {
    const chartFilters = [] as any;
    const chartFilterObj = {
      "title": {
        "name": "Model Techniques"
      },
      "filterType": "universal",
      "isMultySelect": true,
      "values": [
        {
          "name": "GLM",
          "isSelected": true
        },
        {
          "name": "XGB",
          "isSelected": true
        }
      ]
    };
    const typeFilter = ["modelTechniques", "modelTypes"];
    const res = data;
    Object.keys(data).forEach(key => {
      if (typeof (data[key]) === 'object') {
        const x = {
          ...chartFilterObj,
          title: {
            ...chartFilterObj.title,
            name: key.replace(/^([a-z]|[A-Z])$/g, (c, i) => (i ? " " : "") + c.toUpperCase())
          },
          filterType: key,
          values: res[key].map((item, index) => {
            return {
              ...item,
              isSelected: true
            }
          })
        }
        chartFilters.push(x);
      }
    })
    return of('filter', chartFilters.filter((item) => typeFilter.includes(item.filterType)));
  }
}
