import {
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DataTableDirective } from 'angular-datatables';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MetamorphCommonService } from '../../../../services/metamorph-common.service';
import { ValidationBordereauxManagementService } from '../../../../services/validation-bordereaux-management.service';
import { ChildTableValidateComponent } from './child-table-component/child-table-component.component';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { AmendRuleService } from '../../../../services/amend-rule.service';
import { UserServiceService } from 'src/app/shared/services/user-service.service';

@Component({
  selector: 'bordeaux-details-table',
  templateUrl: './bordeaux-details-table.component.html',
  styleUrls: ['./bordeaux-details-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('1100ms ease-in-out')),
    ]),
  ],
})
export class BordeauxDetailsTableComponent implements OnInit {
  @Input() selectedTab: any;
  @Input() amendPayLoad: any;
  @Input() workItem: any;
  @Input() amendReloadFlag: any;
  @Output() amendRuleEvent = new EventEmitter<any>();
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  bordereauxValidationListData: Array<any> = [];
  processId: any;
  yearMonth: any;
  
  operationalData: any;
  validateResponse: any;
  amendResponse: any;
  validateFlag = false;
  versionIndex: any;
  indexAmend: any;
  //Context Menu
  contextmenu = false;
  contextmenuX = 0;
  contextmenuY = 0;
  contextRecord: any;
  contextId: any;
  workItemArray: any;
  contextClass = 'parentClass';
  amendStep = false;
  timeInterval: Subscription;
  timeIntevalSeconds = 20;
  iterationFlag = 0;
  amendFiltered: any;
  selectedVersionInfo: any;
  amendRuleListData: Array<any> = [];
  oprationalDetailsInfo = {
    header: 'Standrization',
    info: [
      { label: 'Standard', value: 'LMS 5.2' },
      { label: 'File Type', value: 'Payment' },
      { label: 'Coberholder', value: 'CIL' },
      { label: 'Execution Month', value: 'Jan-2023' },
    ],
    aditionalConfig: {
      titleText: 'Upload New Data',
      reportingPeriodStartDate: '',
      reportingPeriodEndDate: '',
      versionInfo: '',
    },
  };

  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;
  private childRow: ComponentRef<ChildTableValidateComponent>;
  private uploadEventsSubscription: Subscription;

  constructor(
    public metamorphCommonService: MetamorphCommonService,
    private validationbordereauxManagementService: ValidationBordereauxManagementService,
    private ngxLoader: NgxUiLoaderService,
    private viewRef: ViewContainerRef,
    private _renderer: Renderer2,
    public toastr: ToastrService,
    private route: Router,
    private confirmpopupservice: ConfirmationPopupService,
    private amendRuleService: AmendRuleService,
    public userService: UserServiceService
  ) {}

  ngOnInit(): void {
    this.operationalData = JSON.parse(
      this.metamorphCommonService.getOperationalInfoData()
    );
    this.uploadEventsSubscription = this.metamorphCommonService
      .captureFileUploadEvent()
      .subscribe((data) => {
        if (data === 'validation' && data === this.selectedTab) {
          this.oprationalDetailsInfo.aditionalConfig.titleText =
            'Upload New Data';
          this.oprationalDetailsInfo.aditionalConfig.reportingPeriodEndDate =
            '';
          this.oprationalDetailsInfo.aditionalConfig.reportingPeriodStartDate =
            '';
          this.oprationalDetailsInfo.aditionalConfig.versionInfo = '';
          this.openFileUploader(false, this.oprationalDetailsInfo);
        }
      });
    if(this.amendReloadFlag==true)
    {
       this.getBordereauxValidationListData();
    }
  }

  ngOnChanges() {
    if (this.amendReloadFlag==false && this.amendPayLoad && this.workItem ) {
      this.amendRuleService
        .saveAmendRuleData(this.amendPayLoad, this.workItem)
        .subscribe((response) => {
          if (response && response.code === 201) {
            this.ngxLoader.start();
            this.amendResponse = response.data;
            this.iterationFlag = 0;
            this.getBordereauxValidationListData();
            this.toastr.success(response.successMessage);
          }
        });
    }
  }

  getBordereauxValidationListData(reloadRequired?) {
    this.ngxLoader.start();
    this.bordereauxValidationListData = [];
    this.ngxLoader.start();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      deferRender: true,
      destroy: true,
      order: [[0, 'desc']],
      scrollY: 'calc(100vh - 375px)',
      scrollCollapse: true,
    };
    this.dtOptions.columnDefs = [
      {
        targets: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        orderable: false,
      },
    ];

    this.processId = this.operationalData.filter(function (el) {
      return el.label == 'Process Id';
    })[0].value;
    this.yearMonth = this.operationalData.filter(function (el) {
      return el.label == 'Execution Month';
    })[0].value;
    this.validationbordereauxManagementService
      .getBordereauxValidationListData(this.processId, this.yearMonth)
      .subscribe((response) => {
        this.bordereauxValidationListData = response?.data;
        if (this.iterationFlag == 0) {
          setInterval(() => {
            if (this.iterationFlag == 0) {
              this.checkInProgressCount();
            }
          }, this.timeIntevalSeconds * 1000);

          this.amendReloadFlag==true;
          if (reloadRequired) {
            this.datatableElement.dtInstance.then(
              (dtInstance: DataTables.Api) => {
                dtInstance.destroy();
                this.dtTrigger.next();
                this.ngxLoader.stop();
              }
            );
          } else {
            this.dtTrigger.next();
            this.ngxLoader.stop();
          }
        }
      });
  }

  checkInProgressCount() {
    this.workItemArray = this.bordereauxValidationListData.filter(function (
      value,
      index
    ) {
      value.index = index;
      let selectedVersion = value[value.selected].latestIteration;
      let selectedVersionStatus = value[value.selected].latestIteration.status;
      return selectedVersionStatus == 'Validation In-Progress';
    });
    let workItemCount = this.workItemArray.length;
    if (workItemCount > 0) {
      this.workItemArray.forEach((element) => {
        let selectedVersionWorkItem =
          element[element.selected].latestIteration.workItemId;
        this.validationbordereauxManagementService
          .validateProgressProcess(
            this.processId,
            this.yearMonth,
            selectedVersionWorkItem
          )
          .subscribe((response) => {
            let workItemData = response.data[0];
            let workItemStatus =
              workItemData[workItemData.selected].latestIteration.status;
            if (workItemStatus == 'Validated') {
              let indexCurrent = element.index;
              this.bordereauxValidationListData[indexCurrent][
                this.bordereauxValidationListData[indexCurrent].selected
              ].latestIteration.status = 'Validated';
              this.bordereauxValidationListData[indexCurrent][
                this.bordereauxValidationListData[indexCurrent].selected
              ].latestIteration.downloadOutputLink =
                workItemData[
                  workItemData.selected
                ].latestIteration.downloadOutputLink;
            } else if (workItemStatus == 'System Error') {
              let indexCurrent = element.index;
              this.bordereauxValidationListData[indexCurrent][
                this.bordereauxValidationListData[indexCurrent].selected
              ].latestIteration.status = 'System Error';
            } else if (workItemStatus == 'Validation Failed') {
              let indexCurrent = element.index;
              this.bordereauxValidationListData[indexCurrent][
                this.bordereauxValidationListData[indexCurrent].selected
              ].latestIteration.status = 'Validation Failed';
              this.bordereauxValidationListData[indexCurrent][
                this.bordereauxValidationListData[indexCurrent].selected
              ].latestIteration.downloadErrorReportLink =
                workItemData[
                  workItemData.selected
                ].latestIteration.downloadErrorReportLink;
            }
          });
      });
    } else {
      this.iterationFlag = 1;
    }
  }

  expandRow(trRef, rowData) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      var row = dtInstance.row(trRef);
      if (row.child.isShown()) {
        row.child.hide();
        this._renderer.removeClass(trRef, 'shown');
      } else {
        this.childRow = this.viewRef.createComponent(
          ChildTableValidateComponent
        );
        this.childRow.instance.dataRow = [rowData];
        row.child(this.childRow.location.nativeElement).show();
        this._renderer.addClass(trRef, 'shown');
      }
    });
  }

  /***
   * @desc Validate Process
   * @param Integer (Row Index,Work Item Id)
   * Call service to validate process
   *
   */

  validationProcess(index, workItemId) {
    this.validationbordereauxManagementService
      .validateProcess(workItemId)
      .subscribe((response) => {
        this.validateResponse = response?.data;
        this.toastr.success('Validation In Progress');
        this.bordereauxValidationListData[index][
          this.bordereauxValidationListData[index].selected
        ].oldIteration.unshift(
          this.bordereauxValidationListData[index][
            this.bordereauxValidationListData[index].selected
          ].latestIteration
        );
        this.bordereauxValidationListData[index][
          this.bordereauxValidationListData[index].selected
        ].latestIteration =
          this.validateResponse[0][
            this.validateResponse[0].selected
          ].latestIteration;
        this.versionIndex = this.bordereauxValidationListData[
          index
        ].versions.findIndex((e) => {
          return e == this.bordereauxValidationListData[index].selected;
        });
        this.bordereauxValidationListData[index].versionsInfo[
          this.versionIndex
        ] =
          this.validateResponse[0][
            this.validateResponse[0].selected
          ].latestIteration.workItemId;
        this.validateFlag = true;
        this.iterationFlag = 0;
      });
  }

  landToRespectiveMappingScreen(
    indexInfo,
    uploadId,
    mappingStatus,
    workItemId
  ) {
    let step = '';
    if (mappingStatus.toLowerCase() === 'ready for sheet mapping.') {
      step = 'sheet-mapping';
    } else if (mappingStatus.toLowerCase() === 'sheet mapping is done.') {
      step = 'header-range-selection';
    } else if (mappingStatus.toLowerCase() === 'column mapping is done.') {
      step = 'mapped-column-preview';
    }
    const mappingStatusData = {
      step: step,
      uploadId: uploadId,
      workItemId: workItemId,
    };
    this.oprationalDetailsInfo.aditionalConfig.titleText = 'Upload New Data';
    this.oprationalDetailsInfo.aditionalConfig.reportingPeriodEndDate = '';
    this.oprationalDetailsInfo.aditionalConfig.reportingPeriodStartDate = '';
    this.oprationalDetailsInfo.aditionalConfig.versionInfo = '';
    this.openFileUploader(true, this.oprationalDetailsInfo, mappingStatusData);
  }

  openFileUploader(
    isMappingFlow,
    oprationalDetailsInfo: any,
    mappingStatus?: any
  ) {
    this.metamorphCommonService
      .openFileUploader(
        isMappingFlow,
        oprationalDetailsInfo,
        this.selectedTab,
        false,
        mappingStatus
      )
      .then((reason) => {
        if (reason) {
          this.iterationFlag = 0;
          this.getBordereauxValidationListData(true);
        }
      });
  }

  ngOnDestroy(): void {
    this.uploadEventsSubscription.unsubscribe();
  }

  amendRules(workItemId) {
    this.amendRuleEvent.next({
      workItemId: workItemId,
      processId: this.processId,
      selectedView: 'amend-rule',
    });
  }

  /***
   * @desc Update Selected Version
   * @param Integer (Index Id, Selected Version)
   * Call service to update user selected version and get version related details
   * @returns {Array} Returns selected version details list
   *
   */
  updateVersion(indexId, selectedVersion) {
    this.versionIndex = this.bordereauxValidationListData[
      indexId
    ].versions.findIndex((e) => {
      return e == selectedVersion;
    });
    this.selectedVersionInfo =
      this.bordereauxValidationListData[indexId].versionsInfo[
        this.versionIndex
      ];
    this.validationbordereauxManagementService
      .updateVersionInfo(this.selectedVersionInfo)
      .subscribe((response) => {
        this.validateResponse = response?.data;
        this.bordereauxValidationListData[indexId].selected = selectedVersion;
      });
  }

  deleteValidate(workItem) {
    this.confirmpopupservice
      .confirm(
        'Confirm',
        'Are you sure, you want to Delete the version permanently?',
        '',
        'Yes',
        'Cancel'
      )
      .then((res) => {
        if (res) {
          this.validationbordereauxManagementService
            .deleteValidate(workItem)
            .subscribe((resCode) => {
              if (resCode && resCode.code === 200) {
                this.toastr.success('The version is deleted successfully');
                this.getBordereauxValidationListData(true);
              }
            });
        }
      });
  }

  ruleCountList(workItem) {
    this.amendRuleService
      .getAmendRuleDataPrevious(this.processId, workItem)
      .subscribe((response) => {
        this.amendRuleListData = response?.data;
        if(this.amendRuleListData){
        this.amendFiltered = this.amendRuleListData.filter(function (e) {
          return e.isSelected == true;
        });}
        this.metamorphCommonService
          .openAmendRules(this.amendFiltered, this.processId, workItem)
          .then((data) => {});
      
      });
  }

  uploadCorrectedData(record) {
    this.oprationalDetailsInfo.aditionalConfig.titleText =
      'Upload Corrected Data';
    this.oprationalDetailsInfo.aditionalConfig.reportingPeriodEndDate =
      record?.reportEndDate;
    this.oprationalDetailsInfo.aditionalConfig.reportingPeriodStartDate =
      record?.reportStartDate;
    this.oprationalDetailsInfo.aditionalConfig.versionInfo = record?.version;
    this.oprationalDetailsInfo.aditionalConfig['ruleList'] = record?.ruleListJson;
    this.openFileUploader(false, this.oprationalDetailsInfo);
  }
}
