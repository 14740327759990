<div class="data-table-wrapper" aria-describedby="mydesc">
  <img src="../../../../../../assets/images/slider-icon.svg" alt="filter" class="filter-icon" (click)="applyFilter()">

  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table dataTable no-footer">
    <thead>
      <tr>
        <th scope="col" class="first-col">&nbsp;</th>
        <th scope="col">Process ID</th>
        <th scope="col">File Type</th>
        <th scope="col">Cover Holder</th>
        <th scope="col">Format</th>
        <th scope="col">&nbsp;</th>
        <th scope="col">&nbsp;</th>
      </tr>
    </thead>
    <tbody *ngIf="projectListData?.length != 0">
      <tr #trRef *ngFor="let item of projectListData, let index as index"
        [ngClass]="{ 'expended-outline': item.expended }">
        <td class="first-col">
          <i class="material-icons cursor-pointer" [ngClass]="{ 'expended-icon': item.expended }"
            (click)="expandRow(trRef, item); item.expended = !item.expended">keyboard_arrow_down</i>
        </td>
        <td>{{item?.processName}}</td>
        <td>{{item?.fileType}}</td>
        <td>{{item?.coverHolder}}</td>
        <td>{{item?.format}}</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
      </tr>
    </tbody>
    <tbody *ngIf="projectListData?.length == 0">
      <tr>
        <td colspan="6" class="p-0" class="no-data-available ">No data!</td>
      </tr>
    </tbody>
  </table>
</div>
