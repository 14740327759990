import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DemandforecastingRoutingModule } from './demandforecasting-routing.module';
import { DemandForecastComponent } from './demand-forecast/demand-forecast.component';
import { DemandForecastDashboardComponent } from './demand-forecast/demand-forecast-dashboard/demand-forecast-dashboard.component';
import { DemandPerformanceAnalysisComponent } from './demand-forecast/demand-performance-analysis/demand-performance-analysis.component';
import { SopApprovalComponent } from './sop-approval/sop-approval.component';
import { FormsModule } from '@angular/forms';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InitialSaopPlaningComponent } from './initial-saop-planing/initial-saop-planing.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DemandforecastingComponent } from './demandforecasting.component';
import { DemandForecastShowAnalysisComponent } from './demand-forecast/demand-forecast-show-analysis/demand-forecast-show-analysis.component';


@NgModule({
  declarations: [
    DemandForecastComponent,
    DemandForecastDashboardComponent,
    DemandPerformanceAnalysisComponent,
    SopApprovalComponent,
    InitialSaopPlaningComponent,
    DemandforecastingComponent,
    DemandForecastShowAnalysisComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    HighchartsChartModule,
    NgbModule,
    SharedModule,
    DemandforecastingRoutingModule
  ]
  
})
export class DemandforecastingModule { }
