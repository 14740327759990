<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <!-- tabs -->
      <div class="module-nav">
        <ul class="nav nav-tabs mt-2" id="supplyChain" role="tablist">
          <li class="nav-item">
            <a class="nav-link sub-module" (click)="selectTab('bordereauxManagementOutline', $event)" role="tab"
              [ngClass]="{ 'active':selectedTab==='bordereauxManagementOutline'}">Bordereaux Management Outline</a>
          </li>
        </ul>
      </div>
      <div class="bg-white shadow rounded mt-0 p-2" style="height: calc(100vh - 155px); overflow-y: auto;">
        <!-- tab pannel -->
        <div class="tab-content bg-white" id="pricingTabsContent">
          <div class="tab-pane" id="bordereaux-management-outline" role="tabpanel"
            [ngClass]="{ 'active show':selectedTab==='bordereauxManagementOutline'}">
            <div class="p-2">
              <bordereaux-management-outline [selectedTab]='selectedTab'  ></bordereaux-management-outline>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
