<div class="table-responsive custom-table">
  <h3>Validation Rule</h3>
  <div class="dataTableCustomHeight">
  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table dataTable no-footer">
    <thead>
      <tr>
        <th scope="col">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [(ngModel)]="selectAll" id="defaultCheck1"
              (change)="selectAllRules(selectAll)">
          </div>
        </th>
        <th scope="col"> Rule ID</th>
        <th scope="col">Rule Name</th>
        <th scope="col" style="min-width: 150px;">Rule Discription</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of amendRuleListData, let index as index">
        <td>
          <div class="form-check">
            <input class="form-check-input" [(ngModel)]="item.isSelected" type="checkbox" id="isDefault-{{index}}"
              [disabled]="item.isMandatory"  />
          </div>
        </td>
        <td> Ro{{item?.ruleId}} <span class="is-default" *ngIf="item?.showDefault">D</span></td>
        <td>{{item?.ruleName}}</td>
        <td>{{item?.description}}</td>
      </tr>
    </tbody>
  </table>
  </div>
  <div class="bottom-button-container">
    <button class="btn btn-sm btn-outline-secondary float-left" (click)="goBack()">Back</button>
    <button class="btn btn-sm btn-primary float-right" (click)="saveData()">Save</button>
  </div>
</div>
