<div class="lobBg">
<div class="container-fluid mt-5">
    <div class="row">           
        <div class="col-sm-12">
            <div class="bg-white shadow rounded mt-2 p-2" style="height: calc(100vh - 155px);overflow-y: auto;">
                <div class="data-table-wrapper">
                <div class="container-fluid">
                    <div class="w-75 ml-auto mr-auto mt-5 pt-5">
                        <p>Select the LoB to continue:</p>
                        <div class="row" >
                            <div class="col"  *ngFor = "let lob of lobData; let i=index">
                                <a  [ngClass]="{'active': lob.name == 'Motor Commercial', 'disabledC' : lob.name == 'Property Commercial', 'disabled' : lob.name == 'Motor Personal' , 'disabledP' : lob.name == 'Property Personal' }" href="javascript:void(0)" (click)="getClaimsData(lob);" class="lob-link shadow-sm">
                                    <img src="data:image/png;base64,{{lob.image}}" class="vehicle-type" alt="vehicle"/>
                                    {{lob.name }}
                                </a>
                            </div>
                          
                        </div>

                        <div class="row justify-content-center staticIcons mt-4" >

                            <div class="col-sm-3" >
                                <a  href="javascript:void(0)"  class="lob-link shadow-sm disabledC">
                                    <img src="../../../../../assets/images/Cyber.png" class="vehicle-type " alt="vehicle"/>
                                    Cyber 
                                </a>
                            </div>

                            <div class="col-sm-3" >
                                <a  href="javascript:void(0)"  class="lob-link shadow-sm disabledC">
                                    <img src="../../../../../assets/images/D&O.png" class="vehicle-type " alt="vehicle"/>
                                    D&O 
                                </a>
                            </div>

                            <div class="col-sm-3" >
                                <a  href="javascript:void(0)"  class="lob-link shadow-sm disabledC">
                                    <img src="../../../../../assets/images/Professional-Liability.png" class="vehicle-type " alt="vehicle"/>
                                    Professional Liability
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>   
</div>
<style>
.staticIcons img.vehicle-type {
    margin: 0 auto;
    height: 40px;
}
</style>