<div class="left-panel-offset">
  <div class=" p-0">
    <div class="d-flex">
      <div class="col-sm-12 p-0">
        <div class="" *ngIf="invalidSheetOrHeader">
          <h3 class="text-center text-danger">Invalid sheet or header range has been selected. please go back and select
            different sheet / header range or upload new sheet again!</h3>
        </div>
        <div class="custom-padding" *ngIf="!invalidSheetOrHeader">
          <!-- tabs -->
          <div class="module-nav">
            <ul class="nav nav-tabs" id="column-mapping" role="tablist">
              <li class="nav-item" *ngFor="let item of sheetsList">
                <a class="nav-link sub-module" (click)="selectTab( item, $event)" role="tab"
                  [ngClass]="{ 'active':selectedSheet=== item}"><span class="tab-color-1"></span>{{item}}</a>
              </li>
            </ul>
          </div>
          <div class="bg-white rounded p-0">
            <!-- tab pannel -->
            <div class="tab-content bg-white p-0" id="">
              <div class="column-mapping-container">
                <div class="text-collapse">Collapse All</div>
                <div class="custom-control custom-switch collapse-all-slider">
                  <input type="checkbox" class="custom-control-input" id="customSwitch" [(ngModel)]="isCollapsedAll"
                    (click)="toggleCollapseAll()" [checked]="isCollapsedAll">
                  <label class="custom-control-label" for="customSwitch"></label>
                </div>
                <div class="">
                  <div class="d-flex filter-dropdown p-2">
                    <div class="col-sm-2" [ngStyle]="{'visibility': 'hidden'}">
                      <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Mandatory
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <a class="dropdown-item" href="#">Mandatory 1</a>
                          <a class="dropdown-item" href="#">Mandatory 2</a>
                          <a class="dropdown-item" href="#">Mandatory 3</a>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-2" [ngStyle]="{'visibility': 'hidden'}">
                      <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton2"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          By Column Name
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                          <a class="dropdown-item" href="#">Name 1</a>
                          <a class="dropdown-item" href="#">Name 2</a>
                          <a class="dropdown-item" href="#">Name 3</a>
                        </div>
                      </div>
                    </div>
                    <div class="mandatory-column-label">
                      <span>* mandatory column</span>
                    </div>
                  </div>
                  <div class="d-flex column-header">
                    <div class="col-sm-4 pd-left-5">Destination Template Column</div>
                    <div class="col-sm-4">Uploaded Sheet Column</div>
                    <div class="col-sm-4"></div>
                  </div>
                  <div class="custom-scroll-height">
                    <ng-container *ngFor="let item of formattedSheetMappingData; let i = index;">
                      <div class="column-mapping-head">
                        <div class="header-end">
                          <div class="d-flex">
                            <span class="collapsed-arrow ml-10" (click)="item.isExpended = !item.isExpended">
                              <img src="../../../../../assets/images/down-arrow.png">
                            </span>
                            <div class="col-sm-4">
                              <table class="table table-bordered m-0">
                                <thead>
                                  <tr>
                                    <th scope="col" class="sl-column">{{i+1}}</th>
                                    <th scope="col">
                                      <p class="accrodion-field-name"
                                        *ngIf="item?.destinationTemplateData?.columnName.length > 60"
                                        data-toggle="tooltip" data-placement="right"
                                        ngbTooltip="{{item?.destinationTemplateData?.columnName}}">
                                        {{item?.destinationTemplateData?.columnName | slice:0:60 }}... <span
                                          class="text-danger">*</span></p>
                                      <p class="accrodion-field-name"
                                        *ngIf="item?.destinationTemplateData?.columnName.length <= 60">
                                        {{item?.destinationTemplateData?.columnName}} <span class="text-danger">*</span>
                                      </p>
                                    </th>
                                  </tr>
                                </thead>
                                <ng-container *ngIf="item?.isExpended">
                                  <tbody>
                                    <tr
                                      *ngFor="let sample of item?.destinationTemplateData?.sampleData | slice: 0 : 2; let idx = index">
                                      <td class="sl-column">{{romanSerialNos[idx]}}</td>
                                      <td>{{sample}}</td>
                                    </tr>
                                  </tbody>
                                </ng-container>
                              </table>
                            </div>
                            <img src="../../../../../assets/images/right-arrow.png" width="20" height="20" alt="arrow"
                              class="arrow-position" [ngStyle]="item?.isExpended ? {'top': '40%'} : {'top': '30%'}" />
                            <div class="col-sm-4">
                              <table class="table table-bordered m-0">
                                <thead>
                                  <tr>
                                    <th scope="col" class="p-0">
                                      <div class="user-file-column">
                                        <ng-select class="form-control-sm"
                                          [ngClass]="item?.hasRquiredFieldError && item?.mappedUserSheetColumn === null ? 'hasRequiredFieldError' : ''"
                                          [items]="item?.userDropDownOption" (ngModelChange)='mappingChanged($event, i)'
                                          bindLabel="columnName" [searchable]="true" bindValue="columnName"
                                          [ngModel]="item?.mappedUserSheetColumn" [dropdownPosition]="'down'"
                                          groupBy="isRecomendation"></ng-select>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <ng-container *ngIf="item?.isExpended">
                                  <tbody>
                                    <tr *ngFor="let item of item?.userFileSheetData?.data">
                                      <td style="padding-left: 12px;">{{item}}</td>
                                    </tr>
                                  </tbody>
                                </ng-container>
                              </table>
                            </div>
                            <div class="col-sm-4">
                              <p class="text-danger customTxt ml-1"
                                *ngIf="item?.hasRquiredFieldError && item?.mappedUserSheetColumn === null">
                                <strong>{{item?.destinationTemplateData?.columnName}}</strong> needs to be mapped with
                                appropriate column.
                              </p>
                              <ng-container *ngIf="item?.userFileSheetData?.name">
                                <!-- DO NO DELETE THIS COMMENTED PIECE OF CODE -->
                                <!-- <p class="text-danger customTxt" *ngIf="item?.userFileSheetData?.type.trim() != item?.destinationTemplateData?.columnDataType.trim()">
                                  {{item?.userFileSheetData?.type === 'number' ? 'Numeric' : item?.userFileSheetData?.type }} column can not be mapped. Please map a column having {{item?.destinationTemplateData?.columnDataType}} data type.
                                </p> -->
                                <p class="text-danger customTxt" *ngIf="item?.isDuplicate">
                                  <strong>{{item?.mappedUserSheetColumn}}</strong> has been already mapped to diffrent
                                  column.
                                </p>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-button-container">
          <button class="btn btn-sm btn-outline-secondary pulled-left" (click)="goToBack()">Back</button>
          <button class="btn btn-sm btn-outline-primary" (click)="getExited()">Exit</button>
          <button class="btn btn-sm btn-outline-primary ml-10" [disabled]="invalidSheetOrHeader"
            (click)="goForSave()">Save</button>
          <button class="btn btn-sm btn-primary ml-10" [disabled]="invalidSheetOrHeader"
            (click)="goToNext()">Next</button>
        </div>
      </div>
    </div>
  </div>
</div>
