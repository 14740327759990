<ng-template #modal>
    <div class="modal-header">
      <h5 class="modal-title m-0">{{ modalConfig.modalTitle }}</h5>
      <button type="button"
      class="close"
             (click)="dismiss()"
             *ngIf="modalConfig.hideDismissButton === undefined || !modalConfig.hideDismissButton()"
             [disabled]="modalConfig.disableDismissButton !== undefined && modalConfig.disableDismissButton()">
             <span aria-hidden="true">×</span>
     </button>
    </div>
    <div class="modal-body">
      <ng-content></ng-content>
    </div>
    <div class="modal-footer">
      <button type="button"
       class="btn btn-sm btn-light"
              (click)="dismiss()"
              *ngIf="modalConfig.hideDismissButton === undefined || !modalConfig.hideDismissButton()"
              [disabled]="modalConfig.disableDismissButton !== undefined && modalConfig.disableDismissButton()">
        {{ modalConfig.dismissButtonLabel }}
      </button>
      <button type="button"
              class="btn btn-sm btn-primary"
              (click)="close()"
              *ngIf="modalConfig.hideCloseButton === undefined || !modalConfig.hideCloseButton()"
              [disabled]="modalConfig.disableCloseButton !== undefined && modalConfig.disableCloseButton()">
        {{ modalConfig.closeButtonLabel }}
      </button>
    </div>
  </ng-template>