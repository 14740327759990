import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { FileUploadProgressService } from 'src/app/shared/services/file-upload-progress.service';

@Component({
  selector: 'file-upload-review',
  templateUrl: './file-upload-review.component.html',
  styleUrls: ['./file-upload-review.component.scss'],
})
export class FileUploadReviewComponent implements OnInit {
  @Input() fileData: any;
  @Input() uploadId: any;
  @Input() workItemId: any;

  @Output() nextButtonEvent = new EventEmitter<any>();
  @Output() backButtonEvent = new EventEmitter<any>();
  @Output() fileAutoSubmitted = new EventEmitter<any>();
  @Output() exitButtonEvent = new EventEmitter<any>();
  Object = Object;
  dataFromSheets: any;
  selectedSheet = '';
  sheetDataInfinite: any;
  fileDataPreview: any;
  updatedFilePreviewData: any;
  limit = 10;
  offset = 0;
  uploadIdNew: any;
  payload: any;
  incompleteReview: boolean = false;
  reviewed: boolean = false

  constructor(
    private fileUploadProgressService: FileUploadProgressService,
    private ngxLoader: NgxUiLoaderService,
    public toastr: ToastrService,
    public confirmationPopupService: ConfirmationPopupService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.dataFromSheets = Object.keys(this.fileData.sheetInfo).map((item) => {
      return {
        sheetName: item,
        sheetData: this.fileData.sheetInfo[item].data,
      };
    });
    this.selectedSheet = this.dataFromSheets[0].sheetName;
    this.getFileDataPreview(this.uploadId, this.limit, this.offset);
  }

  getFileDataPreview(uploadId, limit, offset) {
    this.ngxLoader.start();
    this.fileUploadProgressService
      .getFileReviewedData(uploadId, limit, offset)
      .subscribe((response) => {
        if (response && response.code === 200) {
          this.fileDataPreview = response?.data;
          this.updatedFilePreviewData = Object.keys(this.fileDataPreview).map(
            (e) => {
              return {
                ...this.fileDataPreview[e],
                sheetName: e,
                reviewed: false,
              };
            }
          );
        }
        this.ngxLoader.stop();
      });
  }

  selectTab(selectedTab, event) {
    this.selectedSheet = selectedTab;
  }

  confirmationPopup() {
    this.updatedFilePreviewData.forEach((e) => {
      if (!e.reviewed) {
        this.incompleteReview = true;
      }
    });
    const payload = {
      status: 'Ready For Standardization',
      workItemId: this.workItemId,
    };
    if (!this.incompleteReview) {
      this.confirmationPopupService
        .confirm(
          'Confirmation',
          'Are you sure you want to submit <b>' + '</b> ?',
          '',
          'Yes',
          'Cancel'
        )
        .then((confirmed) => {
          if (confirmed) {
            this.fileUploadProgressService
              .finalSubmit(payload, this.workItemId)
              .subscribe((response) => {
                if (response && response.code === 200) {
                  this.toastr.success(response.successMessage);
                  this.fileAutoSubmitted.next('submitted');
                }
              });
          }
        });
    }
  }

  finalSubmit() {
    this.confirmationPopup();
  }

  getExited() {
    this.exitButtonEvent.next('exit');
  }

  goToBack() {
    this.backButtonEvent.next('mapped-column-preview');
  }

  reviewCheck(event) {
    this.updatedFilePreviewData.forEach((e) => {
      e.reviewed = true;
    });
    this.incompleteReview = false;
  }
}
