import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BordereauxManagementService } from '../../../services/bordereaux-management.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import 'daterangepicker';


@Component({
  selector: 'filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss']
})
export class FilterPanelComponent implements OnInit {


  @Input() selectedFilters: any;
  @ViewChild('dateRangePickerInput', {static: true}) dateRangePickerInput!: ElementRef<any>;
  selectedFilterData: any;
  filterData: Array<any> = [];
  public daterange: any = {};
  lastModifiedStart = null;
  lastModifiedEnd = null;
  dateRangePickerInstance: any;
  isLoadingFilters: boolean = false;
  constructor(private activeModal: NgbActiveModal, private ngxLoader: NgxUiLoaderService, public BDXManagementService: BordereauxManagementService,
    private changeDetectorRef: ChangeDetectorRef) { }


  public dismiss() {
    this.activeModal.close(false);
  }

  public resetFilters() {
    this.filterData.forEach(e => {
      e.selectedId = 0;
    })
    Object.keys(this.selectedFilters).forEach(e => {this.selectedFilters[e] = 0});
    const dateRangePickerOptions = {
      start: null,
      end: null
    };
    this.selectedDate(dateRangePickerOptions, dateRangePickerOptions);
    this.activeModal.close(this.selectedFilters);
  }

  public applyFilter() {
    const activeFilterAttributes = {
      fileTypeId: Number(this.filterData.filter(e => { return e.name === 'fileType' })[0].selectedId),
      coverHolderId: Number(this.filterData.filter(e => { return e.name === 'coverHolder' })[0].selectedId),
      formatId: Number(this.filterData.filter(e => { return e.name === 'format' })[0].selectedId),
      subFormatId: 0,
      tagsId: 0,
      yearMonth: this.selectedFilters.yearMonth,
      modifiedBy: '0',
      lastModifiedStart: this.lastModifiedStart === null ? 0 : this.lastModifiedStart,
      lastModifiedEnd: this.lastModifiedEnd === null ? 0 : this.lastModifiedEnd,
    }
    this.activeModal.close(activeFilterAttributes);
  }

  ngOnInit(): void {
    this.getFilterPanelData();
  }

  getFilterPanelData() {
    this.isLoadingFilters = true
    this.BDXManagementService.getMetamorphFilterData().subscribe(response => {
      if (response && response.code === 200) {
        const filterDataResponse = response;
        delete filterDataResponse.code;
        delete filterDataResponse.message;
        Object.keys(filterDataResponse).forEach((e, i) => {
          if(e === 'fileType'|| e === 'coverHolder' || e ==='format') {
            filterDataResponse[e].unshift({ id: 0, name: 'ALL' })
            const temp = e + 'Id';
            this.filterData.push( {
              name: e,
              label: e === 'coverHolder' ? 'Coverholder' : e.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() + e.replace(/([A-Z])/g, " $1").slice(1),
              data: [...filterDataResponse[e]],
              selectedId: this.selectedFilters[temp] ? this.selectedFilters[temp] : 0
            })
          }
        })
        if(this.selectedFilters.lastModifiedEnd === 0 && this.selectedFilters.lastModifiedStart === 0) {
        } else {
          const dateRangePickerOptions = {
            start: this.convertDateStringToTimestamp(this.selectedFilters.lastModifiedStart),
            end: this.convertDateStringToTimestamp(this.selectedFilters.lastModifiedEnd)
          };
          this.selectedDate(dateRangePickerOptions, dateRangePickerOptions);
        }
        this.isLoadingFilters = false
      }
    })
  }
  
  // Options for DateRangePicker
  public options: any = {
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
    opens: 'left',
    pickerPosition: 'top-left',
  };

  public selectedDate(value: any, datepicker?: any) {
    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;
    // use passed valuable to update state
    this.daterange.start = value.start;
    this.daterange.end = value.end;
    this.daterange.label = value.label;
    this.lastModifiedStart = this.formatDate(value.start);
    this.lastModifiedEnd = this.formatDate(value.end)
  }

  formatDate(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  convertDateStringToTimestamp(dateString) {
    const date = new Date(dateString);
    const timestamp = date.getTime();
    return timestamp;
  }



}
