import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { forkJoin, Observable, of } from 'rxjs';
import { ScenarioNamePopoverComponent } from './scenario-name-popover/scenario-name-popover.component';
const CHART_TMEPLATE_BASE_URL = "../../../../../assets/data/scm-mmm/";
const INCREMENTAL_SALE = 'chart-incremental-sales-template.json';
const MEDIA_RESPONSE = 'chart-media-response-curve-template.json';
const RIO_MAC = 'chart-roi-mac-template.json';
const SHARE_SPEND = 'chart-share-spend-template.json';

@Injectable({
  providedIn: 'root'
})
export class ScmMmmService {
  localStorage = localStorage;
  constructor(private http: HttpClient, private modalService: NgbModal, private ngxLoader: NgxUiLoaderService) { }

  getBrandList(): Observable<any> {
    return this.http.get<any>('getBrandDetails/');
  }
  getScenarioList(brandId, scenarioType): Observable<any> {
    return this.http.get<any>('getAllScenario/' + brandId + '/' + scenarioType + '/');
  }

  getOptimizeData(brandId, scenarioId, scenarioType): Observable<any> {
    const currentYear = 2020; // keeping current year as 2020 because we have data for 2020 only , needs to be changed once data is available for current year
    return this.http.get<any>('optimizeTable/' + brandId + '/' + currentYear + '/' + scenarioId + '/' + scenarioType + '/');
  }

  getPortfolioOptimizeData(brandId, scenarioId, scenarioType): Observable<any> {
    const currentYear = 2020; // keeping current year as 2020 because we have data for 2020 only , needs to be changed once data is available for current year
    return this.http.get<any>('optimizeTablePortfolio/' + brandId + '/' + currentYear + '/' + scenarioId + '/' + scenarioType + '/');
  }

  getMediaResponseCurveData(brandId, parentSpendLeverId, mediaSpendLeverIdCSV): Observable<any> {
    return this.http.get<any>('getMediaResponse/' + brandId + '/' + parentSpendLeverId + '/' + mediaSpendLeverIdCSV + '/');
  }

  getSumulateData(brandId, scenarioId, scenarioType): Observable<any> {
    const currentYear = 2020; // keeping current year as 2020 because we have data for 2020 only , needs to be changed once data is available for current year
    return this.http.get<any>('simulateScenario/' + brandId + '/' + currentYear + '/' + scenarioId + '/' + scenarioType + '/');
  }

  getPortfolioSimulateData(brandId, scenarioId, scenarioType): Observable<any> {
    const currentYear = 2020; // keeping current year as 2020 because we have data for 2020 only , needs to be changed once data is available for current year
    return this.http.get<any>('simulatePortfolio/' + brandId + '/' + currentYear + '/' + scenarioId + '/' + scenarioType + '/');
  }

  performOptimization(payload): Observable<any> {
    return this.http.post('optimizeAction/', payload);
  }

  performSimulateAction(payload): Observable<any> {
    return this.http.post('simulateAction/', payload);
  }

  saveNewScenario(payload): Observable<any> {
    return this.http.post('saveScenario/', payload);
  }
  deleteScenarion(scenarioId): Observable<any> {
    return this.http.delete<any>('deleteScenario/' + scenarioId + '/');
  }

  formatIncrementalSalesTemplate(template, chartData) {
    template.series[0].data = chartData
    template.series[0].data.at(-1).isSum = true;
    const newSeries = template.series[0].data.map((element, index) => {
      return {
        ...element,
        color: template.colors[index]
      }
    })
    template.series[0].data = newSeries;
    return template
  }

  getRioMacChartOptionConfigured(template, chartData) {
    let seriesData = {
      name: chartData.name,
      data: Array()
    }
    let categories: Array<any> = [];
    chartData?.data.forEach((element, index) => {
      if (element.hasOwnProperty('value')) {
        seriesData.data.push(element.value);
        template.xAxis.categories.push(element.name)
      }
    });
    template.series.push(seriesData)
    return template;
  }

  getShareOfSpendFormatted(template, chartData) {
    template.series = chartData.data;
    return template;
  }

  public getScenarioName(
    title: string,
    scenarioNamePreview: string,
    btnOkText: string,
    btnCancelText: string
  ): Promise<boolean> {
    //For installing see: https://ng-bootstrap.github.io/#/getting-started
    //For all options see: https://ng-bootstrap.github.io/#/components/modal/api
    const modalRef = this.modalService.open(ScenarioNamePopoverComponent, { centered: true });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.scenarioNamePreview = scenarioNamePreview,
      modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    return modalRef.result;
  }

  openOverlay() {
    this.ngxLoader.start()
 
  }
  closeModel(){
    this.ngxLoader.stopAll();
  }

  getChartTemplateJSON(): Observable<any> {
    const incrementalSaleTemplate = this.http.get<any>(`${CHART_TMEPLATE_BASE_URL}${INCREMENTAL_SALE}`);
    const mediaResponseTemplate = this.http.get<any>(`${CHART_TMEPLATE_BASE_URL}${MEDIA_RESPONSE}`);
    const roiMacTemplate = this.http.get<any>(`${CHART_TMEPLATE_BASE_URL}${RIO_MAC}`);
    const shareSpendTemplate = this.http.get<any>(`${CHART_TMEPLATE_BASE_URL}${SHARE_SPEND}`);
    return forkJoin([incrementalSaleTemplate, mediaResponseTemplate, roiMacTemplate, shareSpendTemplate]);
  }

  setPendingStatus(hasPending) {
    this.localStorage.setItem("hasPending", hasPending)
  }

  getPendingStatus() {
    const pending = this.localStorage.getItem('hasPending');
    return of(pending);
  }

  setGoodToGo(value) {
    this.localStorage.setItem('goodToGo', value)
  }

  getGoodToGo() {
    const goodToGo = this.localStorage.getItem('goodToGo');
    return of(goodToGo);
  }

  formatMediaResponseChartData(chartSeriesData, chartTemplate): Observable<any> {
    const finalresponse = [] as any;
    const config = {
      ...chartTemplate,
      title: {
        ...chartTemplate.title,
        text: 'Media Response Curve'
      },
      series: chartSeriesData.map((item, itemIndex) => {
        return {
          ...item,
          data: item.data,
          name: item.spendLever,
          type: 'line',
          marker: {
            symbol: 'circle',
            fillColor: '#FFFFFF',
            lineWidth: 2,
            lineColor: null
          },
        };
      })
    }
    return config;
  }

  
}
