import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GenericChartService {


  getChartOptionConfigured(chartApiResponse, chartTemplate, chartDesignConfig, seriesColors, chartHeight) {
    const finalresponse = [] as any;
    chartApiResponse.forEach((element, index) => {
      const config = {
        ...chartTemplate,
        chart: {
          ...chartTemplate.chart,
          height: chartHeight
        },
        title: {
          ...chartTemplate.title,
          text: element.title ? element.title : element.name,
        },
        xAxis: {
          ...chartTemplate.xAxis,
          categories: Array.isArray(element.data[0]) ? [...element.data].map((e) => {
            return this.formateDate(chartApiResponse[0]?.chartInfo?.format, e[0])
          }) : [...element.data[0].data].map((e) => {
            return this.formateDate(chartApiResponse[0]?.chartInfo?.format, e[0])
          })
        },
        yAxis: {
          ...chartTemplate.yAxis,
          allowDecimals: false,
          title: {
            text: element?.chartInfo?.yAxis?.name ? element?.chartInfo?.yAxis?.name : ''
          }
        },
        tooltip: {
          shared: true,
          valueDecimals: element?.chartInfo?.decimalPoint,
          pointFormat: '<span style="color:{series.color}">\u25cf</span> {series.name}: <b>{point.y}</b><br/>'
        },
        series: Array.isArray(element.data[0]) ?
          [{
            data: element.data.map(e => {
              if (!!e[0]) {
                e[0] = this.formateDate(element?.chartInfo?.format, e[0])
              }
              if(!!e[1]) {
                if(e[1].toString().indexOf(".") !== -1) {
                  e[1].toFixed(2);
                }
              }
              return e;
            }),
            type: chartDesignConfig.chartType,
            visible: true,
            showInLegend: element.data.name ? true : false,
            lineColor: seriesColors[0],
            color: seriesColors[0],
            marker: {
              symbol: chartDesignConfig.marker.symbol,
              fillColor: chartDesignConfig.marker.fillColor,
              lineWidth: chartDesignConfig.marker.lineWidth,
              lineColor: seriesColors[0]
            }
          }] : element.data.map((item, itemIndex) => {
            if (item.data) {

              item.data = item.data.map(e => {
                if (!!e[0]) {
                  e[0] = this.formateDate(element?.chartInfo?.format, e[0])
                }
                if(!!e[1]) {
                  if(e[1].toString().indexOf(".") !== -1) {
                    e[1].toFixed(2);
                  }
                }
                return e;
              });
            }
            return {
              ...item,
              type: chartDesignConfig.chartType,
              visible: true,
              showInLegend: element.data.length > 1 ? true : false,
              lineColor: seriesColors.length === 1 ? seriesColors[0] : seriesColors[itemIndex],
              color: seriesColors.length === 1 ? seriesColors[0] : seriesColors[itemIndex],
              marker: {
                symbol: chartDesignConfig.marker.symbol,
                fillColor: chartDesignConfig.marker.fillColor,
                lineWidth: chartDesignConfig.marker.lineWidth,
                lineColor: seriesColors.length === 1 ? seriesColors[0] : seriesColors[itemIndex]
              }
            }
          }),
      };
      finalresponse.push(config);
    });
    return finalresponse;
  }

  formateDate(type, date) {
    const year = date.toString().substring(0, 4);
    const month = date.toString().slice(-2);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    if (type === 'month') {
      return months[Number(month) - 1] + '-' + year.slice(-2);
    } else {
      return date;
    }
  }
}
