import { Component, OnInit,Input } from '@angular/core';
import { Router } from '@angular/router';
import { MetamorphCommonService } from '../services/metamorph-common.service';

@Component({
  selector: 'bordereaux-management',
  templateUrl: './bordereaux-management.component.html',
  styleUrls: ['./bordereaux-management.component.scss']
})
export class BordereauxManagementComponent implements OnInit {
  selectedTab: string = 'bordereauxManagementOutline';
  constructor(public metamorphCommonService: MetamorphCommonService, public router: Router) { }
  ngOnInit(): void {

  }

  selectTab(selectedTab, event) {
    this.selectedTab = selectedTab
  }

}
