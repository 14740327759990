import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CoverholderService {

  constructor(private http: HttpClient) { }


  getCoverholderData(coverHolder, selectedFileType,format) : Observable<any> {
    return this.http.get<any>('metamorph/processRules/'+ coverHolder + '/' + selectedFileType + '/' + format + '/')
  }

  

}
