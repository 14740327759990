import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DemandForecastShowAnalysisComponent } from '../demand-forecast/demand-forecast-show-analysis/demand-forecast-show-analysis.component';
const CHART_TMEPLATE_BASE_URL = "../../../../../assets/data/supplyChain/";
const DEMAND_FORECAST_CHART_TEMPLATE_JSON = 'demandForecastChartTemplate.json';

@Injectable({
  providedIn: 'root'
})
export class DemandForecastService {

  constructor(private http: HttpClient,private modalService: NgbModal) { }
  
  getproductFamily(): Observable<any> {
    return this.http.get<any>('productFamilies/');
  }

  getStockKeepingUnitList(id): Observable<any> {
    return this.http.get<any>('productFamily/' + id + '/');
  }
  getDemandForecastChartTemplateJSON(): Observable<any[]> {
    return this.http.get<any>(`${CHART_TMEPLATE_BASE_URL}${DEMAND_FORECAST_CHART_TEMPLATE_JSON}`);
  }
  /***
   * @desc Get Claims Modal API
   * @returns {Object} Returns Get Claims Model API Data
   * 
   */
  getSCMModelDetail(): Observable<any[]> {
    return this.http.get<any>('scmModelStatus');
   }
  
  getSupplyAnalysis(lastMonth,productFamilyId,month,type): Observable<any> {
    return this.http.get<any>('executiveSummaryAnomalies/' + lastMonth + '/'+productFamilyId +'/'+month+'/'+type+'/');
  }

  getPerformanceAPIData(productFamilyId: any, productId?: any): Observable<any> {
    if (productId) {
      return this.http.get<any>('performance/' + productFamilyId + '/' + productId + '/');
    } else {
      return this.http.get<any>('performance/' + productFamilyId + '/');
    }
  }

  getPerformanceDataany(productFamilyId: any, productId?: any): Observable<any> {
    return this.http.get<any>('demandForecast/' + productFamilyId + '/');
  }

  getPerformanceData(productFamilyId: any, productId?: any): Observable<any> {
    let performanceData, demandForcastData, demandPlanningDriversData: any;
    if (productId) {
      performanceData = this.http.get<any>('performance/' + productFamilyId + '/' + productId + '/');
      demandForcastData = this.http.get<any>('demandForecast/' + productFamilyId + '/' + productId + '/');
      demandPlanningDriversData = this.http.get<any>('demandPlanningDrivers/' + productFamilyId + '/' + productId + '/');
    } else {
      performanceData = this.http.get<any>('performance/' + productFamilyId + '/');
      demandForcastData = this.http.get<any>('demandForecast/' + productFamilyId + '/');
      demandPlanningDriversData = this.http.get<any>('demandPlanningDrivers/' + productFamilyId + '/');
    }
    return forkJoin([performanceData, demandForcastData, demandPlanningDriversData]);
  }

  getChartDataConfigured(chartApiResponse, chartTemplate, chartLineColor) {
    const finalresponse = [] as any;
    chartApiResponse.forEach((element, index) => {
      const config = {
        ...chartTemplate,
        title: {
          ...chartTemplate.title,
          text: element.title,
        },
        colors: ['#29c073'],
        xAxis: {
          ...chartTemplate.xAxis,
          categories: [...element.chartData[0].data].map((e) => {
            return e[0]
          })
        },
        series: element.chartData.map((item, itemIndex) => {
          item.data = item.data.map(e => {
            if (!!e[1]) {
              e[1] = Math.round(e[1])
            }
            return e;
          });
          return {
            ...item,
            type: 'line',
            visible: true,
            showInLegend: item.name.length ? true : false,
            lineColor: chartLineColor.length ? chartLineColor : itemIndex === 0 ? '#188fff' : '#ff333a',
            marker: {
              symbol: 'circle',
              fillColor: '#FFFFFF',
              lineWidth: 2,
              lineColor: chartLineColor.length ? chartLineColor : itemIndex === 0 ? '#188fff' : '#ff333a'
            }
          }
        }),
      };
      finalresponse.push(config);
    });
    return finalresponse;
  }

  getDemandForcastChartData(productFamilyId, productId): Observable<any> {
    if (productId) {
      return this.http.get<any>('demandForecast/' + productFamilyId + '/' + productId + '/');
    } else {
      return this.http.get<any>('demandForecast/' + productFamilyId + '/');
    }
  }

  getPerformanceAnalysisData(productFamilyId: any, productId?: any): Observable<any> {
    let modelWiseForecast, mapeTrend, productFamilyMAPE, demandDrivesMonthlyForecast, demandDrivesWeeklyForecast: any;
    modelWiseForecast = this.http.get<any>('modelWiseForecast/' + productFamilyId + '/' + productId + '/');
    mapeTrend = this.http.get<any>('mapeTrend/' + productFamilyId + '/' + productId + '/');
    productFamilyMAPE = this.http.get<any>('productFamilyMAPE/' + productFamilyId + '/');
    demandDrivesMonthlyForecast = this.http.get<any>('demandDrivesMonthlyForecast/');
    demandDrivesWeeklyForecast = this.http.get<any>('demandDrivesWeeklyForecast/' + productFamilyId + '/' + productId + '/');
    return forkJoin([modelWiseForecast, mapeTrend, productFamilyMAPE, demandDrivesMonthlyForecast, demandDrivesWeeklyForecast])
  }

  updateProductFamilyMAPE(productFamilyId: any): Observable<any> {
    return this.http.get<any>('productFamilyMAPE/' + productFamilyId + '/');
  }
  public confirmAddNew(modalConfig): Promise<boolean> {
    const modalRef = this.modalService.open(DemandForecastShowAnalysisComponent, { centered: true, size: 'lg', windowClass: '' });
    modalRef.componentInstance.recommendationData = modalConfig;
    return modalRef.result;
  }
  getLastExecutionMonth(): Observable<any> {
    return this.http.get<any>('lastExecutionMonth/')
  }
}
