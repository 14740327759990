
import {Router} from '@angular/router';
import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  {
  title = 'cpo';
  modalRef: any;
  showSideBar:boolean=true;
  files: File[] = [];
  constructor(public router:Router,private http: HttpClient, private toastr: ToastrService){ 
}

showToaster(){
  this.toastr.success("Hello, I'm the toastr message.")
}

}
