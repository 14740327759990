<div class="mt-5 pt-4 crs-table" style="background: #FAFAFA; height: calc(100vh - 81px);">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">

                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Policy List</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Agent List</a>
                    </li>
                   
                  </ul>
                  <div class="tab-content p-2 bg-white shadow rounded heightScroll " id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table dataTable no-footer"
                        #datatableElement>
                        <thead>
                            <tr>
                                <th scope="col">Policy Number</th>
                                <th scope="col">Customer ID</th>
                                <th scope="col">Customer Name</th>
                                <th scope="col">Policy Type</th>
                               
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><a routerLink="/csr/auto-personal-linesE">POL/2023/HOME/83484</a></td>
                                <td>C17454</td>
                                <td>Jaidip Das</td>
                                <td>Home</td>                                
                            </tr>
                            <tr>
                                <td><a routerLink="/csr/auto-personal-linesD">POL/2023/HOME/83488</a></td>
                                <td>C17453</td>
                                <td>Mark Anthony</td>
                                <td>Home</td>                                
                            </tr>
                            <tr>
                                <td><a routerLink="/csr/auto-personal-linesA">POL/2023/AUTO/83485</a></td>
                                <td>C17447</td>
                                <td>John Doe</td>
                                <td>Auto</td>                                
                            </tr>
                            <tr>
                                <td><a routerLink="/csr/auto-personal-linesB">POL/2023/AUTO/83486</a></td>
                                <td>C17449</td>
                                <td>Inaki William</td>
                                <td>Auto</td>                                
                            </tr>
                            <tr>
                                <td><a routerLink="/csr/auto-personal-linesC">POL/2023/AUTO/83487</a></td>
                                <td>C17451</td>
                                <td>Kevin Peterson</td>
                                <td>Auto</td>                                
                            </tr>                            
                            
                            <tr>
                                <td><a routerLink="/csr/auto-personal-linesF">POL/2023/S-HOME/83489</a></td>
                                <td>C17455</td>
                                <td>Phil Dunphy</td>
                                <td>Specialty Home</td>                                
                            </tr>
                        </tbody>
                    </table>
                    </div>
                    <div class="tab-pane fade agentTable" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <table datatable [dtOptions]="dtOptions1" [dtTrigger]="dtTrigger1" class="table dataTable no-footer"
                        #datatableElement>
                        <thead>
                            <tr>
                                
                                <th scope="col">Agent ID</th>
                                <th scope="col">Agent Name</th>
                               
                               
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><a routerLink="/csr/agent-detail">A43471</a></td>
                               
                                <td>Steven Smith</td>
                              
                                
                            </tr>

                            <tr>
                                <td><a routerLink="/csr/agent-detail2">A43473</a></td>
                               
                                <td>Daniel Jones</td>
                              
                                
                            </tr>
                            <tr>
                                <td><a routerLink="/csr/agent-detail3">A43475</a></td>
                                <td>Vinicius Junior</td>
                            </tr>
                            <tr>
                                <td><a routerLink="/csr/agent-detail4">A43477</a></td>                               
                                <td>Jude Bellingham</td> 
                            </tr> 
                            <tr>
                                <td><a routerLink="/csr/agent-detail5">A43479</a></td>                               
                                <td>David Alaba</td> 
                            </tr> 
                        </tbody>
                    </table>
                    </div>
                   
                  </div>

              
            </div>
        </div>
    </div>

    <router-outlet></router-outlet>

</div>