import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-csr-base',
  templateUrl: './csr-base.component.html',
  styleUrls: ['./csr-base.component.scss']
})
export class CsrBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  ruleListArray:any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtOptions1: DataTables.Settings = {};
  dtTrigger1: Subject<any> = new Subject();
  constructor(private ngxLoader: NgxUiLoaderService) { }

  ngOnInit(): void {
    this.getAmendRuleListData();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.dtTrigger1.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.dtTrigger1.next();
  }
  getAmendRuleListData() {
    this.ruleListArray = [];
    this.ngxLoader.start();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      deferRender: true,   
      language: {
        search: "Filter records:"
      },  
      columnDefs: [
        { orderable: false, targets: [0]},
       
      ],
      order: [],
      destroy: true,
      
      
      
    }
    this.dtOptions1 = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      deferRender: true,
      order: [],
      destroy: true
    }
    this.ngxLoader.stop();
    this.dtTrigger.next();
    this.dtTrigger1.next();

  }

}
