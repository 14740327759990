import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { numberFormat } from 'highcharts';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { ScmMmmStaticService } from '../scm-mmm-static.service';

@Component({
  selector: 'app-scm-static',
  templateUrl: './scm-static.component.html',
  styleUrls: ['./scm-static.component.scss']
})
export class ScmStaticComponent implements OnInit {

  selectedTab: string = '';
  previousTab: string = '';
  selectedTabByNavigation: any;
  selectedBrand: any;
  oldSelectedBrand: any;
  selectedScenario: any;
  oldSelectedScenario: any;

  currentBrand: any
  currentScenario: any

  changeBudget = '';
  changeInBudget = '';

  brandList: Array<any> = [];
  scenarioList: any;
  optimizeChartData: any;
  newSelectedScenario: any;
  saveOptimizeEvents: Subject<void> = new Subject<void>();
  exportOptimizeEvents: Subject<void> = new Subject<void>();
  exportSimulateEvents: Subject<void> = new Subject<void>();
  deleteOptimizeEvents: Subject<void> = new Subject<void>();
  saveSimulateEvents: Subject<void> = new Subject<void>();
  deleteSimulateEvents: Subject<void> = new Subject<void>();
  evaluateOptimizeEvents: Subject<void> = new Subject<void>();
  evaluateSimulateEvents: Subject<void> = new Subject<void>();
  changeScenerioOptimizeEvents: Subject<void> = new Subject<void>();
  changeScenerioSimulateEvents: Subject<void> = new Subject<void>();
  saveData: EventEmitter<boolean> = new EventEmitter();
  localStorage= localStorage
  simulateTableData: any;
  newSelectedBrand: any;
  scmBrandList: Array<any> = [];
  scmScenarioList: Array<any> = [];
  selectedBrandId:any;

  constructor(private scmMmmStaticService: ScmMmmStaticService, public toastr: ToastrService,
    public confirmationPopupService: ConfirmationPopupService, public router: Router) {
      const selectedTab = this.localStorage.getItem('selectedScmTab')
      this.selectedTab = this.previousTab = selectedTab !== null ? selectedTab : 'scm-optimize';
     }

  ngOnInit(): void {
    this.scmMmmStaticService.setPendingStatus('NO');
    this.scmMmmStaticService.setGoodToGo('YES');
    this.scmMmmStaticService.setDataForSimulateScreen();
    this.scmMmmStaticService.setDataForOptimizeScreen();
    if (this.selectedTab === 'scm-optimize') {
      this.getOptimizeMockTableData();
    } else if (this.selectedTab === 'scm-simulate') {
      this.getSimulateMockTableData();
    }
  }

  ngOnChanges() {
  }

   // Getting Brand and Scenario List
  /***
   * @desc Function to get Brand and scenrio List
   * @param Integer (Brand Id) 
   * Call service to get brand and scenario list for 1st selected brand
   * @returns {Array} Returns brand List and scenario list.
   *
   */
  getBrandAndScenarioList() {
    this.scmMmmStaticService.getBrandList().subscribe((response) => {
      if(!!response && response.code === 200) {
        this.scmBrandList = response.data;
        this.selectedBrandId = this.scmBrandList[0].brandId
      }
    })
  }

  // Update selected scenario after adding new scenario
  /***
   * @desc Function to get updated scenrio List and select newly added scnario
   * @param {Object} (Selected Brand Id and newLy Added Scenario Id) 
   * @returns {Array} Returns scenario list.
   *
   */
  updateScenario(event) {
    this.changeBudget = '';
    this.selectedBrand = this.currentBrand = event.selectedBrand;
    this.selectedScenario = this.currentScenario = event.selectedScenario;
    if (this.selectedTab === 'scm-optimize') {
      this.getOptimizeMockTableData(this.selectedBrand, this.selectedScenario);
    } else if (this.selectedTab === 'scm-simulate') {
      this.getSimulateMockTableData(this.selectedBrand, this.selectedScenario);
    }
  }

   /***
   * @desc Function to Emit event on save scenario event
   */
  saveScenario() {
    if (this.selectedTab === 'scm-optimize') {
      this.saveOptimizeEvents.next();
    } else if (this.selectedTab === 'scm-simulate') {
      this.saveSimulateEvents.next();
    }
  }

   /***
   * @desc Function to Emit event on Export scenario event
   */
  exportScenario() {
    this.scmMmmStaticService.getPendingStatus().subscribe(data => {
      if (data === 'NO' || data === null) {
        if (this.selectedTab === 'scm-optimize') {
          this.exportOptimizeEvents.next();
        } else if (this.selectedTab === 'scm-simulate') {
          this.exportSimulateEvents.next();
        }
      } else if (data === 'YES') {
        this.toastr.error("Please save scenario before download!")
      }
    })
  }

  
 /***
   * @desc Function to Emit event on delete scenario event
   */
  deleteScenario() {
    if (this.selectedTab === 'scm-optimize') {
      this.deleteOptimizeEvents.next();
    } else if (this.selectedTab === 'scm-simulate') {
      this.deleteSimulateEvents.next();
    }
  }

   /***
   * @desc Function to Emit event on optimize  event
   */
  optimizeScenario(changeBudget) {
    this.scmMmmStaticService.getPendingStatus().subscribe(data => {
      if(data === 'NO') {
        this.evaluateOptimizeEvents.next(changeBudget);
      }
    })
  }

   /***
   * @desc Function to Emit event on Simulate event
   */
  simulate(event) {
    this.scmMmmStaticService.getPendingStatus().subscribe(data => {
      if(data === 'NO') {
        this.evaluateSimulateEvents.next();
      } else {
        this.confirmationPopupService.confirm('Confirm', 'Do you want to leave the section without saving your changes?', '', 'Yes', 'Cancel')
          .then((confirmed) => {
            if (confirmed) {
              this.evaluateSimulateEvents.next();
              this.scmMmmStaticService.setGoodToGo('YES');
              this.scmMmmStaticService.setPendingStatus('NO');
            } else if (!confirmed) {
              this.scmMmmStaticService.setGoodToGo('YES');
              event.stopPropagation();
            }
          });
      }
    });
  }

  // Tab switching 
  /***
   * @desc Function to switch tabs
   * @param {String, Event} (Selected Tab,  Event ) 
   * it validates pending change status and change Tab
   * @returns {Event} - On Change tab it call methods to initilize the change in the page
   */
  selectTab(selectedTab, event) {
    this.scmMmmStaticService.getPendingStatus().subscribe(pending => {
      if(this.selectedTab === selectedTab) {
        return;
      } 
      if (pending === 'NO' || pending === null) {
        this.selectedTab = selectedTab;
        this.previousTab = selectedTab;
        this.newSelectedScenario = false;
        this.newSelectedBrand = false;
        this.scmMmmStaticService.setGoodToGo('YES');
        if (this.selectedTab === 'scm-optimize') {
          this.getOptimizeMockTableData();
        } else if (this.selectedTab === 'scm-simulate') {
          this.getSimulateMockTableData();
        }
      } else if (pending === 'YES') {
        this.scmMmmStaticService.setGoodToGo('NO');
        this.confirmationPopupService.confirm('Confirm', 'Do you want to leave the section without saving your changes?', '', 'Yes', 'Cancel')
          .then((confirmed) => {
            if (confirmed) {
              this.newSelectedScenario = false;
              this.newSelectedBrand = false;
              this.selectedTab = selectedTab;
              this.previousTab = selectedTab;
              if (this.selectedTab === 'scm-optimize') {
                this.getOptimizeMockTableData();
              } else if (this.selectedTab === 'scm-simulate') {
                this.getSimulateMockTableData();
              }
              this.scmMmmStaticService.setGoodToGo('YES');
              this.scmMmmStaticService.setPendingStatus('NO');
            } else if (!confirmed) {
              this.selectedTab = this.previousTab;
              this.scmMmmStaticService.setGoodToGo('YES');
              event.stopPropagation();
            }
          });
      }
    })
    this.selectedTab = selectedTab;
    this.localStorage.setItem('selectedScmTab', this.selectedTab)
  }

 
  /***
   * @desc Function to Optimize Mock data
   * @param {Number, Number} brandId,scenarioId ) 
   * it validates pending change status and change Tab
   * @returns {Array} - get brand list and scenario list
   */

  getOptimizeMockTableData(brandId?: number, scenarioId?: number) {
    this.scmMmmStaticService.getMockOptimizeTableData().subscribe((response) => {
      if (response) {
        this.optimizeChartData = response
        this.brandList = response.data.map(key => {
          return {
            brandName: key.brandName, brandId: key.brandId
          }
        });
        this.selectedBrand = this.oldSelectedBrand = this.brandList[0].brandId;
        this.getScenerioList(response.data, this.selectedBrand, scenarioId);
      }
    });
  }

  /***
   * @desc Function to Simulate Mock data
   * @param {Number, Number} brandId,scenarioId ) 
   * it validates pending change status and change Tab
   * @returns {Array} - get brand list and scenario list
   */

  getSimulateMockTableData(brandId?: number, scenarioId?: number) {
    this.scmMmmStaticService.getMockSimulateData().subscribe((response) => {
      if (response) {
        this.simulateTableData = response
        this.brandList = response.data.map(key => {
          return {
            brandName: key.brandName, brandId: key.brandId
          }
        });
        this.selectedBrand = this.brandList[0].brandId;
        this.oldSelectedBrand =  this.brandList[0].brandId;
        this.getScenerioList(response.data, this.selectedBrand, scenarioId);
      }
    });
  }

  /***
   * @desc Function to Scenario List 
   * @param {Array,Number, Number}- data, brandId,scenarioId ) 
   * gets scenario list based on brand id
   * @returns {Array} - get  scenario list
   */
  getScenerioList(data, brandId, scenarioId?: number) {
    this.scenarioList = data.filter((item) => {
      return item.brandId === brandId;
    })[0].scenario;
    this.selectedScenario = this.oldSelectedScenario = this.scenarioList[0].scenarioId;
  }

  /***
   * @desc Function call on change brand
   * @param {event}- dropdown change event ) 
   * it validates pending change status and based on that get refreshed scenario list
   * @returns {Array} - get  scenario list
   */

  changeBrand(ev): any {
    const selectedBrand = Number(ev);
    this.scmMmmStaticService.getPendingStatus().subscribe(data => {
      if (data === 'YES') {
        this.scmMmmStaticService.setGoodToGo('NO');
        this.confirmationPopupService.confirm('Confirm', 'Do you want to leave the section without saving your changes?', '', 'Yes', 'Cancel')
          .then((confirmed) => {
            if (confirmed) {
              this.scmMmmStaticService.setPendingStatus('NO');
              this.selectedBrand = selectedBrand;
              this.oldSelectedBrand = selectedBrand;
              this.newSelectedScenario = false;
              if (this.selectedTab === 'scm-optimize') {
                this.getScenerioList(this.optimizeChartData.data, selectedBrand);
              } else if (this.selectedTab === 'scm-simulate') {
                this.getScenerioList(this.simulateTableData.data, selectedBrand);
              }
              this.scmMmmStaticService.setGoodToGo('YES')
            } else if (!confirmed) {
              this.selectedBrand = this.oldSelectedBrand;
            }
          });
      } else if (data === 'NO' || data === null) {
        this.selectedBrand = selectedBrand;
        this.oldSelectedBrand = selectedBrand;
        this.newSelectedScenario = false;
        if (this.selectedTab === 'scm-optimize') {
          this.getScenerioList(this.optimizeChartData.data, selectedBrand);
        } else if (this.selectedTab === 'scm-simulate') {
          this.getScenerioList(this.simulateTableData.data, selectedBrand);
        }
        this.scmMmmStaticService.setGoodToGo('YES');
      }
    });

  }

  /***
   * @desc Function call on change scenario
   * @param {event}- dropdown change event ) 
   * it validates pending change status and based on that trigger method to get data for selected scenario
   * @returns {Array} - get  scenario list
   */
  changeScenario(ev): any {
    const event = Number(ev);
    this.scmMmmStaticService.getPendingStatus().subscribe(data => {
      if (data === 'YES') {
        this.scmMmmStaticService.setGoodToGo('NO')
        this.confirmationPopupService.confirm('Confirm', 'Do you want to leave the section without saving your changes?', '', 'Yes', 'Cancel')
          .then((confirmed) => {
            if (confirmed) {
              this.selectedScenario = event;
              this.oldSelectedScenario = event;
              this.scmMmmStaticService.setGoodToGo('YES')
              this.scmMmmStaticService.setPendingStatus('NO');
            } else if (!confirmed) {
              this.selectedScenario = this.oldSelectedScenario;
              this.scmMmmStaticService.setGoodToGo('YES')
            }
          });
      } else if (data === 'NO' || data === null) {
        this.selectedScenario = event;
        this.oldSelectedScenario = event;
        this.scmMmmStaticService.setGoodToGo('YES')
      }
    });

  }

 /***
   * @desc Function call on Optimize Event
   * @param {Number}- changed Budget ) 
   * it validates pending change status and based on that get refreshed scenario data
   * @returns {Event} - to perform optimization 
   */ 
  optimizeChange(changeBudget) {
    this.scmMmmStaticService.getPendingStatus().subscribe(data => {
      if (data === 'YES') {
        this.confirmationPopupService.confirm('Confirm', 'Do you want to leave the section without saving your changes?', '', 'Yes', 'Cancel')
          .then((confirmed) => {
            if (confirmed) {
              this.scmMmmStaticService.setPendingStatus('NO')
              if (changeBudget === '') {
                this.optimizeScenario('oprimalRangeChange');
              } else if (this.changeBudget === '0' || this.changeBudget === '+10' || this.changeBudget === '10' || this.changeBudget === '-10' || this.changeBudget === '+20' || this.changeBudget === '20') {
                this.changeInBudget = changeBudget;
                this.optimizeScenario(changeBudget);
              } else {
                this.toastr.error("Please enter valid budget change");
              }
            } else if (!confirmed) {

            }
          });
      } else if (data === 'NO' || data === null) {
        if (changeBudget === '') {
          this.optimizeScenario('oprimalRangeChange');
        } else if (this.changeBudget === '+10' || this.changeBudget === '10' || this.changeBudget === '-10' || this.changeBudget === '+20' || this.changeBudget === '20') {
          this.changeInBudget = changeBudget;
          this.optimizeScenario(changeBudget);
        } else {
          this.toastr.error("Please enter valid budget change");
        }
      }
    });
  }

}
