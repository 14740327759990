import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserServiceService {

  constructor(private http:HttpClient) { }
  /***
   * @desc Get Token from localstorage
   * @returns {String} Returns Token from localstorage
   * 
   */
  getToken(accessToken){    
    return localStorage.getItem('token')
  }
  /***
  * @desc Set Local storage Token 
  * 
  */
  setToken(accessToken){    
    localStorage.setItem('token',accessToken)
  } 
  /***
   * @desc Parse User JSON to Object
   * @returns {Object} Returns User Data in Object
   * 
   */
  getUser(){
    let userdata:any = localStorage.getItem('user');
    return JSON.parse(userdata);
  }
  /***
  * @desc Remove Local storage User Object
  * 
  */
  removeUser(){
    localStorage.removeItem('user');
  }
  /***
  * @desc Set Local storage User Object
  */
  
  async setUser(userData){  
    localStorage.setItem('user',JSON.stringify(userData))
  } 
  /***
   * @desc Get Role Name by Role Id
   * @param {Array} Module rolearray
   * @param {Integer} Module roleid
   * @returns {String} Returns Role Name
   * 
   */
  getrolename(rolearray,roleid){
    let dname = '';
    for (var i=0; i < rolearray.length; i++) {     
      if (rolearray[i].roleId == roleid)
      {
        dname= rolearray[i].roles[0].title;
      }
  }
  return dname;
  }
  /***
   * @desc Validate User API
   * @returns {Object} Returns Validate User API result
   * 
   */
  validateUser(): Observable<any> {     
    return this.http.get<any>('validateUser')
  }
  /***
   * @desc Change default role of user
   * @param {Integer} Module userroledata
   * @returns {Object} Returns editUserRole API result
   * 
   */
  changedefaultrole(userroledata): Observable<any> { 
    var userdefaultrole:any = this.getUser();
    userdefaultrole.defaultRoleId = userroledata;
    let rolenamex = this.getrolename(userdefaultrole.user_roles, userroledata);
    userdefaultrole.defaultrolename = rolenamex;
    this.setUser(userdefaultrole);
    const options = {
        id:userdefaultrole.id,
        defaultRoleId: userroledata      
    }    
    return this.http.post('editUserRole/', options);
  }
  /***
   * @desc Checks permission access based on role
   * @param {String} Module moduleName
   * @param {String} Module actionName
   * @returns {Boolean} Returns access status
   * 
   */
  
  checkAccess(moduleName,actionName){
    let roleName = this.getUser()?.defaultrolename;
    let rolePermission = this.getUser()?.user_roles;
    let accessObjectFinal:Object={};
    if(rolePermission && rolePermission.length) {
    rolePermission.forEach(element => {
      let objectTitle = element.roles[0].title;
      let objectRoleArray:any = element.roles[0].role_permissions;
      let permissionObject:Object={};
      objectRoleArray.forEach(element => {
        let permissionName = element.permissions[0].title;
        let crudArray:any = [];
        if(element.add==1){crudArray.push("add");}
        if(element.delete==1){crudArray.push("delete");}
        if(element.edit==1){crudArray.push("edit");}
        if(element.view==1){crudArray.push("view");}
        if(element.upload==1){crudArray.push("upload");}
        permissionObject[permissionName]=crudArray;
      });
      accessObjectFinal[objectTitle]=permissionObject;
    });
    let moduleAccess = accessObjectFinal[roleName];
    if(moduleName in moduleAccess){
      let permissionAccess = moduleAccess[moduleName];
      if(permissionAccess.includes(actionName))
      {
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }
  }
  }
 
}
