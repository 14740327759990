import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DemandForecastComponent } from './demand-forecast/demand-forecast.component';
import { SopApprovalComponent } from './sop-approval/sop-approval.component';



const routes: Routes = [
  {
    path: 'supplychain',
    children: [
      {
        path: 'demand-forecasting',
        component: DemandForecastComponent
      },
      {
        path: 'sop-approval',  
        component: SopApprovalComponent
      }
    ]
  }

];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DemandforecastingRoutingModule { }
