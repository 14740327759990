
<div class="container-fluid mt-5">
    <div class="row">           
        <div class="col-sm-12">
            <div class="module-nav">
             
                <ul class="nav nav-tabs list-inline mb-0">
                    <li class="list-inline-item ">
                        <a  class="dropbtn sub-module active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Projects List</a>                        
                    </li>
                </ul>              
            </div>
            <div class="p-2 bg-white shadow rounded" style="height: calc(100vh - 190px);overflow-y:auto ;overflow-x: hidden;">                
            <nav aria-label="breadcrumb ">
                <ol class="breadcrumb mb-2">
                    <li class="breadcrumb-item" *ngFor="let item of breadcrumb; last as isLast">{{item.name}} </li>
                </ol>
            </nav>
                <div class="data-table-wrapper">
                    <table class="table table-hover table-striped dataTable no-footer"  style="font-size: 11px;"  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" aria-describedby="desc">
                        <thead>
                            <tr *ngFor = "let head of heads">    
                              
                                <th scope="col">{{head.coverageType}}</th>
                                <th scope="col">{{head.modelSelected}}</th>
                                <th scope="col">{{head.createdDate}}</th>
                                <th scope="col">{{head.lastUpdated}}</th>
                                <th scope="col">{{head.status}}</th>
                                <th scope="col" style="width: 75px!important;">{{head.actions}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor = "let policy of policyListData">
                                <td>{{policy?.coverageType}}</td>
                                <td>{{policy.model}}
                                    <span class="material-icons d-inline-block info-icon" *ngIf="policy?.model && policy?.model?.length && policy.modelTypeInfo && policy.modelTypeInfo.length" aria-haspopup="true" aria-expanded="false" data-html="true" 
                                    placement="bottom" [ngbTooltip]="tooltipHtml" tooltipClass="custom-tooltipColor"> info </span>
                                    <ng-template #tooltipHtml>
                                        <div style="text-align: left;" *ngIf="policy?.modelTypeInfo">
                                            <p *ngFor="let item of policy?.modelTypeInfo">
                                                <span>Model Type: {{item?.modelType}}</span><br><span>  Version : {{item?.version}}</span>
                                            </p> 
                                        </div>
                                    </ng-template> 
                                </td>
                                <td>{{policy?.createdAt}}</td>
                                <td>{{policy?.updatedAt}}</td>
                                <td>
                                    <span [ngClass]="policy?.model && policy?.model?.length  ? 'text-success' : 'text-warning'">{{policy?.status}}</span>
                                </td>
                                <td class="action-icons">
                                    <a (click)="navigateToModel(policy)" href="javascript:void(0)" class="has-tooltip" lacement="top" ngbTooltip="Edit Project">
                                        <span class="material-icons">edit</span>
                                    </a>  
                                    <a href="#" class="text-danger has-tooltip" title="Delete Project">
                                        <span class="material-icons">delete_outline</span>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>    