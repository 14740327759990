import { Injectable } from '@angular/core';
import * as XLSX from "xlsx";

@Injectable({
  providedIn: 'root'
})
export class ExportToExcelService {

  constructor() { }

  getFileName = (name: string) => {
    let timeSpan = new Date().toISOString();
    let sheetName = name || "ExportResult";
    let fileName = `${sheetName}-${timeSpan}`;
    return {
      sheetName,
      fileName
    };
  };

  exportArrayToExcel(arr: any[], name: string) {

    const formattedArray = arr.map(item =>
      // Object.keys() method returns an array of a given object's own enumerable property names, 
      // in the same order as we get with a normal loop
      Object.keys(item).reduce((accumulator, currentValue) => {
        // accumulator is the new object we are creating
        // currentValue is each key of item
        accumulator[currentValue.replace(/^([a-z]|[A-Z])/g, (c, i) => (i ? " " : "") + c.toUpperCase())] = item[currentValue];
        return accumulator;
      }, {})
    );


    let { sheetName, fileName } = this.getFileName(name);

    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(formattedArray);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }


  exportTableToExcel(tableId: string, name: string) {
    let { sheetName, fileName } = this.getFileName(name);
    let targetTableElm = document.getElementById(tableId);
    let wb = XLSX.utils.table_to_book(targetTableElm, <XLSX.Table2SheetOpts>{
      sheet: sheetName
    });
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }
}
