<nav aria-label="breadcrumb ">
  <ol class="breadcrumb custom-breadcrumb">
      <li class="" (click)="navigateBackTo('/metamorph/bordereaux-management')"><i class="material-icons">keyboard_backspace</i></li>
      <li class="breadcrumb-item" *ngFor="let item of breadcrumb; last as isLast">
        <a *ngIf="!isLast" href="javascript:void(0);" (click)="navigateBackTo('/metamorph/bordereaux-management')" >{{item}}</a>
        <span *ngIf="isLast">{{item}}</span>
      </li>
  </ol>
</nav>

<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <!-- tabs -->
      <div class="module-nav">
        <ul class="nav nav-tabs" id="supplyChain" role="tablist">
          <li class="nav-item"  *ngIf="user.checkAccess('metamorph-standardization','view')">

            <a class="nav-link sub-module" (click)="selectTab('standardization', 'Standardization', $event)" role="tab"
              [ngClass]="{ 'active':selectedTab==='standardization'}">Standardization</a>
          </li>
          <li class="nav-item" *ngIf="user.checkAccess('metamorph-validation','view')">
            <a class="nav-link sub-module" (click)="selectTab('validation', 'Validation', $event)" role="tab"
              [ngClass]="{ 'active':selectedTab==='validation'}">Validation</a>
          </li>
          <li class="nav-item" *ngIf="user.checkAccess('metamorph-taxvalidation','view')">
            <a class="nav-link sub-module" (click)="selectTab('taxValidatiopn','Tax Validatiopn', $event)" role="tab"
              [ngClass]="{ 'active':selectedTab==='taxValidatiopn'}">Tax-Validation</a>
          </li>
        </ul>
      </div>

      <div class="container-fluid  custom-grid">
        <div class="row info-panel">
          <ng-container  *ngFor="let item of operationalData">
            <div class="col-sm-2" *ngIf="item.label!='Process Id' && item.label!='Standardize Download Template' && item.label!='Validate Download Template'">
                    <div class="custom-dotted-border">
                        <p>{{item?.label}}</p>
                        <ng-container *ngIf="item.label=='Execution Month'">
                          <h3>{{item?.value | customdateformat}}</h3>
                        </ng-container>
                        <ng-container *ngIf="item.label!='Execution Month'">
                          <h3>{{item?.value}}</h3>
                        </ng-container>
                    </div>
            </div>
          </ng-container>
            <div class="col-sm-2 custom-width" *ngIf="user.checkAccess('metamorph-standardization','upload')  && selectedTab==='standardization' && amendRulesShow==false">
            
                <div class="custom-dotted-border ">
                  <ng-container  *ngIf="uploadDisabled === true  && amendRulesShow==false" >
                    <a class="btn btn-outline-primary" href="javascript:void(0)" (click)="openFileUploaderModal()" > Upload New Data  </a>
                  </ng-container>
                  <ng-container  *ngIf="uploadDisabled === false  && amendRulesShow==false">
                    <a class="btn btn-outline-primary disabledUpload" href="javascript:void(0)"  placement="right" [ngbTooltip]="tooltipOptimizeD" tooltipClass="custom-tooltipColor"> Upload New Data  </a>
                    <ng-template #tooltipOptimizeD >
                      <div>User can start new data upload process under current month only</div>
                  </ng-template>
                  </ng-container>                  
                </div>
              </div>
              <div class="col-sm-2 custom-width" *ngIf="user.checkAccess('metamorph-validation','upload')  && selectedTab==='validation' && amendRulesShow==false">
            
                <div class="custom-dotted-border ">
                  <ng-container  *ngIf="uploadDisabled === true  && amendRulesShow==false" >
                    <a class="btn btn-outline-primary" href="javascript:void(0)" (click)="openFileUploaderModal()" > Upload New Data  </a>
                  </ng-container>
                  <ng-container  *ngIf="uploadDisabled === false  && amendRulesShow==false">
                    <a class="btn btn-outline-primary disabledUpload" href="javascript:void(0)"  placement="right" [ngbTooltip]="tooltipOptimizeD" tooltipClass="custom-tooltipColor"> Upload New Data  </a>
                    <ng-template #tooltipOptimizeD >
                      <div>User can start new data upload process under current month only</div>
                  </ng-template>
                  </ng-container>                  
                </div>
              </div>

            <div class="col-sm-2" >
              <ng-container *ngIf="selectedTab==='standardization'">
                <a class="btn btn-outline-primary download-btn" href="{{this.standardizaDownloadLink}}">
                    <span class="material-icons material-symbols-outlined"> download </span>
                    Download Template
                </a>
              </ng-container>
              <ng-container *ngIf="selectedTab==='validation' && amendRulesShow==false">
                <a class="btn btn-outline-primary download-btn" href="{{this.validationDownloadLink}}">
                    <span class="material-icons material-symbols-outlined"> download </span>
                    Download Template
                </a>
              </ng-container>
            </div>
        </div>
    </div>

      <div class="bg-white rounded p-2 " >
        <!-- tab pannel -->
        <div class="tab-content bg-white" id="pricingTabsContent">
          <div class="tab-pane" id="bordereaux-management-outline" role="tabpanel"
            [ngClass]="{ 'active show':selectedTab==='standardization'}" *ngIf="user.checkAccess('metamorph-standardization','view')">
            <div *ngIf="selectedTab==='standardization'">
              <standardization [selectedTab]='selectedTab'></standardization>
            </div>
          </div>

          <div class="tab-pane" id="bordereaux-management-outline" role="tabpanel"
            [ngClass]="{ 'active show':selectedTab==='validation'}" *ngIf="user.checkAccess('metamorph-validation','view')">
            <div *ngIf="selectedTab==='validation'">
              <validation [selectedTab]='selectedTab' (amendRulesTab)="handleAmendRules($event)"></validation>
            </div>
          </div>

          <div class="tab-pane" id="tax-validation" role="tabpanel"
            [ngClass]="{ 'active show':selectedTab==='taxValidation'}" *ngIf="user.checkAccess('metamorph-taxvalidation','view')">
            <div *ngIf="selectedTab==='taxValidation'">
              <tax-validation [selectedTab]='selectedTab'></tax-validation>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
