<div class="row no-gutters pd-b2">
    <button class="accordion active" (click)="toggleAccordian($event)">Demand Drivers</button>
    <div class="panel">
        <div class="">
            <div class="col-sm-6 display-inline">
                <app-generic-chart
                    *ngIf="!!cpiIndexData && cpiIndexData.length"
                    [chartData]="cpiIndexData" 
                    [chartTemplate]="chartTemplate" 
                    [chartOptionConfig]="chartOptionConfig" 
                    [seriesColors]="signleSeriesColors"
                    [updateFlag]="updateFlag"
                    [chartHeight]="200"
                ></app-generic-chart>
            </div>
            <div class="col-sm-6 display-inline">
                <app-generic-chart
                    *ngIf="!!ppiData && ppiData.length"
                    [chartData]="ppiData" 
                    [chartTemplate]="chartTemplate" 
                    [chartOptionConfig]="chartOptionConfig" 
                    [seriesColors]="signleSeriesColors"
                    [updateFlag]="updateFlag"
                    [chartHeight]="200"
                ></app-generic-chart>
            </div>
            <div class="col-sm-6 display-inline">
                <app-generic-chart
                    *ngIf="!!unEmploymentData && unEmploymentData.length"
                    [chartData]="unEmploymentData" 
                    [chartTemplate]="chartTemplate" 
                    [chartOptionConfig]="chartOptionConfig" 
                    [seriesColors]="signleSeriesColors"
                    [updateFlag]="updateFlag"
                    [chartHeight]="200"
                ></app-generic-chart>
            </div>
            <div class="col-sm-6 display-inline">
                <app-generic-chart 
                    *ngIf="!!discoundOffered && discoundOffered.length"
                    [chartData]="discoundOffered" 
                    [chartTemplate]="chartTemplate" 
                    [chartOptionConfig]="chartOptionConfig" 
                    [seriesColors]="signleSeriesColors"
                    [updateFlag]="updateFlag"
                    [chartHeight]="200"
                    >
                </app-generic-chart>
            </div>
          </div>
    </div>
    <div></div>
    <button class="accordion active m-t1" (click)="toggleAccordian($event)">Models</button>
    <div class="panel">
        <div class="">
            <div class="col-sm-6 display-inline">
                <app-generic-chart 
                    
                    [chartData]="modelWiseForecast" 
                    [chartTemplate]="chartTemplate" 
                    [chartOptionConfig]="modelWiseForecastOptionConfig" 
                    [seriesColors]="multySeriesColors"
                    [updateFlag]="updateFlag"
                    [chartHeight]="400">
                </app-generic-chart>                  
            </div>
            <div class="col-sm-6 display-inline">
                <app-generic-chart 
                    
                    [chartData]="mapeTrend" 
                    [chartTemplate]="chartTemplate" 
                    [chartOptionConfig]="chartOptionConfig" 
                    [seriesColors]="signleSeriesColors"
                    [updateFlag]="updateFlag"
                    [chartHeight]="400">
                </app-generic-chart>
            </div>
          </div>
    </div>

    <button class="accordion active m-t1" (click)="toggleAccordian($event)">MAPE by Product Family</button>
    <div class="panel">
        <div class="">
            <div class="text-center chart-container mape-data-table " id="kp-6">
                <div class="text-center chart-container" id="kp-2"*ngIf="!productFamilyMAPE?.length">
                    <h3 class="no-data">No Data Found</h3>
                </div>
                <div class="tableCustomScroll">
                <ng-container *ngIf="productFamilyMAPE?.length">
                    <table class="table table-striped dataTable table-sm no-footer" id="snop-planning-table" style="font-size: 11px;" role="grid" aria-describedby="snop-planning-table-info">
                        <thead class="sticky-table-header">
                            <tr role="row">
                                <th scope="col" class="table-header-cell text-left pl-3">SKU</th>
                                <ng-container *ngFor="let header of productFamilyMAPE[0].data">
                                    <th scope="col" class="table-header-cell">{{getReadableDate(header?.yearMonth)}}</th>                                                                
                                </ng-container>                                                             
                            </tr>
                        </thead>
                        <tbody>
                            <tr role="row" *ngFor="let tableRow of productFamilyMAPE">
                                <td class="text-left pl-3">{{tableRow?.skuName}}</td>
                                <ng-container *ngFor="let tableColumn of tableRow.data">
                                    <td >{{tableColumn?.MAPE | number : '1.0-2'}}</td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>
            </div>
            </div>
        </div>
    </div>
</div>