import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CsrComponent } from './csr.component';
import { CsrBaseComponent } from './csr-base/csr-base.component';
import { AutoPersonalLinesAComponent } from './auto-personal-lines-a/auto-personal-lines-a.component';
import { AutoPersonalLinesBComponent } from './auto-personal-lines-b/auto-personal-lines-b.component';
import { AutoPersonalLinesCComponent } from './auto-personal-lines-c/auto-personal-lines-c.component';
import { AutoPersonalLinesDComponent } from './auto-personal-lines-d/auto-personal-lines-d.component';
import {AutoPersonalLinesEComponent } from './auto-personal-lines-e/auto-personal-lines-e.component';
import {AutoPersonalLinesFComponent } from './auto-personal-lines-f/auto-personal-lines-f.component';
import { AgentComponent } from './agent/agent.component';
import { AgentNewComponent } from './agent-new/agent-new.component';
import { Agent3Component } from './agent3/agent3.component';
import { Agent4Component } from './agent4/agent4.component';
import { Agent5Component } from './agent5/agent5.component';

const routes: Routes = [
  {
    path: 'csr',
    component: CsrComponent,
    children: [
      {
        path: 'csr-list',
        component: CsrBaseComponent
      },
      {
        path: 'auto-personal-linesA',
        component: AutoPersonalLinesAComponent
      },
      {
        path: 'auto-personal-linesB',
        component: AutoPersonalLinesBComponent
      },
      {
        path: 'auto-personal-linesC',
        component: AutoPersonalLinesCComponent
      },
      {
        path: 'auto-personal-linesD',
        component: AutoPersonalLinesDComponent
      },
      {
        path: 'auto-personal-linesE',
        component: AutoPersonalLinesEComponent
      },
      {
        path: 'auto-personal-linesF',
        component: AutoPersonalLinesFComponent
      },
      {
        path: 'agent-detail',
        component: AgentComponent
      },
      {
        path: 'agent-detail2',
        component: AgentNewComponent
      },
      {
        path: 'agent-detail3',
        component: Agent3Component
      },
      {
        path: 'agent-detail4',
        component: Agent4Component
      },
      {
        path: 'agent-detail5',
        component: Agent5Component
      }

    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CsrRoutingModule { }
