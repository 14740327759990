import { Component, Input, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PricingModelAnalysisService } from '../pricing-model-analysis.service';
@Component({
  selector: 'app-pricing-model-analysis-overview',
  templateUrl: './pricing-model-analysis-overview.component.html',
  styleUrls: ['./pricing-model-analysis-overview.component.scss']
})
export class PricingModelAnalysisOverviewComponent implements OnInit {
  @Input() chartFilters: any;
  public overviewModelAnalysisData: any;
 
  public lobProjectId: any;
  localStorage = localStorage;
  public modelTechnique = 1;
  public modelType = 1;
  public viewName = 'overview';
  overviewList: any;
  overviewData: any = [];
  overviewListData: any;

  constructor(private pricingModelAnalysisService: PricingModelAnalysisService, private ngxLoader: NgxUiLoaderService,) { }

  ngOnInit(): void {
    Promise.all([ this.localStorage.getItem('lobProjectId')]).then(values => {
      this.lobProjectId = values[0];
      this.getlobModelAnalysisOverview( this.lobProjectId, this.modelType, this.modelTechnique);
    })
  }

  // Getting Model Analysis Oberview Data
  getlobModelAnalysisOverview( lobProjectId, modelType, modelTechnique) {
    this.ngxLoader.start();
    this.pricingModelAnalysisService.getlobModelAnalysisOverview( lobProjectId, modelType, modelTechnique).subscribe((overviewObj) => {
      if (overviewObj) {
        this.overviewList = overviewObj;
        this.overviewListData = this.overviewList.data;
        this.overviewData = this.overviewListData;
        this.overviewModelAnalysisData = this.overviewData[0].modelAnalysisData;
        this.ngxLoader.stop();
      } else { this.overviewData = []; this.ngxLoader.stop(); }
    });
  }

  // Update Chart using filter panel
  updateStatus(checkboxData: any, filterType: any) {
    if (filterType === 'modelTechniques') {
      this.modelTechnique = checkboxData.id;
      this.getlobModelAnalysisOverview( this.lobProjectId, this.modelType, checkboxData.id);
    } else if (filterType === 'modelTypes') {
      this.modelType = checkboxData.id;
      this.getlobModelAnalysisOverview( this.lobProjectId, checkboxData.id, this.modelTechnique);
    }
  }
}
