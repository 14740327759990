import { Component } from '@angular/core';
import { ConfigService } from 'src/app/config.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent {
  constructor(private configService: ConfigService) {

  }
  getAppVersion() {
    let version = '';
    try {
      version = this.configService.getSettings('versionNumber')
      return version
    } catch (error) {
      return version
    }
  }
}
