<div class="tab-content tab-content-simulate bg-white">
    <div class="container-fluid p-2 ">
        <div class="row no-gutters optimized-tables">
            <div class="col-sm-12">
                <div class="card shadow-sm">
                    <div class="card-body">
                        <table border="0" cellpadding="0" id="SimulateTable"
                            class="table-cs table mb-0 table-simulate table-bordered table-sm " cellspacing="0"
                            style="font-size: 0.7rem;" aria-describedby="mydesc">
                            <thead>
                                <tr>
                                    <th scope="col" colspan="6" class="p-0 bg-primary">
                                        <h5 class="card-title bg-primary">Current Scenario</h5>
                                    </th>
                                    <th scope="col" class="hidden-column"></th>
                                    <th scope="col" colspan="1" class="p-0 bg-success">
                                        <h5 class="card-title bg-success">Simulate Spend </h5>
                                    </th>
                                    <th scope="col" class="hidden-column"></th>
                                    <th scope="col" colspan="5" class="p-0 bg-warning">
                                        <h5 class="card-title bg-warning">Simulated Result</h5>
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="col">Spend Levers</th>
                                    <th scope="col">Spend $</th>
                                    <th scope="col">Output <br>(In Units)</th>
                                    <th scope="col">Margin<br> ( In $ )</th>
                                    <th scope="col">Profit <br>( In $ )</th>
                                    <th scope="col">ROI</th>
                                    <th scope="col" style="width:1%"></th>
                                    <th scope="col" style="width:10%;">Change Spend by
                                        <span *ngIf="selectedChangeType === 'spend_per'">%</span>
                                        <span *ngIf="selectedChangeType === 'spend_usd'">$</span>
                                    </th>
                                    <th scope="col" style="width:1%"></th>
                                    <th scope="col">New Spend</th>
                                    <th scope="col">Output <br>(In Units)</th>
                                    <th scope="col">Margin <br>( In $ )</th>
                                    <th scope="col">Profit <br>( In $ )</th>
                                    <th scope="col">ROI</th>
                                </tr>
                            </thead>

                            <tbody *ngIf="selectedBrandId !== '99'">
                                <tr height="22" class="light-row"
                                    *ngFor="let item of simulateListData | slice:0:simulateListData.length-2; let index = index">
                                    <td height="22" [ngClass]="item.isParent  ? 'is-parent' : 'not-parent'">
                                        {{item?.spendLever}}</td>
                                    <td class="width-85">{{item?.spend | number : '1.0-2'}}</td>
                                    <td class="width-85">{{item?.modelOutput | number : '1.0-2'}}</td>
                                    <td class="width-85">{{item?.margin | number : '1.0-2'}}</td>
                                    <td class="width-85">{{item?.profit | number : '1.0-2'}}</td>
                                    <td class="width-85">{{item?.roiCalculation | number : '1.0-2'}}</td>
                                    <td class="hidden-column"></td>
                                    <td class="width-85">
                                        <ng-container *ngIf="selectedChangeType === 'spend_per'">
                                            <input onlyPositiveAndNegativeNumbers
                                                *ngIf="index !== 0 && index !== simulateListData.length-2 && index !== simulateListData.length-1"
                                                type="text" class="form-control table-form"
                                                [disabled]="selectedScenarioType === 'Simulate Custom'"
                                                [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="simulateListData[index].changeSpendBy"
                                                (ngModelChange)="changeSpendByUpdate($event, item?.spend)"
                                                [ngClass]="simulateListData[index].changeSpendBy < -100 ? 'error-class': ''">
                                        </ng-container>
                                        <ng-container
                                            *ngIf="selectedChangeType === 'spend_usd'">
                                            <input onlyPositiveAndNegativeNumbers
                                                *ngIf="index !== 0 && index !== simulateListData.length-2 && index !== simulateListData.length-1"
                                                type="text" class="form-control table-form"
                                                [disabled]="selectedScenarioType === 'Simulate Custom'"
                                                [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="simulateListData[index].changeSpendBy"
                                                (ngModelChange)="changeSpendByUpdate($event, item?.spend)"
                                                [ngClass]="simulateListData[index].changeSpendBy <(simulateListData[index].spend*-1) ? 'error-class': ''">
                                        </ng-container>
                                    </td>
                                    <td class="hidden-column"></td>
                                    <td class="width-85">{{item?.newSpend | number : '1.0-2'}}</td>
                                    <td class="width-85">{{item?.simulateOutput | number : '1.0-2'}}</td>
                                    <td class="width-85">{{item?.simulateOutputMargin | number : '1.0-2'}}</td>
                                    <td class="width-85">{{item?.simulateOutputProfit | number : '1.0-2'}}</td>
                                    <td class="width-85">{{item?.simulateOutputRoiCalculation | number : '1.0-2'}}</td>
                                </tr>
                            </tbody>

                            <tbody *ngIf="selectedBrandId === '99'">
                              <ng-container *ngFor="let item of simulateListData | slice:0:simulateListData.length-7; let index = index">
                                <tr *ngIf="item.showBrandNameOnly" class="brand-name-row">
                                  <td colspan="6" class="is-parent brand-name-text">{{item.brandName}}</td>
                                  <td class="hidden-column"></td>
                                  <td ></td>
                                  <td class="hidden-column"></td>
                                  <td colspan="5"></td>
                                </tr>
                                <tr *ngIf="!item.showBrandNameOnly" height="22" class="light-row">
                                    <td height="22" [ngClass]="item.isParent  ? 'is-parent' : 'not-parent'">
                                        {{item?.spendLever}}</td>
                                    <td class="width-85">{{item?.spend | number : '1.0-2'}}</td>
                                    <td class="width-85">{{item?.modelOutput | number : '1.0-2'}}</td>
                                    <td class="width-85">{{item?.margin | number : '1.0-2'}}</td>
                                    <td class="width-85">{{item?.profit | number : '1.0-2'}}</td>
                                    <td class="width-85">{{item?.roiCalculation | number : '1.0-2'}}</td>
                                    <td class="hidden-column"></td>
                                    <td class="width-85">
                                        <ng-container *ngIf="selectedChangeType === 'spend_per'">
                                            <input onlyPositiveAndNegativeNumbers
                                                *ngIf="!(item.spendLeverType === 'Media' && item.isParent) && index !== simulateListData.length-2 && index !== simulateListData.length-1"
                                                type="text" class="form-control table-form"
                                                [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="simulateListData[index].changeSpendBy">
                                        </ng-container>
                                        <ng-container *ngIf="selectedChangeType === 'spend_usd'">
                                            <input onlyPositiveAndNegativeNumbers
                                                *ngIf="!(item.spendLeverType === 'Media' && item.isParent) && index !== simulateListData.length-2 && index !== simulateListData.length-1"
                                                type="text" class="form-control table-form"
                                                [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="simulateListData[index].changeSpendBy">
                                        </ng-container>
                                    </td>
                                    <td class="hidden-column"></td>
                                    <td class="width-85">{{item?.newSpend | number : '1.0-2'}}</td>
                                    <td class="width-85">{{item?.simulateOutput | number : '1.0-2'}}</td>
                                    <td class="width-85">{{item?.simulateOutputMargin | number : '1.0-2'}}</td>
                                    <td class="width-85">{{item?.simulateOutputProfit | number : '1.0-2'}}</td>
                                    <td class="width-85">{{item?.simulateOutputRoiCalculation | number : '1.0-2'}}</td>
                                </tr>
                              </ng-container>
                              <tr class="brand-name-row">
                                <td colspan="6" class="is-parent brand-name-text">Portfolio Total</td>
                                <td class="hidden-column"></td>
                                <td ></td>
                                <td class="hidden-column"></td>
                                <td colspan="5"></td>
                              </tr>
                                <tr height="22" class="light-row dark-table"
                                    *ngFor="let item of simulateListData | slice:simulateListData.length-7:simulateListData.length-2; let index = index">
                                    <td height="22" [ngClass]="item.isParent  ? 'is-parent' : 'not-parent'">
                                        {{item?.spendLever}}</td>
                                    <td class="width-85">{{item?.spend | number : '1.0-2'}}</td>
                                    <td class="width-85">{{item?.output | number : '1.0-2'}}</td>
                                    <td class="width-85">{{item?.margin | number : '1.0-2'}}</td>
                                    <td class="width-85">{{item?.profit | number : '1.0-2'}}</td>
                                    <td class="width-85">{{item?.roiCalculation | number : '1.0-2'}}</td>
                                    <td class="hidden-column "></td>
                                    <td class="width-85"></td>
                                    <td class="hidden-column"></td>
                                    <td class="width-85">{{item?.newSpend | number : '1.0-0'}}</td>
                                    <td class="width-85">{{item?.simulateOutput | number : '1.0-2'}}</td>
                                    <td class="width-85">{{item?.simulateOutputMargin | number : '1.0-2'}}</td>
                                    <td class="width-85">{{item?.simulateOutputProfit | number : '1.0-2'}}</td>
                                    <td class="width-85">{{item?.simulateOutputRoiCalculation | number : '1.0-2'}}</td>
                                </tr>
                            </tbody>

                            <tfoot>
                                <tr height="22" class="light-row"
                                    *ngFor="let item of simulateListData | slice:simulateListData.length-2:simulateListData.length; let index = index; let last = last">
                                    <th scope="col" height="22">{{item?.spendLever}}</th>
                                    <th scope="col" class="width-85">
                                        <ng-container *ngIf="!last">{{item?.spend | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.spend !== 0">{{item?.spend | number : '1.0-2'}} <span *ngIf="item?.spend && item?.spend !== 0">%</span></ng-container>
                                            <ng-container *ngIf="item?.spend === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                    <th scope="col" class="width-85">
                                        <ng-container *ngIf="!last">{{item?.modelOutput | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.modelOutput !== 0">{{item?.modelOutput | number : '1.0-2'}} <span *ngIf="item?.modelOutput && item?.modelOutput !==0">%</span></ng-container>
                                            <ng-container *ngIf="item?.modelOutput === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                    <th scope="col" class="width-85">
                                        <ng-container *ngIf="!last">{{item?.margin | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.margin !== 0">{{item?.margin | number : '1.0-2'}} <span *ngIf="item?.margin && item?.margin !== 0">%</span></ng-container>
                                            <ng-container *ngIf="item?.margin === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                    <th scope="col" class="width-85">
                                        <ng-container *ngIf="!last">{{item?.profit | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.profit !== 0">{{item?.profit | number : '1.0-2'}} <span *ngIf="item?.profit && item?.profit !== 0">%</span></ng-container>
                                            <ng-container *ngIf="item?.profit === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                    <th scope="col" class="width-85">
                                        <ng-container *ngIf="!last">{{item?.roiCalculation | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.roiCalculation !== 0">{{item?.roiCalculation | number : '1.0-2'}} <span *ngIf="item?.roiCalculation && item?.roiCalculation !== 0">%</span></ng-container>
                                            <ng-container *ngIf="item?.roiCalculation === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                    <th scope="col" class="hidden-column"></th>
                                    <th scope="col"></th>
                                    <th scope="col" class="hidden-column"></th>
                                    <th scope="col" class="width-85">
                                        <ng-container *ngIf="!last">{{item?.newSpend | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.newSpend !== 0">{{item?.newSpend | number : '1.0-2'}} <span *ngIf="item?.newSpend && item?.newSpend !== 0">%</span></ng-container>
                                            <ng-container *ngIf="item?.newSpend === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                    <th scope="col" class="width-85">
                                        <ng-container *ngIf="!last">{{item?.simulateOutput | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.simulateOutput !== 0">{{item?.simulateOutput | number : '1.0-2'}} <span *ngIf="item?.simulateOutput && item?.simulateOutput !== 0">%</span></ng-container>
                                            <ng-container *ngIf="item?.simulateOutput === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                    <th scope="col" class="width-85">
                                        <ng-container *ngIf="!last">{{item?.simulateOutputMargin | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.simulateOutputMargin !== 0">{{item?.simulateOutputMargin | number : '1.0-2'}} <span *ngIf="item?.simulateOutputMargin && item?.simulateOutputMargin !==0">%</span></ng-container>
                                            <ng-container *ngIf="item?.simulateOutputMargin === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                    <th scope="col" class="width-85">
                                        <ng-container *ngIf="!last">{{item?.simulateOutputProfit | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.simulateOutputProfit !== 0">{{item?.simulateOutputProfit | number : '1.0-2'}} <span *ngIf="item?.simulateOutputProfit && item?.simulateOutputProfit !== 0">%</span></ng-container>
                                            <ng-container *ngIf="item?.simulateOutputProfit === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                    <th scope="col" class="width-85">
                                        <ng-container *ngIf="!last">{{item?.simulateOutputRoiCalculation | number : '1.0-2'}}</ng-container>
                                        <ng-container *ngIf="last">
                                            <ng-container *ngIf="item?.simulateOutputRoiCalculation !== 0">{{item?.simulateOutputRoiCalculation | number : '1.0-2'}} <span *ngIf="item?.simulateOutputRoiCalculation && item?.simulateOutputRoiCalculation !== 0">%</span></ng-container>
                                            <ng-container *ngIf="item?.simulateOutputRoiCalculation === 0">0.00 %</ng-container>
                                        </ng-container>
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div id="accordion" style="margin: 1rem 0 0 0" *ngIf="selectedBrandId !== '99'">
            <div class="card">
                <button class="accordion collapsed" data-toggle="collapse" data-target="#collapseTwo"
                    aria-expanded="false" aria-controls="collapseTwo">
                    Simulated Scenario Analysis
                </button>

                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                    <div class="">
                        <div class="row no-gutters">
                            <div class="col-sm-12 col-md-6" *ngIf="!!incrementalSaleChartTemplate">
                                <app-charts [chartOption]="incrementalSaleChartTemplate" [updateFlag]="updateFlag">
                                </app-charts>
                            </div>

                            <div class="col-sm-12 col-md-6" *ngIf="!!mediaResponseChartTemplate">
                                <app-charts [chartOption]="mediaResponseChartTemplate" [updateFlag]="updateFlag">
                                </app-charts>
                            </div>

                            <div class="col-sm-12 col-md-6" *ngIf="!!roiMacChartTemplate">
                                <app-charts [chartOption]="roiMacChartTemplate" [updateFlag]="updateFlag"></app-charts>
                            </div>

                            <div class="col-sm-12 col-md-6" *ngIf="!!shareSpendChartTemplate">
                                <app-charts [chartOption]="shareSpendChartTemplate" [updateFlag]="updateFlag">
                                </app-charts>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
