import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({ 
  providedIn: 'root'
})
export class ClaimsListService {
 
  getClaimsHead: any;
  constructor(private http:HttpClient, private ngxLoader: NgxUiLoaderService,) { 
    
    
  }



 /***
   * @desc returning JSON from getData
   * @returns {Object} Returns JSON from getData
   * 
   */
  getData(){
    return [
         { claim_no: 'ERA4726121', claim_type: 'Motor Personal', policy_start_date: '06/21/2020', vehicle_class: 'Compact', vehicle_make:'Honda', notified_date:'03/10/2020', severity_of_damage:'Medium', reported_by:'Policyholder', recommendation:'View' },
         { claim_no: 'ERK4726122', claim_type: 'Motor Personal', policy_start_date: '06/21/2020', vehicle_class: 'Compact', vehicle_make:'Honda', notified_date:'03/10/2020', severity_of_damage:'Medium', reported_by:'Policyholder', recommendation:'View' },
         { claim_no: 'ERK4726123', claim_type: 'Motor Personal', policy_start_date: '06/21/2020', vehicle_class: 'Compact', vehicle_make:'Honda', notified_date:'03/10/2020', severity_of_damage:'Medium', reported_by:'Policyholder', recommendation:'View' },
         { claim_no: 'ERK4726124', claim_type: 'Motor Personal', policy_start_date: '06/21/2020', vehicle_class: 'Compact', vehicle_make:'Honda', notified_date:'03/10/2020', severity_of_damage:'Medium', reported_by:'Policyholder', recommendation:'View' },
         { claim_no: 'ERK4726125', claim_type: 'Motor Personal', policy_start_date: '06/21/2020', vehicle_class: 'Compact', vehicle_make:'Honda', notified_date:'03/10/2020', severity_of_damage:'Medium', reported_by:'Policyholder', recommendation:'View' },
         { claim_no: 'ERK4726126', claim_type: 'Motor Personal', policy_start_date: '06/21/2020', vehicle_class: 'Compact', vehicle_make:'Honda', notified_date:'03/10/2020', severity_of_damage:'Medium', reported_by:'Policyholder', recommendation:'View' },
         { claim_no: 'ERK4726127', claim_type: 'Motor Personal', policy_start_date: '06/21/2020', vehicle_class: 'Compact', vehicle_make:'Honda', notified_date:'03/10/2020', severity_of_damage:'Medium', reported_by:'Policyholder', recommendation:'View' },
    
       ] 
  }

/***
   * @desc Table head JSON from getDataa
   * @returns {Object} Returns Table head JSON from getDataa
   * 
   */
  getDataa(){
    return [
         { claim_no: 'Claim No.', claim_type: 'Claim Type', policy_start_date: 'Policy Start Date', lossDate: 'Loss Date', notified_date:'Notified Date',  recommendation:'Recommendation',action:'Action' }
    ]
  }

   /***
   * @desc Get Claims List API
   * @returns {Object} Returns Get Claims list API Data
   * 
   */
  getClaimList(): Observable<any[]> {
   return this.http.get<any>('claims');
  }

  /***
   * @desc Get Claims Modal API
   * @returns {Object} Returns Get Claims Model API Data
   * 
   */
  getClaimModelDetail(): Observable<any[]> {
    return this.http.get<any>('claimsModel');
   }

  /***
   * @desc Delete Claims API
   * @param {String} Module claimId
   * @returns {Object} Returns Delete Claims
   * 
   */
  deleteClaim(claimId): Observable<any> {​​​​​​​​
    const options = {​​​​​​​​
    headers: new HttpHeaders({​​​​​​​​}​​​​​​​​),
    body: {​​​​​​​​
    id: claimId
    }​​​​​​​​
    }​​​​​​​​
    return this.http.delete('claims/',options);
    }​​​​​​​​

  
}
