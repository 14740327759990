<div class="bg">
    <div class="container-fluid mt-5">
        <div class="row">
            <div class="col-sm-12">

                <div class="module-nav">
                    <div class="float-right">
                        <form class="form-inline">
                            <label class="my-1 mr-2 mt-1" for="p-fam">Product Family</label>
                            <select class="form-control form-control-sm mr-3 mt-1" id="p-fam"
                                *ngIf="productFamilies && productFamilies.length" #productFamily id="pageSize"
                                [(ngModel)]="productFamilyValue"
                                (ngModelChange)="productFamily.value = onProductFamilyChange($event)"
                                [ngModelOptions]="{standalone: true}">
                                <option *ngFor="let item of productFamilies" value="{{item.id}}">{{item?.name}}</option>
                            </select>
                            <ng-container *ngIf="selectedTab === 'performanceAnalysis' && performanceData.length">
                                <label class="my-1 mr-2 mt-1" for="p-sku">SKU</label>
                                <select class="form-control form-control-sm mr-3 mt-1" id="p-sku" style="width: 150px;"
                                    (change)="changeSKU(selectedSKU)" [(ngModel)]="selectedSKU"
                                    [ngModelOptions]="{standalone: true}">
                                    <option *ngFor="let item of stockKeepingUnits" [ngValue]="item.id">{{item?.name}}
                                    </option>
                                </select>
                                <label class="my-1 mr-2 mt-1" for="p-sku">{{performanceData[1]?.name}}</label>
                                <span class="badge badge-success font-weight-bolder"
                                    style="font-size: .75rem;">{{performanceData[1]?.data}}</span>
                            </ng-container>

                        </form>
                    </div>
                    <ul class="nav nav-tabs mt-2" id="supplyChain" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link sub-module" id="demandPlanningDashboard-tab"
                                [ngClass]="{ 'active':selectedTab==='demandPlanningDashboard'}" role="tab"
                                (click)="selectTab('demandPlanningDashboard', $event)">Demand Planning Dashboard</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link sub-module" id="performance-tab"
                                [ngClass]="{ 'active':selectedTab==='performanceAnalysis'}" role="tab"
                                (click)="selectTab('performanceAnalysis', $event)">Performance Analysis</a>
                        </li>
                    </ul>
                </div>
                <div class="tab-content bg-white" id="pricingTabsContent">

                    <div class="tab-pane " id="demandPlanningDashboard" role="tabpanel"
                        [ngClass]="{ 'active show':selectedTab==='demandPlanningDashboard'}">
                        <div class="p-2">
                            <div *ngIf="productFamilies?.length; then thenBlock else elseBlock"></div>
                            <ng-template #thenBlock>
                                <app-demand-forecast-dashboard [selectedProductFamily]="selectedProductFamily"
                                    [productFamilies]="productFamilies"></app-demand-forecast-dashboard>
                            </ng-template>
                            <ng-template #elseBlock></ng-template>
                        </div>
                    </div>
                    <div class="tab-pane" id="performanceAnalysis" role="tabpanel"
                        [ngClass]="{ 'active show':selectedTab==='performanceAnalysis'}">
                        <div class="p-3" *ngIf="productFamilies?.length && stockKeepingUnits?.length">
                            <app-demand-performance-analysis [selectedProductFamily]="selectedProductFamily"
                                [selectedSKU]="selectedSKU" [productFamilies]="productFamilies">
                            </app-demand-performance-analysis>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>