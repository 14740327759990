<div class="mt-5 pt-0" style="background: #FAFAFA;">
    <div class="container-fluid">

        

        <div class="row ">
            <div class="col-md-9 mt-2">
                <div class="policy-type">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4 pl-3 mb-0">
                                    <div class="policy-list">
                                        <h5>Policy Type: <span>Auto</span></h5>
                                        <div class="perils-list">
                                            <p class="peril accident active">
                                                <img src="assets/images/Auto.png"  class="policy-icon" />
                                                <!-- <span>Auto</span> -->
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-0">
                                    <div class="policy-details">
                                        <h5>Customer ID <span> C17447</span></h5>
                                        <h5>Policy Number<span>POL/2023/AUTO/83485</span>  </h5>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-0">
                                    <div class="policy-details status">
                                        <h5>Renewal Status<span>Auto Renewed</span></h5>
                                        <h5>Renewal Date <span>20 Oct 2023 </span> </h5>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        <div class="col-md-6">
                            <div class="policy-coverages">
                                <h5>Policy Coverages</h5>
                                <div class="perils-list">
                                    <p class="peril accident active">
                                        <img src="assets/images/peril-accident.png" />
                                        <span>Accidental Damage</span>
                                    </p>
                                    <p class="peril accident active">
                                        <img src="assets/images/peril-theft.png" />
                                        <span>Theft</span>
                                    </p>
                                    <p class="peril accident active">
                                        <img src="assets/images/property-demage.png" />
                                        <span>Property Damage</span>
                                    </p>
                                    <p class="peril accident active">
                                        <img src="assets/images/peril-fire.png" />
                                        <span>Fire</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-md-3 mt-2">
                <div class="policy-rating">
                    <h5>Key Features</h5>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="policy-list">
                                <div class="perils-list pb-0">
                                    <p class="peril accident active">
                                        <img src="assets/images/Key Features.png" />
                                        <!-- <span>Key Features</span> -->
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <span class="badge badge-features">High income group
                            </span>

                            <span class="badge badge-features"> Homeowner
                            </span>

                            <span class="badge badge-features"> Affluent neighborhood
                            </span>

                          


                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="auto-personal-column">
                    <div class="auto-personal-item">
                        <h2 class="groupHeading">Group A</h2>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Head of household age</h4>
                            <div class="metric-val">51-65</div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Type of Property</h4>
                            <div class="metric-val">Single Family
                            </div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Household Income</h4>
                            <div class="metric-val">$250,000+ </div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Household Size</h4>
                            <div class="metric-val">2 Persons
                            </div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Home ownership</h4>
                            <div class="metric-val">Homeowner</div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Age of
                                Children
                            </h4>
                            <div class="metric-val">13-18</div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Renewal Probability</h4>
                            <div class="metric-val">67%
                            </div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">CSAT</h4>
                            <div class="metric-val">4</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row mt-2">

            <div class="col-md-3 pr-3">
                <div class="metric features chat-box">
                    <h4 class="groupHeading"><span><img src="assets/images/chat-bot.png" /></span>CSR in Call

                    </h4>
                    <ul class="reasons chat-content">
                        <li class="question"> Why is there a increase in premium as compared to previous one?

                        </li>
                        <li class="answer">
                            The change in billing amount is due to adding a new vehicle and adjusting the coverage for that vehicle. This expanded coverage increased premium, resulting in a higher invoice amount.

                        </li>
                            
                        <li class="question">Break down premium costs for each vehicle and coverage type?
                        </li>
                        <li class="answer">
                                Liability coverage for your existing vehicle (2020 Honda Civic) costs $500 for 6 months.<br>
                                Comprehensive and collision coverage for your new vehicle (2022 Toyota Camry) costs $700 for 6 months. <br>
                                Total Monthly Premium = $83.33 + $116.67 = $200 per month for both vehicles.

                        </li>
                                <!-- <li class="question"> Is he eligible for any discounts?
                                </li> -->

                    </ul>
                    <ul class="send-message">
                        <li >
                            <input type="text" placeholder="Start Typing Here..." class="form-control">
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-6">
                        <div class="metric features">
                            <h4 class="groupHeading"><span><img src="assets/images/Billing History.png" /></span>Billing
                                History/Policy/Claim Experience</h4>
                            <ul class="reasons">
                                <li>New vehicle added to the policy .</li>
                                <li>Change in comprehensive coverage for vehicle.</li>
                                <li>No claim History.</li>
                            </ul>
                        </div>
                    </div>
        
        
                    <div class="col-md-6 pr-3">
                        <div class="metric features">
                            <h4 class="groupHeading"><span><img src="assets/images/Segment Recommendation.png" /></span>Segment
                                Recommendations </h4>
                            <ul class="reasons">
                                <li>Clear and concise explanations for premium increase  and billing procedures.</li>
                               
                            </ul>
                        </div>
                    </div>
        
        
                    <div class="col-md-4">
                        <div class="metric features">
                            <h4 class="groupHeading"><span><img src="assets/images/Driver Profile.png" /></span>Driver Profile
                            </h4>
                            <ul class="reasons">
                                <li> Frequent long-distance driver</li>
                                <li>Demonstrates adherence to traffic rules, follows speed limits, and practices defensive
                                    driving.</li>
                            </ul>
                        </div>
                    </div>
        
                    <div class="col-md-4">
                        <div class="metric features">
                            <h4 class="groupHeading"><span><img src="assets/images/Cross-sell.png" /></span>Cross-sell/Upsell
                                Recommendations
        
                            </h4>
                            <ul class="reasons">
                                <li>Homeowner Policy.</li>
                                <li>Personal Injury Protection.</li>
                            </ul>
                        </div>
                    </div>
        
                    <div class="col-md-4 pr-3">
                        <div class="metric features">
                            <h4 class="groupHeading"><span><img src="assets/images/Customer Feedback.png" /></span>Customer
                                Feedback
                            </h4>
                            <ul class="reasons">
                                <li>My premium increased even though I've filed no claims. I plan to switch</li>
                            </ul>
                        </div>
                    </div>
        
                </div>
            </div>

          
        </div>


    </div>
</div>