
import { ActivatedRoute } from '@angular/router';
import { PricingService } from '../pricing.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PricingModelValidationService } from '../pricing-model-validation/pricing-model-validation.service'
import { ConfigService } from 'src/app/config.service';

@Component({
  selector: 'app-pricing-model-interpretability',
  templateUrl: './pricing-model-interpretability.component.html',
  styleUrls: ['./pricing-model-interpretability.component.scss']
})
export class PricingModelInterpretabilityComponent implements OnInit {
  breadcrumb: Array<any> = [];
  @Output() public onTabChangeEmit: EventEmitter<any> = new EventEmitter<any>();
  pricingModules: any;
  tabName: any;
  chartFilters: any;
  public lobProjectId: any;
  localStorage = localStorage;
  public modelTechnique = 1;
  public modelType = 1;
  impactChartFilters: any;

  constructor(
    private pricingModelValidationService: PricingModelValidationService,
    private pricingService: PricingService, private configService: ConfigService,
    private activatedRoute: ActivatedRoute) {
    this.pricingModules = this.pricingService.PRICING_MODULES;
  }

  ngOnInit(): void {
    this.pricingService.activePricingModule = 'INTER';
    Promise.all([this.localStorage.getItem('lobProjectId')]).then(values => {
      this.lobProjectId = values[0];
      const item = this.activatedRoute.snapshot.url[0].path.replace('-', ' ').replace(/^([a-z]|[A-Z])$/g, (c, i) => (i ? " " : "") + c.toUpperCase())
      // Adding Breadcrumb lavel 3
      this.pricingService.addBreadcrumbData({ "level": "3", "name": item }).subscribe(data => {
        this.breadcrumb = data;
        this.getFilterData(this.lobProjectId);
      });
    });
  }

  getActiveClass(activeModule) {
    if (this.pricingService.activePricingModule == activeModule) {
      return 'active show';
    } else {
      return '';
    }
  }

  getdata(value) {
    this.tabName = value;
    this.onTabChangeEmit.emit(value);
  }

  // Getting filter panel data and construct filter panel based on type 
  getFilterData(lobProjectId) {
    this.pricingModelValidationService.getFilterChartPanelData(lobProjectId).subscribe(data => {
      if (data) {
        this.pricingModelValidationService.formatFilterPanelData(data).subscribe((formattedResponse) => {
          this.chartFilters = formattedResponse;
        });
      }
    });
  }

  // Getting Impact filter data
  getImpactFilterData(lobProjectId) {
    const finalResponse = [] as any;
    this.pricingModelValidationService.getImpactChartFilterData(lobProjectId).subscribe(responseData => {
      if (responseData) {
        responseData.forEach((element, index) => {
          const formattedResponse = {
            ...element,
            title: index === 0 ? "Python" : "Model Types",
            filterType: index === 0 ? "modelTechniques" : "modelTypes",
            data: element.data.map((el, i) => {
              return {
                ...el,
                isSelected: i === 0 ? true : false
              }
            })
          }
          finalResponse.push(formattedResponse);
        });
      }
    });
    this.impactChartFilters = finalResponse;
  }
}

