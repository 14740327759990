<div class="full-screen">
  <div class="right-aligned-container">
    <div class="d-flex bottom-border">
      <div class="col-sm-10 p-0">
        <span class="header-text">Filters</span>
      </div>
      <div class="col-sm-2">
        <i class="material-icons close-icon" (click)="dismiss()">close</i>
      </div>
    </div>
    <div class="filter-panel-scroll-container">
      <ng-container *ngIf="filterData.length">
        <div class="row" *ngFor="let filterAttributes of filterData">
          <div class="col-sm-12" *ngIf="filterAttributes.name === 'ruleTypes'|| filterAttributes.name === 'lastModifiedBy'">
            <div class="form-group">
              <label class="form-label" for="fileTypes">{{filterAttributes.label}}</label>
              <select class="form-control" [(ngModel)]="filterAttributes.selectedId" [ngModelOptions]="{standalone: true}">
                <option *ngFor="let item of filterAttributes.data" [ngValue]="item?.id">{{item?.name}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label class="form-label" for="fileTypes">Last Modified Start Date</label>
              <!-- Date range picker -->
              <input type="text" class="form-control" name="daterangeInput" [(ngModel)]="selectedFilters.lastModifiedStart" daterangepicker [options]="options" (selected)="selectedDate($event, daterange)" />
            </div>
          </div>
        </div>
         
      </ng-container>
    </div>

    <div class="row">
      <div class="col-sm-12 text-center">
        <button class="btn btn-link reset-button" (click)="resetFilters()">Reset Filters</button>
      </div>
      <div class="col-sm-12">
        <button class="btn btn-primary btn-block no-border-radius" (click)="applyFilter()">SUBMIT</button>
      </div>
    </div>
  </div>
</div>