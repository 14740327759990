import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject, of, forkJoin } from 'rxjs';
const CHART_TMEPLATE_BASE_URL = "../../../../assets/data/pricing/";
const EXPODATA_CHART_TEMPLATE_JSON = 'liftChartTemplate.json';

@Injectable({
  providedIn: 'root'
})
export class PricingDataExplorationService {
  private updatedFilterValue: Subject<any> = new Subject<any>();
  private sharedFilterEvent: Subject<any> = new Subject<any>();
  updatedFilterValue$: Observable<any> = this.updatedFilterValue.asObservable();
  sharedFilterEvent$: Observable<any> = this.sharedFilterEvent.asObservable();

  constructor(private http: HttpClient) { }


  /**
   * sets filter chart template json
   */
  setUpdatedChartFilterData(updatedData) {
    this.updatedFilterValue.next(updatedData);
  }

  /**
   * Gets filter chart template json
   * @return updatedFilterValue data
   */
  getUpdatedChartFilterData(): Observable<any> {
    return this.updatedFilterValue.asObservable();
  }

  getFilterChartPanelData(lobProjectId): Observable<any> {
    return this.http.get<any>('pricing/allFilters/' + lobProjectId + '/');
  }
  getEDAChartTemplateJSON(): Observable<any[]> {
    return this.http.get<any>(`${CHART_TMEPLATE_BASE_URL}${EXPODATA_CHART_TEMPLATE_JSON}`);
  }

  formatFilterPanelData(data): Observable<any> {
    const chartFilters = [] as any;
    const chartFilterObj = {
      "title": {
        "name": "Model Techniques"
      },
      "filterType": "universal",
      "isMultySelect": true,
      "values": [
        {
          "name": "GLM",
          "isSelected": true
        },
        {
          "name": "XGB",
          "isSelected": true
        }
      ]
    };

    const res = data;
    Object.keys(data).forEach(key => {
      if (typeof (data[key]) === 'object') {
        const x = {
          ...chartFilterObj,
          title: {
            ...chartFilterObj.title,
            name: key.replace(/^([a-z]|[A-Z])$/g, (c, i) => (i ? " " : "") + c.toUpperCase())
          },
          filterType: key,
          values: res[key].map((item, index) => {
            return {
              ...item,
              isSelected: true
            }
          })
        }
        chartFilters.push(x);
      }
    })
    return of(chartFilters)
  }

  getLobModelValidationExpoDataChart(lobProjectId, modelType, exploratoryVariables): Observable<any> {
    return this.http.get<any>('EDA/' + lobProjectId + '/' + modelType + '/' + exploratoryVariables + '/');
  }

  formateEDAAPIData(chartSeriesData, chartTemplate): Observable<any> {
    const finalresponse = [] as any;
    chartSeriesData.forEach((element, index) => {
      const config = {
        ...chartTemplate,
        title: {
          ...chartTemplate.title,
          text: element.exploratoryVariable.replace(/(^|_)(\w)/g, function ($0, $1, $2) {
            return ($1 && ' ') + $2.toUpperCase()
          }) + ' ' + element.modelType,
        },
        xAxis: {
          ...chartTemplate.xAxis,
          categories: [...element.EDAData[0].data].map((e) => {
            if (typeof (e[0]) === 'string') {

              return e[0];
            }
          }),
          type: 'category'
        },
        yAxis: element.EDAData.map((el, index) => {
          return {
            type: 'logarithmic',
            opposite: index === 0 ? false : true,
            title: {
              text: el.name[0].toUpperCase() + el.name.substring(1),
              style: {
                color: index === 0 ? '#7cb5ec' : '#434348'
              }
            },
            labels: {
              format: '{value}',
              style: {
                color: index === 0 ? '#7cb5ec' : '#434348'
              }
            }
          }
        }),
        tooltip: {
          valueDecimals: 2,
        },
        series: element.EDAData.map((item, itemIndex) => {
          return {
            ...item,
            yAxis: itemIndex,
            type: itemIndex === 0 ? 'column' : 'spline',
            dashStyle: itemIndex === 0 ? 'column' : 'Dash',
            visible: true
          };
        })
      }
      finalresponse.push(config);
    });
    return of(finalresponse);
  }
}
