import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
const CHART_TMEPLATE_BASE_URL = "../../../../assets/data/notification/";
const EXPODATA_CHART_TEMPLATE_JSON = 'notification.json';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  log: any;
  private _notificationData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public notificationData: Observable<any> = this._notificationData.asObservable();

  constructor(private http: HttpClient) { }

  setNotification(): Observable<any[]> {
    return this.http.get<any>(`${CHART_TMEPLATE_BASE_URL}${EXPODATA_CHART_TEMPLATE_JSON}`);
  }

  getNotification(endPoint): Observable<any> {
    return this.http.get<any>(endPoint);
  }
  sendNotificationData(data) {
    this._notificationData.next(data);
  }
  readNotification(requestPayLoad, endPoint): Observable<any> {
    return this.http.put<any>(endPoint, requestPayLoad);
  }

  getReadableDate(date) {
    const year = date.toString().substring(0, 4);
    const month = date.toString().slice(-2);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months[Number(month) - 1] + '-' + year.slice(-2);
  }

}
