import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from 'src/app/config.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
const TOASTER_TIMEOUT = 30000;

@Injectable()
export class HttpInterceptorsInterceptor implements HttpInterceptor {

  constructor(public toastr: ToastrService, private config: ConfigService, private loader: NgxUiLoaderService, private modalService: NgbModal) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const idToken = localStorage.getItem('msal.idtoken');

    if (request.url.indexOf('/assets') === -1) {

      if (idToken) {
        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + idToken) });
      }
      if(request.url.indexOf('upload') !== -1 || request.url.indexOf('file') !== -1){
      request = request.clone({ headers: request.headers.set('enctype', 'multipart/form-data') });

      }
      else {
        request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });

      }
      request = request.clone({ url: this.config.getSettings('baseUrl') + `/${request.url}` });

    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.loader.stopAll();
        this.modalService.dismissAll();
        let data = {
        };
        data = {
          reason: error && error.error && error.error.reason ? error.error.reason : '',
          status: error.status
        };
        if (request.url.indexOf('/validateUser') === -1) {
          this.toastr.error(error.error.errorMessage ? error.error.errorMessage : error.error.message, '', { timeOut: TOASTER_TIMEOUT });
        }
        return throwError(error);

      }));
  }
}

