import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs/Rx';
import { ConfirmationPopupService } from '../../services/confirmation-popup.service';
import { FileUploadProgressService } from '../../services/file-upload-progress.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MetamorphCommonService } from '../../../module/metamorph/metamorph-base/services/metamorph-common.service';
import { event } from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'file-upload-progress',
  templateUrl: './file-upload-progress.component.html',
  styleUrls: ['./file-upload-progress.component.scss']
})
export class FileUploadProgressComponent implements OnInit {

  @Input() existingFile: any;
  @Input() isMappingFlow: any;
  @Input() operationalDetails: any;
  @Input() fileUploaderConfig: any;
  @Input() existingFileInfo: any;
  @Output() fileUploaded = new EventEmitter<any>();
  // @Output() daterangeStart = new EventEmitter<any>();
  uploadedFile: Array<any> = [];
  width: any = 0;
  uploadStarted: boolean = false;
  uploadProcessCompleted: boolean = false;
  progress: number = 0;
  public fileData = {
    sheetInfo: [],
    workItemId: ''
  };
  processId: any;
  operationalData: any;
  yearMonth: any;
  // filePath = 'standardization';
  step = 'readyToUpload';
  fileUploadData: any;
  progressSteps = [
    { stepName: 'Upload', status: 'completed' },
    { stepName: 'Mapping', status: 'active' },
    { stepName: 'Review', status: '' },
    { stepName: 'Complete', status: '' },
  ]
  destinationTemplateData: any;
  sheetMappingSucessResponeData: any;
  existingSheetMappingData: any;
  daterangeStart = "";
  daterangeEnd = "";
  subFormat: any;
  public daterange: any = {};
  uploadId: any;
  workItemId: any;
  uploadPostApiPayload: any
  readyToUploadSubmit: boolean = false;
  submittedFile: boolean = false;
  fileNotSupported: boolean = false;
  rejectedFileSize: string = '';
  rejectedFileName: string = '';
  constructor(public metamorphCommonService: MetamorphCommonService, public fileUploadProgressService: FileUploadProgressService, private confirmationPopupService: ConfirmationPopupService
    , private ngxLoader: NgxUiLoaderService, private activeModal: NgbActiveModal, public toastr: ToastrService) { }

  ngOnInit(): void {
    this.daterangeStart = this.operationalDetails?.aditionalConfig?.reportingPeriodStartDate;
    this.daterangeEnd = this.operationalDetails?.aditionalConfig?.reportingPeriodEndDate;
    // If exisingFileInfo found then get the sheet info of the file by upload ID
    if (this.existingFileInfo && this.existingFileInfo?.uploadId && this.existingFileInfo?.step, this.existingFileInfo?.workItemId) {
      this.ngxLoader.start()
      this.fileUploadProgressService.getSheetInfoByUploadId(this.existingFileInfo.uploadId).subscribe(sheetInfoResponse => {
        if (sheetInfoResponse && sheetInfoResponse.code === 200) {
          this.fileData.sheetInfo = sheetInfoResponse.data;
          this.ngxLoader.stopAll();
          this.uploadId = this.existingFileInfo.uploadId;
          this.step = this.existingFileInfo.step;
          this.workItemId = this.existingFileInfo.workItemId;
          this.uploadProcessCompleted = true;
        }
      });
      this.uploadStarted = true;
    }
    this.operationalData = this.operationalDetails?.info
    const operationalDataFromLocalStorage = JSON.parse(
      this.metamorphCommonService.getOperationalInfoData()
    );
    if (this.operationalData) {
        this.operationalData = this.operationalData;
        this.subFormat = this.operationalData.filter(function (el) {
          return el.label == 'Standard';
        })[0].value;
        this.processId = operationalDataFromLocalStorage.filter(function (el) {
          return el.label == 'Process Id';
        })[0].value;
        this.yearMonth = operationalDataFromLocalStorage.filter(function (el) {
          return el.label == 'Execution Month';
        })[0].value;
      }

      // Close file uploader on browser back button
      fromEvent(window, 'popstate').subscribe(()=>{
        this.exit()
      })

  }

  // canceling upload and returning back to base screen
  public decline() {
    this.metamorphCommonService.triggerFileUploadEvent(null);
    this.activeModal.close(false);
  }

  /**
   * Process Upload
   * On submit Upload Post API will be called and ttep will be changed to 'sheet-mapping' user will be redirected to the sheet mapping screen
   */
  public submitUpload() {
    this.ngxLoader.start();
    this.fileUploadProgressService.uploadProcess(this.uploadPostApiPayload,  this.fileUploaderConfig.filePath).subscribe(response => {
      if (response && response.code === 200) {
        this.fileUploadProgressService.getSheetInfoByUploadId(this.uploadPostApiPayload.uploadId).subscribe(sheetInfoResponse => {
          if (sheetInfoResponse && sheetInfoResponse.code === 200) {
            this.uploadId = this.uploadPostApiPayload.uploadId
            this.fileData.sheetInfo = sheetInfoResponse.data;
            this.workItemId = response.data.workItemId;
            this.uploadProcessCompleted = true
            this.fileUploadData = response;
            this.submittedFile = true;
            if(this.fileUploaderConfig.processFile) {
              this.step = 'sheet-mapping'
            } else {
              this.exit();
            }
          }
        });
      }
    })
  }

  goToNext() {
    this.step = 'sheet-mapping'
  }

  // file selection started
  onSelect(event) {
    if (!event.addedFiles.length) {
      this.rejectedFileName = event.rejectedFiles[0].name;
      this.rejectedFileSize = this.fileUploadProgressService.getFileSize(event.rejectedFiles[0].size) +' ' + this.fileUploadProgressService.getFileSizeUnit(event.rejectedFiles[0].size)
      this.fileNotSupported = true
    } else {
    this.fileNotSupported = false;
    this.uploadStarted = true;
    this.uploadedFile = [];
    const files = event.addedFiles;
    this.step = 'upload-progress'
    this.processFiles(files);
    }
  }

  // processing file before starting upload and displaying progress bar
  processFiles(files) {
      for (const file of files) {
        var reader = new FileReader();
        reader.readAsDataURL(file); // read file as data url
        reader.onload = (event: any) => {
          // called once readAsDataURL is completed
          this.progress = 0;
          this.uploadedFile.push({
            FileName: file.name,
            FileSize:
              this.fileUploadProgressService.getFileSize(file.size) +
              ' ' +
              this.fileUploadProgressService.getFileSizeUnit(file.size),
            FileType: file.type,
            FileUrl: event.target.result,
            FileProgessSize: 0,
            FileProgress: 0,
            ngUnsubscribe: new Subject<any>(),
          });
          this.startProgress(file, this.uploadedFile.length - 1);
        };
      }
  }

  // After processing file upload will be started and file upload service upload the file to backend
  async startProgress(file, index) {
    this.uploadStarted = true;
    // this.ngxLoader.stop()
    let filteredFile = this.uploadedFile.filter((u, index) => index === index).pop();
    if (filteredFile != null) {
      let fileSize = this.fileUploadProgressService.getFileSize(file.size);
      let fileSizeInWords = this.fileUploadProgressService.getFileSizeUnit(file.size);
      if(fileSize > 5000) {
        this.toastr.error('Please upload file up to 5MB of size')
        this.activeModal.close()
      } else {
        if (this.fileUploadProgressService.isApiSetup) {
          let formData = new FormData();
          formData.append('file', file);
          this.fileUploadProgressService
            .uploadMedia(formData, this.fileUploaderConfig.filePath).pipe().subscribe(
              (event: HttpEvent<any>) => {
                switch (event.type) {
                  case HttpEventType.Sent:
                    this.progress = 0;
                    break;
                  case HttpEventType.ResponseHeader:
                    break;
                  case HttpEventType.UploadProgress:
                    this.progress = Math.round(event.loaded / event.total * 100);
                    filteredFile.FileProgessSize = `${((fileSize * this.progress) / 100).toFixed(2)} ${fileSizeInWords}`;
                    this.fakeWaiter(Math.floor(Math.random() * 35) + 1);
                    break;
                  case HttpEventType.Response:
                    if (event.body.code === 200) {
                      this.readyToUploadSubmit = true
                      let currentStatus = ''
                      if(this.fileUploaderConfig.filePath === 'standardization') {
                        currentStatus = 'Data uploaded'
                      } else if ( this.fileUploaderConfig.filePath === 'validation') {
                        currentStatus = 'Ready for Validation'
                      } else if ( this.fileUploaderConfig.filePath === 'validation' && this.operationalDetails?.aditionalConfig?.titleText === 'Upload Corrected Data') {
                        currentStatus = 'Corrected Data Uploaded'
                      }
                      // metamorph/upload post API will be called here
                      this.uploadPostApiPayload = {
                        uploadId: event.body.data.uploadId,
                        inputFileId: event.body.data.fileId,
                        processId: this.processId,
                        coreProcess: this.fileUploaderConfig.filePath,
                        currentStatus: currentStatus,
                        ingestionType: "manual",
                        subFormat: this.subFormat,
                        yearMonth: this.yearMonth,
                        reportStartDate: this.daterangeStart,
                        reportEndDate: this.daterangeEnd
                      }
                      if ( this.operationalDetails?.aditionalConfig?.titleText === 'Upload Corrected Data' ) {
                        this.uploadPostApiPayload['version'] =  this.operationalDetails?.aditionalConfig?.versionInfo;
                        this.uploadPostApiPayload['currentStatus'] = 'Corrected Data Uploaded';
                        this.uploadPostApiPayload['ruleList'] = JSON.parse(this.operationalDetails?.aditionalConfig?.ruleList);
                      }
                    }
                }
              }
            );
        } else {
          for (var f = 0; f < fileSize + fileSize * 0.0001; f += fileSize * 0.01) {
            filteredFile.FileProgessSize = f.toFixed(2) + ' ' + fileSizeInWords;
            var percentUploaded = Math.round((f / fileSize) * 100);
            filteredFile.FileProgress = percentUploaded;
            await this.fakeWaiter(Math.floor(Math.random() * 35) + 1);
          }
        }
      }
    }
  }

  fakeWaiter(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  // clearing NGX dropzone
  removeFile() {
    this.uploadedFile = [];
    this.uploadStarted = false;
    this.readyToUploadSubmit = false;
    this.fileUploaded.next({ id: '' })
  }

  // Options for DateRangePicker
  public options: any = {
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
    opens: 'right'
  };

  // getting date range selected and assigning formatted startDate/endDate to global variable
  public selectedDate(value: any, datepicker?: any) {
    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;
    // use passed valuable to update state
    this.daterange.start = value.start;
    this.daterange.end = value.end;
    this.daterange.label = value.label;
    this.daterangeStart = new Date(new Date(this.daterange.start).toString().split('GMT')[0] + ' UTC').toISOString();
    this.daterangeEnd = value.end
  }

  // handeling next button click event
  handleNextEvent(event) {
    if (event === 'sheet-mapping') {
      this.step = 'header-range-selection'
    } else if (event === 'header-range-selection') {
      this.step = 'column-mapping'
    } else if (event === 'column-mapping') {
      this.step = 'mapped-column-preview'
      this.progressSteps[1].status = 'completed'
      this.progressSteps[2].status = 'active'
    } else if (event === 'mapped-column-preview') {
      this.progressSteps[2].status = 'completed'
      this.progressSteps[3].status = 'active'
    }
  }

  // handeling back button click event
  handleBackEvent(event) {
    if (event === 'mapped-column-preview') {
      this.step = 'column-mapping'
      this.progressSteps[1].status = 'active'
      this.progressSteps[2].status = ''
    } else if (event === 'column-mapping') {
      this.progressSteps[0].status = 'completed'
      this.progressSteps[1].status = 'active'
      this.step = 'header-range-selection'
    } else if (event === 'header-range-selection') {
      this.step = 'sheet-mapping'
    } else if (event === 'sheet-mapping') {
      this.step = 'upload-progress'
    }
  }

  fileAutoSubmitted(event) {
    if (event === 'submitted') {
      this.activeModal.close(true);
      this.metamorphCommonService.triggerFileUploadEvent(null);
    }
  }

  setDestinationTemplateData(event) {
    this.destinationTemplateData = event;
  }
  sheetMappingSucessRespone(event) {
    this.sheetMappingSucessResponeData = event
  }

  captureSheetMappingData(event) {
    this.existingSheetMappingData = event;
  }

  exit() {
    this.metamorphCommonService.triggerFileUploadEvent(null);
    this.activeModal.close(true);
  }

  handleExitEvent(event) {
    this.exit()
  }

}
