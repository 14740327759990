import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserServiceService } from '../../shared/services/user-service.service';
import { UserAddEditService } from '../admin/user-add-edit/user-add-edit.service';
import { ProfileService } from './profile.service';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  userdata: any = {};
  editable: boolean = true;
  fileUploadCheck: boolean = false;
  userRole: any = [];
  defaultrolename: any = '';
  otherrolename: any = '';
  userData: any = [];
  selectedFile: File = null as any;
  public isVisible: boolean = false;
  roleListnew: any = [];
  userDataView = [
    {
      firstName: '',
      lastName: '',
      email: '',
      userName: '',
      userImage: '',
      user_roles: '',
      roleIdArray: '',
      defaultRoleId: ''
    },
  ];
  roleList: any = [];
  submitted = false;
  title = 'project';
  registerForm = new FormGroup({
    userName: new FormControl(''),
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    email: new FormControl(''),
    role: new FormControl(''),
    photo: new FormControl(''),
    imgsrc: new FormControl(''),
  });
  isRemovedProfilePhoto: boolean = false;
  imgsrc: any = '';
  userImage: any;
  userProfileName: string;
  userProfileImage: any;

  constructor(
      private createUserService: UserAddEditService,
      private formBuilder: FormBuilder,
      private activeModal: NgbActiveModal,
      public toastr: ToastrService,
      private userSharedService: UserServiceService,
      private profileService: ProfileService) { }
  ngOnInit(): void {
    this.imgsrc = this.profileService.getDefaultProfileImage();
    this.editUser();
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      userName: ['', [Validators.required]],
    });
    this.getRoles();
  }
  /***
   * @desc Fetch Roles mapped to User and initiate roleList with fetched Object
   * 
   */
  getRoles() {
    this.createUserService.getRoles().subscribe(roleObj => {
      const rolelistnew: any = roleObj;
      const obj = [] as any;
      for (var i = 0; i < rolelistnew.data.length; i++) {
        var dname = [] as any;
        dname["id"] = rolelistnew.data[i].id;
        dname["roleName"] = rolelistnew.data[i].title;
        obj.push(dname);
      }
      this.roleList = obj;
    });
  }
  get f() { return this.registerForm.controls; }
  /***
  * @desc Fetching User Details for local storage user object and passing to edit user profile html
  * 
  */
  editUser() {   
    let imgsrc: any = '';
    this.userDataView = [];
    this.userDataView.push(this.userSharedService.getUser());
    let rolearray: any = this.userDataView[0].user_roles;
    let roleArrayfinal: any = [];
    var drole: any = '';
    var orole: any = [];
    this.isRemovedProfilePhoto = false;
    for (var j = 0; j < rolearray.length; j++) {
      if (rolearray[j].roles[0].roleId != this.userDataView[0].defaultRoleId) {
        orole.push(rolearray[j].roles[0].title);
      }
      if (rolearray[j].roles[0].roleId == this.userDataView[0].defaultRoleId) {
        drole = rolearray[j].roles[0].title;
      }
    }
    this.defaultrolename = drole;
    this.otherrolename = orole;
    if (this.userDataView[0].userImage) {
      this.userProfileImage = this.userDataView[0].userImage;
      this.imgsrc = this.userDataView[0].userImage;
      this.fileUploadCheck = true;
    }
    else { 
      this.userProfileImage = "";
      this.fileUploadCheck = false;
     }    
    this.userProfileName =  this.userDataView[0].firstName+" "+ this.userDataView[0].lastName;
     }
   /***
   * @desc Fetching Edit User Form details and passing to Edit User Function
   * @param {Object} Module userDetails
   * 
   */
  onSubmitEdit(userDetails) {
    this.submitted = true;
    if (this.registerForm.invalid) {
      this.toastr.error('Please Fill All Register Field')
    } else {
      this.registerForm.value.imgsrc = this.imgsrc;
      this.userdata = this.registerForm.value;
      this.userdata.status = 1;
      
      this.userdata.id = userDetails.id;
      if (this.fileUploadCheck == false || this.isRemovedProfilePhoto == true) {
        this.userdata.userImage = '';
      } else {
        this.userdata.userImage = this.userdata.imgsrc;
      }
      delete this.userdata["role"];
      delete this.userdata["imgsrc"];
      this.editUserAPI(this.userdata);
    }
  }
  /***
   * @desc Function to create object from uploaded files
   * 
   */
  fileChange(e: any) {
    this.readThis(e.target);
    const file = e.srcElement.files[0];
    this.fileUploadCheck = true;
    this.imgsrc = window.URL.createObjectURL(file);
    this.isRemovedProfilePhoto = false;
    this.userProfileImage=true;
  }
  /***
   * @desc Function to read files content
   * 
   */
  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.imgsrc = myReader.result;
    };
    myReader.readAsDataURL(file);
  }
  /***
   * @desc Function to call edit User API with edit form details
   * @param {Object} Module userObj
   * 
   */
  editUserAPI(userObj) {
    this.createUserService.editUserStatus(userObj).subscribe((responseObj) => {
        this.getUserDetails();
        this.toastr.success('User Edited, logging out...');
        this.activeModal.close(true);
      });
  }
  /***
   * @desc Function to route to User list if clicked cancel
   * 
   */
  onReset() {
    this.activeModal.close(false);
  }
  /***
   * @desc Function to call validate user api for modifying local storage user object after editing user profile.
   * 
   */
  async getUserDetails() {
    this.userSharedService.validateUser().subscribe(userobj => {
      this.userData = userobj.data;
      if (this.userData) {
        let rolenamex = this.userSharedService.getrolename(this.userData.user_roles, this.userData.defaultRoleId);
        this.userData.defaultrolename = rolenamex;
        this.userSharedService.setUser(this.userData);
      }
    });
  }

  /***
   *  @desc  Remove Profile Photo uploaded by user and display default profile photo
   */
  removeProfilePhoto() {
    this.isRemovedProfilePhoto = true;
    this.imgsrc = this.profileService.getDefaultProfileImage();
  }
}
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }
    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}
