import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UploadFileComponent } from './component/upload-file/upload-file.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ModalComponent } from './component/modal/modal.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { LoaderModalComponent } from './component/loader-modal/loader-modal.component';
import { ChartsComponent } from './component/charts/charts.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { GenericChartComponent } from './component/generic-chart/generic-chart.component';
import { ConfirmationPopupComponent } from './component/confirmation-popup/confirmation-popup.component';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { PositiveAndNegativeNumbersDirective } from './directives/positive-and-negative-numbers.directive';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { FileUploadProgressComponent } from './component/file-upload-progress/file-upload-progress.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReadExcelDirective } from './directives/read-excel.directive';
import { FileProgressBreadcrumbComponent } from './component/file-upload-progress/file-progress-breadcrumb/file-progress-breadcrumb.component';
import { SheetMappingComponent } from './component/file-upload-progress/sheet-mapping/sheet-mapping.component';
import { SelectHeaderRangeComponent } from './component/file-upload-progress/select-header-range/select-header-range.component';
import { ColumnMappingComponent } from './component/file-upload-progress/column-mapping/column-mapping.component';
import { SelectFieldPopupComponent } from './component/file-upload-progress/select-field-popup/select-field-popup.component';
import { FileUploadReviewComponent } from './component/file-upload-progress/file-upload-review/file-upload-review.component';
import { InfoContainerComponent } from './component/file-upload-progress/info-container/info-container.component';
import { MappingConfirmationPopupComponent } from './component/file-upload-progress/mapping-confirmation-popup/mapping-confirmation-popup.component';
import { Daterangepicker } from 'ng2-daterangepicker';
import { CustomdateformatPipe } from '../module/metamorph/metamorph-base/customdateformat.pipe';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { RuleBuilderComponent } from './component/rule-builder/rule-builder.component';
import { CoreModule } from "../core/core.module";

@NgModule({
    declarations: [
        ModalComponent,
        UploadFileComponent,
        LoaderModalComponent,
        ChartsComponent,
        GenericChartComponent,
        ConfirmationPopupComponent,
        OnlyNumberDirective,
        PositiveAndNegativeNumbersDirective,
        FileUploadProgressComponent,
        ReadExcelDirective, FileProgressBreadcrumbComponent,
        SheetMappingComponent,
        SelectHeaderRangeComponent,
        ColumnMappingComponent,
        SelectFieldPopupComponent,
        FileUploadReviewComponent,
        InfoContainerComponent,
        MappingConfirmationPopupComponent,
        CustomdateformatPipe,
        RuleBuilderComponent
    ],
    exports: [
        ModalComponent,
        UploadFileComponent,
        ChartsComponent,
        GenericChartComponent,
        ConfirmationPopupComponent,
        OnlyNumberDirective,
        PositiveAndNegativeNumbersDirective,
        FormsModule,
        ReactiveFormsModule,
        DataTablesModule,
        FileUploadProgressComponent,
        ReadExcelDirective,
        CustomdateformatPipe
    ],
    bootstrap: [],
    imports: [
        CommonModule,
        NgbModule,
        NgSelectModule,
        NgxDropzoneModule,
        NgxUiLoaderModule,
        HighchartsChartModule,
        FormsModule,
        ReactiveFormsModule,
        DataTablesModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            preventDuplicates: true,
            closeButton: true
        }),
        Daterangepicker,
        NgbPopoverModule,
        CoreModule
    ]
})
export class SharedModule { }
