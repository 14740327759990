import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmationPopupComponent } from '../component/confirmation-popup/confirmation-popup.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationPopupService {

  constructor(private modalService: NgbModal) { }

  public confirm(
    title: string,
    message: string,
    discription:string,
    btnOkText: string,
    btnCancelText: string): Promise<boolean> {
    //For installing see: https://ng-bootstrap.github.io/#/getting-started
    //For all options see: https://ng-bootstrap.github.io/#/components/modal/api
    const modalRef = this.modalService.open(ConfirmationPopupComponent, { centered: true });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.discription = discription;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

}