import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PricingProjectService } from './pricing-project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PricingService } from '../pricing.service';
import { ConfigService } from 'src/app/config.service';

@Component({
  selector: 'app-pricing-project',
  templateUrl: './pricing-project.component.html',
  styleUrls: ['./pricing-project.component.scss']
})
export class PricingProjectComponent implements OnInit, OnDestroy {
  heads: any = [];
  policyList: any;
  policyData: any = [];
  policyListData: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  pricingModules: any;
  breadcrumb: Array<any> = [];
  localStorage = localStorage;

  constructor(private pricingProjectService: PricingProjectService, private ngxLoader: NgxUiLoaderService, private activatedroute: ActivatedRoute, private router: Router,
    private pricingService: PricingService, private configService: ConfigService) { }

  ngOnInit(): void {
    this.pricingService.activePricingModule = 'PROJECTS';
    // Adding lavel 1 breadcrumb
    this.pricingService.addBreadcrumbData({ "level": "1", "name": "" }).subscribe(data => {
      this.breadcrumb = data;
    })
    this.heads = this.pricingProjectService.taskListHead();
    let lobId = this.pricingService.getLobId();
    this.getPricingList(lobId);
    this.pricingModules = this.pricingService.PRICING_MODULES;
  }
  /***
* @desc Price project list table data and pagination 
*/
  getPricingList(lobId) {
    this.ngxLoader.start();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [10, 20, 30],
      processing: true,
      columnDefs: [
        {
          targets: [5],
          orderable: false
        }]
    }
    this.pricingProjectService.getPricingList(lobId).subscribe((policyObj) => {
      if (policyObj) {
        this.policyList = policyObj;
        this.policyListData = this.policyList.data;
        this.policyData = this.policyListData;
        this.dtTrigger.next();
        this.ngxLoader.stop();
      } else { this.policyData = []; this.ngxLoader.stop(); }
    });
  }

  /***
   * @desc Data Table Destory function
   */
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  navigateToModel(policy) {
    this.localStorage.setItem('lobProjectId', policy.projectId);
    this.pricingService.addBreadcrumbData({ "level": "2", "name": policy.coverageType }).subscribe(data => {
      this.breadcrumb = data;
    })
    this.localStorage.setItem('selectedModel', policy.model)
    this.router.navigate(['/pricing/model-manage']);
    this.pricingService.activePricingModule = this.pricingModules.MANAGE;
    this.pricingService.sendNavClickEvent("NEXT", this.pricingModules.MANAGE);
  }
}