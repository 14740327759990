import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { ExportToExcelService } from 'src/app/shared/services/export-to-excel.service';
import { ScmMmmService } from '../../scm-mmm.service';


@Component({
  selector: 'app-scm-simulate',
  templateUrl: './scm-simulate.component.html',
  styleUrls: ['./scm-simulate.component.scss']
})
export class ScmSimulateComponent implements OnInit {
  simulateListData: Array<any> = [];
  simulateListDataBuffer: string;
  @Input() scenarioList: any;
  @Input() scmBrandList: any;
  @Input() selectedScenarioId: any;
  @Input() selectedBrandId: any;
  @Input() evaluateSimulateEvents: any;
  @Input() changeTypeEvents: any;
  @Input() exportSimulateEvents: any;
  @Input() saveSimulateEvents: any;
  @Input() deleteSimulateEvents: any;
  @Input() tabChangeEvent: any;
  @Input() changeScenerioSimulateEvents: any;
  @Input() selectedScenarioType: any;
  @Input() selectedChangeType: any;
  @Output() newSimulateScenarioAdded = new EventEmitter<any>();
  @Output() scenarioDeleted = new EventEmitter<any>();
  selectedTab: any;
  localStorage = localStorage
  public updateFlag: boolean = false;
  public saveEventsSubscription: Subscription;
  public simulateEventSubscription: Subscription;
  public deleteSimulateEventsSubscription: Subscription;
  public exportSimulateToExcelEventsSubscription: Subscription;
  public tabChangeSubscription: Subscription;
  public changeScenerioSimulatSubscription: Subscription;
  public changeTypeSubscription: Subscription;
  public newScenarioData: Array<any> = [];

  public selectedSenarioData: any;
  public invalidInput: boolean = false;
  public mediaResponseChartTemplate: any;
  public incrementalSaleChartTemplate: any;
  public roiMacChartTemplate: any;
  public shareSpendChartTemplate: any;
  public recentChangeIndex: any;
  public mediaResponseDataResponse: any;
  public showOptimizeChart: boolean;
  public updatedChangeSpendBy: Array<any> = [];
  constructor(private scmMmmService: ScmMmmService, public toastr: ToastrService,
    public confirmationPopupService: ConfirmationPopupService,
    private exportToExcelService: ExportToExcelService) { }

  ngOnInit(): void {
    // Subscribing save scenario event
    this.saveEventsSubscription = this.saveSimulateEvents.subscribe((brandName) => {
      this.saveScenario(brandName)
    });
    // Subscribing Sumulate Action event
    this.simulateEventSubscription = this.evaluateSimulateEvents.subscribe(data => {
      if (data !== null) {
        if (this.simulateListDataBuffer === JSON.stringify(this.simulateListData)) {
          this.toastr.error('Please make change to perform simulation!')
        } else {
          if (this.invalidInput) {
            this.toastr.error("Please use valid input!")
          } else {
            this.performSimulateAction()
          }
        }
      }
    });
    // Subscribing delete scenario event
    this.deleteSimulateEventsSubscription = this.deleteSimulateEvents.subscribe(() => {
      this.deleteScenario()
    });
    // Subscribing expoert to excel event
    this.exportSimulateToExcelEventsSubscription = this.exportSimulateEvents.subscribe(() => {
      this.exportSimulateScenarioTable()
    });
    // subscribing scenario change event
    this.changeScenerioSimulatSubscription = this.changeScenerioSimulateEvents.subscribe((data) => {
      if (data) {
        this.selectedScenarioId = data.selectedScenarioId;
        this.selectedScenarioType = data.selectedScenarioType;
        if (this.selectedBrandId !== '99') {
          this.scmMmmService.openOverlay();
          this.getSimulateDataAndChartTemplate()
        } else {
          this.scmMmmService.openOverlay();
          this.getSimulateDataForPortfolio();
        }
      }
    })
    //  Subscribing Sumulate Type change event
    this.changeTypeSubscription = this.changeTypeEvents.subscribe((change) => {
      if (change !== null) {
        this.selectedChangeType = change;
        this.simulateListData.forEach(e => {
          if(e.changeSpendBy) {
            e.changeSpendBy = 0;
          }
        });
      }
    })
    // Subscribing tab change event
    this.tabChangeSubscription = this.tabChangeEvent.subscribe(data => {
      if (data !== null) {
        this.scmMmmService.getGoodToGo().subscribe(status => {
          if (status === 'YES') {
            this.selectedTab = data.selectedTab;
            this.selectedScenarioId = data.selectedScenarioId;
            this.selectedScenarioType = data.selectedScenarioType;
            if (data.selectedTab === 'scm-simulate') {
              this.scmMmmService.openOverlay();
              this.getSimulateDataAndChartTemplate();
            }
          }
        })
      }
    });
  }


  ngOnDestroy() {
    this.saveEventsSubscription.unsubscribe();
    this.deleteSimulateEventsSubscription.unsubscribe();
    this.evaluateSimulateEvents.unsubscribe();
    this.exportSimulateToExcelEventsSubscription.unsubscribe();
    this.tabChangeSubscription.unsubscribe();
    this.changeScenerioSimulatSubscription.unsubscribe();
    this.changeTypeSubscription.unsubscribe();
  }

  // Chart and Table data fetch call
  /***
   * @desc Function to Get Simulate Table and Chart using API call
   * Get API to do API call for getting Simulate Table and Charts Data
   * @returns {Objects} Returns API response and does a function call which takes Table and chart data as parameters
   *
   */
  getSimulateDataAndChartTemplate() {
    this.simulateListData = [];
    this.simulateListDataBuffer = '';
    // Get API data
    this.scmMmmService.getSumulateData(this.selectedBrandId, this.selectedScenarioId.toString(), this.selectedScenarioType).subscribe(response => {
      if (response && response.code === 200) {
        this.selectedSenarioData = response.data;
        this.selectedChangeType = response.data.simulateType;
        const parentSpendLeverId = this.selectedSenarioData.scenario[0].scenarioData.filter(e => { return e.spendLeverType === 'Media' && e.isParent })[0].spendLeverId;
        const mediaSpendLeverIdList = this.selectedSenarioData.scenario[0].scenarioData.filter(e => { return e.spendLeverType === 'Media' }).map(el => { return el.spendLeverId }).join();
        this.scmMmmService.getMediaResponseCurveData(this.selectedBrandId, parentSpendLeverId, mediaSpendLeverIdList).subscribe((mediaResponseData) => {
          if (mediaResponseData && mediaResponseData.code === 200) {
            this.mediaResponseDataResponse = mediaResponseData.data.data;
            this.distributeDataAmongChartsAndTable(this.selectedSenarioData, this.mediaResponseDataResponse.reverse());
            this.scmMmmService.closeModel();
          }
        })
      } else {
        this.selectedSenarioData = [];
        this.mediaResponseDataResponse = {}
        this.scmMmmService.closeModel();
      }
    })
  }

  // Chart and Table data fetch call
  /***
   * @desc Function to Get Simulate Table data for Portfolio using API call
   * Get API to do API call for getting Simulate Table and Charts Data
   * @returns {Objects} Returns API response which contains Table data for portfolio
   *
   */
  getSimulateDataForPortfolio() {
    this.simulateListData = [];
    this.simulateListDataBuffer = '';
    // Get API data
    this.scmMmmService.getPortfolioSimulateData(this.selectedBrandId, this.selectedScenarioId.toString(), this.selectedScenarioType).subscribe(response => {
      if (response && response.code === 200) {
        let modifiedList = []
        if(this.selectedBrandId === '99') {
          const brands =  this.scmBrandList.filter(e => e.brandId !== this.selectedBrandId);
          brands.forEach(element => {
            modifiedList.push({
              ...element,
              showBrandNameOnly: true
            });
            modifiedList.push(...response.data.scenario[0].scenarioData.filter(e => e.brandId === element.brandId))
          });

          response.data.scenario[0].scenarioData.forEach(element => {
            if(!element.hasOwnProperty('brandId')) {
              modifiedList.push(element);
            }
          })
        }
        this.simulateListData = modifiedList;
        this.simulateListDataBuffer = JSON.stringify(this.simulateListData);
        this.scmMmmService.closeModel();
      } else {
        this.simulateListData = [];
        this.simulateListDataBuffer = '';
        this.scmMmmService.closeModel();
      }
    })
  }

  // Distribute Data in tables and Charts
  /***
  * @desc Function to modify API response and plot it on table and charts
  * @param {Array, Object}
  * Call service to get static chart config JSON and construct final chart Config with data
  * @returns {Array,Object} Returns formatted Array to display data on Table and Chart config to display data.
  *
  */
  distributeDataAmongChartsAndTable(selectedSenarioData, mediaResponseCurvedata) {
    this.scmMmmService.getChartTemplateJSON().subscribe(chartTemplates => {
      // Get All the chart templates
      this.incrementalSaleChartTemplate = chartTemplates[0];
      this.roiMacChartTemplate = chartTemplates[2];
      this.shareSpendChartTemplate = chartTemplates[3];
      this.simulateListData = selectedSenarioData.scenario[0].scenarioData;
      this.simulateListDataBuffer = JSON.stringify(this.simulateListData);

      // Setting series data into the chart
      this.mediaResponseChartTemplate = this.scmMmmService.formatMediaResponseChartData(mediaResponseCurvedata, chartTemplates[1]);
      // format and inject data for incremental sales chart data
      this.incrementalSaleChartTemplate = this.scmMmmService.formatIncrementalSalesTemplate(this.incrementalSaleChartTemplate, selectedSenarioData.scenario[0].chartData[0].data)
      // ROI Mac Chart configuration
      this.roiMacChartTemplate = this.scmMmmService.getRioMacChartOptionConfigured(this.roiMacChartTemplate, selectedSenarioData.scenario[0].chartData[1]);
      // Share of Spend Chart configuration
      this.shareSpendChartTemplate = this.scmMmmService.getShareOfSpendFormatted(this.shareSpendChartTemplate, selectedSenarioData.scenario[0].chartData[2])
      this.showOptimizeChart = true
      this.scmMmmService.closeModel();
    });
  }

  // Simulate Action
  /***
  * @desc Function to perform Simulate Action using API call
  * @param {Integer} Module changedBudget
  * Based on the condition it created simulate Action API payload and does the service call
  * @returns {function} Returns a function which takes Simulate Action API response as parameters
  *
  */
  performSimulateAction() {
    this.scmMmmService.openOverlay();
    // constructing Payload for Simulate Action Post API
    // keeping current year as 2020 because we have data for 2020 only , needs to be changed once data is available for current year
    const simulateActionPayload = {
      "brandId": this.selectedBrandId,
      "scenarioId": this.selectedScenarioId,
      "scenarioType": this.selectedScenarioType,
      "year": 2020, // keeping current year as 2020 because we have data for 2020 only
      "changeType": this.selectedChangeType === 'spend_per' ? '%' : '$',
      "optimalRange": this.simulateListData.slice(1).slice(0, -2).map(e => { return { "spendLeverId": Number(e.spendLeverId), "changeSpendBy": e.changeSpendBy ? Number(e.changeSpendBy) : 0 } })
    }
    // this.simulateListData = [];
    this.scmMmmService.performSimulateAction([simulateActionPayload]).subscribe(simulatedData => {
      this.newScenarioData = simulatedData.data;
      this.scmMmmService.setPendingStatus('YES');
      this.distributeDataAmongChartsAndTable(this.newScenarioData, this.mediaResponseDataResponse)
    });

  }

  // Save custom scenarios
  /***
   * @desc Function to Save Scenario
   * @param {Array} API Payload
   * Opens scenario name popup and gets the scenario name to construct final API payload Array to save scenario
   * @returns {Object} Returns API response on success.
  */
  saveScenario(brandName) {
    // Functionality to created new scenario name suggestion
    if (this.newScenarioData['scenario'] && this.newScenarioData['scenario'].length) {
      let proposedScenarioName = '';
      if(this.scenarioList.length === 1) {
        proposedScenarioName = brandName + '1'
      } else {
          let lastCharacter = isNaN( Number(this.scenarioList.slice().pop().scenarioName.slice(-1))) ? '1' : (Number(this.scenarioList.slice().pop().scenarioName.slice(-1)) + 1).toString() ;
          proposedScenarioName = this.scenarioList.slice().pop().scenarioName.slice(0, -1) + lastCharacter;
      }
      // Populating pop-up for new scenario name during save functionality
      this.scmMmmService.getScenarioName('Scenario', proposedScenarioName, 'Save', 'Cancel').then((scenarioName) => {
        this.scmMmmService.openOverlay();
        const selectedTableRecord = this.simulateListData.slice(0, -2);
        if (scenarioName) {
          // constructing payload for saving scenario
          // keeping current year as 2020 because we have data for 2020 only , needs to be changed once data is available for current year
          const newScenarioObject = {
            brandId: this.selectedBrandId,
            scenarioId: this.selectedScenarioId,
            scenarioType: 'Simulate Custom',
            scenarioName: scenarioName,
            currentYear: 2020, // keeping current year as 2020 because we have data for 2020 only
            currentMonth: new Date().getMonth() + 1,
            simulateType: this.selectedChangeType,
            scenarioInput: <any>[]
          }

          selectedTableRecord.forEach(element => {
            newScenarioObject.scenarioInput.push(
              {
                spendLeverId: element.spendLeverId,
                changeValue: element.changeSpendBy,
                optimizeOutput: {
                  newSpend: element.newSpend,
                  modelOutput: element.simulateOutput,
                }
              }
            )
          })

          // saveSimulateNewScenario
          this.scmMmmService.saveNewScenario([newScenarioObject]).subscribe(success => {
            if (success) {
              this.selectedScenarioId = success.data.scenarioId;
              this.scmMmmService.setPendingStatus('NO');
              this.newSimulateScenarioAdded.emit({ 'selectedBrandId': this.selectedBrandId, 'selectedScenarioId': this.selectedScenarioId });
              this.newScenarioData = [];
              this.scmMmmService.closeModel();
              this.toastr.success("Scenario has been saved successfully!");
            }
          });
        } else if (!scenarioName) {
          this.scmMmmService.closeModel();
          this.toastr.warning("Scenario changes has not been saved!");
        }
      });
    } else {
      this.toastr.error("There is no change in scenario to SAVE");
    }
  }

  // Delete scenario (Only custom scenarios can be deleted)
  /***
   * @desc Checks for the scenario type and deletes Custome Scenario
   * @param {string} - Selected Scenario ID
   * Call service to delete the scenario
   * @returns {Object} Returns API response.
   */
  deleteScenario() {
    if (this.selectedScenarioId === 1) {
      this.toastr.error("Base scenario cannot be deleted!")
    } else {
      const selectedScenarioName = this.scenarioList.filter(e => { return e.scenarioId === this.selectedScenarioId })[0].scenarioName;
      this.confirmationPopupService.confirm('Confirm', 'Are you sure you want to delete scenario <b>' + selectedScenarioName + '</b> ?', '', 'Delete', 'Cancel')
        .then((confirmed) => {
          if (confirmed) {
            this.scmMmmService.deleteScenarion(this.selectedScenarioId).subscribe(response => {
              this.scenarioDeleted.emit({ 'selectedBrandId': this.selectedBrandId });
              this.toastr.success("Scenario deleted successfully!")
            })
          }
        });
    }
  }

  // Handeling validation for change spend by column.
  /***
   * @desc Validating Change Spend By value input
   * Checks if selected change type is % then change spend by should not be less then -100
   * Checks if selected change type is $ then change spend by should not be less then (spen * -1)
   * @returns validation check boolean to implement error class in DOM along with toaster.
   */
  changeSpendByUpdate(event, spend) {
    if (this.selectedChangeType === 'spend_per' && event < -100) {
      this.toastr.error('Change spend by value can not be less then -100%');
      this.invalidInput = true;
    } else if (this.selectedChangeType === 'spend_usd' && event < (spend * -1)) {
      this.toastr.error('Change spend by value can not be less then spend value');
      this.invalidInput = true;
    } else {
      this.invalidInput = false;
    }
  }

  // Export table data to excel.
  /***
   * @desc Handling Export to excel functionality
   * Calles expoertToExcel Service to create Excel file.
   * @returns Exported excel .
   */
  exportSimulateScenarioTable() {
    const selectedScenarioName = this.scenarioList.filter(e => { return e.scenarioId === this.selectedScenarioId })[0].scenarioName;
    this.exportToExcelService.exportTableToExcel('SimulateTable', selectedScenarioName)
  }
}
