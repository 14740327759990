<div class="mt-5 pt-0" style="background: #FAFAFA;">
    <div class="container-fluid">

        

        <div class="row ">
            <div class="col-md-9 mt-2">
                <div class="policy-type">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4 pl-3 mb-0">
                                    <div class="policy-list">
                                        <h5>Policy Type: <span>Home</span></h5>
                                        <div class="perils-list">
                                            <p class="peril accident active">
                                                <img src="assets/images/home-icon.png" class="policy-icon" style="height: 60px;"/>
                                                <!-- <span>Auto</span> -->
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-0">
                                    <div class="policy-details">
                                        <h5>Customer ID <span>
                                            C17453
                                        </span></h5>
                                        <h5>Policy Number<span>POL/2023/HOME/83488</span>  </h5>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-0">
                                    <div class="policy-details status">
                                        <h5>Renewal Status<span>Renewed</span></h5>
                                        <h5>Renewal Date <span>20 Sep 2023</span> </h5>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        <div class="col-md-6">
                            <div class="policy-coverages">
                                <h5>Policy Coverages</h5>
                                <div class="perils-list">
                                    <p class="peril accident active">
                                        <img src="assets/images/windstorm.png" />
                                        <span>Dwelling</span>
                                    </p>
                                    <p class="peril accident active">
                                        <img src="assets/images/fences.PNG" />
                                        <span>Other Structure</span>
                                    </p>
                                    <p class="peril accident active">
                                        <img src="assets/images/peril-theft.png" />
                                        <span>Content</span>
                                    </p>
                                    
                                    <!-- <p class="peril accident active">
                                        <img src="assets/images/fire-lightining.png" />
                                        <span>Fire and lightning</span>
                                    </p> -->
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-md-3 mt-2">
                <div class="policy-rating">
                    <h5>Key Features</h5>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="policy-list">
                                <div class="perils-list pb-0">
                                    <p class="peril accident active">
                                        <img src="assets/images/Key Features.png" />
                                        <!-- <span>Key Features</span> -->
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <span class="badge badge-features">High income group
                            </span>

                            <span class="badge badge-features"> Homeowner
                            </span>

                           


                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="auto-personal-column">
                    <div class="auto-personal-item">
                        <h2 class="groupHeading">Group C</h2>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Head of household age</h4>
                            <div class="metric-val">36-45
                            </div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Type of Property</h4>
                            <div class="metric-val">Single Family
                            </div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Household Income</h4>
                            <div class="metric-val">$120,000-$140,999

                            </div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Household Size</h4>
                            <div class="metric-val">3+ Persons

                            </div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Home ownership</h4>
                            <div class="metric-val">Homeowner</div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Age of
                                Children
                            </h4>
                            <div class="metric-val">7-9</div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">Renewal Probability</h4>
                            <div class="metric-val">65%
                            </div>
                        </div>
                    </div>

                    <div class="auto-personal-item">
                        <div class="metric">
                            <h4 class="metric-label">CSAT</h4>
                            <div class="metric-val">-</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row mt-2">

            <div class="col-md-3 pr-3">
                <div class="metric features chat-box">
                    <h4 class="groupHeading"><span><img src="assets/images/chat-bot.png" /></span>CSR in Call

                    </h4>
                    <ul class="reasons chat-content">
                        <li class="question"> What are the Factors Contributing to Eligibility for Discounts?</li>
                        <li class="answer">Eligibility for discounts is influenced by various factors such as claims history, home safety features and bundling policies
                        </li>
                        <li class="question">Is the Policy Holder eligible you for any discounts?</li>
                        <li class="answer">Policy Holder is eligible for claims-free discount and  qualify for a security system discount
                        </li>
                        

                    </ul>
                    <ul class="send-message">
                        <li >
                            <input type="text" placeholder="Start Typing Here..." class="form-control">
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-6">
                        <div class="metric features">
                            <h4 class="groupHeading"><span><img src="assets/images/Billing History.png" /></span>Billing
                                History/Policy/Claim Experience
        
                            </h4>
                            <ul class="reasons">
                                <li>No Claim in the past three years.</li>
                                <li>Change in Security Plan</li>
                            </ul>
                        </div>
                    </div>
        
        
                    <div class="col-md-6 pr-3">
                        <div class="metric features">
                            <h4 class="groupHeading"><span><img src="assets/images/Segment Recommendation.png" /></span>Segment
                                Recommendations </h4>
                            <ul class="reasons">
                                <li>Clarity in communication regarding discounts and eligibility criteria

                                </li>
                            </ul>
                        </div>
                    </div>
        
        
                    <div class="col-md-4">
                        <div class="metric features">
                            <h4 class="groupHeading"><span><img src="assets/images/Driver Profile.png" /></span>Property risk Factors

                            </h4>
                        

                            <ul class="reasons">
                                <li>Proximity to Fire Stations</li>
                                <li>Age of the property   - 20 years</li>
                                <li>Estimated house value - $50,000–$74,999 </li>

                               
                            </ul>
                        </div>
                    </div>
        
                    <div class="col-md-4">
                        <div class="metric features">
                            <h4 class="groupHeading"><span><img src="assets/images/Cross-sell.png" /></span>Cross-sell/Upsell
                                Recommendations
        
                            </h4>
                            <ul class="reasons">
                                

                                <li> Bundling homeowner's insurance with auto insurance .
                                </li>
                            </ul>
                        </div>
                    </div>
        
                    <div class="col-md-4 pr-3">
                        <div class="metric features">
                            <h4 class="groupHeading"><span><img src="assets/images/Customer Feedback.png" /></span>Customer
                                Feedback
                            </h4>
                            <ul class="reasons">
                                <li>NA

                                </li>
                            </ul>
                        </div>
                    </div>
        
                </div>
            </div>

          
        </div>


    </div>
</div>