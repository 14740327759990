import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Highcharts from 'highcharts';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { ExportToExcelService } from 'src/app/shared/services/export-to-excel.service';
import { ScmMmmStaticService } from '../../scm-mmm-static.service';

@Component({
  selector: 'app-scm-optimize-static',
  templateUrl: './scm-optimize-static.component.html',
  styleUrls: ['./scm-optimize-static.component.scss']
})
export class ScmOptimizeStaticComponent implements OnInit {

  @Input() selectedScenario: any;
  @Input() selectedBrand: any;
  @Input() scenarioList: any;
  @Input() saveOptimizeEvents: any;
  @Input() exportOptimizeEvents: any;
  @Input() evaluateOptimizeEvents: any;
  @Input() deleteOptimizeEvents: any;
  @Input() changeScenerioOptimizeEvents: any;
  @Input() selectedTab: any;
  Highcharts: typeof Highcharts = Highcharts;
  localStorage = localStorage;
  @Output() newScenarioAdded = new EventEmitter<any>();
  public selectedSenarioData: any;
  disableEditing: boolean = false;
  updateFlag: boolean = false;
  isFirstLoad: boolean = true;
  changeInBudget: any;
  selectedOrginalData: any;
  public incrementalSaleChartTemplate: any;
  public mediaResponseChartTemplate: any;
  public roiMacChartTemplate: any;
  public shareSpendChartTemplate: any;
  public optimizeChartData: any;
  public mediaResponseChartData: any;
  public newScenarioData: Array<any> = [];
  public saveEventsSubscription: Subscription;
  public deleteEventsSubscription: Subscription;
  public evaluateEventsSubscription: Subscription;
  public exportToExcelEventsSubscription: Subscription;


  mediaResponseChartOptionConfig = {
    chartType: 'line',
    marker: {
      symbol: 'circle',
      fillColor: '#FFFFFF',
      lineWidth: 2,
      lineColor: ''
    }
  }
  roiMacOptionConfig = {
    chartType: 'column',
    marker: {}
  }
  mediaResponseChartColor = ['#fcd203']
  multySeriesColors = ['#188fff', '#ff333a', '#00C073', '#e447ea', '#33ddff', '#ff9c33', '#461a63']
  selectedSenarioTableRecored: Array<any> = [];
  selectedSenarioTableFooter: Array<any> = [];
  incrementalSaleChartData: any;
  roiMacChartData: any;
  shareOfSpendData: any;

  constructor(public scmMmmStaticService: ScmMmmStaticService, public toastr: ToastrService,
              private ngxLoader: NgxUiLoaderService,
              private confirmationPopupService: ConfirmationPopupService,
              private exportToExcelService: ExportToExcelService) { }

  ngOnInit(): void {
    // remove previous saved data from local storage
    this.localStorage.removeItem('optimizeTableData');
    this.scmMmmStaticService.setDataForOptimizeScreen();
    // Save event Subscription
    this.saveEventsSubscription = this.saveOptimizeEvents.subscribe(() => {
      this.saveScenario()
    });
    // Export to excel event subcription
    this.exportToExcelEventsSubscription = this.exportOptimizeEvents.subscribe(() => {
      this.exportScenarioToExcel()
    });
    // Delete event subscription
    this.deleteEventsSubscription = this.deleteOptimizeEvents.subscribe(() => {
      this.deleteScenario()
    });
    // Optimize event subscription
    this.evaluateEventsSubscription = this.evaluateOptimizeEvents.subscribe(data => {
      if (!!data) {
        this.changeInBudget = data;
        if(this.changeInBudget === '0'){
          this.selectedScenario = 1;
          this.getChartTemplates()
        } else if(Number(this.changeInBudget) < -100) {
          this.toastr.error('Budget change can not be less then -100%!');
        }
        else if (this.changeInBudget === '+10' || this.changeInBudget === '10' || this.changeInBudget === '-10' || this.changeInBudget === '+20' || this.changeInBudget === '20') {
          this.updateChangedBudget(this.changeInBudget, this.selectedBrand);
        } else if (this.changeInBudget === 'oprimalRangeChange' && this.selectedBrand === 2) {
          this.updateChangedOptimalRange();
        }
      }
    });
  }

  ngOnDestroy() {
    this.saveEventsSubscription.unsubscribe();
    this.deleteEventsSubscription.unsubscribe();
    this.evaluateEventsSubscription.unsubscribe();
    this.exportToExcelEventsSubscription.unsubscribe();
  }

  ngOnChanges(): void {
    this.scmMmmStaticService.getGoodToGo().subscribe(data => {
      if(data === 'YES') {
        if (this.selectedTab === 'scm-optimize') {
              this.getChartTemplates();
              if (this.selectedBrand === 2) {
                this.disableEditing = true;
              } else {
                this.disableEditing = false;
              }
        }
      }
    }) 
  }
  /**
   * This methoda will fetch and filter the updated table and chart data distribute updated data to the view
   */
  /***
   * @desc Function to reflect change for optimal range
   * Call another method to get data with respect to changed optimal range from localstorage for getting Optimize Table and Charts Data
   * @returns {Objects} Returns chart and table data set chart data into chart template and show chart and table
   *
   */
  updateChangedOptimalRange() {
    // fetch, filter and update the data as per input 
    var changes = this.selectedSenarioTableRecored.filter((item) => {
      return item.spendLever === 'Digital';
    });

    if (changes[0].min === '-30' && changes[0].max === '30') {
      this.scmMmmStaticService.getChartTemplateJSON().subscribe(chartTemplates => {
        this.incrementalSaleChartTemplate = chartTemplates[0];
        this.mediaResponseChartTemplate = chartTemplates[1];
        this.roiMacChartTemplate = chartTemplates[2];
        this.shareSpendChartTemplate = chartTemplates[3];
        this.scmMmmStaticService.getOptimalRangeChange().subscribe(data => {
          this.newScenarioData = data.scenario;
          this.selectedSenarioData = data.scenario[0];
          this.selectedSenarioTableRecored = this.selectedSenarioData.scenarioData;
          this.mediaResponseChartData = this.selectedSenarioData.mediaResponseCurve;
          this.incrementalSaleChartTemplate.series[0].data = this.selectedSenarioData.chartData[0].data;
          this.roiMacChartTemplate.series = [this.selectedSenarioData.chartData[1]];
          this.shareSpendChartTemplate.series = this.selectedSenarioData.chartData[2].data;
          this.scmMmmStaticService.setPendingStatus('YES');
        });
      });
    } else {
      this.toastr.error('Please enter valid scenario!');
    }
  }

  /***
   * @desc Function to Get Optimize Table and Chart using local storage for updated budget
   * Get data from localstorage for getting Optimize Table and Charts Data
   * @returns [object] Returns chart and table data set chart data into chart template and show chart and table
   *
   */
  updateChangedBudget(change, brand) {
    this.scmMmmStaticService.getChartTemplateJSON().subscribe(chartTemplates => {
      this.incrementalSaleChartTemplate = chartTemplates[0];
      this.mediaResponseChartTemplate = chartTemplates[1];
      this.roiMacChartTemplate = chartTemplates[2];
      this.shareSpendChartTemplate = chartTemplates[3];
      this.scmMmmStaticService.getChangedBudgetData(change, brand).subscribe((changedData) => {
        if (!changedData) {
          this.toastr.error("Please enter valid budget change for this brand");
        } else {
          this.ngxLoader.start();
          this.selectedOrginalData = this.selectedSenarioData;
          this.selectedSenarioData.scenarioData.map((element) => {
            var result = changedData.scenario[0].optimizedOutput.filter(item => item.spendLever == element.spendLever);
            if (result.length > 0) {
              element.changeInIncrementalSale = result[0].changeInIncrementalSale
              element.changeInSpend = result[0].changeInSpend
              element.max = result[0].max
              element.maxSpend = result[0].maxSpend
              element.min = result[0].min
              element.minSpend = result[0].minSpend
              element.optimizedMargin = result[0].optimizedMargin
              element.optimizedModelOutput = result[0].optimizedModelOutput
              element.optimizedNewSpend = result[0].optimizedNewSpend
              element.optimizedProfit = result[0].optimizedProfit
              element.optimizedRoiCalculation = result[0].optimizedRoiCalculation
              this.scmMmmStaticService.setPendingStatus('YES');
            }
            return element
          });
          this.selectedSenarioData.mediaResponseCurve = changedData.mediaResponseCurve;
          this.selectedSenarioData.chartData = changedData.chartData;
          this.selectedSenarioTableRecored = this.selectedSenarioData.scenarioData;
          this.mediaResponseChartData = this.selectedSenarioData.mediaResponseCurve;
          this.incrementalSaleChartTemplate.series[0].data = this.selectedSenarioData.chartData[0].data;
          this.roiMacChartTemplate.series = [this.selectedSenarioData.chartData[1]];
          this.shareSpendChartTemplate.series = this.selectedSenarioData.chartData[2].data;
          this.ngxLoader.stop();
          this.newScenarioData = [this.selectedSenarioData];
          this.updateFlag = true
        }
      });
    });
  }

 /***
   * @desc Function to Get Optimize Table and Chart using local storage
   * Get data from localstorage for getting Optimize Table and Charts Data
   * @returns {Objects} Returns chart and table data set chart data into chart template and show chart and table
   *
   */
  getChartTemplates() {
    this.ngxLoader.start();
    this.scmMmmStaticService.getChartTemplateJSON().subscribe(chartTemplates => {
      this.incrementalSaleChartTemplate = chartTemplates[0];
      this.mediaResponseChartTemplate = chartTemplates[1];
      this.roiMacChartTemplate = chartTemplates[2];
      this.shareSpendChartTemplate = chartTemplates[3];
      let response = JSON.parse(this.localStorage.getItem('optimizeTableData') || '{}')
      this.selectedSenarioData = response.data.filter(element => {
        return element.brandId === this.selectedBrand;
      })[0].scenario.filter(item => {
        return item.scenarioId === this.selectedScenario;
      })[0];
      this.selectedSenarioTableRecored = this.selectedSenarioData.scenarioData
      this.mediaResponseChartTemplate.series = this.selectedSenarioData.mediaResponseCurve.data;
      this.incrementalSaleChartTemplate.series[0].data = this.selectedSenarioData.chartData[0].data;
      this.roiMacChartTemplate.series = [this.selectedSenarioData.chartData[1]];
      this.shareSpendChartTemplate.series = this.selectedSenarioData.chartData[2].data;
      this.ngxLoader.stop();
    });
  }


  minValueUpdate(min, i) {
    this.selectedSenarioTableRecored[i].min = min;
  }
  maxValueUpdate(max, i) {
    this.selectedSenarioTableRecored[i].max = max;
  }

  /***
   * @desc Function to Save Scenario
   * @param {Array} API Payload 
   * Opens scenario name popup and gets the scenario name to construct final API payload Array to save scenario
   * @returns {Object} Returns API response on success.
  */
  saveScenario() {
    if (this.newScenarioData.length) {
      let proposedScenarioName = '';
      if(isNaN(this.scenarioList.slice().pop().scenarioName.slice(-1))) {
        if(this.scenarioList.slice().pop().scenarioName.slice(-4) === '_New') {
          proposedScenarioName = this.scenarioList.slice().pop().scenarioName + '1'
        } else {
          proposedScenarioName = this.scenarioList.slice().pop().scenarioName + '_New'
        }
      } else {
        let lastCharacter = Number(this.scenarioList.slice().pop().scenarioName.slice(-1)) + 1;
        proposedScenarioName = this.scenarioList.slice().pop().scenarioName.slice(0,-1) + lastCharacter;
      }
      this.scmMmmStaticService.getScenarioName('Scenario', proposedScenarioName, 'Save', 'Cancel').then((scenarioName) => {
        if (scenarioName) {
          this.scmMmmStaticService.saveOptimizedBudgetScenario(this.selectedBrand, this.selectedScenario, this.newScenarioData[0], scenarioName).subscribe(success => {
            if (success) {
              this.selectedScenario = success.newSenarioId;
              this.scmMmmStaticService.setPendingStatus('NO');
              this.newScenarioAdded.emit({ 'selectedBrand': this.selectedBrand, 'selectedScenario': this.selectedScenario });
              this.newScenarioData = [];
               this.selectedBrand = 1;
               this.selectedScenario = 1;
              this.toastr.success("New scenario saved successfully !");
            }
          });
        } else if (!scenarioName) {
          this.newScenarioData = [];
          this.scmMmmStaticService.setPendingStatus('NO');
          this.toastr.info("New Optimization has been discarded!");
        }
      });
    } else {
      this.toastr.error("No change in scenario to save");
    }
  }

 /**
   * @desc Handles chart pannel expend anc collapse
   * @param event - Mouse Event
   * Toggles class 'active' in the class list of event.target(DOM Element)
   * @returns DOM element with updated max-hight and scroll-height .
   */
  toggleAccordian(event) {
    var element = event.target;
    element.classList.toggle("active");
    var panel = element.nextElementSibling;
    if (panel.style.maxHeight === '') {
      panel.style.maxHeight = '0px';
    } else if (panel.style.maxHeight === '0px') {
      panel.style.maxHeight = panel.scrollHeight + "px";
    } else if (panel.style.maxHeight !== '0px') {
      panel.style.maxHeight = '0px';
    }
  }

 

   // Delete scenario (Only custom scenarios can be deleted)
  /***
   * @desc Checks for the scenario type and deletes Custome Scenario
   * @param {string} - Selected Scenario ID
   * Call service to delete the scenario
   * @returns {Object} Returns API response.
   */
  deleteScenario() {
    if(this.selectedScenario === 1) {
      this.toastr.error("Base scenario cannot be deleted!")
    } else {
      const selectedScenarioName = this.scenarioList.filter(e => { return e.scenarioId === this.selectedScenario})[0].scenarioName;
      this.confirmationPopupService.confirm('Confirm', 'Are you sure you want to delete scenario ' + selectedScenarioName  + '?', '', 'Delete', 'Cancel')
        .then((confirmed) => {
        if (confirmed) {
          this.scmMmmStaticService.deleteOptimizeScenario(this.selectedBrand, this.selectedScenario).subscribe(data => {
            this.newScenarioAdded.emit({ 'selectedBrand': this.selectedBrand, 'selectedScenario': 1 });
            this.toastr.success("Scenario deleted successfully!")
          })
        } else if (!confirmed) {

        }
      });
    }
  }

 // Export table data to excel.
  /***
   * @desc Handling Export to excel functionality
   * Calles expoertToExcel Service to create Excel file.
   * @returns Exported excel .
   */
  exportScenarioToExcel() {
    const selectedScenarioName = this.scenarioList.filter(e => { return e.scenarioId === this.selectedScenario})[0].scenarioName;
    this.exportToExcelService.exportTableToExcel('OptmizeTable', selectedScenarioName)
  }
}
