<div class="confirmation-modal-header">
    <h3 class="confirmation-modal-title">Select Model</h3>
</div>

<div class="modal-body">
    <div class="mb-2 row" *ngIf="loadEverything">
        <label for="modelTechSelect" class="col-sm-5">Select Model Technique
            <span class="field-mandatory">*</span>
        </label>
        <select class="form-control form-control-sm  w-50 col-sm-6" id="modelTechSelect" tabindex="-98"
            [(ngModel)]="selectedModel" [ngModelOptions]="{standalone: true}"
            (change)="selectAnotherModel(selectedModel)">
            <option *ngFor="let item of modelData" [ngValue]="item.modelId">{{item?.model}}</option>
        </select>
    </div>
    <div class="glmV glmV1" *ngIf="loadEverything">
        <ng-container *ngFor="let item of selectedModelTypeAndVersion">
            <label class="d-block">{{item?.modelType}} - 
                
                <span class="text-green"> Version {{item?.version}}</span>
            </label>
        </ng-container>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-light btn-sm addcustom" (click)="decline()">Cancel</button>
    <button type="button" class="btn btn-primary btn-sm" (click)="accept()">SUBMIT</button>
</div>