<footer class="footer mt-auto p-2">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <p class="mb-0">{{getAppVersion()}}<span class="ml-2">Copyright © 2024 WNS. All rights reserved.</span></p>
                
            </div>
            <div class="col text-right">
                <img src="../../../assets/images/wns-logo.png" alt="WNS">
            </div>
        </div>
    </div>
</footer>