import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserAddEditService {
  constructor(private http: HttpClient) { }
  /***
   * @desc PUT - Function to call Add User API
   * @param {Object} Module userDatanew
   * @returns {Object} Returns API Response
   * 
   */
  createUser(userDatanew): Observable<any> {
    userDatanew.userImage = userDatanew.imgsrc;
    userDatanew.status = 1;
    userDatanew.defaultRoleId = userDatanew.default_role;
    delete userDatanew["photo"];
    delete userDatanew["role"];
    delete userDatanew["imgsrc"];
    return this.http.put('user/', userDatanew);
  }
  /***
   * @desc PUT - Function to call Add User Role API
   * @param {Object} Module userRoleData
   * @returns {Object} Returns API Response
   * 
   */
  AddUserRole(userRoleData): Observable<any> {
    return this.http.put('rbac/userRoles/', userRoleData);
  }
  /***
   * @desc GET - Function to call fetch roles API
   * @returns {Object} Returns API Response
   * 
   */
  getRoles(): Observable<any[]> {
    return this.http.get<any>('rbac/roles/');
  }
  /***
   * @desc POST - Function to edit User and its roles API
   * @returns {Object} Returns API Response
   * 
   */
  editUser(userData): Observable<any> {
    return this.http.post('updateUserRole/', userData); 
  }
  /***
   * @desc POST - Function to update user details without modifying its roles
   * @returns {Object} Returns API Response
   * 
   */
  editUserStatus(userData): Observable<any> {
    return this.http.post('user/', JSON.stringify(userData));
  }
}
