import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customdateformat'
})
export class CustomdateformatPipe implements PipeTransform {
  dateValue: string
  year:any;
  month:any;

  /***
   * @desc Pipe to transform date to Month-Year Format
   * @param any (Date Value)
   * @returns {String} Returns date in mm-YY format
   *
   */    
  
  transform(value: any, ...args): unknown {
    this.dateValue  =value;
    this.year = this.dateValue.toString().substring(0, 4);
    this.month = this.dateValue.toString().substring(4, 7);
    const date = new Date();
    date.setMonth(this.month-1);
    this.month = date.toLocaleString('en-US', { month: 'short' });
    return this.month+'-'+this.year;
  }

}
