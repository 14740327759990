import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { element } from 'protractor';
import { PricingModelAnalysisService } from '../pricing-model-analysis.service';

@Component({
  selector: 'app-pricing-model-analysis-comparision',
  templateUrl: './pricing-model-analysis-comparision.component.html',
  styleUrls: ['./pricing-model-analysis-comparision.component.scss']
})
export class PricingModelAnalysisComparisionComponent implements OnInit {
  @Input() chartFilters: any;
  public comparisionData: any;
  comparisonChartFilters: any;
  public lobProjectId: any;
  comparisionList: any;
  comparisionListData: any;
  localStorage = localStorage;
  public modelTechnique = '1,2';
  public modelType = 1;
  public viewName = 'comparision';
  public modelTechniqueCheckbox = [1, 2];
  constructor(private pricingModelAnalysisService: PricingModelAnalysisService, private ngxLoader: NgxUiLoaderService, private toastr: ToastrService) { }

  ngOnInit(): void {
    Promise.all([this.localStorage.getItem('lobProjectId')]).then(values => {
      this.lobProjectId = values[0];
      this.getlobModelAnalysisComparision(this.lobProjectId, this.modelType, this.modelTechnique);
    })
  }

  ngOnChange() {
    const temp = this.chartFilters[this.chartFilters.findIndex((obj => obj.filterType === 'modelTechniques'))].data.forEach(e => { e.isSelected = true });
    this.chartFilters = temp;
  }

  // Fetching Model Analysis Comparision data
  getlobModelAnalysisComparision(lobProjectId, modelType, modelTechnique) {
    this.ngxLoader.start();
    this.pricingModelAnalysisService.getlobModelAnalysisComparision(lobProjectId, modelType, modelTechnique).subscribe((comparisionObj) => {
      if (comparisionObj) {
        this.comparisionList = comparisionObj;
        this.comparisionListData = this.comparisionList.data;
        this.comparisionData = this.comparisionListData;
        this.ngxLoader.stop();
      } else { this.comparisionData = []; this.ngxLoader.stop(); }
    });
  }

  // Update chart data using filter
  updateComparisionData(checkboxData: any, filterType: any) {
    if (filterType === 'modelTechniques') {
      this.chartFilters[this.chartFilters.findIndex((obj => obj.filterType === 'modelTechniques'))].data.forEach(e => { if (checkboxData.id === e.id) { e.isSelected = checkboxData.isSelected } });
      if (this.modelTechniqueCheckbox.includes(checkboxData.id) && !checkboxData.isSelected) {
        this.modelTechniqueCheckbox = this.modelTechniqueCheckbox.filter(e => e !== checkboxData.id);
      }
      else if (!this.modelTechniqueCheckbox.includes(checkboxData.id) && checkboxData.isSelected) {
        this.modelTechniqueCheckbox.push(checkboxData.id);
        this.modelTechniqueCheckbox = this.modelTechniqueCheckbox.sort(function (a, b) { return a - b; });
      }
      if (!this.modelTechniqueCheckbox.length) {
        this.toastr.error('Select at least one Model Technique');
        this.comparisionData = [];
      } else {
        this.modelTechnique = this.modelTechniqueCheckbox.join(',');
        this.getlobModelAnalysisComparision(this.lobProjectId, this.modelType, this.modelTechnique);
      }
    } else if (filterType === 'modelTypes') {
      if (!this.modelTechniqueCheckbox.length) {
        this.toastr.error('Select at least one Model Technique');
        this.comparisionData = [];
      } else {
        this.modelType = checkboxData.id;
        this.getlobModelAnalysisComparision(this.lobProjectId, this.modelType, this.modelTechnique);
      }
    }
  }
}
